import throttle from 'lodash.throttle';

export interface IDimension {
  // 滚动位置：需要外界调用 updateOffset 后方可确保获取到的值为最新
  pageOffsetY: number;
  pageOffsetX: number;
  // 窗口尺寸：外界获取到的值一定为最新
  innerWidth: number;
  innerHeight: number;
  isOffsetValid: boolean;
  isSizeValid: boolean;
}

/**
 * 视口状态
 * 包括窗口大小和滚动位置
 */
export default class Dimension implements IDimension {
  private dimensionInfo: IDimension;

  private scrollDelta: number = 0;

  private fastScrollThreshold = 0;

  public isFastScroll = false;

  private scrollingTimeout;

  constructor() {
    this.dimensionInfo = {
      pageOffsetY: window.scrollY,
      pageOffsetX: window.scrollX,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      isOffsetValid: true,
      isSizeValid: true,
    };
    this.fastScrollThreshold = 300;
    this.addListener();
  }

  public get isSizeValid(): boolean {
    return this.dimensionInfo.isSizeValid;
  }

  public get isOffsetValid(): boolean {
    return this.dimensionInfo.isOffsetValid;
  }

  public get pageOffsetX(): number {
    if (!this.dimensionInfo.isOffsetValid) {
      this.dimensionInfo.pageOffsetX = window.scrollX;
      this.dimensionInfo.isOffsetValid = true;
    }
    return this.dimensionInfo.pageOffsetX;
  }

  public get pageOffsetY(): number {
    if (!this.dimensionInfo.isOffsetValid) {
      const currentOffsetY = window.scrollY;
      this.scrollDelta = Math.abs(currentOffsetY - this.dimensionInfo.pageOffsetY);
      this.dimensionInfo.pageOffsetY = currentOffsetY;
      this.dimensionInfo.isOffsetValid = true;
      if (this.scrollDelta > this.fastScrollThreshold) {
        this.isFastScroll = true;
      } else {
        this.isFastScroll = false;
      }
    }
    return this.dimensionInfo.pageOffsetY;
  }

  public get innerWidth(): number {
    if (!this.dimensionInfo.isSizeValid) {
      this.dimensionInfo.isSizeValid = true;
      this.dimensionInfo.innerWidth = window.innerWidth;
    }
    return this.dimensionInfo.innerWidth;
  }

  public get innerHeight(): number {
    if (!this.dimensionInfo.isSizeValid) {
      this.dimensionInfo.isSizeValid = true;
      this.dimensionInfo.innerHeight = window.innerHeight;
    }
    return this.dimensionInfo.innerHeight;
  }

  public set innerWidth(innerWidth: number) {
    this.dimensionInfo.isSizeValid = true;
    this.dimensionInfo.innerWidth = innerWidth;
  }

  public set innerHeight(innerHeight: number) {
    this.dimensionInfo.isSizeValid = true;
    this.dimensionInfo.innerHeight = innerHeight;
  }

  /**
   * 更新滚动位置
   * @return 滚动位置是否发生了变化
   */
  public updateOffset() {
    this.dimensionInfo.pageOffsetY = window.scrollY;
    this.dimensionInfo.pageOffsetX = window.scrollX;
    this.dimensionInfo.isOffsetValid = true;
  }

  private addListener() {
    // TODO of leonidas 现阶段靠 react hook 同步窗口尺寸, 后续需要统一
    window.addEventListener('resize', this.onResize.bind(this));
    window.addEventListener('scroll', throttle(this.onScroll.bind(this), 100));
  }

  private onResize() {
    // 标记窗口尺寸信息已失效
    this.dimensionInfo.isSizeValid = false;
  }

  private onScroll() {
    // 标记滚动位置信息已失效
    this.dimensionInfo.isOffsetValid = false;
    clearTimeout(this.scrollingTimeout);
    this.scrollingTimeout = setTimeout(() => {
      this.dimensionInfo.isOffsetValid = true;
    }, 200);
  }
}
