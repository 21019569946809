import React from "react";
import SbEditable from "storyblok-react";
import MarkDown from '../../markdown'
import LazyLoad from "react-lazyload"
import "./style.scss";

const iconDescSection = ({content, maxWidth }) => {
  return (
    <SbEditable content={content}>
      {
        content.length > 0 ? (
          <div className="desc-section-list" style={maxWidth ? { maxWidth } : {}}>
            {
              content.map((item, index) => (
                <div className="desc-section-item" key={index}>
                  <div className="title-icon">
                    <LazyLoad offset={1000} once>
                      <img src={item.icon} />
                    </LazyLoad>
                  </div>
                  <div className="desc" style={{ color: item.descColor && item.descColor.color || '#000' }} dangerouslySetInnerHTML={  MarkDown(item.desc) }></div>
                </div>
              ))
            }
          </div>
        ) : null
      }
    </SbEditable>
  )
}

export default iconDescSection;
