/* eslint-disable */
import React from 'react';
import SbEditable from "storyblok-react"
import { getAsset, MOBILE_MAX_WIDTH } from '../../../../utils/helper';
import VideoPlayer from '../../video';
import LazyWrapper from '../../utils/LazyWrapperScroll';
import Card from './card';

import './style.scss';
import { StoryblokAsset } from './card/type';


export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export enum VerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align'
}

export interface Props {
  id?: string;
  gaSend: () => {};
  videoAllDowngrade: boolean;
  videoDowngradeInObsoleteDevice?: boolean;
  videoDowngradeInMini?: boolean;
  videoPerformanceMode?: boolean;
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;
  componentVerticalAlign: VerticalAlign;
  componentMinHeight: string;
  pureBackgroundColor: string;
  backgroundGradient: string;
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  anchorId?: string; // 锚点

  // 背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundImageWidth?: string;
  backgroundImageHeight?: string;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundFixed?: BackgroundFixed;
  backgroundParallax?: boolean;
  // 背景视频
  hasBackgroundVideo: boolean;
  isFirstScreen: boolean;
  backgroundVideoWidth?: string;
  backgroundVideoHeight?: string;
  enablebackgroundVideoImage?: boolean;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideo?: StoryblokAsset;
  backgroundVideoPoster?: StoryblokAsset;
  backgroundVideoImage?: StoryblokAsset;
  backgroundVideoMaskColor?: string;
  backgroundVideoMaskOpacity?: string;
  // 卡片
  cards?: Array<any>;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export default class HeroVertical extends React.Component<Props> {
  public static defaultProps = {
    videoAllDowngrade: false,
    componentVerticalAlign: VerticalAlign['center-align'],
    componentMinHeight: '',
    pureBackgroundColor: '',
    backgroundGradient: '',
    paddingTop: '64px',
    paddingBottom: '64px',
    paddingLeft: '24px',
    paddingRight: '24px',
    fadeTransiation: FadeTransiation.none,
    mediaTransiation: FadeTransiation.none,

    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundImageWidth: '',
    backgroundImageHeight: '',
    backgroundPosition: 'center',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,

    hasBackgroundVideo: false,
    isFirstScreen: false,
    backgroundVideoWidth: '',
    backgroundVideoHeight: '',
    enablebackgroundVideoImage: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: true,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoImage: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',

    cards: [Card.defaultProps],
  };

  render(): JSX.Element {
    const { props } = this;
    const aosFade = props.fadeTransiation;
    const mediaFade = props.mediaTransiation;
    const isUC = typeof window !== 'undefined' && window.navigator.userAgent.match(/UCBrowser/ig);
    const verticalAlignStyleName = `mcardv-e-vertical-${props.componentVerticalAlign}`;
    const { visibleByDefault, gaSend } = props;
    let backgroundImage;
    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundAttachment?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
    } = {
      backgroundColor: props.pureBackgroundColor,
    };

    if (props.hasBackgroundImage) {
      backgroundImage = getAsset(props.backgroundImage);
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;
      componentStyle.backgroundAttachment = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';

      if (props.backgroundGradient && !isUC) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    const componentWrapperStyle: {
      minHeight: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
    } = {
      minHeight: props.componentMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
    };

    const renderBackgroundVideo = (): JSX.Element => {
      const enablebackgroundVideoImage = props.enablebackgroundVideoImage && getAsset(props.backgroundVideoImage);
      const {
        backgroundVideoHeight,
        backgroundVideoWidth,
        isFirstScreen,
        videoDowngradeInObsoleteDevice,
        videoDowngradeInMini,
        videoPerformanceMode,
      } = props;

      const needCalcFlag = isFirstScreen;
      const vRatio = +backgroundVideoHeight / +backgroundVideoWidth;
      const downgradeDom = (
        <div className="mcardv-e-component-downgrade-image" style={{ backgroundImage: `url(${enablebackgroundVideoImage ? getAsset(props.backgroundVideoImage) : getAsset(props.backgroundVideoPoster)})` }} />
      );
      const videoPosterAttr = {};
      videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster'] = getAsset(props.backgroundVideoPoster);
      const style: any = {
        position: 'relative',
        width: '100%',
        height: '100%',
      };
      if (isFirstScreen) {
        style.backgroundSize = 'cover';
        style.backgroundPosition = 'center';
        style['--bgPoster'] = `url(${getAsset(props.backgroundVideoPoster)})`;
        style['--bgDowngrade'] = `url(${enablebackgroundVideoImage ? getAsset(props.backgroundVideoImage) : getAsset(props.backgroundVideoPoster)})`;
      }
      return props.videoAllDowngrade ? downgradeDom : (
        <div className="mcardv-e-component-background-video" style={{
          height: needCalcFlag && `min(${vRatio * 100}vw, ${vRatio * MOBILE_MAX_WIDTH}px)`,
        }}>
          <VideoPlayer
            visiblePlay={props.backgroundVideoVisiblePlay}
            replayWhenRevisible={props.backgroundVideoVisiblePlay}
            downgradeDom={downgradeDom}
            lazyLoad
            lazyLoadThreshold={1200}
            style={style}
            isFirstScreen={isFirstScreen}
            videoDowngradeInObsoleteDevice={videoDowngradeInObsoleteDevice}
            videoDowngradeInMini={videoDowngradeInMini}
            videoPerformanceMode={videoPerformanceMode}
          >
            <video style={{ 
                opacity: enablebackgroundVideoImage ? 0 : 1,
                width: needCalcFlag && `min(100vw, ${MOBILE_MAX_WIDTH}px)`,
                height: needCalcFlag && `min(${vRatio * 100}vw, ${vRatio * MOBILE_MAX_WIDTH}px)`,
              }} muted className="bgvideo" {...videoPosterAttr} loop={props.backgroundVideoLoop}>
              {getAsset(props.backgroundVideo) && <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.backgroundVideo)} />}
            </video>
          </VideoPlayer>

          <div className="mcardv-e-video-mask" style={{ backgroundColor: props.backgroundVideoMaskColor, opacity: props.backgroundVideoMaskOpacity }}/>

        </div>
      );
    };


    const cards = props.cards && props.cards.length > 0
      ? (
        <div>
          {
          props.cards.map((card, index) => (
          <SbEditable content={card} key={card && card._uid || ''}>
            <Card
              key={index}
              visibleByDefault={visibleByDefault}
              gaSend={gaSend}
              {...card}
              videoAllDowngrade={props.videoAllDowngrade}
              aosFade={aosFade}
              mediaFade={mediaFade}
              index={index}
              id={props.id}
              videoDowngradeInObsoleteDevice={props.videoDowngradeInObsoleteDevice}
              videoDowngradeInMini={props.videoDowngradeInMini}
              videoPerformanceMode={props.videoPerformanceMode}
            />
          </SbEditable>
          ))
        }
        </div>
      ) : null;

    const backgroundVideo = props.hasBackgroundVideo ? renderBackgroundVideo() : null;

    const child = [
      backgroundVideo,
      <div id={props.anchorId || ''} className={`mcardv-e-hero-vertical-wrapper ${verticalAlignStyleName}`} style={componentWrapperStyle}>
        <div className="mcardv-e-hero-vertical-container">
          {cards}
        </div>
      </div>,
    ];
    return (
      <LazyWrapper
        visibleByDefault={visibleByDefault}
        parallax={props.hasBackgroundImage && props.backgroundParallax}
        type="bg"
        src={backgroundImage}
        bgWidth={props.backgroundImageWidth}
        bgHeight={props.backgroundImageHeight}
        className="atom-design-v1 mcardv-e-hero-vertical-component"
        style={componentStyle}
        tagType='div'
      >
        {child}
      </LazyWrapper>
    );
  }
}
