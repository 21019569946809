import React from "react";
import SbEditable from "storyblok-react"
const descItem = props => {
 const { content } = props;
return (
    <SbEditable content={content}>
        <div>
            { content.title &&
                <h3 className="infomation-modal-subtitle" dangerouslySetInnerHTML={{ __html:content.title}}></h3>
            }
            <p className="infomation-modal-desc" dangerouslySetInnerHTML={{ __html:content.desc}}></p>
        </div>
    </SbEditable> )
}
export default descItem