import React, { Component } from "react";
import SbEditable from "storyblok-react"
import Slider from "react-slick";
import SlicerItem from "./slicerItem";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "./style.css"
import { isArray } from "lodash";
import { hasWindow } from '../../../utils/helper';

export default class SimpleSlider extends Component {
  constructor(props){
    super(props)

  }
  render() {
    const { blok,lang } = this.props;
    const { PauseWhenHoveSlide, isLoop, autoplaySpeed, speed } = blok;
    const settings = {
      dots: true, 
      adaptiveHeight: false,
      infinite: true,  
      speed:Number(speed) || 500, 
      slidesToShow: 1, 
      slidesToScroll: 1, 
      arrows:false, 
      autoplaySpeed:Number(autoplaySpeed) || 3000, 
      autoplay:isLoop || false, 
      pauseOnDotsHover:PauseWhenHoveSlide || false, 
      pauseOnFocus:PauseWhenHoveSlide || false, 
      pauseOnHover: PauseWhenHoveSlide || false, 
      lazyLoad: false,
      fade: true, 
      customPaging: i => (
        <div
          className="slicer_dos"
        >
        </div>
      )
    };
    
    return (
      <SbEditable content={blok}  key={blok._uid}>
      <div className="img-swiper" style={{maxHeight: hasWindow && window.screen.height}}>
        <div className="wrap">
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {
              blok.SlicerList && isArray(blok.SlicerList) &&
              blok.SlicerList.map((item)=>{
                return (
                <React.Fragment key={item?._uid}>
                  <div className="Slider-content">
                  <SlicerItem content={item} lang={lang} className="Slider-content"></SlicerItem>
                  </div>
                </React.Fragment>
                )
              })
            }
          </Slider>
        </div>
        <div className='MyArrows MyArrows-left' onClick={()=>this.slider.slickPrev()}>
        </div>
        <div className='MyArrows MyArrows-right' onClick={()=>this.slider.slickNext()}>
        </div>
      </div>
      </SbEditable>
    );
  }
}
 
