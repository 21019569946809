import React from "react"
import SbEditable from "storyblok-react"
import { DeviceInfo } from '../../../../hooks/device';
import { styles } from '../../../../utils/helper'
import MarkDown from '../../markdown'
import './style.scss'


const ec1706Remark = ({ blok }) => {
  const { _uid, mobileTextStyles, pcTextStyles, mPadding, pcPadding, align, text_color, max_width, opacity, bg_color, text } = blok;
  const { isMobile: isM } = DeviceInfo();
  const textStyles = isM ? styles(mobileTextStyles) : styles(pcTextStyles);
  const padding = isM ? mPadding : pcPadding;
  const textStyle = {
    textAlign: align || null,
    color: text_color && text_color.color || null,
    maxWidth: max_width || '1200px',
    opacity: opacity || 1,
    ...textStyles,
  }
  return (
    <SbEditable content={blok} key={_uid} >
      <div className="ec1706-remark-wrapper" style={{ backgroundColor: bg_color && bg_color.color || null, padding }}>
        <div style={textStyle} className="text-wrapper" dangerouslySetInnerHTML={ MarkDown(text) } ></div>
      </div>
    </SbEditable>
  )
}

export default ec1706Remark;

