import React from "react";
import IconDescSec from './index';
import SbEditable from "storyblok-react";
import { DeviceInfo } from '../../../../hooks/device';

const IconDescList = (props) =>{
  const { blok } = props;
  const { iconDescSec = [], backgroundColor, paddingPc, paddingMobile } = blok;
  const { isMobile: isM } = DeviceInfo();
  const padding = isM ? (paddingMobile || '0 48px 20px'): (paddingPc || '0 48px 128px');

  return (
    <SbEditable content={blok}>
      <div className="iconDescList-wraper" style={{backgroundColor: backgroundColor || '#000', padding: padding}}>
          <IconDescSec content={iconDescSec} />
      </div>
    </SbEditable>
  )
}

export default IconDescList;