import React from "react"
import SbEditable from "storyblok-react"

const CustomStyle = ({blok}) => (
  <SbEditable content={blok} key={blok._uid} >
    {blok.customCSS ? (
      <style dangerouslySetInnerHTML={{ __html: blok.customCSS }} />
    ) : null}
  </SbEditable>
);
export default CustomStyle;