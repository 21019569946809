import React from 'react';
import { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import { PropsWithAos } from './type';

import './style.scss';

const Description = (props: PropsWithAos) => {
  const { aosFade } = props;

  const descriptionStyle: {
    color?: string;
    fontWeight?: any;
    fontSize?: string;
    lineHeight?: string;
    marginTop?: string;
    letterSpacing?: string;
    margin?: string;
  } = {
    fontWeight: props.descriptionFontWeight && TextFontWeight[props.descriptionFontWeight] ? TextFontWeight[props.descriptionFontWeight] : undefined,
    fontSize: props.descriptionFontSize,
    lineHeight: GetLineHeight(props.descriptionFontSize, props.descriptionLineHeight),
    color: props.descriptionColor,
    marginTop: props.hasDividedLine ? '0' : '',
    letterSpacing: props.descriptionLetterSpacing,
    ...props.descriptionMargin ? { margin: props.descriptionMargin } : {},
  };

  return (
    <p
      data-aos={aosFade}
      data-aos-once
      className="mcardv-description"
      style={descriptionStyle}
      dangerouslySetInnerHTML={{ __html: props.descriptionText || '' }}
    />
  );
};

export default Description;
