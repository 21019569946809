import Page from "./page";
import AuthorizedPage from "./authorized_page";

import {
  ComponentNotFound,
  IconListing,
  Banner,
  Editorial,
  Button,
  ButtonRow,
  VideoBlock,
  Text,
  Image,
  Slider,
  Dealers,
  Listing,
  EventsListing,
  DownloadsBlock,
  ProfileBlock,
  ProductBlock,
  Survey,
  Table,
  Comparison,
  CompetitionRegistration,
  CompetitionUpload,
  CompetionVoting,
  ImageComparer,
  CompetitionListing,
  LensComparison,
  EC1706MaterialDesignBackupSection,
  EC1706Remark,
  EC1706LockScreenWithMedia,
  EC1706Banner,
  EC1706ParallaxHeaderImage,
  EC1706InfoCard,
  EC1706VideoBanner,
  Viewfinder,
  ImgSwiper,
  SlicerItem,
  ButtonPlayInformation,
  DescItem,
  DescItemWithImg,
  DescItemWithSubscribe,
  WarmUpBanner,
  Polymerization,
  PolymerizationItem,
  IconDescList,
  HeroinesBlock,
  infoCardHorizontal,
  infoCardVertical,
  infoTabHorizontal,
  infoFaq,
  ZoomImageSwiper,
  CustomStyle,
  CompetitionCategory
} from "./blocks";

const ComponentList = {
  page: Page,
  authorized_page: AuthorizedPage,
  event: Page,
  settings: Page,
  chapter_heading: IconListing,
  icon_listing: IconListing,
  banner: Banner,
  editorial: Editorial,
  button: Button,
  button_row: ButtonRow,
  video_block: VideoBlock,
  heroines_block: HeroinesBlock,
  text_block: Text,
  image_block: Image,
  slider: Slider,
  dealers: Dealers,
  listing: Listing,
  competition_listing: CompetitionListing,
  events_listing: EventsListing,
  downloads_block: DownloadsBlock,
  profile_block: ProfileBlock,
  product_block: ProductBlock,
  survey: Survey,
  table: Table,
  comparison: Comparison,
  competition_registration: CompetitionRegistration,
  competition_upload: CompetitionUpload,
  competition_voting: CompetionVoting,
  image_comparer: ImageComparer,
  lens_comparison: LensComparison,
  ec1706_material_design_backup_section: EC1706MaterialDesignBackupSection,
  ec1706_remark: EC1706Remark,
  ec1706_lock_screen_with_media: EC1706LockScreenWithMedia,
  ec1706_banner: EC1706Banner,
  ec1706_parallax_header_image: EC1706ParallaxHeaderImage,
  ec1706_info_card: EC1706InfoCard,
  ec1706_video_banner: EC1706VideoBanner,
  viewfinder:Viewfinder,
  img_swiper: ImgSwiper,
  slider_item: SlicerItem,
  button_paly_information: ButtonPlayInformation,
  desc_item: DescItem,
  descItemWithImg:DescItemWithImg,
  desc_item_with_subscribe: DescItemWithSubscribe,
  warm_up_banner: WarmUpBanner,
  polymerization:Polymerization,
  polymerizationItem:PolymerizationItem,
  iconDescList:IconDescList,
  zoom_image_swiper: ZoomImageSwiper,
  infoFaq: infoFaq,
  infoCardHorizontal: infoCardHorizontal,
  infoCardVertical: infoCardVertical,
  infoTabHorizontal: infoTabHorizontal,
  customStyle: CustomStyle,
  competition_category: CompetitionCategory,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
