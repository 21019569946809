import React from "react";
import SbEditable from "storyblok-react";
import "./button_row.css";
import Button from "./button";
import ButtonPlayYoutube from "./button_play_youtube";
import ButtonPlayVideo from "./button_play_video";
import ButtonDemoRequest from "./button_demo_request";
import ButtonLogout from "./button_logout";
import ButtonWatchVideo from "./button_watch_video"

export default class extends React.Component {
  render() {
    const { content, lang } = this.props;
    return (
      <SbEditable content={content}>
        <div className="cta-row">
          {content &&
            content.buttons &&
            content.buttons.map((button, key) => {
              switch (button.component) {
                case "button_link":
                  return <Button content={button} key={key} />;
                case "button_play_youtube":
                  return (
                    <ButtonPlayYoutube content={button} key={key} lang={lang} />
                  );
                case "button_play_video":
                  return (
                    <ButtonPlayVideo content={button} key={key} lang={lang} />
                  );
                case "button_demo_request":
                  return (
                    <ButtonDemoRequest content={button} key={key} lang={lang} />
                  );
                case "button_logout":
                  return (
                    <ButtonLogout content={button} key={key} lang={lang} />
                  );
                case "button_watch_video":
                  return (
                    <ButtonWatchVideo content={button} key={key} lang={lang} />
                  );
                default:
                  return null;
              }
            })}
        </div>
      </SbEditable>
    );
  }
}
