import React, { useRef, useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import MarkDown from '../../markdown';
import LazyLoad from 'react-lazyload';
import Button from '../../button';
import ButtonPlayInformation  from "../../button_play_information";
import IconDescSec from '../IconDescSection';
import "./style.scss";

const lazyLoadOffset = 2500;
const Card = ({content, isM, lang, transmissionInfoCard, isIE}) => {
  const {
    construct, textConstruct, imageType, image, mobileImage, title, desc, btn, descList, textPaddingTop, width, ImageMargin, pcImgPosition, modelButton
  } = content;
  const textDom = useRef(null);
  const [textWrapperHeight, setTextWrapperHeight] = useState('auto');
  const targetImage = isM ? (mobileImage || image) : image;
  const flexStyle = {
    flexDirection: 'row',
    minHeight: isM && imageType === 'bg' ? textWrapperHeight : 'auto',
    alignItems: isM ? null:pcImgPosition,
  };
  const bgStyle = {
    top: textPaddingTop || '50%',
  }

  // 布局不同时 图片的左右间距
  const imageMargin = {
    margin: imageType !== 'bg' ? construct === 'right-image' ? (ImageMargin || '0') : '0 118px 0 0' : 0,
  }
  const transmissionImageMargin = {
    margin: construct === 'right-image' ? '0 0 0 16px' : '0 16px 0 0',
  }
  
  const bgImageWdith = {
    width: transmissionInfoCard ? '100%' : imageType === 'bg' ? width || '2560px' : (isM ? '880px' : '' )
  }

  const bgImageHeight = {
    height: transmissionInfoCard ? '' : (isM ? '' : '858px')
  }

  const cardItemClassName = `ec1706-info-card-item${transmissionInfoCard ? ' transmission-info-card-item' : ''}${isIE ? ' ec1706-info-card-item-downgrade' : ''}`;

  let contentOutWrapperClass = isM ? 'content-out-wrapper content-out-wrapper-m' : 'content-out-wrapper content-out-wrapper-p';

  if (imageType === 'bg') {
    contentOutWrapperClass += ' bg';
    if (construct === 'right-image') {
      contentOutWrapperClass += ' right-image';
    } else {
      contentOutWrapperClass += ' left-image';
    }
    if (textConstruct === 'top') {
      contentOutWrapperClass += ' top';
    } else {
      contentOutWrapperClass += ' center';
    }
  }

  if (construct === 'right-image') {
    flexStyle.flexDirection = 'row-reverse';
  }

  useEffect(() => {
    if (textDom && textDom.current && isM && imageType === 'bg') {
      setTextWrapperHeight(`${textDom.current.clientHeight}px`)
    }
  })

  
  return (
    <SbEditable content={content}>
      <div className={cardItemClassName} style={flexStyle}>
        { isM && title && <div className="title"  dangerouslySetInnerHTML={ MarkDown(title) }></div>}
        <div className="image-wrapper" style={transmissionInfoCard ? transmissionImageMargin : imageMargin}>
          <LazyLoad offset={lazyLoadOffset} once>
            <img className="image" src={targetImage} alt="" style={bgImageWdith, bgImageHeight}/>
          </LazyLoad>
        </div>
        <div className={contentOutWrapperClass} ref={textDom} style={isM ? {} : bgStyle}>
          <div className="content-wrapper">
            { !isM && title && <div className="title"  dangerouslySetInnerHTML={ MarkDown(title) }></div>}
            { desc && <div className="content-desc"  dangerouslySetInnerHTML={ MarkDown(desc) } ></div> }
            {
              btn && btn.length > 0 ? (
                <div className="info-card-btn-wrapper">
                  {
                    btn.map(btnItem => (
                      <div className="info-card-btn-wrapper" key={btnItem._uid}>
                        <Button content={btnItem} key={btnItem._uid} lang={lang} />
                      </div>
                    ))
                  }
                </div>
              ) : null
            }
            {/* 新添加弹框组件 */}
            {
               modelButton && modelButton.length > 0 ? (
                 <div>{
                   modelButton.map((item)=>(
                    <ButtonPlayInformation content={item}  key={item._uid} lang={lang}/>
                   ))
                   }
                   </div>
               ) : null
            }
            {
              descList && descList.length > 0 ? (
                <div className="desc-list-wrapper">
                  <IconDescSec content={descList} />
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Card;
