import React from "react"
import SbEditable from "storyblok-react"
import ButtonRow from "./button_row"
import MarkDown from './markdown'
import './text.css'

export default class extends React.Component {

  render() {
    const { blok, key, lang } = this.props
    const secStyle = {
      backgroundColor : blok.background_color || null,
      fontSize: blok.text_size || null,
      lineHeight: blok.text_line_height || null,
      padding: blok.padding_value || null,
      maxWidth: blok.customize_max_width || null,
      color: blok.customize_text_color && blok.customize_text_color.color || null,
    }
      return (
        <SbEditable content={blok}>
        <div key={key} className={`et ${blok.align} ${blok.width} ${blok.text_color} ${blok.clear_text_margin ? 'clear-default-p-margin' : ''}`} style={secStyle} >
            <div dangerouslySetInnerHTML={ MarkDown(this.props.blok.text) } ></div>
            {blok.button_row && blok.button_row.map((row) =>{
                    return (
                        <ButtonRow content={row} lang={lang} /> 
                    )
                }
            )}
        </div>
        </SbEditable>
      )
  }
}
