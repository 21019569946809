import React from "react"
import "./social_share.css"

const SocialShare = (props) => {
    let { url, sites, lang } = props;
    
    if (!url || !sites || !sites.length) {
        return null;
    }

    const sharers = {
        facebook: {
            shareUrl: 'https://www.facebook.com/sharer/sharer.php',
            params: {
              u: url,
              hashtag: props.facebook_hashtag,
            },
        },
        linkedin: {
            shareUrl: 'https://www.linkedin.com/shareArticle',
            params: {
              url: url,
              mini: true,
            },
        },
        twitter: {
            shareUrl: 'https://twitter.com/intent/tweet/',
            params: {
                text: props.twitter_title,
                url: url,
                hashtags: props.twitter_hashtags,
                via: props.twitter_via,
            },
        },
        weibo: {
            shareUrl: 'http://service.weibo.com/share/share.php',
            params: {
              url: url,
              title: props.weibo_title,
              pic: props.weibo_image,
              language: 'zh_cn',
            },
        },
    }

    const handleClick = (type) => {
        const sharer = sharers[type];
        var p = sharer.params || {},
        keys = Object.keys(p),
        i,
        str = keys.length > 0 ? '?' : '';
        for (i = 0; i < keys.length; i++) {
            if (str !== '?') {
            str += '&';
            }
            if (p[keys[i]]) {
            str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
            }
        }
        sharer.shareUrl += str;
        var popWidth = sharer.width || 600,
            popHeight = sharer.height || 480,
            left = window.innerWidth / 2 - popWidth / 2 + window.screenX,
            top = window.innerHeight / 2 - popHeight / 2 + window.screenY,
            popParams = 'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left,
            newWindow = window.open(sharer.shareUrl, '', popParams);

        if (window.focus) {
            newWindow.focus();
        }
    }


    return (
        <ul className="social-share-list">
            <li>
                <a 
                    href={ lang === 'ja-jp' ? 'https://www.instagram.com/hasselblad.japan/?hl=ja' : 'https://www.instagram.com/hasselblad' } 
                    className="social-share-icon social-share-instagram"
                    target="_blank"
                />
            </li>
            {sites.map((item) => {
                if (!sharers[item]) return null;
                return (
                    <li>
                        <button 
                            className={`social-share-icon social-share-${item}`}
                            onClick={handleClick.bind(this, item)}
                        />
                    </li>
                )
            })}
        </ul>
    )
}

export default SocialShare