import React from "react"
import SbEditable from "storyblok-react"
import "./button.scss"
import "./modal.css"
import t from "../../utils/languages"
import Modal from "react-modal"
import API from "../../utils/api"
import AUTHAPI from "../../utils/api_auth"
import _ from "lodash"
import { isLoggedIn } from "../../services/auth"

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isSubmitted: false,
      products: [],
      initialProducts: props.content.products,
      selectedProducts: [],
      user: {
        email: '',
        firstname: '',
        surname: '',
        city: '',
        zip_code: '',
        country_code: '',
        phone: ''
      },
      error: {
        email: false,
      },
      area: '',
      areas: [],
      countries: {}
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  componentDidMount = () => {

    if (isLoggedIn()) {
      AUTHAPI.get('/me')
        .then(response => {
          this.setState({ user: response.data.user })
        })
    }
    if (this.state.products.length === 0) {
      API.get('/products?current=1').then(response => {
        /*var products =
          _.chain(response.data)
            .groupBy('group_name')
            .map(function (products, group) {
              return {
                name: group,
                products: products
              }
            }).value();
            */
        var products = response.data;
        var tempSelectProducts = [];
        for (let i = 0; i < this.state.initialProducts.length; i++) {
          var tempProduct = {
            id : this.state.initialProducts[i]
          }  
          tempSelectProducts.push(tempProduct)       
        }
        var t = _.intersectionBy(products,tempSelectProducts, 'id')
        this.setState({ products: products, selectedProducts : t })
      }).catch(e => {
        console.log(e)
      })

      API.get('/countries/demo').then(response => {

        let countriesMapped = {}
        for (let index = 0; index < response.data.countries.length; index++) {
          countriesMapped[response.data.countries[index].id] = response.data.countries[index];
        }
        var user = this.state.user;
        user.country_code = response.data.userCountry;
        this.setState({ countries: countriesMapped, user: user })

      })
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }



  submitRequest = () => {
    this.setState({ isLoading : true })
    var request = {
      user : this.state.user,
      products : this.state.selectedProducts
    }
    var self = this;

    if (isLoggedIn()){
      AUTHAPI.put('/me', this.state.user).then = () =>{
          API.post('/demorequest', request).then(() => {
            self.setState({ isLoading : false, isSubmitted : true })
            console.log('updated')  
          })
      }
    }
    else{
      API.post('/demorequest', request).then(() => {
        console.log('not logged in')

        self.setState({ isLoading : false, isSubmitted : true })
  
      })
    }
  }


  handle = event => {
    window.dataLayer.push({ 'event': 'demo-request-open' })
    const target = event.target
    const value = target.value
    const name = target.name
    let error = this.state.error;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    switch (name) {
      case 'email':
        error.email = !emailRegex.test(value);
        break;
      default:
        break;
    }

    if (name === 'country_code') {
      if (this.state.countries[value].areas.length > 0) {
        this.setState({ areas: this.state.countries[value].areas })
      }
      else {
        this.setState({ areas: [], area: '' })
      }
    }

    let user = this.state.user;
    user[name] = value;

    this.setState({
      error,
      user: user
    })
  }

  render() {
    const { content, lang } = this.props
    const { products, user, error, countries, areas } = this.state
    if (content) {
      return (
        <SbEditable content={content}>
          <button className="cta" onClick={this.handleOpenModal}>
            {content.text || t('menu.DemoRequest', lang)}
          </button>
          <Modal className="modal" overlayClassName="overlay" isOpen={this.state.showModal} contentLabel="Minimal Modal Example">
            <button className="close" onClick={this.handleCloseModal}>
              <img alt="close" src="/assets/icons/close-black.svg" />
            </button>
            <div className="modal__content" style={{ display: !this.state.isSubmitted ? 'block' : 'none'}}>
              <div className=" editorial column">
                <div className="et center fifty">
                  <h1>{t('menu.DemoRequest', lang)}</h1>
                </div>

                {/*
                <div className="et center thirty-three" style={{ display: this.state.thankYou ? 'none' : 'block' }}>
                  <div>
                    <label htmlFor="model">
                      <div>{t('demo.selectProduct', lang)}</div>
                      <select name="model" onChange={this.addProduct.bind(this)} >
                        <option disabled="disabled" value="" defaultChecked={true}>{t('profile.selectProduct', this.props.lang)}</option>
                        {products.map((group) =>
                          <optgroup key={group.name} label={group.name}>
                            {group.products.map((product, key) =>
                              <option value={product.id} key={key} >{product.name}</option>
                            )}
                          </optgroup>
                        )}
                      </select>
                    </label>
                  </div>
                </div>
                */}
                { this.state.selectedProducts.map((s) => {

                    var sp = {}


                    return (
                      <div className="et center twenty-five" >
                        <div className="name">
                          <h2>{s.name}</h2>
                        </div> 
                      </div>
                    )
                    }


                    )}
              </div> 
              <div className="editorial row" style={{ maxWidth: '768px', margin: 'auto', float: 'none', paddingTop:'0' }}>
                <div className="et fifty">
                  <label htmlFor="firstname">
                    <div>
                      <span className={!error.firstname ? "show" : null}>
                        {t('profile.firstNamePlaceHolder', lang)}
                      </span>
                    </div>
                    <input name="firstname" className={error.firstname ? "error" : null} type="text" placeholder={t('profile.firstNamePlaceHolder', lang)} value={user.firstname} onChange={this.handle.bind(this)} />
                  </label>

                </div>
                <div className="et fifty">

                  <label htmlFor="surname">
                    <div>
                      <span className={!error.surname ? "show" : null}>
                        {t('profile.lastNamePlaceHolder', lang)}
                      </span>
                    </div>
                    <input name="surname" className={error.surname ? "error" : null} type="text" placeholder={t('profile.lastNamePlaceHolder', lang)} value={user.surname} onChange={this.handle.bind(this)} />
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="email">
                    <div>
                      <span className={(!error.email || user.email === '') ? "show" : null}>
                        {t('profile.emailPlaceHolder', lang)}
                      </span>
                      <span className={`error ${(error.email && user.email !== '') ? "show" : null} `}>{t('newsletter.emailInvalid', lang)}</span>
                    </div>
                    <input name="email" className={error.email ? "error" : null} type="email" placeholder={t('profile.emailPlaceHolder', lang)} value={user.email} onChange={this.handle.bind(this)} />
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="phone">
                    <div>
                      <span className={!error.phone ? "show" : null}>
                        {t('profile.phonePlaceHolder', lang)}
                      </span>
                    </div>
                    <input name="phone" className={error.phone ? "error" : null} type="text" placeholder={t('profile.phonePlaceHolder', lang)} value={user.phone} onChange={this.handle.bind(this)} />
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="city">
                    <div>
                      <span className={!error.city ? "show" : null}>
                        {t('profile.cityPlaceHolder', lang)}
                      </span>
                    </div>
                    <input name="city" className={error.city ? "error" : null} type="text" placeholder={t('profile.cityPlaceHolder', lang)} value={user.city} onChange={this.handle.bind(this)} />
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="zip_code">
                    <div>
                      <span className={!error.zip_code ? "show" : null}>
                        {t('profile.zipPlaceHolder', lang)}
                      </span>
                    </div>
                    <input name="zip_code" className={error.zip_code ? "error" : null} type="text" placeholder={t('profile.zipPlaceHolder', lang)} value={user.zip_code} onChange={this.handle.bind(this)} />
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="country_code">
                    <div>{t('newsletter.selectYourCountry', lang)}</div>
                    <select name="country_code" value={user.country_code} onBlur={this.handle.bind(this)} onChange={this.handle.bind(this)} >
                      {Object.values(countries).map((c) => <option key={c.id} value={c.id}>{c.t}</option>)}
                    </select>
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="area" style={{ display: areas.length > 0 ? 'block' : 'none' }}>
                    <div>{t('demo.selectArea', lang)}</div>
                    <select name="area" value={user.area} onBlur={this.handle.bind(this)} onChange={this.handle.bind(this)} >
                      {areas.map((c, index) => <option key={index} value={c.id}>{c.name}</option>)}
                    </select>
                  </label>
                </div>
                </div> 
              <div className="editorial row" style={{ maxWidth: '768px', margin: 'auto', paddingTop:'0', float: 'none' }}>

                <div className="et center hundred">
                  <div className="cta-row">
                    <button className={`cta ` } onClick={this.submitRequest} >{t('demo.submitRequest', lang)}</button>
                  </div>
                </div>
              </div>

            </div>
            <div className="modal__content" style={{ display: this.state.isSubmitted ?  'block' : 'none'}}>
              <div className=" editorial column">
                <div className="et center-center thirty-three">
                  <h1>Thank you</h1>
                  <p>Thank you for your interest in Hasselblad products!</p>
                  <p>A Hasselblad representative will contact you shortly to arrange the details of your demo.</p>
                </div>
              </div>
            </div>
          </Modal>
        </SbEditable>
      )
    }
    else {
      return (<></>)
    }
  }
}