import React from "react";
import SbEditable from "storyblok-react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { DeviceInfo } from '../../../hooks/device';
import ZoomImage from '../image';
import LinkFix from "../../../utils/links"
import LazyLoad from "react-lazyload"
import { checkIsSafari } from "../../../../helper/utils"
import 'swiper/swiper-bundle.css';
import './style.scss'
SwiperCore.use([Navigation, Pagination]);

const zoomImageSwiper = ({blok}) => {
    const descList = [];
    const carouselList = [];
    const {
      sectionPaddingPC, sectionPaddingMobile,
      bottomLinkUrl, bottomLinkText,
      zoomImageSwiperCards,
     } = blok;
    const id = blok._uid;
    const { isMobile: isM } = DeviceInfo();
    const isSafari = checkIsSafari();
    const hasSwiperList = Array.isArray(zoomImageSwiperCards) && zoomImageSwiperCards.length > 0;
    const hasSwiperController = Array.isArray(zoomImageSwiperCards) && zoomImageSwiperCards.length > 1;

    const params = {
      initialSlide: 0,
      speed: 600,
      spaceBetween: isM ? 48 : 16,
      slidesPerView: 'auto',
      centeredSlides: true,
      preventClicks: false,
      slideToClickedSlide: true,
      navigation: {
        nextEl: `.carousel-swiper-btn-next-${id}`,
        prevEl: `.carousel-swiper-btn-prev-${id}`,
      },
      preventClicksPropagation: true, // 防止拖拽 swiper 时触发 zoomImage 弹窗
      touchMoveStopPropagation: true,
      pagination: {
        el: `.carousel-pagination-${id}`,
        clickable: true,
      },
      onSlideChange: (swiper) => {
        const { realIndex } = swiper;
        /* 描述文字显隐  */
        for (let i = 0; i < descList.length; i += 1) {
          if (descList[i].classList.contains('active')) {
            descList[i].classList.remove('active');
          }
        }
        descList[realIndex].classList.add('active');

        /* 移除所有 cursor 态 */
        const targetCls = '.section-carousel-image-wraper';
        const targetDoms = document.querySelectorAll(targetCls);
        for (let i = 0; i < targetDoms.length; i += 1) {
          if (targetDoms[i].classList.contains('active')) {
            targetDoms[i].classList.remove('active');
          }
        }
      },
      onSlideChangeTransitionEnd: (swiper) => {
        const targetCls = `.section-carousel-image-wraper-${swiper.realIndex}`;
        const targetDom = document.querySelector(targetCls);
        if (targetDom) {
          targetDom && targetDom.classList.add('active');
        }
      },
    };
    const sectionStyle = {
      padding: isM ? sectionPaddingMobile : sectionPaddingPC,
    };

    return (
    <SbEditable content={blok} key={blok._uid}>
      <section
        style={sectionStyle}
        className={`section-carousel-groups ${isM ? 'section-carousel-groups-m' : 'section-carousel-groups-pc'} ${isSafari ? 'is-safari-section-carousel' : ''}`}
      >
        <div className="section-carousel-groups-content">
          <Swiper {...params}>
            {hasSwiperList && zoomImageSwiperCards.map((item, index) => (
              <SwiperSlide key={`${id}-carousel-${index}`}>
                <div
                  className="carousel-image"
                  ref={(c) => { carouselList[index] = c; }}
                >
                  {isM
                    ? (
                      <LazyLoad offset={1000} once>
                        <div className="section-carousel-img" style={{ backgroundImage: `url(${item.imgListMobile[0].mobile_image})` }} />
                      </LazyLoad>
                    )
                    : (
                      item.imgList && item.imgList[0] && item.imgList.slice(0, 1).map((zoomImg) => zoomImg.zoom_image_id ? (
                        <div
                          key={zoomImg?._uid}
                          className={`section-carousel-image-wraper ${`section-carousel-image-wraper-${index}`} ${zoomImg?.zoom_image_id && 'with-cursor'} ${index===0 ? 'active' : ''} `}
                        >
                          <ZoomImage modalModeOn={true} blok={zoomImg} uid={zoomImg?._uid} />
                        </div>
                      ) : (
                        <LazyLoad offset={1000} once>
                          <div key={zoomImg?._uid} className="section-carousel-img" style={{ backgroundImage: `url(${zoomImg.image})` }} />
                        </LazyLoad>
                      ))
                    )
                  }
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="carousel-control-wrapper">
            <div className="carousel-desc-wrapper">
              {hasSwiperList && zoomImageSwiperCards.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item-desc ${index === 0 ? 'active' : ''}`}
                  ref={(c) => { descList[index] = c; }}
                >
                  <div
                    key={`${id}-carousel-desc-${index}`}
                    className="carousel-item-desc-text"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                </div>
              ))}
              <div className="carousel-desc-wrapper-autoheight">
                {hasSwiperList && zoomImageSwiperCards.map((item, index) => (
                  <div key={index} className="carousel-item-desc">
                    <div
                      key={`${id}-carousel-desc-${index}-autoheight`}
                      className="carousel-item-desc-text"
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="carousel-control-box">
              {/* navigation */}
              {hasSwiperController && (
                <div className="carousel-navigation-wrapper">
                  <div className={`carousel-swiper-btn-prev-${id} carousel-swiper-btn-prev`} />
                  <div className={`carousel-swiper-btn-next-${id} carousel-swiper-btn-next`} />
                </div>
              )}

              {/* pagination */}
              {hasSwiperController && (
                <div className={`carousel-pagination-${id} carousel-pagination`} />
              )}
            </div>
          </div>
        </div>
        {bottomLinkUrl && bottomLinkUrl.cached_url && (
          <div className="carousel-bottom-content">
            <a href={LinkFix(bottomLinkUrl)} className="carousel-gallery-link" dangerouslySetInnerHTML={{ __html: bottomLinkText }} />
          </div> 
        )}
      </section>
    </SbEditable>
    );
}


export default zoomImageSwiper;
