import React from "react"
import Components from "./components.js"
import Layout from "./layout"

export default class extends React.Component {

  componentDidMount = () => {
    if (typeof document === 'undefined') return;
    document.body.style.overflow = 'auto';
    document.body.classList.remove('menu-opened');
    document.querySelector('html').classList.remove('menu-opened');
  }

//const Page = props => (
  render () {
    const props = this.props.page ? this.props.page : this.props;
    return (
      <Layout page={props}>
        { props.blok.body && props.blok.body.map((blok) => {
          if (!blok.languages || (blok.languages && blok.languages.includes(props.lang))){
            return React.createElement(Components(blok.component), { key: blok._uid, blok: blok, lang:props.lang, path: props.path })
          }
          else{
            return null;
          }
        })} 
    </Layout>
    )
  }
}
