import React from 'react';
import classNames from 'classnames';
import TabPanel, { Props as PanelProps } from './TabPanel';

import './style.scss';

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
  'right' = 'right',
}
export interface Props {
  panels?: React.ReactNode;
  activeKey?: string;
  tabTheme?: string;
  tabAlign?: tabAlign;
}


export interface State {
  minHeight: number;
}

export default class TabContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      minHeight: 0,
    };
  }


  render(): JSX.Element {
    const { props } = this;
    const { state } = this;

    const contents = React.Children.map(props.panels, (child: React.ReactElement<PanelProps>) => {
      const { key } = child;
      const { minHeight } = state;
      const {
        children,
        height,
        ...others
      } = child.props;
      if (height && height > minHeight) {
        this.setState({
          minHeight: height,
        });
      }
      return (
        <TabPanel
          key={`tab-panel-${key}`}
          active={props.activeKey === key}
          {...others}
        >
          {children}
        </TabPanel>
      );
    });

    const tabContentCls = classNames(
      'tabh-atom-horizontal-tab-content',
      `tabh-atom-horizontal-tab-content-${props.tabAlign}`,
      `tabh-atom-horizontal-tabs-content-theme-${props.tabTheme}`,
    );
    return (
      <div className={tabContentCls} style={{ minHeight: state.minHeight }}>
        {contents}
      </div>
    );
  }
}
