/**
 * 基于 Ant Design Carousel 进行 UI 调整
 * https://github.com/ant-design/ant-design/blob/master/components/carousel/index.tsx
 */

import * as React from 'react';
import debounce from 'lodash.debounce';
import classnames from 'classnames';

import './style.scss';

if (typeof window !== 'undefined') {
  const matchMediaPolyfill = (mediaQuery: string): MediaQueryList => ({
    media: mediaQuery,
    matches: false,
    // tslint:disable-next-line
    addListener: () => {},
    // tslint:disable-next-line
    removeListener: () => {},
  });
  window.matchMedia = window.matchMedia || matchMediaPolyfill;
}

/* 直接引用 react-slick 会有问题 */
// tslint:disable-next-line
const SlickCarousel = require('../react-slick').default;

export type CarouselEffect = 'scrollx' | 'fade';

export interface Props {
  effect?: CarouselEffect;
  dots?: boolean;
  vertical?: boolean;
  autoplay?: boolean;
  easing?: string;
  beforeChange?: (from: number, to: number) => void;
  afterChange?: (current: number) => void;
  style?: React.CSSProperties;
  accessibility?: boolean;
  nextArrow?: HTMLElement | any;
  prevArrow?: HTMLElement | any;
  pauseOnHover?: boolean;
  className?: string;
  adaptiveHeight?: boolean;
  arrows?: boolean;
  autoplaySpeed?: number;
  centerMode?: boolean;
  centerPadding?: string | any;
  cssEase?: string | any;
  dotsClass?: string;
  draggable?: boolean;
  fade?: boolean;
  focusOnSelect?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  lazyLoad?: boolean;
  rtl?: boolean;
  slide?: string;
  slidesToShow?: number;
  slidesToScroll?: number;
  speed?: number;
  swipe?: boolean;
  swipeToSlide?: boolean;
  touchMove?: boolean;
  touchThreshold?: number;
  variableWidth?: boolean;
  useCSS?: boolean;
  slickGoTo?: number;
}

export default class Carousel extends React.PureComponent<Props, {}> {
  innerSlider: any;

  slick: any;

  static defaultProps = {
    dots: false,
    arrows: false,
    draggable: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };

  constructor(props: Props) {
    super(props);
    this.onWindowResized = debounce(this.onWindowResized, 500, {
      leading: false,
    });
  }

  componentDidMount(): void {
    const { autoplay } = this.props;
    if (autoplay) {
      window.addEventListener('resize', this.onWindowResized);
    }
    this.innerSlider = this.slick && this.slick.innerSlider;
  }

  componentWillUnmount(): void {
    const { autoplay } = this.props;
    if (autoplay) {
      window.removeEventListener('resize', this.onWindowResized);

      // const onWindowResized: any = this.onWindowResized;
      // onWindowResized.cancel();
    }
  }

  onWindowResized = (): void => {
    const { autoplay } = this.props;
    if (autoplay && this.slick && this.slick.innerSlider && this.slick.innerSlider.autoPlay) {
      this.slick.innerSlider.autoPlay();
    }
  };

  saveSlick = (node: any): void => {
    this.slick = node;
  };

  slickNext(): void {
    this.slick.slickNext();
  }

  slickPrev(): void {
    this.slick.slickPrev();
  }

  slickGoTo(slide: number, dontAnimate: boolean): void {
    this.slick.slickGoTo(slide, dontAnimate);
  }

  render(): JSX.Element {
    const props = {
      ...this.props,
    };

    if (props.effect === 'fade') {
      props.fade = true;
    }
    const vCls = props.vertical ? 'atom-utils-carousel-vertical' : 'atom-utils-carousel';
    const cls = classnames(
      props.className,
      vCls,
    );
    return (
      <div className={cls}>
        <SlickCarousel ref={this.saveSlick} {...props} />
      </div>
    );
  }
}
