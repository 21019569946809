/*
 * 从 https://github.com/valcol/react-hydration-on-demand/blob/master/src/index.js 改动而来。
 * 
 * 改动点：
 * 1. isClientSide 从 exenv 改成判断 window 是否存在
 * 2. 默认 wrapper 从 section 改成 div，并且可通过 Wrapper 参数改变
 * 3. 增加 removeWrapperOnHydration 参数，给调用者提供 hydration 后去除包裹层的功能
 * 4. idleCallback 的执行不再明确 timeout，也不再调用 RequestAnimationFrame
 * 5. 增加 wrapperInitialProps 参数，用于给 hydration 前的 wrapper 赋值，优先级高于 wrapperProps
 * 6. 如果使用 IntersectionObserver 判断 hydrate 时机，那么 IE11 下直接 hydrate
 * 
 * 
 * 更多文档可查阅: https://github.com/valcol/react-hydration-on-demand
 */

import 'intersection-observer';

import React, {
  useState, useRef, useEffect, useLayoutEffect, 
} from 'react';

const isClientSide = typeof window !== 'undefined';

const eventListenerOptions = {
  once: true,
  capture: true,
  passive: true,
};

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withHydrationOnDemandServerSide = (WrappedComponent) => ({
  Wrapper = 'div',
  wrapperProps,
  wrapperInitialProps,
  ...props
}) => (
  <Wrapper data-hydration-on-demand {...wrapperProps} {...wrapperInitialProps}>
    <WrappedComponent {...props} />
  </Wrapper>
);

const withHydrationOnDemandClientSide = ({
  disableFallback = false,
  onBefore = Function.prototype,
  removeWrapperOnHydration = false,
  on = [],
}) => (WrappedComponent) => {
  const WithHydrationOnDemand = ({
    Wrapper = 'div',
    forceHydration = false,
    wrapperProps,
    wrapperInitialProps,
    ...props
  }) => {
    const rootRef = useRef(null);
    const cleanupFunctions = useRef([]);
    const [isHydrated, setIsHydrated] = useState(false);

    const cleanUp = () => {
      cleanupFunctions.current.forEach((fn) => fn());
      cleanupFunctions.current = [];
    };

    const hydrate = async () => {
      cleanUp();
      if (isHydrated) return;

      await onBefore();
      setIsHydrated(true);
    };

    const initDOMEvent = (type, getTarget = () => rootRef.current) => {
      const target = getTarget();
      target.addEventListener(type, hydrate, eventListenerOptions);
      cleanupFunctions.current.push(() => {
        if (!target) return;
        target.removeEventListener(type, hydrate, eventListenerOptions);
      });
    };

    const initTimeout = (delay = 2000) => {
      if (delay < 0) return;

      const timeout = setTimeout(hydrate, delay);
      cleanupFunctions.current.push(() => clearTimeout(timeout));
    };

    const initIdleCallback = () => {
      if (!('requestIdleCallback' in window)) {
        initTimeout();
        return;
      }

      const idleCallback = requestIdleCallback(
        () => hydrate(),
      );

      if (!('cancelIdleCallback' in window)) return;

      cleanupFunctions.current.push(() => {
        cancelIdleCallback(idleCallback);
      });
    };

    const initIntersectionObserver = (getOptions = Function.prototype) => {
      // IE11 下直接解冻
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      if (!('IntersectionObserver' in window) || isIE11) {
        hydrate();
        return;
      }

      const options = getOptions();
      const observer = new IntersectionObserver(([entry], observer) => {
        if (!entry.isIntersecting || !(entry.intersectionRatio > 0)) return;

        hydrate();
      }, options);

      cleanupFunctions.current.push(() => {
        if (!observer) return;
        observer.disconnect();
      });

      observer.observe(rootRef.current);
    };

    // 检测是否正在交互（滚动），如果不在交互状态中，那进行解冻
    const initStopInteractCallback = (options) => {
      let hydrateTimeout;
      let isHydrated = false;
      let handleTouchStart = () => {
        clearHydrateTimeout();
      };

      let handleTouchEnd = () => {
        setHydrateTimeout();
      };

      let handleScroll = () => {
        clearHydrateTimeout();
        setHydrateTimeout();
      };

      function setHydrateTimeout() {
        hydrateTimeout = setTimeout(() => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
            window.removeEventListener('scroll', handleScroll);
            if(isHydrated) {
              return;
            }
            isHydrated = true;
            hydrate();
            return;
        }, options);
      }

      function clearHydrateTimeout() {
        if(hydrateTimeout) {
          clearTimeout(hydrateTimeout);
          hydrateTimeout = null;
        }
      }
      window.addEventListener('touchstart', handleTouchStart, { passive: true });
      window.addEventListener('touchend', handleTouchEnd, { passive: true });
      window.addEventListener('scroll', handleScroll, { passive : true });
    }

    const initEvent = (type, options) => {
      switch (type) {
        case 'delay':
          initTimeout(options);
          break;
        case 'visible':
          initIntersectionObserver(options);
          break;
        case 'idle':
          initIdleCallback();
          break;
        case 'stop-interact':
          initStopInteractCallback(options);
          break;
        default:
          initDOMEvent(type, options);
      }
    };

    useLayoutEffect(() => {
      if (isHydrated) return;

      const wasRenderedServerSide = !!rootRef.current.getAttribute(
        'data-hydration-on-demand'
      );
      const shouldHydrate = (!wasRenderedServerSide && !disableFallback) || forceHydration;
      if (shouldHydrate) hydrate();
    });

    useEffect(() => {
      if (isHydrated || forceHydration) return;
      on.forEach((event) => (Array.isArray(event) ? initEvent(...event) : initEvent(event)));
      return cleanUp;
    }, []);

    if (!isHydrated) {
      // 客户端渲染时跳过DOM差异检测 https://github.com/facebook/react/issues/10923
      return (
        <Wrapper
          ref={rootRef}
          dangerouslySetInnerHTML={{ __html: '' }}
          suppressHydrationWarning
          {...wrapperProps}
          {...wrapperInitialProps}
        />
      );
    }
    if(removeWrapperOnHydration) {
      return <WrappedComponent {...props} />
    }
    return <Wrapper {...wrapperProps}>
      <WrappedComponent {...props} />
    </Wrapper>;
  };

  WithHydrationOnDemand.displayName = `withHydrationOnDemand(${getDisplayName(
    WrappedComponent
  )})`;

  return WithHydrationOnDemand;
};
const withHydrationOnDemand = (options = {}) => {
  if (isClientSide) return withHydrationOnDemandClientSide(options);

  return withHydrationOnDemandServerSide;
};

export default withHydrationOnDemand;
