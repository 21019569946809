/* eslint-disable */
// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { ScrollManager } from '../../../../../utils/scrollManager';
import { checkVisible } from '../../../../../utils/checkElementInView';
import { getAsset } from '../../../../../utils/helper';
import TabBar from './TabBar';
import TabContent from './TabContent';
import TabPanel, { Props as PanelProps } from './TabPanel';
import TabCard from './tabCard';


import './style.scss';
import { StoryblokAsset } from '../../../pc/InfoCardHorizontal/card/type';

export enum tabPosition {
  'top' = 'top',
  'center' = 'center',
}

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
  'right' = 'right',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabPresentation {
  'carousel' = 'carousel',
  'manual-switch' = 'manual-switch',
}

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}


interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarTitle: StoryblokAsset;
  tabDesc: string;
  tabKey: string;
}

export enum fadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export interface Props {
  id?: string;
  responsiveBorder: number;
  // 基础配置
  tabPosition: tabPosition;
  tabAlign?: tabAlign;
  intervalBisection?: boolean;
  tabOverallWidth?: string;
  spacingFromTheCard?: string;
  tabBarContent?: tabBarContent;
  tabBarIconHeight?: string; // tabBar icon 高度
  tabTheme?: tabTheme; // tab标题颜色
  tabPresentation: tabPresentation; // tab的展示方式tabCarouselInterval
  tabCarouselInterval?: string; // 轮播间隔时长
  fadeTransiation: fadeTransiation;
  mediaTransiation: fadeTransiation;
  gridWidth?: string;
  cardGapWidth?: string;
  hideTabBarWhenSingle?: boolean; // 单个时是否不展示tab

  // 功能配置
  activeKey?: string;
  defaultActiveKey?: string;
  children: React.ReactNode | string;
  tabContent?: Array<TabPanelItem>;
  cardConfigList?: Array<Record<string, any>>;
}

export interface State {
  activeKey?: string;
}

function getDefaultActiveKey(props: Props): string | number | null {
  let activeKey: string | number | null;
  React.Children.forEach(props.children, (child: React.ReactElement<PanelProps>) => {
    if (child && !activeKey) {
      activeKey = child.key;
    }
  });
  return activeKey;
}

export default class Tabs extends React.PureComponent<Props, State> {
  carousel: any;

  cardDom: any;

  tabCardDom: any;

  tabDom: any;

  cards: any;

  activeIndex: number;

  videoFlag: boolean;

  videoListenerAdded: boolean;

  tabVisible?: boolean;

  visualDom?: boolean;

  defaultActiveIndex?: number;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.defaultActiveIndex = 0;
    let activeKey;
    if ('activeKey' in props) {
      activeKey = props.activeKey;
    } else if ('defaultActiveKey' in props) {
      activeKey = props.defaultActiveKey;
      props.tabContent.forEach((tab, tabIndex) => {
        if (tab.tabKey === activeKey) {
          this.defaultActiveIndex = tabIndex;
        }
      });
    } else {
      activeKey = getDefaultActiveKey(props);
    }
    this.state = {
      activeKey,
    };
    this.videoListenerAdded = false;
    this.handleTabClick = this.handleTabClick.bind(this);
    this.renderCard = this.renderCard.bind(this);
    this.getFadeTransiation = this.getFadeTransiation.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
    this.getProps = this.getProps.bind(this);
    this.bindScroll = this.bindScroll.bind(this);

    this.cardDom = [];
    this.tabVisible = true;
    this.visualDom = false;
  }

  componentDidMount(): void {
    const { cardConfigList, tabContent, defaultActiveKey } = this.props;
    if (this.tabVisible) {
      window.addEventListener('scroll', this.bindScroll);
    }
    this.cardDom = this.tabCardDom.cardDom;
    if (cardConfigList[0].hasBackgroundVideo || (cardConfigList[0].hasMedia && cardConfigList[0].media === 'video' && cardConfigList[0].foregroundVideo)) {
      this.handleVideo(0, true);
      this.tabCardDom.carousel.slick.slickPause();
    }
    this.bindScroll();

    // 如果有默认key，将切到对应卡片内容
    if ('defaultActiveKey' in this.props) {
      tabContent.forEach((tab, tabIndex) => {
        if (tabIndex !== 0 && tab.tabKey === defaultActiveKey) {
          this.handleTabClick(defaultActiveKey, tabIndex);
        }
      });
    }
  }

  componentWillUnmount(): void {
    if (this.tabVisible) {
      window.removeEventListener('scroll', this.bindScroll);
    }
  }

  getFadeTransiation(): fadeTransiation {
    return this.props.fadeTransiation;
  }

  getProps(prop: string): any {
    return this.props[prop];
  }

  handleTabClick(activeKey: string, index: number): void {
    this.setState({
      activeKey,
    });
    this.handleVideo(index, true);

    const cardSlick = this.tabCardDom.carouselCard;
    for (const element of cardSlick) {
      element.classList.add('slick-hide');
      element.classList.remove('slick-active', 'slick-current', 'slick-show');
    }
    cardSlick[index].classList.add('slick-active', 'slick-current', 'slick-show');
    cardSlick[index].classList.remove('slick-hide');
    this.tabCardDom.carousel.slickGoTo(index);
  }

  static TabPanel: typeof TabPanel = TabPanel;

  handleVideo(index: number, videoPlayFlag: boolean): void {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    const { props } = this;
    this.videoFlag = videoPlayFlag;
    this.activeIndex = index;
    let activeVideo: any;
    const activeCard = props.cardConfigList[this.activeIndex];
    const activeIsBgVideoDom = activeCard.hasBackgroundVideo && getAsset(activeCard.backgroundVideo);
    const activeIsFormVideoDom = activeCard.hasMedia && activeCard.media === 'video' && activeCard.foregroundVideo;

    if (this.videoListenerAdded) return;

    const videoLoadingListener = () => {
      if (activeIsBgVideoDom) {
        activeVideo = _this.cardDom[this.activeIndex] && _this.cardDom[this.activeIndex].cardBgVideoDom;
      } else if (activeIsFormVideoDom) {
        activeVideo = _this.cardDom[this.activeIndex] && _this.cardDom[this.activeIndex].cardForegroundVideo?.current;
      }

      if (this.videoFlag && ScrollManager.getInstance().checkElementInView(_this.tabDom) && activeVideo && activeVideo.poster) {
        // 如果前景视频配置了只播放一次，播放前需要把重播按钮隐藏
        if (_this.cardDom[this.activeIndex]?.cardForegroundVideoReplay?.current) {
          _this.cardDom[this.activeIndex].cardForegroundVideoReplay.current.style.display = 'none';
        }

        // 停止其他视频视频
        for (let i = 0; i < _this.cardDom.length; i += 1) {
          if (_this.cardDom[i].cardBgVideoDom && _this.cardDom[i].cardBgVideoDom.poster && _this.cardDom[i] !== _this.cardDom[this.activeIndex]) {
            _this.cardDom[i].cardBgVideoDom.pause();
          }

          if (_this.cardDom[i].cardForegroundVideo?.current && _this.cardDom[i] !== _this.cardDom[this.activeIndex]) {
            _this.cardDom[i].cardForegroundVideo.current.pause();
          }
        }

        const foregroundVideoPlayType = _this.cardDom[this.activeIndex]?.props?.foregroundVideoPlayType
        if (_this.cardDom[this.activeIndex]?.cardForegroundVideoPlay?.current && foregroundVideoPlayType === 'withController') {
          _this.cardDom[this.activeIndex].cardForegroundVideoPlay.current.style.display = 'none';
        }
        // 视频加载完成触发播放
        if (activeVideo.duration >= 0) {
          // eslint-disable-next-line no-param-reassign
          activeVideo.currentTime = 0;
          const playPromise = activeVideo.play();
          if (playPromise && playPromise.then && typeof playPromise.then === 'function') {
            playPromise.then(() => null, () => null).catch(() => null);

            if (_this.cardDom[this.activeIndex]?.cardForegroundVideoPause?.current && foregroundVideoPlayType === 'withController') {
              _this.cardDom[this.activeIndex].cardForegroundVideoPause.current.style.display = 'block';
            }
          }
          this.videoFlag = false;
        } 

        // 监听当前视频播放结束后的操作
        activeVideo.addEventListener('ended', () => {
          if (props.tabPresentation === 'carousel') {
            _this.tabCardDom.carousel.slick.slickNext();
            _this.setState({
              activeKey: props.tabContent[this.activeIndex].tabKey,
            });
          }
        });
      }
      // 如果当前tab是不在可视范围内，则停止当前视频播放；
      if (!ScrollManager.getInstance().checkElementInView(_this.tabDom) && activeVideo && !activeVideo.paused) {
        activeVideo.pause();
        this.videoFlag = true;
      }
    };
    ScrollManager.getInstance().addNode(_this.tabDom, {}, [videoLoadingListener]);
    this.videoListenerAdded = true;
  }

  bindScroll(): void {
    const { props } = this;
    // eslint-disable-next-line max-len
    const card = props.cardConfigList[0];
    const elmVisible = checkVisible(this.tabDom, 0, 0);
    const playSlick = this.tabVisible && elmVisible && props.tabPresentation === 'carousel' && !card.hasBackgroundVideo && (card.media === 'picture' || !card.hasMedia);
    // 如果没有背景视频也没有前景视频
    if (playSlick) {
      this.visualDom = true;
      this.tabVisible = false;
    }
  }

  renderCard(cardMargin: React.CSSProperties): JSX.Element {
    const { props } = this;
    const card = props.cardConfigList[0];
    const time = Number(props.tabCarouselInterval);
    const autoplayFlag = props.tabPresentation === 'carousel' && !card.hasBackgroundVideo && (card.media === 'picture' || !card.hasMedia);
    const parameter = {
      fade: true,
      speed: 600,
      autoplay: autoplayFlag,
      autoplaySpeed: time,
      touchMove: false,
      ...this.defaultActiveIndex ? { initialSlide: this.defaultActiveIndex } : {}  ,
      beforeChange: (oldIndex: number, newIndex: number) => {
        const activeIsBgVideoDom = this.cardDom && this.cardDom[newIndex] && this.cardDom[newIndex].cardBgVideoDom !== null;
        const activeIsFormVideoDom = this.cardDom && this.cardDom[newIndex] && this.cardDom[newIndex].cardForegroundVideo?.current !== null;
        if (this.tabCardDom.carousel && this.tabCardDom.carousel.slick && props.tabPresentation === 'carousel') {
          if (activeIsBgVideoDom || activeIsFormVideoDom) {
            // 当前dom节点是bg video 停止自动轮播 轮播时间根据视频的总时长决定
            this.tabCardDom.carousel.slick.slickPause();
            this.handleVideo(newIndex, true);
            this.setState({
              activeKey: props.tabContent[newIndex].tabKey,
            });
          } else {
            // 当前dom节点是图片
            // eslint-disable-next-line no-lonely-if
            if (this.visualDom) {
              this.setState({
                activeKey: props.tabContent[newIndex].tabKey,
              });
              this.tabCardDom.carousel.slick.slickPlay();
              this.tabCardDom.carousel.slick.slickNext();
            } else {
              this.tabCardDom.carousel.slick.slickGoTo(0);
            }
          }
        }
      },
    };

    return (
      <div style={cardMargin}>
        <TabCard
          id={props.id}
          parameter={parameter}
          cardConfigList={props.cardConfigList}
          fadeTransiation={props.fadeTransiation}
          mediaTransiation={props.mediaTransiation}
          cardGapWidth={props.cardGapWidth}
          gridWidth={props.gridWidth}
          tabPresentation={props.tabPresentation}
          ref={(c) => { this.tabCardDom = c; }}
        />
      </div>
    );
  }

  render(): JSX.Element {
    const { props } = this;
    const {
      activeKey,
    } = this.state;
    const { tabContent = [], hideTabBarWhenSingle } = props;
    const showTabBar = tabContent.length > 1 || tabContent.length === 1 && !hideTabBarWhenSingle;

    const tabBarCls = classNames(
      'tabh-atom-horizontal-tabs',
      `tabh-atom-horizontal-tabs-${props.tabAlign}`,
    );

    let cardMargin: React.CSSProperties;
    switch (props.tabPosition) {
      case 'top':
        cardMargin = { marginTop: props.spacingFromTheCard };
        break;
      default:
        cardMargin = { marginBottom: props.spacingFromTheCard };
    }

    return (
      <div
        className={tabBarCls}
        ref={(t) => { this.tabDom = t; }}
      >
        {props.tabPosition === 'center' ? this.renderCard(cardMargin) : null}
        {showTabBar && <TabBar
          key="tabBar"
          responsiveBorder={props.responsiveBorder}
          panels={props.children}
          activeKey={activeKey}
          tabAlign={props.tabAlign}
          tabTheme={props.tabTheme}
          tabBarIconHeight={props.tabBarIconHeight}
          tabBarContent={props.tabBarContent}
          tabOverallWidth={props.tabOverallWidth}
          intervalBisection={props.intervalBisection}
          onTabClick={this.handleTabClick}
        />}
        {props.tabPosition === 'top' ? this.renderCard(cardMargin) : null}
        <TabContent
          panels={props.children}
          activeKey={activeKey}
          tabAlign={props.tabAlign}
          tabTheme={props.tabTheme}
          key="tabContent"
        />
      </div>
    );
  }
}
