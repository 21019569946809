import React from "react"

export enum TextFontSize {
  'default' = '',
  '10px' = '10px',
  '12px' = '12px',
  '14px' = '14px',
  '16px' = '16px',
  '18px' = '18px',
  '20px' = '20px',
  '24px' = '24px',
  '28px' = '28px',
  '32px' = '32px',
  '40px' = '40px',
  '48px' = '48px',
  '56px' = '56px',
  '64px' = '64px',
  '72px' = '72px',
  '80px' = '80px',
}

export enum TextFontWeight {
  'default' = '',
  'regular' = 400,
  'semibold' = 600,
}

export enum TextLineHeight {
  'default' = '',
  'standard' = 'standard',
  'compact' = 'compact',
}

enum StandardLineHeightMap {
  '10px' = '16px',
  '12px' = '16px',
  '14px' = '24px',
  '16px' = '24px',
  '18px' = '28px',
  '20px' = '28px',
  '24px' = '32px',
  '28px' = '36px',
  '32px' = '40px',
  '40px' = '48px',
  '48px' = '56px',
  '56px' = '64px',
  '64px' = '72px',
  '72px' = '80px',
  '80px' = '88px',
  '88px' = '96px',
  '96px' = '104px',
  '104px' = '112px',
  '112px' = '120px',
  '120px' = '128px',
}

enum CompactLineHeightMap {
  '10px' = '16px',
  '12px' = '16px',
  '14px' = '20px',
  '16px' = '20px',
  '18px' = '24px',
  '20px' = '24px',
  '24px' = '28px',
  '28px' = '32px',
  '32px' = '36px',
  '40px' = '48px',
  '48px' = '52px',
  '56px' = '60px',
  '64px' = '68px',
  '72px' = '76px',
  '80px' = '84px',
  '88px' = '92px',
  '96px' = '100px',
  '104px' = '108px',
  '112px' = '116px',
  '120px' = '124px',
}

export function GetLineHeight(fontSize?: TextFontSize, lineHeight?: TextLineHeight): any {
  return lineHeight;
  // if (!fontSize || !lineHeight) {
  //   return undefined;
  // }
  // const LineHeightMap = lineHeight === TextLineHeight.compact ? CompactLineHeightMap : StandardLineHeightMap;
  // return LineHeightMap[fontSize];
}

export const elementSelectItems = [
  { label: '默认', value: '' },
  { label: 'h1', value: 'h1' },
  { label: 'h2', value: 'h2' },
  { label: 'h3', value: 'h3' },
  { label: 'h4', value: 'h4' },
  { label: 'h5', value: 'h5' },
  { label: 'h6', value: 'h6' },
  { label: 'p', value: 'p' },
];

export enum ElementLabel {
  'h1' = 'h1',
  'h2' = 'h2',
  'h3' = 'h3',
  'h4' = 'h4',
  'h5' = 'h5',
  'h6' = 'h6',
  'p' = 'p',
}

export const elementSelectorProps = {
  label: '标签设置',
  type: 'select',
  items: elementSelectItems,
};

const TagText = (props) => {
  const { tagType, ...rest } = props;
  if (tagType === 'h1') {
    return <h1 {...rest} />;
  } if (tagType === 'h2') {
    return <h2 {...rest} />;
  } if (tagType === 'h3') {
    return <h3 {...rest} />;
  } if (tagType === 'h4') {
    return <h4 {...rest} />;
  } if (tagType === 'h5') {
    return <h5 {...rest} />;
  } if (tagType === 'h6') {
    return <h6 {...rest} />;
  } if (tagType === 'p') {
    return <p {...rest} />;
  }
}

export default TagText;