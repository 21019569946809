import React from "react"
import SbEditable from "storyblok-react"
import Image from './image'
import './slider.css'

export default class extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeIndex : 0
        }
    }

    changeImage = direction => {
        let pos = this.state.activeIndex;
        let max = this.props.blok.slides.length;
        if (pos + direction < 0 ){
            pos = max-1;
        }
        else if(pos + direction >= max){
            pos = 0;
        }
        else{
            pos += direction
        }
        this.setState({ activeIndex : pos});
    }

    render() {
        const { blok } = this.props

        return (
        <SbEditable content={blok}>

            <div className="slider">
                <button className="previous-slide" onClick={ () => this.changeImage(-1) }>
                    &larr;
                </button>
                { blok.slides.map((slide, key) => {
                    return (
                        <div className={`slide ${ key === this.state.activeIndex ? 'active' : null }`} key={key} >
                            <Image blok={slide} />
                        </div>
                    )
                }) }
                <button className="next-slide" onClick={ () => this.changeImage(1) }>
                    &rarr;
                </button>
            </div>
        </SbEditable>
      )
  }
}
