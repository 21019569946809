import React, { useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import {
  checkIsIE,
} from '../../../../utils/helper';
import { DeviceInfo } from '../../../../hooks/device';
import Card from './card';
import AnimationCard from './animationCard';
import './style.scss'



const InfoCard = (props) => {
  const { blok, lang } = props;
  const {
    infoCardList = [],
  } = blok;

  const [isIE, setIsIE] = useState(false);
  const { isMobile: isM } = DeviceInfo();

  useEffect(() => {
    // 是否是ie
    setIsIE(checkIsIE());
  },[])
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="ec1706-info-card-wrapper">
        <div className="ec1706-info-card-list">
          {
            infoCardList && infoCardList.length > 0 && infoCardList.map((item) => {
              let children = null;
              switch (item.component) {
                case 'animation_info_card':
                  children = (<AnimationCard content={item} isM={isM} lang={lang} />)
                  break;
                case 'transmission_card_info':
                  children = (<Card content={item} isIE={isIE} isM={isM} lang={lang} transmissionInfoCard={true} />)
                  break;
                case 'info_card':
                default:
                  children = (<Card content={item} isIE={isIE} isM={isM} lang={lang} />)
                  break
              }
              return (
                <div className={`ec1706-info-card-item-wrapper${item.imageType && item.imageType === 'bg' ? ' bg-image-layout' : ''}`} key={item._uid} style={{ backgroundColor: item.bgColor && item.bgColor.color, padding: isM ?  (item.mobileSectionPadding || '64px 0 24px') : (item.sectionPadding || '128px 0') }}>
                  {children}
                </div>
              )
            })
          }
        </div>
      </div>
    </SbEditable>
  )
}

export default InfoCard;

