/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import TabBar from './TabBar';
import Tabcontent from './TabContent';
import TabCard from './TabCard';
import { getAsset } from '../../../../../utils/helper';

import './style.scss';

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'none' = 'none',
}

export enum VerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum tabPosition {
  'top' = 'top',
  'center' = 'center',
}

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}

export interface Props {
  id?: string;
  gaSend?: () => {};
  responsiveBorder: number;
  // 基础配置
  tabPosition: tabPosition;
  tabAlign?: tabAlign;
  tabBarMargin?: string;
  tabOverallWidth?: string;
  spacingFromTheCard?: string;
  tabBarContent?: tabBarContent;
  tabContentStyle?: Array<{ styleKey: string; styleValue: string }>;
  tabBarIconHeight?: string; // tabBar icon 高度
  tabTheme?: tabTheme; // tab标题颜色
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;
  videoAllDowngrade: boolean;
  tabDesc?: boolean;
  hideTabBarWhenSingle?: boolean; // 单个时是否不展示tab

  // 功能配置
  activeKey?: string;
  defaultActiveKey?: string;
  children: React.ReactNode | string;
  tabContent?: Array<TabPanelItem>;
  cards?: Array<Record<string, any>>;
  paddingStyle: React.CSSProperties;
}

export interface State {
  activeKey?: string;
}

const checkElementInView = (element: JSX.Element): boolean => {
  let el = element;
  if (el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;
    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
    return (
      top < (window.pageYOffset + window.innerHeight)
      && left < (window.pageXOffset + window.innerWidth)
      && (top + height) > window.pageYOffset
      && (left + width) > window.pageXOffset
    );
  }
  return false;
};

export default class Tabs extends React.PureComponent<Props, State> {
  tabCard: any;

  tab: any;

  activeIndex: number;

  clickCount: number;

  defaultActiveIndex?: number;

  constructor(props: Props) {
    super(props);
    const activeKey = props.defaultActiveKey || (props.tabContent && props.tabContent[0] && props.tabContent[0].tabKey);
    this.state = { activeKey };

    this.defaultActiveIndex = 0;
    if (props.defaultActiveKey) {
      props.tabContent.forEach((tab, tabIndex) => {
        if (tab.tabKey === props.defaultActiveKey) {
          this.defaultActiveIndex = tabIndex;
        }
      });
    }

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
    this.activeIndex = 0;
    this.clickCount = 0;
  }

  componentDidMount(): void {
    const ua = window.navigator.userAgent;
    const isIOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const isUC = !!ua.match(/UCBrowser/ig);
    const isAndroid = !!ua.match(/android/ig);
    const isQQBrowser = !!ua.match(/\bm?qqbrowser\/([0-9.]+)/ig);
    const downgrade = isAndroid || (isIOS && (isUC || isQQBrowser));
    if (!downgrade) {
      this.handleVideo(0, true);
    }

    // 如果有默认key，将切到对应卡片内容
    const { tabContent, defaultActiveKey } = this.props;
    if ('defaultActiveKey' in this.props) {
      tabContent.forEach((tab, tabIndex) => {
        if (tabIndex !== 0 && tab.tabKey === defaultActiveKey) {
          this.handleTabClick(defaultActiveKey, tabIndex);
        }
      });
    }
  }

  handleVideo(index: number, videoPlayFlag: boolean): void {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    const { props } = this;
    let videoFlag = videoPlayFlag;
    let activeVideo: any;
    const activeCard = props.cards[index];
    const activeIsBgVideoDom = activeCard.hasBackgroundVideo && getAsset(activeCard.backgroundVideo);
    const activeIsFormVideoDom = activeCard.hasMedia && activeCard.media === 'video' && activeCard.foregroundVideo;
    let { clickCount } = this;
    clickCount += 1;
    this.clickCount = clickCount;
    this.activeIndex = index;
    // eslint-disable-next-line complexity
    function videoLoadingListener(): void {
      let cardDom: any = null;
      if (_this.tabCard && _this.tabCard.cardDom) {
        cardDom = _this.tabCard.cardDom;
      }
      if (activeIsBgVideoDom) {
        activeVideo = cardDom && cardDom[_this.activeIndex] && cardDom[_this.activeIndex].cardBgVideoDom;
      } else if (activeIsFormVideoDom) {
        activeVideo = cardDom && cardDom[_this.activeIndex] && cardDom[_this.activeIndex].cardForegroundVideo?.current;
      }

      if (videoFlag && checkElementInView(_this.tab) && activeVideo) {
        // 如果前景视频配置了只播放一次，播放前需要把重播按钮隐藏
        if (cardDom[_this.activeIndex] && cardDom[_this.activeIndex].cardForegroundVideoReplay?.current) {
          cardDom[_this.activeIndex].cardForegroundVideoReplay.current.style.display = 'none';
        }
        // 停止其他视频视频
        for (const [i, element] of cardDom.entries()) {
          if (element.cardBgVideoDom && element.cardBgVideoDom.poster && i !== _this.activeIndex) {
            element.cardBgVideoDom.pause();
          }

          if (element.cardForegroundVideo?.current && i !== _this.activeIndex) {
            element.cardForegroundVideo.current.pause();
          }
        }

        const foregroundVideoPlayType = cardDom[_this.activeIndex] && cardDom[_this.activeIndex].props && cardDom[_this.activeIndex].props.foregroundVideoPlayType;
        if (cardDom[_this.activeIndex] && cardDom[_this.activeIndex].cardForegroundVideoPlay?.current && foregroundVideoPlayType === 'withController') {
          cardDom[_this.activeIndex].cardForegroundVideoPlay.current.style.display = 'none';
        }
        // 视频加载完成触发播放
        if (activeVideo.duration >= 0) {
          // eslint-disable-next-line no-param-reassign
          activeVideo.currentTime = 0;
          const playPromise = activeVideo.play();
          if (playPromise && playPromise.then && typeof playPromise.then === 'function') {
            playPromise.then(() => null, () => null).catch(() => null);

            if (cardDom[_this.activeIndex] && cardDom[_this.activeIndex].cardForegroundVideoPause?.current && foregroundVideoPlayType === 'withController') {
              cardDom[_this.activeIndex].cardForegroundVideoPause.current.style.display = 'block';
            }
          }
          videoFlag = false;
        } else if (_this.clickCount === clickCount) {
          requestAnimationFrame(videoLoadingListener);
        }
      }
      // 如果当前tab是不在可视范围内，则停止当前视频播放；
      if (!checkElementInView(_this.tab) && activeVideo && !activeVideo.paused) {
        activeVideo.pause();
        videoFlag = true;
      }
    }
    videoLoadingListener();
  }

  handleTabClick(key: string, index: number): void {
    this.handleVideo(index, true);
    this.setState({ activeKey: key });
    const cardSlick = this.tabCard.carouselCard;
    for (const element of cardSlick) {
      element.classList.add('slick-hide');
      element.classList.remove('slick-active', 'slick-current', 'slick-show');
    }
    cardSlick[index].classList.add('slick-active', 'slick-current', 'slick-show');
    cardSlick[index].classList.remove('slick-hide');
    this.tabCard.carousel.slick.slickGoTo(index);
  }

  render(): JSX.Element {
    const { props, defaultActiveIndex } = this;
    const { activeKey } = this.state;
    const { tabContent = [], hideTabBarWhenSingle } = props;
    const showTabBar = tabContent.length > 1 || tabContent.length === 1 && !hideTabBarWhenSingle;
    return (
      <div ref={(t) => { this.tab = t; }} className={classNames(`mtabh-tab-horizontal-theme-${props.tabTheme}`)}>
        { props.tabPosition === 'center' ? (
          <div style={props.paddingStyle}>
            <TabCard
              id={props.id}
              gaSend={props.gaSend}
              tabContent={props.tabContent}
              spacingFromTheCard={props.spacingFromTheCard}
              tabPosition={props.tabPosition}
              cards={props.cards}
              fadeTransiation={props.fadeTransiation}
              mediaTransiation={props.mediaTransiation}
              videoAllDowngrade={props.videoAllDowngrade}
              ref={(t) => { this.tabCard = t; }}
              defaultActiveIndex={defaultActiveIndex}
            />
          </div>
        ) : null}
        {showTabBar && <TabBar
          responsiveBorder={props.responsiveBorder}
          tabAlign={props.tabAlign}
          tabBarMargin={props.tabBarMargin}
          activeKey={activeKey}
          tabContent={props.tabContent}
          onTabClick={this.handleTabClick}
          tabBarContent={props.tabBarContent}
          tabBarIconHeight={props.tabBarIconHeight}
          tabOverallWidth={props.tabOverallWidth}
        />}

        { props.tabPosition === 'top' ? (
          <div style={props.paddingStyle}>
            <TabCard
              tabContent={props.tabContent}
              spacingFromTheCard={props.spacingFromTheCard}
              tabPosition={props.tabPosition}
              cards={props.cards}
              fadeTransiation={props.fadeTransiation}
              mediaTransiation={props.mediaTransiation}
              videoAllDowngrade={props.videoAllDowngrade}
              ref={(t) => { this.tabCard = t; }}
            />
          </div>
        ) : null}
        { props.tabBarContent !== 'none' && props.tabDesc ? (
          <div style={props.paddingStyle}>
            <Tabcontent
              activeKey={activeKey}
              tabAlign={props.tabAlign}
              tabContent={props.tabContent}
              tabContentStyle={props.tabContentStyle}
            />
          </div>
        ) : null }
      </div>
    );
  }
}
