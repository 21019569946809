import React from "react";
import SbEditable from "storyblok-react";
import "./button.scss";
export default class extends React.Component {
  /**
   * 登出账号
   */
  logout = (e) => {
    const { lang } = this.props;
    e.preventDefault();

    window.localStorage.removeItem("authorization_token");
    window.localStorage.removeItem("refresh_token");

    let url = `${process.env.GATSBY_ACCOUNT_API_URL}logout?service=${window.location.origin}/${lang === 'default' ? '' : lang}`
    window.location.href = url;
  };

  render = () => {
    const { content } = this.props;

    if (content) {
      return (
        <SbEditable content={content}>
          <a
            href="javascript:void(0);"
            className={`cta logout-btn ${content.button_type}`}
            onClick={(e) => this.logout(e)}
          >
            <span>{content.text}</span>
          </a>
        </SbEditable>
      );
    } else {
      return <></>;
    }
  };
}
