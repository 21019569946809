/* eslint-disable */
import classNames from 'classnames';
import React from 'react';
import VideoPlayer from '../../video';
import LazyWrapper from '../../utils/LazyWrapper';
import { getAsset } from '../../../../utils/helper';
import Tab from './tab';
import Card from '../HeroVertical/card';

import './style.scss';
import { StoryblokAsset } from '../../pc/InfoCardHorizontal/card/type';

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export enum VerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum tabPosition {
  'top' = 'top',
  'center' = 'center',
}

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}

export interface Props {
  id?: string;
  gaSend?: () => {};
  responsiveBorder: number;
  videoAllDowngrade: boolean;
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;
  componentVerticalAlign: VerticalAlign;
  componentMinHeight: string;
  pureBackgroundColor: string;
  backgroundGradient: string;
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  // 背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundFixed?: BackgroundFixed;
  backgroundParallax?: boolean;
  // 背景视频
  hasBackgroundVideo: boolean;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideo?: StoryblokAsset;
  backgroundVideoPoster?: StoryblokAsset;

  // tab basic
  tabDefaultActiveIndex?: number;
  tabPosition: tabPosition;
  tabAlign: tabAlign;
  tabBarMargin?: string;
  tabOverallWidth?: string;
  spacingFromTheCard?: string;
  tabBarContent?: tabBarContent;
  tabContentStyle?: Array<{ styleKey: string; styleValue: string }>;
  tabBarIconHeight?: string;
  tabTheme: tabTheme;
  tabDesc?: boolean;
  hideTabBarWhenSingle?: boolean; // 单个时是否不展示tab

  // 卡片
  cards?: Array<Record<string, any>>;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export default class TabHorizontal extends React.Component<Props, {}> {
  public static defaultProps = {
    videoAllDowngrade: false,
    componentVerticalAlign: VerticalAlign['center-align'],
    componentMinHeight: '',
    pureBackgroundColor: '',
    backgroundGradient: '',

    // 内边距
    paddingTop: '64px',
    paddingBottom: '64px',
    paddingLeft: '24px',
    paddingRight: '24px',

    // 模块过渡
    fadeTransiation: FadeTransiation.none,
    mediaTransiation: FadeTransiation.none,

    // 背景图片
    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundPosition: 'center',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,

    // 背景视频
    hasBackgroundVideo: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: true,
    backgroundVideo: {},
    backgroundVideoPoster: {},

    // 结构
    tabDefaultActiveIndex: 1,
    tabPosition: tabPosition.center,
    tabAlign: tabAlign.center,
    tabOverallWidth: '',
    spacingFromTheCard: '24px',
    tabBarContent: tabBarContent.headline,
    tabBarIconHeight: '32px',
    tabTheme: 'dark',
    tabDesc: true,
    hideTabBarWhenSingle: false,

    // 卡片
    cards: [{
      ...Card.defaultProps,
      ...{
        tabBarIcon: {},
        tabBarHoverIcon: {},
        tabBarTitle: 'tab name1',
        tabDesc: 'tab desc1',
        tabKey: 'tab1',
      },
    }, {
      ...Card.defaultProps,
      ...{
        tabBarIcon: {},
        tabBarHoverIcon: {},
        tabBarTitle: 'tab name2',
        tabDesc: 'tab desc2',
        tabKey: 'tab2',
      },
    }],
  };

  render(): JSX.Element {
    const { props } = this;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const aosFade = props.fadeTransiation;
    const isUC = typeof window !== 'undefined' && window.navigator.userAgent.match(/UCBrowser/ig);
    const verticalAlignStyleName = `mtabh-e-vertical-${props.componentVerticalAlign}`;
    const { visibleByDefault } = props;
    let backgroundImage;
    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundAttachment?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
    } = { backgroundColor: props.pureBackgroundColor };

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = getAsset(props.backgroundImage);
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;
      componentStyle.backgroundAttachment = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';

      if (props.backgroundGradient && !isUC) {
        componentStyle.backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      componentStyle.backgroundImage = props.backgroundGradient;
    }

    const componentWrapperStyle: {
      minHeight: string;
      paddingTop: string;
      paddingBottom: string;
    } = {
      minHeight: props.componentMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
    };

    const paddingStyle: {
      paddingLeft: string;
      paddingRight: string;
    } = {
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
    };

    const videoPosterAttr = {};
    videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster'] = getAsset(props.backgroundVideoPoster);

    const renderBackgroundVideo = (): JSX.Element => {
      const downgradeDom = (
        <div className="mtabh-e-component-downgrade-image" style={{ backgroundImage: `url(${getAsset(props.backgroundVideoPoster)})` }} />
      );
      return (
        <div className="mtabh-e-component-background-video">
          <VideoPlayer
            visiblePlay={props.backgroundVideoVisiblePlay}
            replayWhenRevisible={props.backgroundVideoVisiblePlay}
            downgradeDom={downgradeDom}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <video muted className="bgvideo" {...videoPosterAttr} loop={props.backgroundVideoLoop}>
              {getAsset(props.backgroundVideo) && (
                <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' src={getAsset(props.backgroundVideo)} />
              )}
            </video>
          </VideoPlayer>
        </div>
      );
    };

    const backgroundVideo = props.hasBackgroundVideo && !props.videoAllDowngrade ? renderBackgroundVideo() : null;
    const tabContent = (props.cards || []).map(({
      tabBarIcon, tabBarHoverIcon, tabBarTitle, tabDesc, tabKey,
    }) => ({
      tabBarIcon, tabBarHoverIcon, tabBarTitle, tabDesc, tabKey,
    })) as TabPanelItem[];

    const tabLength = tabContent.length;
    // eslint-disable-next-line no-restricted-globals, radix
    const propsDefaultActiveIndex = isNaN(+props.tabDefaultActiveIndex) ? 0 : parseInt(String(+props.tabDefaultActiveIndex));
    const defaultActiveIndex = propsDefaultActiveIndex <= tabLength && propsDefaultActiveIndex >= 1 ? propsDefaultActiveIndex - 1 : 0;
    tabContent?.forEach((item, index) => {
      item.tabKey = item.tabKey || `tab${index}`;
    });
    const sectionCls = classNames(
      'atom-design-v1',
      'mtabh-e-tab-horizontal-component',
    );

    const child = [
      backgroundVideo,
      <div className={`mtabh-e-tab-horizontal-wrapper ${verticalAlignStyleName}`} style={componentWrapperStyle}>
        <div className="mtabh-e-tab-horizontal-container">
          <Tab
            id={props.id}
            gaSend={props.gaSend}
            responsiveBorder={props.responsiveBorder}
            tabPosition={props.tabPosition}
            spacingFromTheCard={props.spacingFromTheCard}
            tabAlign={props.tabAlign}
            tabContent={tabContent}
            tabBarContent={props.tabBarContent}
            tabContentStyle={props.tabContentStyle}
            tabBarMargin={props.tabBarMargin}
            tabBarIconHeight={props.tabBarIconHeight}
            tabOverallWidth={props.tabOverallWidth}
            tabTheme={props.tabTheme}
            cards={props.cards}
            defaultActiveKey={tabContent[defaultActiveIndex].tabKey}
            fadeTransiation={props.fadeTransiation}
            mediaTransiation={props.mediaTransiation}
            videoAllDowngrade={props.videoAllDowngrade}
            tabDesc={props.tabDesc}
            paddingStyle={paddingStyle}
            hideTabBarWhenSingle={props.hideTabBarWhenSingle}
          />
        </div>
      </div>,
    ];

    return (
      <LazyWrapper
        parallax={props.hasBackgroundImage && props.backgroundParallax}
        type="bg"
        src={backgroundImage}
        className={sectionCls}
        style={componentStyle}
      >
        {child}
      </LazyWrapper>
    );
  }
}
