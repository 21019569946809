import React from "react"
import API from "../../utils/api"
import "./dealer-locator.css"
//import Map from "./map"
import Modal from "react-modal"
import t from "../../utils/languages"

class Dealers extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            dealers: [], 
            allCountries: [], 
            allDealers: [], 
            selectedDealer: {},
            country: '',
            showModal: false,
            isLoading: true

        };


        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    componentDidMount = () => {
        Promise.all([API.get('/dealers'), API.get('/countries')])
        .then((res) => {
            var dealers = res[0].data;
            var countries = res[1].data.countries;
            this.setState({allCountries : countries, country : res[1].data.userCountry, allDealers : dealers, isLoading: false });
        })
        .catch((err) => {
            console.log(err)
        })
      }


    getProducts = () => {
        var prods = [];
        var lang = this.props.lang;
        this.state.selectedDealer.products && this.state.selectedDealer.products.map(p =>{
            { 
                switch (p) {
                    case "H5":
                        prods.push( t('dealerLocator.HSystem', lang ))
                        break;
                    case "H6":
                        prods.push(t('dealerLocator.HSystem', lang ))
                        break;
                    case "X1D":    
                        prods.push(t('dealerLocator.XSystem', lang ))
                        break;
                    default:
                        prods.push(p)
                        break;
                }
    
            }})
        return prods.join(', ');
    }

    render (){
        var lang = this.props.lang
        return (
            <div className="dealers-wrapper">
                <div className="dealer__filter">
                    <label htmlFor="country">
                        <div>{ t('newsletter.selectYourCountry', lang) }</div> 
                        <select id="country" value={this.state.country} onChange={(e) => this.setState({ country: e.target.value }) } >
                            { this.state.allCountries && this.state.allCountries.map((c, index) => <option key={index} value={c.id}>{c.t}</option>)}
                        </select>
                    </label> 
                </div>
                <div className="dealers">
                    
                    { this.state.allDealers && this.state.allDealers.filter((d) => d.countryCode === this.state.country).map((d, i)=>{
                        return (
                            <div className="dealer-block" key={ 'dealer-' + i }>
                                
                                <img src="/assets/icons/dealer-locator-black.svg" />
                                <div className="db__text" >
                                    <h4 key={i}>{d.name}</h4>
                                    <div>
                                        <p>{ d.address && <span>{d.address}<br /></span> }<span>{d.city}, {d.country}</span></p>
                                        <a href="#" onClick={() =>{  this.setState( {selectedDealer : d } ); this.handleOpenModal(); }}>{ t('dealerLocator.showInformation', lang) } ⟶</a>
                                    </div>
                                </div>
                            </div>
                            )
                    })}
                    { this.state.allDealers.filter((d) => d.countryCode === this.state.country).length === 0 && !this.state.isLoading && 
                        <h5 style={{ padding:'0 10px' } }>{ t('dealerLocator.noDealers', lang) }</h5>
                    }
                    <div className="dealer-contact" >
                        <h1>{this.state.selectedDealer.name}</h1>
                    </div>
                </div>
                {/*<div className="map-wrapper">
                    <Map blok={this.props.blok} dealers={this.state.allDealers}></Map>
                </div> */}

                <Modal className="modal" overlayClassName="overlay" isOpen={this.state.showModal} contentLabel="">
                    <button className="close" onClick={this.handleCloseModal}>
                        <img alt="close" src="/assets/icons/close-black.svg" />
                    </button>
                    <div className="modal__content">
                        <div className=" editorial column">
                            <div className="et center hundred">
                                <h1>{ this.state.selectedDealer.name } </h1>
                                <h3>{ this.state.selectedDealer.address }</h3>
                                <h3>{ this.state.selectedDealer.city }, { this.state.selectedDealer.country }</h3>

                            </div>
                            <div className="et center-center hundred">
                                <h5>
                                {
                                  this.getProducts()
                                }
                                </h5>
                                
                            </div>
                            <div className="et center-center hundred">
                                <p>
                                    <a href={ 'mailto:' + this.state.selectedDealer.email } >{ this.state.selectedDealer.email } </a>
                                </p>
                                <p>
                                    <a href={ 'tel:' + this.state.selectedDealer.phone }>{ this.state.selectedDealer.phone }</a>
                                </p>
                            </div>
                            <div className="et center-center hundred">
                                <div>
                                    {/* 语言为中文且记录百度地图坐标*/}
                                   {(lang==='zh-cn' && (this.state.selectedDealer.baidulat && this.state.selectedDealer.baidulng))?
                                    (
                                            <p>
                                                <a className="cta secondary" target="_blank"  href={ 'http://api.map.baidu.com/geocoder?location='+this.state.selectedDealer.baidulat+','+this.state.selectedDealer.baidulng+'&output=html' } ><span>{ t('dealerLocator.getDirections', lang) }</span></a>
                                            </p>
                                    ):
                                    ((this.state.selectedDealer.lat && this.state.selectedDealer.lng) &&
                                        <p>
                                            <a className="cta secondary" target="_blank"  href={ 'https://www.google.com/maps/search/?api=1&query='+this.state.selectedDealer.lat+',' + this.state.selectedDealer.lng } ><span>{ t('dealerLocator.getDirections', lang) }</span></a>
                                        </p>
                                    )
                                    }
                                    { this.state.selectedDealer.url &&
                                    <p>
                                        <a className="cta secondary" target="_blank" href={ this.state.selectedDealer.url }><span>{ t('dealerLocator.dealerWeb', lang) }</span></a>
                                    </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        )
    }

}

export default Dealers
