import React from "react"
import SbEditable from "storyblok-react"
import "./button.scss"
import t from "../../utils/languages"
import Modal from "react-modal"


export default class extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showModal : false,
  		showLoading : true
    }
  }
  closeModal(){
    var timer = setTimeout(() => {
      if(document.getElementsByClassName('ln__cta').length) {
      document.getElementsByClassName('ln__cta')[document.getElementsByClassName('ln__cta').length-1].classList.remove('eventDisable')
      }
      clearTimeout(timer);
    }, 50);
  }
  openModal(){
    this.setState({ showModal : true, showLoading : true });
    if(document.getElementsByClassName('ln__cta').length) {
    document.getElementsByClassName('ln__cta')[document.getElementsByClassName('ln__cta').length-1].classList.add('eventDisable')
    }
  }
  render() {
    const { content, lang } = this.props
    const url = content.url.url;
    var videoUrl = 	url ? url.split("v=").pop() : "";
    videoUrl = "https://www.youtube.com/embed/" + videoUrl + "?&autoplay=0&feature=oembed&rel=0&enablejsapi=1&version=3&playerapiid=ytplayer";


    return (
      <SbEditable content={content}>
        {
          <>
            <button className="video-link" onClick={() => this.openModal() } >
            <img src={content.play_icon?.filename || "/assets/icons/play.svg"} alt="play" />
              {
                content.hideText ? null : (
                  <h3>{ t('video.button', lang)}</h3>
                )
              }
            </button>
            <Modal className="modal black fullscreen youtube-btn-modal" overlayClassName="overlay" isOpen={this.state.showModal} contentLabel="" shouldFocusAfterRender={false} onAfterClose={this.closeModal}>
              <button className="close" onClick={() => this.setState({ showModal : false,  showLoading : true})}>
                <img alt="close" src="/assets/icons/close-white.svg" />
              </button>
              <img className="loading-indicator" src="/assets/icons/loading-indicator.svg" alt="Loading" style={{ display: `${this.state.showLoading ? 'block' : 'none'}` }}/>
              <img className="loading-indicator" src="/assets/icons/loading-indicator.svg" alt="Loading" style={{ display: `${this.state.showLoading ? 'block' : 'none'}` }}/>
              <div className="modal__content">
              <iframe id="videoIframe" title={videoUrl} style={{ height: 'calc(100vh - 70px)', padding: '0 20px', width:'100%', border:'none', visibility: `${this.state.showLoading ? 'hidden' : 'visible'}` }} src={videoUrl} onLoad={() => this.setState({ showLoading : false })}></iframe>  
              </div>

            </Modal>
          </>
        }
      </SbEditable>
    )
  }
}
