import React from "react";
import SbEditable from "storyblok-react";
import "./warm_up_banner.css";
import MarkDown from "./markdown";
import ButtonPlayInformation from "./button_play_information";

export default class extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { blok, lang } = this.props;
    return (
      <SbEditable content={blok}>
       <div className="banner warm_up_banner"> 
        <div className="banner__bg">
          <div class="banner_mobile_show default_minheight">
            <picture>
              <source media="(max-width: 767px)" srcSet={blok.imageMobile.filename} />
              <img
                src={blok.imageMobile.filename}
                class="default_minheight banner_img"
                alt=""
                style={{minHeight: blok.minHeightMobile ? blok.minHeightMobile : null}}
              />
            </picture>
          </div>
          <div class="banner_pc_show default_minheight">
            <picture>
              <source media="(min-width: 768px)" srcSet={blok.imagePc.filename} />
              <img
                src={blok.imagePc.filename}
                class="default_minheight banner_img"
                alt=""
                style={{minHeight: blok.minHeight ? blok.minHeight : null}}
              />
            </picture>
          </div>
          <div className="b__content__wrapper">
            <aside
              className={`b__content ${blok.vertical_align} ${blok.horizontal_align}`}
            >
              <div className={`b__content__message ${blok.message_color}`}>
                <h1 dangerouslySetInnerHTML={MarkDown(blok.time)} className="warm_up_time" />
                <div className="warm_up_title">{blok?.title}</div>
                <div className="btn-wrapper-group">
                  {blok.button &&
                    blok.button.length > 0 &&
                    blok.button.map((item) => (
                      <div className="btn-wrapper" key={item._uid}>
                        <ButtonPlayInformation
                          content={item}
                          key={item._uid}
                          lang={lang}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
       </div>
      </SbEditable>
    );
  }
}
