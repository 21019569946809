import React, { useState } from 'react';
import SbEditable from "storyblok-react"
//import API from "../../utils/api"
import "./filters.css"
import "./events-listing.css"
import Link from "gatsby-link"
import LinkFix from "../../utils/links"

import { GetEvents } from "../../hooks/events"
//import { Countries } from "../../hooks/countries"
//import t from "../../utils/languages"


const EventListing = props => {

    const evnts = GetEvents()
    //const cntrs = Countries();

    /*const [country, setCountry] = useState('')
    const [countries] = useState(cntrs)*/
    const [events] = useState(evnts)
    //const lang = props.lang

    /*useEffect(() => {
        API.get("/countries")
        .then(result => {
            setCountry(result.data.userCountry )
        })
    })*/

    return (
        <SbEditable content={props}>
            {/*
            <div className="filters">
                <div className="filters__container">
                    <label htmlFor="country">
                        <div>{ t('event.selectCountry', lang)}</div> 
                        <select id="country" value={country} onChange={(e) => setCountry(e.target.value) } >
                            { countries && countries.map((c, index) => <option key={index} value={c.id}>{c.t}</option>)}
                        </select>
                    </label> 
                </div>
            </div>
            */}
            <div className="events">
                <div className="event-items">
                    { events && events.filter(d => { 
                        return d.lang === props.lang} )
                        .map((d, i)=>{
                            return (
                                <div className="event-item" key={d.url}>
                                    <Link to={ d.url } style={ {textDecoration: 'none'} }>
                                        <img src={d.img} /> 
                                        <h5>
                                            <span>{ d.title}</span>
                                        </h5>
                                    </Link> 
                                    {/*<h5>
                                        { new Date(Date.parse(d.start)).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric'})}
                                    </h5>*/}
                                    <h5>Online event</h5>
                                </div>
                            )
                    })}
                </div>
            </div> 
        </SbEditable>
    )

}

export default EventListing