/* eslint-disable */
// @ts-nocheck
import React from 'react';
import SbEditable from "storyblok-react"
import Carousel from '../../../utils/Carousel';
import Card from '../../InfoCardHorizontal/card';

import './style.scss';

export enum fadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export interface Props {
  id?: string;
  parameter?: any;
  fadeTransiation: fadeTransiation;
  mediaTransiation: fadeTransiation;
  gridWidth?: string;
  tabPresentation?: string;
  cardGapWidth?: string;
  cardConfigList?: Array<Record<string, any>>;
}

export default class TabCard extends React.Component<Props, {}> {
  cardDom: any;

  carousel: any;

  carouselCard: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.getProps = this.getProps.bind(this);
    this.cardDom = [];
    this.carouselCard = [];
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  getProps(prop: string): any {
    return this.props[prop];
  }

  render(): JSX.Element {
    const { props } = this;
    const renderCard = this.props.cardConfigList ? this.props.cardConfigList.map((card, index) => (
      <div
        key={index}
        ref={(c) => { this.carouselCard[index] = c; }}
      >
      <SbEditable content={card} key={card && card._uid || ''}>
        <Card
          id={props.id}
          gaSend={() => this.getProps('gaSend')}
          {...card}
          key={`card-${index}`}
          index={index}
          getFadeTransiation={() => this.getProps('fadeTransiation')}
          getMediaTransiation={() => this.getProps('mediaTransiation')}
          getGapWidth={() => this.getProps('cardGapWidth')}
          getGridWidth={() => this.getProps('gridWidth')}
          getTabPresentation={() => this.getProps('tabPresentation')}
          ref={(c) => { this.cardDom[index] = c; }}
        />
      </SbEditable>
      </div>
    )) : null;
    return (
      <Carousel {...props.parameter} ref={(c) => { this.carousel = c; }}>
        {renderCard}
      </Carousel>
    );
  }
}
