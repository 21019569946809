import * as React from 'react';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

export enum Type {
  'bg' = 'bg',
  'img' = 'img',
  'component' = 'component',
}

export interface Props {
  type?: string;
  src?: string;
  tagType?: string;
  style?: object;
  parallax?: boolean;
  [key: string]: any;
}

export interface State {
  backgroundImage?: string;
}

const defaultOptions = { threshold: 2000 };

function backgroundImageSrcHandler(imageSrc): string {
  if (!imageSrc) {
    return imageSrc;
  }
  let backgroundImage;
  if (!/\burl\s*\(/i.test(imageSrc) && /^\s*(\.|(\/)|http)/.test(imageSrc)) { // 如果src为 url('xxx.img')则不需要帮忙加上url
    backgroundImage = `url("${imageSrc}")`;
  } else {
    backgroundImage = imageSrc;
  }
  return backgroundImage;
}

/**
 * 懒加载组件，支持：
 * - 懒加载模块(默认)
 * - img标签 type="img"
 * - css background-image type="bg"
 * 用法：
 * 1. 直接生成img 标签：<LazyWrapper type="img" src="图片链接" />
 * 2. 使用 css background-image形式，这种形式会默认生成一个包裹标签，同时懒加载背景图，可以制定tagType标记需要生成标签类型
 *     <LazyWrapper type="bg"
 *       src="background-image链接"
 *       tagType="section">
 *        <div>组件内容</div>
 *     </LazyWrapper>
 * 3. 使用懒加载组件模式（整个组件都会被懒加载）且不会有额外包裹标签: <LazyWrapper><LazyWrapper>
 */
class LazyWrapper extends React.Component<Props, State> {
  targetDom: Element | HTMLDivElement | null;

  animationFrame: any;

  listenersToRemove: Array<Function>;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: { type: string; src: string; tagType: string; style: {}; parallax: boolean; };

  constructor(defaultProps: object) {
    super(defaultProps);

    this.state = {};
    this.listenersToRemove = [];
    this.setBackgroundImageAfterLoad = this.setBackgroundImageAfterLoad.bind(this);
  }

  componentDidMount(): void {
    const { parallax, visibleByDefault } = this.props;
    if (visibleByDefault) {
      if (parallax) {
        this.parallaxScroll();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.animationFrame && window) {
      window.cancelAnimationFrame(this.animationFrame);
    }

    this.listenersToRemove.forEach((item) => {
      if (typeof item === 'function') {
        item();
      }
    });
  }

  private setBackgroundImageAfterLoad(): void {
    const { src, parallax } = this.props;
    if (!src) {
      return;
    }
    const imageSrc = src;

    const backgroundImage = backgroundImageSrcHandler(imageSrc);
    this.setState({ backgroundImage });

    // 有视差的话增加视差效果
    if (parallax) {
      this.parallaxScroll();
    }
  }

  private parallaxScroll(): void {
    const factor = 0.1;
    let indicatorPosition = 0;
    const elm = this.targetDom;
    const self = this;
    if (!elm || typeof window === 'undefined') {
      return;
    }

    let windowHeight = window.innerHeight;

    // 计算偏移 修复全屏背景视差图片没有撑满的bug
    let { backgroundSize } = this.computeBgSize();
    let offset = this.computeBgSize().backgroundOffset;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;
    function listenEvent(): void {
      // 窗口尺寸变化时更新高度
      windowHeight = window.innerHeight;
      backgroundSize = ctx.computeBgSize().backgroundSize;
      offset = ctx.computeBgSize().backgroundOffset >= 200 ? 200 : ctx.computeBgSize().backgroundOffset;
    }
    listenEvent();
    window.addEventListener('resize', listenEvent);
    ctx.listenersToRemove.push(() => {
      window.removeEventListener('resize', listenEvent);
    });

    function loop(): void {
      const scrollOffset = window.scrollY || window.pageYOffset;
      if (!elm || typeof window === 'undefined') {
        return;
      }

      let temp = elm;
      let { offsetTop } = elm;
      while (temp.offsetParent) {
        temp = temp.offsetParent;
        offsetTop += temp.offsetTop;
      }
      const elHeight = elm.offsetHeight;
      if (scrollOffset >= offsetTop - windowHeight && scrollOffset <= offsetTop + elHeight) {
        const scrollPercent = (offsetTop - windowHeight - scrollOffset) / (windowHeight + elHeight);
        indicatorPosition += (scrollPercent - indicatorPosition) * factor;
        const calcOffset = indicatorPosition * offset;
        const styleValue = `${calcOffset}px`;
        elm.style['background-position-y'] = styleValue;
        elm.style['background-size'] = backgroundSize;
        elm.style['background-position-x'] = 'center';
      }
      self.animationFrame = requestAnimationFrame(loop);
    }
    loop();
  }

  // 计算视差背景图片的size
  // eslint-disable-next-line class-methods-use-this
  computeBgSize(): object {
    const elm = this.targetDom;
    let backgroundSize = 'auto calc(100% + 200px)';
    let backgroundOffset = 200;
    if (!elm || typeof window === 'undefined') {
      return {};
    }
    const {
      bgWidth,
      bgHeight,
    } = this.props;
    if ((!bgWidth && !bgHeight) || (bgWidth === '' && bgHeight === '')) {
      return {
        backgroundSize,
        backgroundOffset,
      };
    }
    const backgroundImageWidth = parseInt(bgWidth, 10);
    const backgroundImageHeight = parseInt(bgHeight, 10);
    const bgRadio = backgroundImageWidth / backgroundImageHeight;
    const domWidth = elm.clientWidth;
    const domHeight = elm.clientHeight;
    const domRadio = domWidth / domHeight;
    if (bgRadio > domRadio) {
      // 当图片高度小于容器高度，撑满高度计算宽度，没有上下视差
      const backgroundWidth = domHeight * bgRadio;
      const backgroundSizeOffsetX = backgroundWidth - domWidth;
      backgroundSize = `calc(100% + ${backgroundSizeOffsetX}px) 100%`;
      backgroundOffset = 0;
    } else {
      // 当图片高度大于容器高度，撑满宽度计算高度，有上下视差浮动
      const backgroundHeight = domWidth / bgRadio;
      const backgroundSizeOffsetY = backgroundHeight - domHeight;
      backgroundSize = `100% calc(100% + ${backgroundSizeOffsetY}px)`;
      backgroundOffset = backgroundSizeOffsetY;
    }
    return { backgroundSize, backgroundOffset };
  }

  public render(): JSX.Element {
    const {
      children, type, src, tagType = 'div', visibleByDefault, ...componentProp
    } = this.props;

    if (type === 'img') {
      const placeholderIStyle = {
        color: 'transparent',
        display: 'inline-block',
        minWidth: '1px',
      };
      const { placeholder = <i style={placeholderIStyle} /> } = this.props;
      const restProps = {
        ...defaultOptions,
        ...componentProp,
      };
      return (
        <LazyLoadImage
          visibleByDefault={visibleByDefault}
          {...restProps}
          placeholder={placeholder}
          src={src}
        />
      );
    }

    if (type === 'bg') {
      // 如果是懒加载wrapper, 这懒加载bg
      const { style = {}} = componentProp;

      const { backgroundImage } = this.state;

      const lazyDom = (
        <LazyLoadComponent
          afterLoad={this.setBackgroundImageAfterLoad}
          threshold={componentProp.threshold || defaultOptions.threshold}
        >
          <span style={{ display: 'none' }} />
        </LazyLoadComponent>
      );

      let Dom;

      if (visibleByDefault) {
        Dom = React.createElement(tagType, {
          ...componentProp,
          ref: (elem) => { this.targetDom = elem; },
          style: {
            ...style,
            backgroundImage: backgroundImageSrcHandler(src),
          },
        }, children);
      } else {
        Dom = React.createElement(tagType, {
          ...componentProp,
          ref: (elem) => { this.targetDom = elem; },
          style: {
            ...style,
            backgroundImage,
          },
        }, lazyDom, children);
      }
      return Dom;
    }

    return (<LazyLoadComponent visibleByDefault={visibleByDefault} {...componentProp}>{children}</LazyLoadComponent>);
  }
}

LazyWrapper.defaultProps = {
  type: 'component',
  src: '',
  tagType: 'div',
  style: {},
  parallax: false,
};

export default LazyWrapper;
