import { IDimension } from './dimension';
import ScrollNode from './scrollNode';

/**
 * 判断节点是否处于视口
 * 判断逻辑迁移自 @link checkElementInView.js
 */
export function isInView(scrollNode: ScrollNode, dimension: IDimension): boolean {
  const top = scrollNode.top;
  const left = scrollNode.left;
  const width = scrollNode.width;
  const height = scrollNode.height;

  return top < (dimension.pageOffsetY + dimension.innerHeight)
    && left < (dimension.pageOffsetX + dimension.innerWidth)
    && (top + height) > dimension.pageOffsetY
    && (left + width) > dimension.pageOffsetX;
}

/**
 * 判断节点到屏状态并执行回调，执行场景：
 * 1.浏览器不支持 InterSectionObserver，在节点被加入滚动管理器时该方法会立即执行一次
 * 2.外部直接调用 checkElementInView 并要求不使用节点位置缓存
 */
export function handleNodeInView(node: ScrollNode, inView: boolean, dimension: IDimension) {
  if (inView && !node.inView) {
    node.inView = true;
    node.callback?.scrollIntoView?.(dimension);
    node.startFrameTask();
  } else if (!inView && node.inView) {
    node.inView = false;
    node.callback?.scrollOutOfWiew?.(dimension);
    node.stopFrameTask();
  }
}

/**
 * 判断节点到屏状态并执行回调，执行场景：
 * 浏览器支持 InterSectionObserver，在节点被加入滚动管理器时使用该方法注册 callback
 */
export function handleNodeIntersection(node: ScrollNode, dimension: IDimension, margin: number = 100) {
  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    let inView = false;
    entries.every((entry: IntersectionObserverEntry) => {
      // 只要有一个 entry 相交就认为相交
      if (entry.isIntersecting) {
        inView = true;
        return false;
      }
      return true;
    });

    if (inView && !node.inView) {
      node.inView = true;
      node.callback?.scrollIntoView?.(dimension);
      node.startFrameTask();
    } else if (!inView && node.inView) {
      node.inView = false;
      node.callback?.scrollOutOfWiew?.(dimension);
      node.stopFrameTask();
    }
  };
  const observer = new IntersectionObserver(intersectionCallback, { rootMargin: `${margin}px` });
  observer.observe(node.element);
}

export function isSupportIntersectionObserver() {
  return window && 'IntersectionObserver' in window && 'isIntersecting' in window.IntersectionObserverEntry.prototype;
}
