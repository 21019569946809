import React from "react";
import SbEditable from "storyblok-react";
import Link from "gatsby-link"
import './style.css'
import { isArray } from "lodash";
import LinkFix from "../../../utils/links"

const PolymerizationItem = (props) => {
    const { content, lang } = props
    const { itemNumber } = content;
    return (
        <SbEditable content={content} lang={lang}>
           <div className="polymerizationItem">
                {  content && content.polymerizationItemContent && isArray(content.polymerizationItemContent) && content.polymerizationItemContent.map((item)=>{

                    return itemNumber <= 1 ? (<a href={(item.cardUrl && item.cardUrl.cached_url && LinkFix(item.cardUrl)) || (item.cardUrl && item.cardUrl.url)} style={{textDecoration:'none'}} key={item?.id} target="_blank" rel="noopener noreferrer">
                        <div className="polymerization-item-content">
                            <div className="polymerization-item-img-content">
                                <img className="polymerization-item-img" src={item.imgPC}></img>
                            </div>
                            <div className="polymerization-item-desc-content">
                                <div className="polymerization-item-desc">
                                    <p className="polymerization-item-top-desc" dangerouslySetInnerHTML={{ __html:item.descTop}}></p>
                                    <h4 className="polymerization-item-mainTitle" dangerouslySetInnerHTML={{ __html: item.mainTile}}></h4>
                                    <p className="polymerization-item-button-desc" dangerouslySetInnerHTML={{ __html: item.descButtom}}></p>
                                </div>
                            </div>
                        </div>
                    </a>) : (<a href={(item.cardUrl && item.cardUrl.cached_url && LinkFix(item.cardUrl)) || (item.cardUrl && item.cardUrl.url)} className={`polymerization-item-link-${itemNumber}`} key={item?.id} target="_blank" rel="noopener noreferrer">
                        <div className={`polymerization-item-content-${itemNumber}`}>
                            <div className="polymerization-item-img-content-more">
                                <img className="polymerization-item-img" src={item.imgPC}></img>
                            </div>
                            <div className={`polymerization-item-desc-content-${itemNumber}`}>
                                <div className="polymerization-item-desc">
                                    <p className="polymerization-item-top-desc" dangerouslySetInnerHTML={{ __html:item.descTop}}></p>
                                    <h4 className="polymerization-item-mainTitle" dangerouslySetInnerHTML={{ __html: item.mainTile}}></h4>
                                    <p className="polymerization-item-button-desc" dangerouslySetInnerHTML={{ __html: item.descButtom}}></p>
                                </div>
                            </div>
                        </div>
                    </a>);
                    })
                }
           </div> 
        </SbEditable>
    );
}
export default PolymerizationItem;
  