import React from "react"
import SbEditable from "storyblok-react"
import "./competition_upload.css"
import API from "../../../utils/api_auth"
import FileUpload from "./competition_file_upload"
import SocialShare from "../social_share"
import Modal from "../../../components/CustomModal"
import MarkDown from '../markdown'
import { navigate } from 'gatsby';
import * as xss from 'xss';
import LinkFix from "../../../utils/links";
import { isEmptyString } from '../../../utils/helper';

const imagePrefix = "https://uploads.hasselblad.com/1000x-community/";

const ALL_FORM_KEYS = [
    'series_name',
    'series_description',
    'any_non_photo_processes',
    'process_description',
];
const REQUIRED_MAP = {
    process_description: { requiredBy: 'any_non_photo_processes', requiredValue: 'yes' },
};

const isError = (dataSource, key) => {
    /* 默认每一项都必填 */
    if (!REQUIRED_MAP[key]) return isEmptyString(dataSource[key]);

    /* 只有关联项命中必填条件时，该项才必填 */
    const { requiredBy, requiredValue } = REQUIRED_MAP[key];
    const isRequired = dataSource[requiredBy] === requiredValue;
    return isRequired && isEmptyString(dataSource[key]);
};

const filterRequiredData = (dataSource) => {
    if (!dataSource) return {};

    /* 筛选出必填的字段 */
    const keepKeys = Object.keys(dataSource).filter(key => !REQUIRED_MAP[key] || (dataSource[REQUIRED_MAP[key].requiredBy] === REQUIRED_MAP[key].requiredValue));
    /* 返回筛选后的数据 */
    return keepKeys.reduce((prevData, dataKey) => ({ ...prevData, [dataKey]: dataSource[dataKey] }), {});
};

export default class extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            isSubmittedModalOpen: false, //提交成功弹窗
            ready: false, // （图片准备完毕）控制submit提交按钮
            registered: false, // 用户是否已投稿过该类别
            loading: true, 
            consent : false,
            files : [], // 上传的图片
            uploading: false, // 控制提交按钮的 loading 样式
            submission_id : null,
            submitedFail: false, // 是否提交错误
            isSubmitted : false,
            previouslySubmitted: false, // 是否之前已投稿过该类别
            imageList: [], // 历史投稿拿回来的图片列表
            social_share_image: '',
            formError: { // 表单字段错误
            },
            formAllCorrect: false, // 是否所有表单字段校验通过
            formContentChanged: false, // 是否修改过表单任意内容
            content: {
                series_name: '',
                series_description: '',
                any_non_photo_processes: '',
                process_description: '',
            },
        }
        this.handleContentChange = this.handleContentChange.bind(this);
    }

    componentDidMount = () => {

        // 进入页面查看是否已报名总比赛
        API.get('/competitions/' + this.props.blok.main_competition_id + '/entity')
            .then((data) => {
                if (data.data.registered){

                    // 如果已报名总比赛，继续查询是否已报名该类别
                    API.get('/competitions/' + this.props.blok.competition_id + '/submission')
                        .then((data) => {

                            // 如果已报名该类别，取回数据回填历史报名信息
                            if (data && data.data && data.data.registered){
                                this.setState({
                                    registered: data.data.registered,
                                    loading: false,
                                });

                                // 取到之前上传的图片，并填充
                                const submissions = data.data.submissions;
                                if (submissions && submissions.length > 0) {

                                    // 取到之前上传的图片地址，并填充
                                    const imageList = submissions[submissions.length - 1].urls;
                                    const social_share_image = imageList[0] ? `${imagePrefix}${imageList[0]}` : '';

                                    // 取到之前填写的表单字段值，并填充
                                    let content = {};
                                    let hasContent = false;
                                    if (submissions[submissions.length - 1] && submissions[submissions.length - 1].content) {
                                        try {
                                            content = JSON.parse(submissions[submissions.length - 1].content);
                                            hasContent = true;
                                        } catch (e) {
                                            content = {};
                                        }
                                    }

                                    this.setState({
                                        imageList,
                                        content,
                                        formAllCorrect: !!(data.data.registered && hasContent), // 如果用户之前提交过表单，当前表单初始应该为校验通过
                                        ready: false, // 判断用户如果之前提交过作品，再次进来没有重新上传，submit按钮状态为disabled
                                        social_share_image,
                                    });
                                }
                            } else {

                                // 如果未报名该类别，不需要还原数据
                                this.setState({ registered: false, loading: false, previouslySubmitted: false })
                            }
                        })
                        .catch((err) => {
                            this.setState({ loading: false })
                        })
                } else {

                    // 如果未报名总比赛，则跳转回总报名页
                    navigate(LinkFix(this.props.blok.competition_start_page))
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
            })

    }

    handleContentChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        let { formError, content } = this.state;

        content[name] = value;
        formError[name] = isError(content, name);

        let noError = true;
        ALL_FORM_KEYS.forEach((key) => {
            if (isError(content, key)) {
                noError = false;
            }
        });

        this.setState({
            formError,
            content,
            formContentChanged: true,
            formAllCorrect: noError
        })
    }

    handleDrop = (file, position) =>{
        const { registered } = this.state;
        let _files = this.state.files
        _files[position] = file

        this.setState({ files : _files })
        if (registered && _files.length > 0 && ((_files[0] && _files[0].replaced) || (_files[1] && _files[1].replaced) || (_files[2] && _files[2].replaced))) {
            // 如果用户之前上传过作品，且当前修改超过一张图片，修改submit按钮为可提交的状态
            this.setState({ ready : true }) 
        } else if(!registered && _files.length === 3 && _files[0] !== null && _files[1] !== null && _files[2] !== null ) {
            // 判断用户第一次上传并且图片数量满足3张的条件下，修改submit按钮为可提交的状态
            this.setState({ ready : true })
        } else {

            this.setState({ ready : false })
        }
    }

    uploadImage = (file, submission_id, isFirst) => {
        return new Promise((resolve, reject) => {
            let self = this;

            let extraInfo = {};
            let queryStr = '';

            try{
                extraInfo = {
                    lastModified: file.lastModified,
                    fileName: file.name,
                    size: file.size,
                    type: file.type,
                }
                queryStr = new URLSearchParams(extraInfo).toString();
            } catch(ex){
                window.ga && window.ga('send', 'event', 'Competition Submission', 'get file info - error', ex); 
            }
            
            API.get('/competitions/' + this.props.blok.competition_id +'/upload?' + queryStr)
                .then(function(response){
                    var data = response.data
                    var formData = new FormData();
                    var id = response.data.id;
                    if (file && file.id) {
                        API.post('/competitions/' + self.props.blok.competition_id +'/upload', {
                            exif: '-',
                            submissionId : submission_id,
                            id : file.id
                        })
                        .then(() => {
                            window.ga && window.ga('send', 'event', 'Competition Submission', 'Photo uploaded');

                            setTimeout(function(){          
                                resolve();
                            }, 2000);
                            
                        })
                        .catch((err) => {
                            window.ga && window.ga('send', 'event', 'Competition Submission', 'Photo uploaded - error', err);
                            reject()
                        });
                    } else {

                        const filekey =  data.data && data.data.fields &&  data.data.fields.Key;
                        let social_share_image = '';

                        if (isFirst && filekey) {
                            social_share_image = `${imagePrefix}${filekey.replace(/^community\//, '')}`
                        }

                        for (var key in data.data.fields) {
                            formData.append(key, data.data.fields[key]);
                        }
                    
                        formData.append('file', file);
                        let extraInfo = {};
                        let queryStr = '';

                        try{
                            /**
                             * 验证是否有兼容性问题，重新获取一遍file信息，看能不能读取到
                             */
                            const fileFromForm = formData.get('file')
                            extraInfo = {
                                lastModified: fileFromForm && fileFromForm.lastModified,
                                fileName:  fileFromForm && fileFromForm.name,
                                size:  fileFromForm && fileFromForm.size,
                                type: fileFromForm && fileFromForm.type,
                            }
                            queryStr = new URLSearchParams(extraInfo).toString();
                        } catch(ex){
                            window.ga && window.ga('send', 'event', 'Competition Submission', 'get file info from form - error', ex); 
                        }

                        fetch(data.data.url, {
                            method: 'POST',
                            body: formData
                        })
                        .then((uploadRes) => {
                            if(!uploadRes.ok){
                                window.ga && window.ga('send', 'event', 'Competition Submission', 'error file upload s3 error not ok');
                                return reject('response code is not ok');
                            }
                            API.post('/competitions/' + self.props.blok.competition_id +'/upload' + `?${queryStr}`, {
                                    exif: '-',
                                    submissionId : submission_id,
                                    id : id,
                                    filekey,
                                })
                                .then((postRes) => {
                                    if (postRes.status !== 200) {
                                        window.ga && window.ga('send', 'event', 'Competition Submission', 'error file upload s3 error not ok');
                                        return reject('response code is not ok');
                                    }

                                    window.ga && window.ga('send', 'event', 'Competition Submission', 'Photo uploaded');
                                    if (social_share_image) {
                                        self.setState({
                                            social_share_image,
                                        })
                                    }
                                setTimeout(function(){          
                                    resolve();
                                }, 3000);
                                
                            })
                            .catch((err) => {
                                /** * 走到此异常为用户的上传内容被服务端检查出问题，如果是这样，不再直传s3, 记录文件 */
                                API.post('/competitions/' + self.props.blok.competition_id +'/file', formData).then(res => {
                                    console.log('upload', res)
                                }).catch(ex => {
                                    console.log(ex)
                                }).finally(() => {
                                    window.ga && window.ga('send', 'event', 'Competition Submission', 'Photo uploaded - error', err);
                                    reject(err);
                                })
                            });
                    }).catch(ex=>{
                        window.ga && window.ga('send', 'event', 'Competition Submission', 'Photo uploaded - error file upload s3 error', ex);
                        reject(ex)
                    });
                }
                });
            
        });
    }

    submit = () => {
        const { registered, content } = this.state;
        this.setState({ uploading : true, submitedFail: false });

        const contentData = filterRequiredData(content);
        const contentString = xss(JSON.stringify(contentData));

        // 提交前删除上一次提交的记录
        // API.delete('/competitions/' + this.props.blok.competition_id +'/submission')
        //     .then(() => {

                // 标记该用户已投稿该类别比赛
                API.post('/competitions/'+ this.props.blok.competition_id +'/registration')
                .then(data => {

                    // 上传第一张图
                    this.uploadImage(this.state.files[0],  data.data.submissionId, true).then(() => {

                        // 上传第二张图
                        this.uploadImage(this.state.files[1],  data.data.submissionId).then(()=>{

                            // 上传第三张图
                            this.uploadImage(this.state.files[2],  data.data.submissionId).then(()=>{

                                // 传完图片后，提交投稿比赛表单
                                API.post('/competitions/' + this.props.blok.competition_id +'/submit', { submissionId: data.data.submissionId, content: contentString })
                                .then((result) => {
                                    this.setState({ uploading : false, isSubmitted: true,  isSubmittedModalOpen: true,});
                                    console.log(result);
                                }).catch(err => {
                                    this.setState({ uploading : false, submitedFail: true});
                                    console.error('competition submit error:', err);
                                })
                            }).catch(err => {
                                this.setState({ uploading : false, submitedFail: true});
                                console.error('upload image 3 error:', err);
                            })
                        }).catch(err => {
                            this.setState({ uploading : false, submitedFail: true});
                            console.error('upload image 2 error:', err);
                        })
                    }).catch(err => {
                        this.setState({ uploading : false, submitedFail: true});
                        console.error('upload image 1 error:', err);
                    })
                }).catch(err => {
                    this.setState({ uploading : false, submitedFail: true});
                    console.error('competition registration error:', err);
                })
            // }).catch(err => {
            //     this.setState({ uploading : false, submitedFail: true});
            //     console.error('competition submission refresh error:', err);
            // })
    }

    closeAndReturn = () => {
        navigate(LinkFix(this.props.blok.competition_categories_page))
    }

    render() {
        const { blok, lang } = this.props
        const {
            imageList,
            registered,
            loading,
            ready, // 图片准备完毕
            social_share_image,
            isSubmitted,
            submitedFail,
            content,
            formError,
            formAllCorrect,
            formContentChanged,
            uploading,
        } = this.state;

        const {
            series_name = {},
            series_description = {},
            any_non_photo_processes = {},
            process_description = {},
        } = blok;
        const social_share = blok.social_share && blok.social_share[0];

        if (social_share && social_share_image) {
            social_share.weibo_image = social_share_image;
        }

        // 表单字段不完整 或 图片不完整时无法提交表单
        const submitDisabled = !formAllCorrect || !ready;
        // 重新提交时，如果改了图片 或者 文字，且最终 formAllCorrect 了，可以重新提交
        const resubmitDisabled = !((formContentChanged || ready) && formAllCorrect);
        
        return (
            <div>
            <SbEditable content={blok}>
            
                {/* 作品上传 */}
                {!loading ? (
                    <>
                    <div className="editorial row competition-img-wrapper-row">
                        <div className="et center-center thirtythree">
                            <FileUpload coverText={blok.coverText} blok={blok} registered={registered}  file={imageList && imageList[0]}  lang={lang} handleDrop={this.handleDrop} id="fileUpload1" position="0" disabled={uploading}/>
                        </div>
                        <div className="et center-center thirtythree">
                            <FileUpload blok={blok} registered={registered}  file={imageList && imageList[1]}  lang={lang} handleDrop={this.handleDrop}  id="fileUpload2" position="1" disabled={uploading}/>
                        </div>
                        <div className="et center-center thirtythree">
                            <FileUpload blok={blok} registered={registered}  file={imageList && imageList[2]} lang={lang} handleDrop={this.handleDrop} id="fileUpload3" position="2" disabled={uploading}/>
                        </div>
                    </div>

                    {/* 作品描述表单  */}
                    <div className="editorial row competition-upload-wrapper-row">
                    <div class="competition-upload-wrapper-divider"><div /></div>
                    <div class="competition-upload-wrapper">
                        {/* 系列名称 */}
                        <div className="">
                            <label htmlFor="series_name">
                                <div>
                                    <span className={`show ${formError.series_name ? "onError" : ""}`}>{series_name?.[0]?.form_item_title}</span>
                                </div>
                                <span className={`errorTip ${formError.series_name ? "errorShow" : ""} `}>{series_name?.[0]?.form_empty_error}</span>
                                <div className="competition-upload-textarea-wrapper">
                                    <textarea disabled={uploading} className="series_name" name="series_name" type="text" placeholder={series_name?.[0]?.form_item_placeholder} value={content && content.series_name || ''} onChange={this.handleContentChange} />
                                </div>
                            </label> 
                        </div>
                        {/* 系列描述 */}
                        <div className="">
                            <label htmlFor="series_description">
                                <div>
                                    <span className={`show ${formError.series_description ? "onError" : ""}`}>{series_description?.[0]?.form_item_title}</span>
                                </div>
                                <span className={`errorTip ${formError.series_description ? "errorShow" : ""} `}>{series_description?.[0]?.form_empty_error}</span>
                                <div className="competition-upload-textarea-wrapper">
                                    <textarea disabled={uploading} name="series_description" type="text" placeholder={series_description?.[0]?.form_item_placeholder} value={content && content.series_description || ''} onChange={this.handleContentChange} />
                                </div>
                            </label> 
                        </div>
                        {/* 是否进行非图像处理 */}
                        <div className="">
                            <label htmlFor="any_non_photo_processes">
                                <div>
                                    <span className={`show ${formError.any_non_photo_processes ? "onError" : ""}`}> 
                                        {any_non_photo_processes?.[0]?.form_item_title}
                                    </span>
                                </div>
                                <span className={`errorTip ${formError.any_non_photo_processes ? "errorShow" : ""} `}>{any_non_photo_processes?.[0]?.form_empty_error}</span>
                                <select disabled={uploading} name="any_non_photo_processes"  value={content.any_non_photo_processes} onBlur={this.handleContentChange} onChange={this.handleContentChange} >
                                    <option key="any_non_photo_processes" value="">{any_non_photo_processes?.[0]?.form_item_placeholder}</option>
                                    {any_non_photo_processes?.[0]?.select_options.map((c, index) => <option key={index} value={c.value || ''}>{c.text}</option>)}
                                </select>
                            </label>
                        </div>
                        {content && content.any_non_photo_processes === 'yes' ? (
                            <div className="">
                                <label htmlFor="process_description">
                                    <div>
                                        <span className={`show ${formError.process_description ? "onError" : ""}`}>{process_description?.[0]?.form_item_title}</span>
                                    </div>
                                    <span className={`errorTip ${formError.process_description ? "errorShow" : ""} `}>{process_description?.[0]?.form_empty_error}</span>
                                    <div className="competition-upload-textarea-wrapper">
                                        <textarea disabled={uploading} name="process_description" type="text" placeholder={process_description?.[0]?.form_item_placeholder} value={content && content.process_description || ''} onChange={this.handleContentChange} />
                                    </div>
                                </label> 
                            </div>
                        ) : null}
                    </div>
                    <div class="competition-upload-wrapper-divider"><div /></div>
                    </div>
                    </>
                ) : null}
                
                {/* 作品提交 */}
                <div className="editorial row submit-button-wrap">
                    <div className="et center hundred submit-btn-content">
                        <div className="cta-row">
                            <button
                                className={`cta ${submitDisabled ? 'disabled' : ''}  ${this.state.uploading || loading  ? 'loading' : null } `}
                                disabled={ submitDisabled || this.state.uploading }
                                onClick={ this.submit }
                                style={{
                                    /* 如果已经注册过，或已提交，则不显示提交按钮 */
                                    opacity: registered || isSubmitted ? 0 : 1,
                                }}
                            >
                                { blok.button_text ? blok.button_text : 'Submit' }
                            </button>
                        </div > 
                        <div
                            className="resubmit-btn"
                            style={{
                                /* 如果已注册 且图片已就位 且本次未提交，显示重新提交按钮 */
                                zIndex: registered && !isSubmitted ? 9 : -1,
                            }}
                        >
                            <button
                                className={`cta ${resubmitDisabled ? 'disabled' : ''} ${this.state.uploading || loading  ? 'loading' : null } `}
                                disabled={ resubmitDisabled || this.state.uploading }
                                onClick={ this.submit }
                                style={{
                                    opacity: registered && !isSubmitted  ? 1 : 0,
                                }}
                            >
                                { blok.resubmit_button_text || 'Resubmit' }
                            </button>
                        </div>  
                        {submitedFail ? (
                            <div className="submit-fial-props">{blok.uploadImageFailPrompt || 'Uploaded unsuccessfully, please check the IMG requirements.'}</div>
                        ) : null}
                       
                    </div>
                </div>

                {/* 上传作品成功后再次访问页面会弹窗提示用户是否需要重新提交 */}
                <Modal className="modal submitted-modal" overlayClassName="overlay" isOpen={this.state.isSubmittedModalOpen} contentLabel="">
                    <button className="close modal-close" onClick={()=> {this.setState({isSubmittedModalOpen: false, isSubmitted: false, ready: false, formContentChanged: false, registered: true})}}>
                        <img alt="" src="/assets/icons/close-black.svg" />
                    </button>
                    <div className="modal__content">
                        <div className="editorial column">
                            <div className="modal-check-icon"></div>
                            <div className="et center-center sixty-six" dangerouslySetInnerHTML={ MarkDown(blok.submission_completed_message) } >
                            </div>
                            <div className="et center-center sixty-six social-share-row">
                                <SocialShare {...social_share} lang={lang} />
                            </div>
                            { blok.close_button_text ? (
                                <div className="et center-center sixty-six button-row">
                                    <div className="cta-row">
                                        <button className={`cta  `} onClick={ this.closeAndReturn } >{ blok.close_button_text }</button>
                                    </div>  
                                </div>
                            ) : null }
                        </div>
                    </div>
                </Modal>
            </SbEditable>
            </div>
        )
    }
}

