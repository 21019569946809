
export const isBrowser = () => typeof window !== "undefined"

export const getToken = () => {
    return isBrowser() && window.localStorage.getItem("authorization_token") && window.localStorage.getItem("refresh_token") 
        ? { authorizationToken : window.localStorage.getItem("authorization_token"), refreshToken : window.localStorage.getItem("refresh_token") }
        : { }
}

const setTokens = tokens =>{
    window.localStorage.setItem("authorization_token", tokens.authorizationToken)
    window.localStorage.setItem("refresh_token", tokens.refreshToken)
}

export const handleLogin = (authorizationToken, refreshToken ) => {
    setTokens({ authorizationToken : authorizationToken, refreshToken: refreshToken})
}

export const isLoggedIn = () => {
  const tokens = getToken()

  return !!tokens.authorizationToken
}

export const logout = callback => {
  setTokens({})
  callback()
}