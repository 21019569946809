import React from "react"
import SbEditable from "storyblok-react"
import Components from "../components"


export default class extends React.Component {
  render() {
    const { blok } = this.props
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div className={`editorial ${blok.layout}`}>
            <div className="items">
            { blok.items && blok.items.map((item) => {
                return (
                    <div>
                      
                    </div>  
                )
            })} 
            </div>
        
  
          { blok.items && blok.items.map((item) => {
              
            return (
                <div>
                    { item.image && item.image.map((image) => {
                        return ( React.createElement(Components(image.component), { key: blok._uid, blok: image }))
                    })}
                </div>
            )
          })} 
        </div>
      </SbEditable>
    )
  }
}
