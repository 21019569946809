/* eslint-disable */
// @ts-nocheck
import React from 'react';
import SbEditable from "storyblok-react"
import Carousel from '../../../utils/Carousel';
import classNames from 'classnames';
import Card from '../../HeroVertical/card';
import { StoryblokAsset } from '../../HeroVertical/card/type';
import './style.scss';

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

export enum tabPosition {
  'top' = 'top',
  'center' = 'center',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'none' = 'none',
}

export interface Props {
  id?: string;
  gaSend?: () => {};
  // 基础配置
  tabTheme?: tabTheme; // tab标题颜色
  activeKey?: string;
  videoAllDowngrade: boolean;
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;
  tabContent?: Array<TabPanelItem>;
  tabPosition?: tabPosition;
  spacingFromTheCard?: string;
  defaultActiveIndex?: number;
  cards?: Array<Record<string, any>>;
}

export default class TabCard extends React.Component<Props, {}> {
  carousel: any;

  carouselCard: any;

  cardDom: any;

  constructor(props: Props) {
    super(props);
    this.renderTabContent = this.renderTabContent.bind(this);
    this.carouselCard = [];
    this.cardDom = [];
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  renderTabContent(): JSX.Element {
    const { props } = this;
    const params = {
      autoplay: false,
      speed: 600,
      fade: true,
      touchMove: false,
      ...props.defaultActiveIndex ? { initialSlide: props.defaultActiveIndex } : {},
    };
    const aosFade = props.fadeTransiation;
    const mediaFade = props.mediaTransiation;
    const renderCard = this.props.cards ? this.props.cards.map((card, index) => {
      const cardCls = classNames(
        'mtabh-tab-content-item',
      );
      return (
        <div
          key={index}
          className={cardCls}
          ref={(c) => { this.carouselCard[index] = c; }}
        >
          <SbEditable content={card} key={card && card._uid || ''}>
          <Card
            id={props.id}
            gaSend={props.gaSend}
            videoAllDowngrade={props.videoAllDowngrade}
            aosFade={aosFade}
            mediaFade={mediaFade}
            {...card}
            key={`card-${index}`}
            index={index}
            ref={(c) => { this.cardDom[index] = c; }}
          />
          </SbEditable>
        </div>
      );
    }) : null;

    return (
      <Carousel
        {...params}
        ref={(c) => { this.carousel = c; }}
      >
        {renderCard}
      </Carousel>
    );
  }

  render(): JSX.Element {
    const { props } = this;
    let cardMargin: React.CSSProperties;
    switch (props.tabPosition) {
      case 'top':
        cardMargin = { marginTop: props.spacingFromTheCard };
        break;
      default:
        cardMargin = { marginBottom: props.spacingFromTheCard };
    }
    return (
      <div className="mtabh-tab-item-card" style={cardMargin}>
        {this.renderTabContent()}
      </div>
    );
  }
}
