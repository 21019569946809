/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import { Props as PanelProps } from './TabPanel';
import { getAsset } from '../../../../../utils/helper';
import _debounce from 'lodash.debounce';

import './style.scss';

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
  'right' = 'right',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}
export interface Props {
  responsiveBorder: number;
  // 基础配置
  tabAlign?: tabAlign;
  intervalBisection?: boolean;
  tabOverallWidth?: string;
  spacingFromTheCard?: string;
  tabBarContent?: tabBarContent;
  tabBarIconHeight?: string; // tabBar icon 高度
  tabTheme?: tabTheme; // tab标题颜色

  panels?: React.ReactNode;
  activeKey?: string;
  onTabClick?: (key: string, index: number) => void;
}

export interface State {
  inkBarStyle: React.CSSProperties;
}

export interface Style {
  width: string;
  transform: string;
  WebkitTransform: string;
  MozTransform: string;
}

function setTransform(style: Style, v: string): void {
  const styles = style;
  if (style && typeof style === 'object' && v) {
    styles.transform = v;
    styles.WebkitTransform = v;
    styles.MozTransform = v;
  }
}

export default class TabBar extends React.PureComponent<Props, State> {
  inkBarStyleUpdated: boolean;
  debounceResize: any;
  tabBar: HTMLElement | null;

  activeTab: HTMLElement | null;

  constructor(props: Props) {
    super(props);
    this.state = { inkBarStyle: { transition: 'none' }};
    this.renderTabs = this.renderTabs.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.updateInkBarStyle = this.updateInkBarStyle.bind(this);
    this.renderBar = this.renderBar.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount(): void {
    this.updateInkBarStyle({ transition: 'none' });
    this.debounceResize = _debounce(this.onResize, 300);
    window.addEventListener('resize', this.debounceResize);
  }

  componentDidUpdate(prevProps: Props): void {
    const preActiveKey = prevProps.activeKey;
    const { activeKey } = this.props;
    if (preActiveKey === activeKey) {
      return;
    }
    this.updateInkBarStyle();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.debounceResize);
  }

  onResize(): void {
    const windowWidth = document.documentElement.clientWidth || window.innerWidth;
    const isMobile = windowWidth < this.props.responsiveBorder;
    if (!isMobile && !this.inkBarStyleUpdated) {
      this.updateInkBarStyle();
      this.inkBarStyleUpdated = true;
      window.removeEventListener('resize', this.debounceResize);
    }
  }

  handleTabClick(key: string, index: number): void {
    const { onTabClick } = this.props;
    if (onTabClick) {
      onTabClick(key, index);
    }
  }

  updateInkBarStyle(otherStyle?: object): void {
    if (this.activeTab && this.tabBar) {
      const style = {
        ...otherStyle || {},
        width: '0px',
      };
      const tabOffset = this.activeTab.offsetLeft;
      const containerOffset = this.tabBar.offsetLeft;
      const left = tabOffset - containerOffset;
      const width = this.activeTab.clientWidth;
      setTransform(style, `translate3d(${left}px,0,0)`);
      style.width = `${width}px`;
      this.setState({ inkBarStyle: style });
    }
  }

  renderBar(): JSX.Element {
    const style = this.state.inkBarStyle;
    return (
      <div
        style={style}
        className="tabh-aotm-horizontal-tab-inkbar"
      />
    );
  }

  renderTabs(): any {
    const { props } = this;

    const rst = React.Children.map(props.panels, (child: React.ReactElement<PanelProps>, index) => {
      const { key } = child;
      const cls = classNames(
        'tabh-tab-item',
        { 'tabh-tab-item-active': props.activeKey === key },
      );

      const events = { onClick: this.handleTabClick.bind(this, key, index) };

      const iconSty = {
        height: props.tabBarIconHeight,
        display: 'block',
      };

      let TabBarWrap: React.ReactNode | string;
      switch (props.tabBarContent) {
        case 'headline':
          TabBarWrap = (<div dangerouslySetInnerHTML={{ __html: child.props.tab }} />);
          break;
        case 'icon':
          TabBarWrap = (
            <div className="tabh-icon-box" style={iconSty}>
              <img className="tabh-tab-icon" src={getAsset(child.props.tabBarIcon)} alt="" style={iconSty} />
              <img className="tabh-tab-hover-icon" src={getAsset(child.props.tabBarHoverIcon)} alt="" style={iconSty} />
            </div>
          );
          break;
        case 'none':
          TabBarWrap = (<div className="tabh-tab-line" />);
          break;
        default:
          TabBarWrap = (
            <div className="tabh-icon-text">
              <div className="tabh-icon-box" style={iconSty}>
                <img className="tabh-tab-icon" src={getAsset(child.props.tabBarIcon)} alt="" style={iconSty} />
                <img className="tabh-tab-hover-icon" src={getAsset(child.props.tabBarHoverIcon)} alt="" style={iconSty} />
              </div>
              <div className="tabh-icon-title" dangerouslySetInnerHTML={{ __html: child.props.tab }} />
            </div>
          );
      }

      return (
        <div
          className={cls}
          style={{
            // 防止tab bar宽度因为transition导致从block到inline-block带来的宽度计算异常
            display: 'inline-block',
          }}
          key={key}
          {...events}
          ref={(tab) => { if (props.activeKey === key) { this.activeTab = tab; } }}
          data-ga-label={`all-${index}-tab-button`}
        >
          {TabBarWrap}
        </div>
      );
    });
    return rst;
  }

  render(): JSX.Element {
    const { props } = this;

    const renderTabs = this.renderTabs();
    const renderBar = this.renderBar();

    const tabBarCls = classNames(
      'tabh-atom-horizontal-tab-bar',
      `tabh-atom-horizontal-tab-bar-${props.tabAlign}`,
      `tabh-atom-horizontal-tab-bar-${props.tabBarContent}`,
      `tabh-atom-horizontal-tab-bar-theme-${props.tabTheme}`,
      { 'tabh-atom-horizontal-tab-bisection': (props.tabBarContent !== 'none') && props.intervalBisection && props.tabOverallWidth },

    );

    const tabBarStyl = { width: props.tabOverallWidth };
    const renderBarLine = props.tabBarContent !== 'none' ? renderBar : null;

    return (
      <div
        className={tabBarCls}
        style={(props.tabBarContent !== 'none' && props.intervalBisection) ? tabBarStyl : {}}
      >
        <div
          ref={(tabBar) => { this.tabBar = tabBar; }}
          className="tabh-tab-bar-box"
        >
          {renderBarLine}
          <div className="tabh-tab-bar-wrap">
            {renderTabs}
          </div>
        </div>
      </div>
    );
  }
}
