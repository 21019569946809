import React from "react";
import SbEditable from "storyblok-react";
import ButtonRow from "./button_row";
import "./banner.css";
import MarkDown from "./markdown";
import Visibility from "react-visibility-sensor";
import LazyLoad from 'react-lazyload';
import { styles } from '../../utils/helper';

export default class extends React.Component {
  isVisible = (visible) => {
    if (this.refs.videoRef){
      this.refs.videoRef.play();
    }
  };

  isActive = () => {
    return this.props.blok.video_mode === "play-visible";
  };
  render() {
    const { blok, lang } = this.props;
    const { margin, pc_banner_width, pc_padding, pc_img_object_fit, content_message_styles } = blok || {};
    const contentMessageStyle = styles(content_message_styles);
    return (
      <SbEditable content={blok}>
        <Visibility
          active={this.isActive}
          onChange={this.isVisible}
          partialVisibility={"bottom"}
        >
          <div
            className={`banner ${pc_banner_width ? 'pc-banner-custom-width' : ''}`}
            style={{
              backgroundColor: blok.background_color
                ? blok.background_color.color === "#f40000"
                  ? null
                  : blok.background_color.color
                : null,
              ...margin ? { margin } : {},
              ...pc_banner_width ? { width: pc_banner_width } : {},
              ...pc_padding ? { padding: pc_padding } : {},
            }}
          >
            <div
              className={` ${
                blok.image_type === "foreground"
                  ? "banner__foreground"
                  : "banner__bg"
              } ${blok.video ? "banner__video" : null}`}
            >
              {blok.video && (
                <div className="video-block hide-mobile">
                  <div className="vb__background vb__background__video">
                    <LazyLoad offset={2000} once>
                      {this.isActive() ? (
                        <video
                          ref="videoRef"
                          muted={true}
                          x5-playsinline="true"
                          playsinline="true"
                          webkit-playsinline="true"
                          poster={blok.main_image}
                          src={blok.video}
                        ></video>
                      ) : (
                        <video
                          loop="loop"
                          muted="muted"
                          autoPlay
                          x5-playsinline="true"
                          playsinline="true"
                          webkit-playsinline="true"
                          poster={blok.main_image}
                          src={blok.video}
                        ></video>
                      )}
                    </LazyLoad>
                  </div>
                </div>
              )}
               <div class='banner_mobile_show default_minheight' style={{minHeight:blok.mobile_min_image_height?blok.mobile_min_image_height : null}}>
                <picture
                  className={`${
                    blok.main_image ? "" : blok.video ? "hide-pc" : ""
                  }`}
                >
                  <source media="(max-width: 767px)"  srcSet={blok.mobile_image} />
                  <img src={blok.main_image} class='default_minheight banner_img' style={{minHeight:blok.mobile_min_image_height?blok.mobile_min_image_height : null}} alt=""/>
                </picture>
              </div>
              <div class='banner_pc_show default_minheight' style={{minHeight:blok.pc_min_image_height?blok.pc_min_image_height : null}}>
                <picture
                  className={`${
                    blok.main_image ? "" : blok.video ? "hide-pc" : ""
                  }`}
                >
                  <source media="(min-width: 768px)"  srcSet={blok.main_image} />
                  <img src={blok.main_image} class='default_minheight banner_img' style={{minHeight:blok.pc_min_image_height?blok.pc_min_image_height : null, ...(pc_img_object_fit ? { objectFit: pc_img_object_fit } : {})}} alt=""/>
                </picture>
              </div>    
              <div className={`b__content__wrapper ${blok.bannerEmbedded ? 'b__content__wrapper_embedded' : ''}`}>
                <aside
                  className={`b__content ${blok.vertical_align} ${blok.horizontal_align}`}
                >
                    <div className={`b__content__message ${blok.message_color}`} style={contentMessageStyle}>
                      <div 
                        dangerouslySetInnerHTML={MarkDown(this.props.blok.text)}
                      ></div>
                      {blok.button_row &&
                        blok.button_row.map((row, index) => {
                          return (
                            <ButtonRow content={row} key={index} lang={lang} />
                          );
                        })}
                    </div>
                </aside>
              </div>
            </div>
          </div>
        </Visibility>
      </SbEditable>
    );
  }
}

