import React from "react";
import SbEditable from "storyblok-react"
const descItemWithImg = props => {
const { content } = props;
return (
    <SbEditable content={content}>
        <div>
        { content && content.Title &&
            <h3 className="infomation-modal-subtitle-with-img" dangerouslySetInnerHTML={{ __html:content.Title}}></h3>
        }
        {/* <p className="infomation-modal-desc" dangerouslySetInnerHTML={{ __html:content.desc}}></p> */}
        {
            content && content.content && content.content.map((item) => {
                if(item.component === "descItemWithImgDesc" )return(
                    <>
                    {
                  item.desc &&  
                    <p className="infomation-modal-desc" dangerouslySetInnerHTML={{ __html:item.desc}}></p>
                    }
                    </>
                )
                if(item.component === "descItemWithImgImg" )return(
                    <div className="descItemWithImg-wrap">
                        {item.PcImg &&
                            <img className="descItemWithImg-img-pc" src={item.PcImg}></img>
                        }
                        {item.MobileImg &&
                            <img className="descItemWithImg-img-m" src={item.MobileImg}></img>
                        }
                    </div>
                )
            })
        }
        </div>
    </SbEditable> )
}
export default descItemWithImg