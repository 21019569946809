import React from 'react';
import TagText, { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import {
  Props as ParentProps,
  EyeBrowType,
  HeadlineType,
} from './type';
import './style.scss';

interface Props extends ParentProps {
  aosFade: any;
}

// eslint-disable-next-line complexity
const Headline = (props: Props) => {
  const {
    aosFade,
  } = props;

  const connectToppingImage = props.hasToppingImage && !props.hasEyeBrow;
  const connectEyebrowImage = props.hasEyeBrow && props.eyeBrowType === EyeBrowType.svg;
  const connectEyebrowText = props.hasEyeBrow && props.eyeBrowType === EyeBrowType['pure-text'];
  const headlineStyleName = `cardh-headline ${connectToppingImage || connectEyebrowImage ? 'cardh-connect-pic' : ''} ${connectEyebrowText ? 'cardh-connect-eyebrow-text' : ''}`;

  if (props.headlineType === HeadlineType.svg) {
    let replaceholderText = <h2 className="cardh-visual-hidden" dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
    if (props?.headlineTag) {
      replaceholderText = <TagText tagType={props.headlineTag} className="cardh-visual-hidden" dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
    }
    return (
      <>
        <img
          data-aos={aosFade}
          data-aos-once
          src={getAsset(props.headlineSVG)}
          className={headlineStyleName}
          style={{ height: props.headlineSVGHeight }}
          alt={filterHtmlTag(props.headlineText || '')}
        />
        {replaceholderText}
      </>
    );
  }

  const headlineStyle: {
    color?: string;
    backgroundImage?: string;
    WebkitBackgroundClip?: string;
    fontWeight?: any;
    fontSize?: string;
    lineHeight?: string;
    opacity?: number;
    paddingBottom?: string;
    marginBottom?: string;
    letterSpacing?: string;
    margin?: string;
  } = {
    fontWeight: props.headlineFontWeight && TextFontWeight[props.headlineFontWeight] ? TextFontWeight[props.headlineFontWeight] : undefined,
    fontSize: props.headlineFontSize,
    lineHeight: GetLineHeight(props.headlineFontSize, props.headlineLineHeight),
    letterSpacing: props.headlineLetterSpacing,
    ...props.headlineMargin ? { margin: props.headlineMargin } : {},
  };

  if (props.headlineType === HeadlineType['pure-text']) {
    headlineStyle.color = props.headlinePureColor;
  } else {
    headlineStyle.backgroundImage = props.headlineGradientColor;
    headlineStyle.WebkitBackgroundClip = 'text';
    headlineStyle.color = 'transparent';
    headlineStyle.paddingBottom = '0.1em';
    headlineStyle.marginBottom = '-0.1em';
  }

  return <TagText tagType={props.headlineTag || 'h2'} data-aos={aosFade} data-aos-once className={headlineStyleName} style={headlineStyle} dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
};

export default Headline;
