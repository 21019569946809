import Axios from "axios"
import api from "./api"
import { getToken, handleLogin } from "../services/auth"
import createAuthRefreshInterceptor from 'axios-auth-refresh';


const authInstance = Axios.create({
    baseURL: process.env.GATSBY_HASSELBLAD_API_URL,
    timeout: 30000
  });

authInstance.interceptors.request.use((request) => {
    let tokens = getToken()
    request.headers.Authorization = tokens.authorizationToken;

    return request;
});

const refreshAuthLogic = failedRequest => api.get('/authentication/refresh/' + getToken().refreshToken)
    .then(tokenRefreshResponse => {
        handleLogin(tokenRefreshResponse.data.authorization_token, tokenRefreshResponse.data.refresh_token)
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.authorization_token;
        return Promise.resolve();
    });

createAuthRefreshInterceptor(authInstance, refreshAuthLogic);

export default authInstance