import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './videoPlayer.css';

export default class VideoPlayer extends React.Component {

  componentDidMount() {
    this.player = videojs(this.videoNode, {
      ...this.props,
      controlBar: {
        'pictureInPictureToggle': false
      }
    }, () => {
      setTimeout(() => {
        this.player.play();
      }, 50);
    });
  }

  componentWillUnmount() {
    console.log('DDD');
    if (this.player) {
      this.player.pause();
      this.player.dispose();
    }
  }

  render() {
    return (
        <div data-vjs-player className="video-wrapper">
          <video width="100%" height="100%" ref={ node => this.videoNode = node } className="video-js"></video>
        </div>
    )
  }
}