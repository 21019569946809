import React from 'react';
import SbEditable from 'storyblok-react';
import MarkDown from '../markdown';
import API from '../../../utils/api';
import { isIOS, checkIsSafari }from "../../../../helper/utils"
import { FULL_LANG_CODE_TO_SIMPLE_LANGS_CODE } from '../../../utils/languages_map';
import './heroines_block.css';

const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*\.){1,63}[a-z0-9]+$/i;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        email: '',
        website: '',
        relevantLink: '',
        reason: '',
        story: '',
        language: FULL_LANG_CODE_TO_SIMPLE_LANGS_CODE[this.props.lang],
      },
      checked: false,
      error: {
        email: false,
        name: false,
        website: false,
        relevantLink: false,
        reason: false,
        story: false,
      },
      subscribeBtnActive: false,
      success: false,
      reasonLetterNum: 0,
      storyLetterNum: 0,
      isLoading: false,
      timeout: true,
    };
  }

  // 重置数据
  handleResetData = () => {
    const resetData = {
      name: '',
      email: '',
      website: '',
      relevantLink: '',
      reason: '',
      story: '',
      language: FULL_LANG_CODE_TO_SIMPLE_LANGS_CODE[this.props.lang],
    };
    const resetError = {
      email: false,
      name: false,
      website: false,
      relevantLink: false,
      reason: false,
      story: false,
    };
    this.setState({
      data: resetData,
      error: resetError,
      checked: false,
      subscribeBtnActive: false,
      reasonLetterNum: 0,
      storyLetterNum: 0,
    });
  };

  // 处理点击事件
  handleSubmit = () => {
    this.setState({
      isLoading: true,
    });
    const { data } = this.state;
    API.post('/newsletter/subscribeheroines', data)
      .then((result) => {
        this.setState({
          success: true,
          isLoading: false,
          timeout: false,
        });
        this.handleResetData();
        setTimeout(() => {
          this.setState({ 
            timeout: true,
            success: false,
          });
        }, 3000);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          timeout: false,
        });
        setTimeout(() => {
          this.setState({ timeout: true });
        }, 3000);
        console.log(err, 'error');
      });
  };

  handle = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let { error, data } = this.state;

    switch (name) {
      case 'email':
        if (reg.test(value)) {
          this.setState({
            subscribeBtnActive: true,
          });
        } else {
          this.setState({
            subscribeBtnActive: false,
          });
        }
        break;
      case 'reason':
        this.setState({
          reasonLetterNum: value.length,
        });
        break;
      case 'story':
        this.setState({
          storyLetterNum: value.length,
        });
        break;
      default:
        break;
    }
    data[name] = value;
    this.setState({
      error,
      data,
    });
  };

  handleOnblur = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let { error } = this.state;

    switch (name) {
      case 'email':
        error.email = !value || !reg.test(value);
        break;
      case 'name':
        error.name = !value;
        break;
      case 'website':
        error.website = !value;
        break;
      case 'relevantLink':
        error.relevantLink = !value;
        break;
      case 'reason':
        error.reason = !value;
        break;
      case 'story':
        error.story = !value;
        break;
      default:
        break;
    }

    this.setState({
      error,
    });
  };

  handleSubscribeFocus = () => {
    let { error } = this.state;
    error.email = false;
    this.setState({
      error,
    });
  };

  handleSubscribeKeyUp = (event) => {
    const target = event.target;
    const value = target.value;
    if (reg.test(value)) {
      this.setState({
        subscribeBtnActive: true,
      });
    } else {
      this.setState({
        subscribeBtnActive: false,
      });
    }
  };

  // 更新checkbox
  updateSubscription = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };
  
  /**
   * 处理 maxlength
   */
  handleMaxLength = (str, maxLen) => {
    // ios 和 safari 换行特殊处理
    const isSafari = checkIsSafari();
    if(isIOS().ios || isSafari) {
      let reg = new RegExp(/\n/g);
      let lineNum = str.match(reg);
      return lineNum ? (lineNum.length + maxLen) : maxLen;
    }else {
      return maxLen;
    }
  }

  render = () => {
    const { blok } = this.props;
    const {
      title,
      nameLabel,
      emailLabel,
      linkFirstLabel,
      linkSecondLabel,
      textareaFirstLabel,
      textareaSecondLabel,
      namePlaceholder,
      emaliPlaceholder,
      link1Placeholder,
      link2Placeholder,
      textarea1Placeholder,
      textarea2Placeholder,
      emailErrorTip,
      nameErrorTip,
      websiteErrorTip,
      relevantErrorTip,
      reasonErrorTip,
      storyErrorTip,
      buttonText,
      agreeConfig,
      successText,
      cmpMargin,
      cmpmarginBottom,
      errorText,
    } = blok;

    const {
      error,
      data,
      checked,
      subscribeBtnActive,
      success,
      reasonLetterNum,
      storyLetterNum,
      isLoading,
      timeout,
    } = this.state;
    const { name, website, relevantLink, reason, story  } = data;
    const dataError = !name || !website || !relevantLink || !reason || !story; // 有信息错误
    const submitDisabled = dataError || !checked || !subscribeBtnActive || success; // 信息错误/未勾选协议/邮箱错误/提交之后

    return (
      <SbEditable content={blok}>
        <div
          style={{ marginBottom: cmpmarginBottom }}
          className="heronies-container"
        >
          <div className="heronies-content">
            <div
              style={{ marginTop: cmpMargin }}
              className="heronies-title"
              dangerouslySetInnerHTML={MarkDown(title)}
            />
            <div className="editorial row">
              <div className="et fifty">
                <label htmlFor="name">
                  <div className="heroines-label">
                    <span
                      className="show"
                      dangerouslySetInnerHTML={{ __html: nameLabel }}
                    ></span>
                  </div>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    maxLength="255"
                    placeholder={namePlaceholder}
                    value={data.name}
                    onBlur={this.handleOnblur}
                    onChange={this.handle.bind(this)}
                  />
                  <span
                    className={
                      error.name && data.name === ''
                        ? 'heroines-email-show'
                        : 'heroines-email-no-show'
                    }
                  >
                    {nameErrorTip}
                  </span>
                </label>
              </div>
              <div className="et left fifty">
                <label htmlFor="email">
                  <div className="heroines-label heronies-label-mobile">
                    <span
                      className="show"
                      dangerouslySetInnerHTML={{ __html: emailLabel }}
                    ></span>
                  </div>
                  <input
                    name="email"
                    type="email"
                    maxLength="255"
                    placeholder={emaliPlaceholder}
                    value={data.email}
                    onChange={this.handle}
                    onBlur={this.handleOnblur}
                    onFocus={this.handleSubscribeFocus}
                    onKeyUp={this.handleSubscribeKeyUp}
                  />
                  {/* 错误文案 */}
                  <span
                    className={
                      (error.email && data.email !== '') || (data.email === '' && error.email)
                        ? 'heroines-email-show'
                        : 'heroines-email-no-show'
                    }
                  >
                    {emailErrorTip}
                  </span>
                </label>
              </div>
              <div className="heroines-pc-link-box">
                <div className="et fifty">
                  <label
                    htmlFor="website"
                    className="heronies-link-content-first"
                  >
                    <div style={{ margin: '32px 0 0 0' }}>
                      <span
                        className="show"
                        dangerouslySetInnerHTML={{ __html: linkFirstLabel }}
                      ></span>
                    </div>
                  </label>
                </div>
                <div className="et fifty">
                  <label
                    htmlFor="relevantLink"
                    className="heronies-link-content-first"
                  >
                    <div style={{ margin: '32px 0 0 0' }}>
                      <span
                        className="show"
                        dangerouslySetInnerHTML={{ __html: linkSecondLabel }}
                      ></span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="heroines-pc-link-box">
                <div className="et fifty">
                  <label
                    htmlFor="website"
                    className="heronies-link-content-first"
                  >
                    <input
                      name="website"
                      id="website"
                      type="text"
                      maxLength="255"
                      placeholder={link1Placeholder}
                      value={data.website}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind(this)}
                    />
                    <span
                      className={
                        error.website && data.website === ''
                          ? 'heroines-email-show'
                          : 'heroines-email-no-show'
                      }
                    >
                      {websiteErrorTip}
                    </span>
                  </label>
                </div>
                <div className="et fifty">
                  <label
                    htmlFor="relevantLink"
                    className="heronies-link-content-first"
                  >
                    <input
                      name="relevantLink"
                      id="relevantLink"
                      type="text"
                      maxLength="255"
                      placeholder={link2Placeholder}
                      value={data.relevantLink}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind(this)}
                    />
                    <span
                      className={
                        error.relevantLink && data.relevantLink === ''
                          ? 'heroines-email-show'
                          : 'heroines-email-no-show'
                      }
                    >
                      {relevantErrorTip}
                    </span>
                  </label>
                </div>
              </div>
              {/* mobile */}
              <div className="heroines-mobile-link-box">
                <div className="et fifty">
                  <label htmlFor="website">
                    <div>
                      <span
                        className="show"
                        dangerouslySetInnerHTML={{ __html: linkFirstLabel }}
                      ></span>
                    </div>
                    <input
                      name="website"
                      id="website"
                      type="text"
                      maxLength="255"
                      placeholder={link1Placeholder}
                      value={data.website}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind(this)}
                    />
                    <span
                      className={
                        error.website && data.website === ''
                          ? 'heroines-email-show'
                          : 'heroines-email-no-show'
                      }
                    >
                      {websiteErrorTip}
                    </span>
                  </label>
                </div>
                <div className="et fifty">
                  <label htmlFor="relevantLink">
                    <div>
                      <span
                        className="show"
                        dangerouslySetInnerHTML={{ __html: linkSecondLabel }}
                      ></span>
                    </div>
                    <input
                      name="relevantLink"
                      id="relevantLink"
                      type="text"
                      maxLength="255"
                      placeholder={link2Placeholder}
                      value={data.relevantLink}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind(this)}
                    />
                    <span
                      className={
                        error.relevantLink && data.relevantLink === ''
                          ? 'heroines-email-show'
                          : 'heroines-email-no-show'
                      }
                    >
                      {relevantErrorTip}
                    </span>
                  </label>
                </div>
              </div>
              <div className="et hundred">
                <label htmlFor="reason">
                  <div>
                    <span
                      className="show"
                      dangerouslySetInnerHTML={{ __html: textareaFirstLabel }}
                    ></span>
                  </div>
                  <div style={{ position: 'relative', marginTop: '16px' }}>
                    <textarea
                      style={{ width: '100%', height: '112px', resize: 'none' }}
                      name="reason"
                      id="reason"
                      type="text"
                      maxLength={this.handleMaxLength(data.reason, 1000)}
                      placeholder={textarea1Placeholder}
                      value={data.reason}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind()}
                    />
                    <div className="heroines-letter-num">{`${reasonLetterNum} / 1000 `}</div>
                  </div>
                  <span
                    className={
                      error.reason && data.reason === ''
                        ? 'heroines-email-show'
                        : 'heroines-email-no-show'
                    }
                  >
                    {reasonErrorTip}
                  </span>
                </label>
              </div>
              <div className="et hundred">
                <label htmlFor="story">
                  <div>
                    <span
                      className="show"
                      dangerouslySetInnerHTML={{ __html: textareaSecondLabel }}
                    ></span>
                  </div>
                  <div style={{ position: 'relative', marginTop: '16px' }}>
                    <textarea
                      style={{ width: '100%', height: '112px', resize: 'none' }}
                      name="story"
                      id="story"
                      type="text"
                      maxLength={this.handleMaxLength(data.reason, 1000)}
                      placeholder={textarea2Placeholder}
                      value={data.story}
                      onBlur={this.handleOnblur}
                      onChange={this.handle.bind(this)}
                    />
                    <div className="heroines-letter-num">{`${storyLetterNum} / 1000 `}</div>
                  </div>
                  <span
                    className={
                      error.story && data.story === ''
                        ? 'heroines-email-show'
                        : 'heroines-email-no-show'
                    }
                  >
                    {storyErrorTip}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="et left hundred heroines-checkbox">
            <input
              id="checked"
              type="checkbox"
              checked={checked}
              onChange={this.updateSubscription}
            />
            <label htmlFor="checked">
              <span className="agree-config" dangerouslySetInnerHTML={{ __html: agreeConfig }}></span>
            </label>
          </div>
          <div className="cta-row heroines-button">
            <button
              disabled={submitDisabled}
              className={`cta ${
                isLoading ? 'loading' : null
              } ${submitDisabled && 'disabled'}`}
              onClick={() => this.handleSubmit()}
            >
              {buttonText}
            </button>
            <div
              style={{ opacity:  timeout ? 0 : 1 }}
              className={ success ? 'heroines-success-tip' : 'heroines-error-tip' }
              dangerouslySetInnerHTML={{ __html: success ? successText : errorText }}
            ></div>
          </div>
        </div>
      </SbEditable>
    );
  };
}
