import React, { useEffect } from "react"
import Page from './page'
import Layout from "./layout";
import { isLoggedIn } from "../services/auth"
import t from "../utils/languages"

function LogIn(props) {
  const lang = props.page && props.page.lang;
  const isLoggedIn = props.isLoggedIn;
  const isSSR = typeof window === "undefined"
  //const loginPage = 'https://account.hasselblad.com/login?service=https://api.hasselblad.com/authentication/cas/my-hasselblad/';
  const pathName = isSSR ? '/my-hasselblad' : window.location.pathname;
  const stage = process.env.GATSBY_HASSELBLAD_ENV === 'stage';
  const accountHost = stage ? 'https://hb-account.dbeta.me/' : 'https://account.hasselblad.com/'
  const loginPage = `${accountHost}login?service=`+ process.env.GATSBY_HASSELBLAD_API_URL +'authentication/cas' + (stage ? '/https://stage.hasselblad.com' : '') + pathName;

  if (isLoggedIn && !isSSR) {
    return new Page(props.page);
  }
  else if (!isLoggedIn && !isSSR){
    return (
      <Layout page={props.page}>
        <div className="editorial row" style={{ background:"#e6e6e6" }}>

          <div class="et center-center fifty black" >
            <div>
              <h2>{t('account.login', lang)}</h2>
              <p>{t('account.login.to.h', lang)}</p>
              <div className="cta-row">
                <a className="cta" href={loginPage}>{t('account.log_in', lang)}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="editorial row">
          <div class="et center-center fifty black">
            <div>
              <h3>{t('account.create', lang)}</h3>
              <p>{t('account.access', lang)}</p>
              <div className="cta-row">
                { /*<a className="cta" href="https://account.hasselblad.com/signup?service=https%3A%2F%2Fwww.hasselblad.com%2Fmy-hasselblad%2F">Sign up</a> */}
                <a className="cta" href={ `${accountHost}signup?service=`+ encodeURIComponent(process.env.GATSBY_HASSELBLAD_API_URL +'authentication/cas' + (stage ? '/https://stage.hasselblad.com' : '') + pathName )}>{t('account.signup', lang)}</a>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
  else{
    return <Layout page={props.page} />
  }
}

const AuthorizedPage = props => {

  useEffect(() => {
    if (typeof document === 'undefined') return;
    document.body.style.overflow = 'auto';
    document.body.classList.remove('menu-opened');
    document.querySelector('html').classList.remove('menu-opened');
  })
  
  return (
    <LogIn isLoggedIn={isLoggedIn()} page={props} />
  )
  
}

export default AuthorizedPage
