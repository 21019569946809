import React, { useState, useEffect } from 'react';
import SbEditable from "storyblok-react"
import _debounce from 'lodash.debounce';
import { DeviceInfo } from '../../../hooks/device';
import './style.scss';

const ToggleAnswer = (props) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const { questionIndex, maxHeightSetMap } = props;
  const maxHeightSet = maxHeightSetMap[questionIndex];
  return (
    <div
      className="infofaq-answer-wrapper"
      style={{ maxHeight: props.open ? maxHeight : 0, opacity: props.open ? 1 : 0 }}
    >
      <div
        className="infofaq-answer"
        ref={(ele) => {
          if (!maxHeightSet && ele) {
            maxHeightSetMap[questionIndex] = true;
            setMaxHeight(ele.clientHeight + 16);
          }
        }}
        dangerouslySetInnerHTML={{ __html: props.answer }}
      />
    </div>
  );
};

const QuestionList = (props) => {
  const [activeMap, setActiveMap] = useState({});
  const [maxHeightSetMap, setMaxHeightSetMap] = useState({});

  const onResize = () => {
    setMaxHeightSetMap({});
  };

  useEffect(() => {
    onResize();
    const debounceResize = _debounce(onResize, 300);
    window.addEventListener('resize', debounceResize);
  }, []);

  return (
    <div>
      {(props.questions || []).map((questionItem, questionIndex) => {
        const { question, answer } = questionItem;
        const open = activeMap[questionIndex];
        return (question && answer) ? (
        <SbEditable content={questionItem} key={questionItem && questionItem._uid || `faq_item_${questionIndex}`}>
          <div className="infofaq-question-item">
            <div
              className={`infofaq-question ${open ? 'infofaq-open' : ''}`}
              onClick={() => {
                setActiveMap({
                  ...activeMap,
                  [questionIndex]: !activeMap[questionIndex],
                });
              }}
            >
              <span className="infofaq-text">{question}</span>
              <div className="infofaq-angle-down" />
            </div>
            <ToggleAnswer open={open} answer={answer} maxHeightSetMap={maxHeightSetMap} setMaxHeightSetMap={setMaxHeightSetMap} questionIndex={questionIndex}  />
          </div>
        </SbEditable>
        ) : null;
      })}
    </div>
  );
};

const InfoFAQ = ({blok}) => {
  const { isMobile: isM } = DeviceInfo();

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="infofaq-container">
        <div className="infofaq-section">
          <h1 className="infofaq-title" style={{ paddingTop: isM ? blok.faqTitleTopM : blok.faqTitleTopPC }} dangerouslySetInnerHTML={{ __html: blok.faqTitle }} />
          <div className="infofaq-group-faq">
            {/* 问题列表 */}
            <QuestionList questions={blok.questions} />
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default InfoFAQ;