import {
  TextLineHeight, TextFontSize, ElementLabel,
} from '../../../basic/Text';

interface ForegroundItem {
  media: CardMedia;
  mediaBorderRadius?: string;
  mediaDistance: string;
  foregroundImage?: StoryblokAsset;
  foregroundImageAlt?: string;
  foregroundHasParallax?: boolean;
  foregroundParallax?: ForegroundParallax;
  foregroundHeight?: string;
  foregroundVideo?: StoryblokAsset;
  foregroundVideoWebm?: StoryblokAsset;
  foregroundVideoPoster?: StoryblokAsset;
  foregroundVideoPlayType?: VideoPlayType;
}

export interface Props extends ForegroundItem {
  _uid: string;
  id?: string;
  gaSend: () => {};
  // 结构
  leftCols: LeftCols;
  contentSize: ContentSize;
  verticalAlign: VerticalAlign;
  horizontalAlign: HorizontalAlign;
  cardLayout: CardLayout;
  canZoomImage?: boolean;
  zoomImageConfig?: any[];
  showZoomIconInMedia?: boolean;

  // 尺寸
  minHeight?: string;
  marginTop: string;
  marginBottom: string;
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  // 样式
  borderRadius: string;
  backgroundColor: string;
  backgroundGradient?: string;
  textColor: TextColor;
  linkColor: LinkColor;
  linkFontSize?: TextFontSize;
  linkLineHeight?: TextLineHeight;
  // 跳转
  hasJumpLink: boolean;
  jumpType: JumpType;
  jumpLink: StoryblokLink;
  jumpLinkGaLabel?: string;

  // 卡片背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundImageWidth?: string;
  backgroundImageHeight?: string;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundFixed?: BackgroundFixed;
  backgroundParallax?: boolean;
  // 背景视频
  hasBackgroundVideo: boolean;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideoHoverPlay?: boolean;
  backgroundVideo?: StoryblokAsset;
  backgroundVideoPoster?: StoryblokAsset;
  backgroundVideoMaskColor?: string;
  backgroundVideoMaskOpacity?: string;
  // media
  hasMedia: boolean;
  foregroundLayout?: ForegroundLayout;
  foregroundListGap?: string;
  foregroundList?: ForegroundItem[];
  foregroundCredit?: string;
  // content
  contentPaddingTop: string;
  contentPaddingLeft: string;
  contentPaddingRight: string;
  contentPaddingBottom: string;
  // tag
  hasTag: boolean;
  tagText?: string;
  tagHeight?: string;
  tagWidth?: string;
  tagColor?: string;
  tagRadius?: string;
  // 顶部图
  hasToppingImage: boolean;
  toppingImage: StoryblokAsset;
  toppingImageHeight: string;
  toppingAlt?: string;

  // 眉头
  hasEyeBrow: boolean;
  eyeBrowType?: EyeBrowType;
  eyeBrowSVG?: StoryblokAsset;
  eyeBrowSVGHeight?: string;
  eyeBrowText?: string;
  eyeBrowTag?: ElementLabel;
  eyeBrowPureColor?: string;
  eyeBrowGradientColor?: string;
  eyeBrowFontSize?: TextFontSize;
  eyeBrowFontWeight?: string;
  eyeBrowLineHeight?: TextLineHeight;
  eyeBrowLetterSpacing?: string;
  eyeBrowMargin?: string;
  // 主标题
  hasHeadline: boolean;
  headlineType: HeadlineType;
  headlineSVG?: StoryblokAsset;
  headlineSVGHeight?: string;
  headlineText?: string;
  headlineTag?: ElementLabel;
  headlinePureColor?: string;
  headlineGradientColor?: string;
  headlineFontSize?: TextFontSize;
  headlineFontWeight?: string;
  headlineLineHeight?: TextLineHeight;
  headlineLetterSpacing?: string;
  headlineMargin?: string;

  // 副标题
  hasSubHeadline: boolean;
  subHeadlineType: HeadlineType;
  subHeadlineText?: string;
  subHeadlineTag?: ElementLabel;
  subHeadlineColor?: string;
  subHeadlineGradientColor?: string;
  subHeadlineFontSize?: TextFontSize;
  subHeadlineFontWeight?: string;
  subHeadlineLineHeight?: TextLineHeight;
  subHeadlineLetterSpacing?: string;
  subHeadlineMargin?: string;

  // 分割线
  hasDividedLine: boolean;
  dividedBlockHeight?: string;
  dividedLineWidth?: string;
  dividedLineHeight?: string;
  dividedLineRadius?: string;
  dividedLineColor?: string;
  dividedLineGradientColor?: string;
  // 详细介绍
  hasDescription: boolean;
  descriptionColor?: string;
  descriptionText?: string;
  descriptionFontSize?: TextFontSize;
  descriptionFontWeight?: string;
  descriptionLineHeight?: TextLineHeight;
  descriptionLetterSpacing?: string;
  descriptionMargin?: string;

  // 主按钮
  // 次按钮
  // 次按钮 - 2
  // 链接
  hasCardLinks: boolean;
  links?: Array<object>;
  // 备注
  hasNote: boolean;
  noteMarginTop?: string;
  noteText?: string;
  noteColor?: string;
  noteFontSize?: TextFontSize;
  noteFontWeight?: string;
  noteLineHeight?: TextLineHeight;
  noteLetterSpacing?: string;
  noteMargin?: string;

  // 第一行icons
  hasIcons1: boolean;
  icons1MaxWidth?: string;
  icons1Margin: string;
  icons1width: string;
  icons1Layout: IconLayout;
  icons1Height: string;
  icons1?: Array<Icon>;
  // 第二行icons
  hasIcons2: boolean;
  icons2MaxWidth?: string;
  icons2Margin: string;
  icons2width: string;
  icons2Layout: IconLayout;
  icons2Height: string;
  icons2?: Array<Icon>;

  getFadeTransiation: Function;
  getMediaTransiation: Function;
  getGapWidth: Function;
  getGridWidth: Function;
  index: number;

  // 3d ar
  secondaryButtonClickID: string;
  thirdlyButtonClickID: string;
  // 按钮排列方式
  buttonListTypeColumn: boolean;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export interface PropsWithAos extends Props {
  aosFade: any;
}

export enum TextColor {
  'black' = 'black',
  'white' = 'white',
}

export enum LinkColor {
  'black' = 'black',
  'white' = 'white',
  'dark' = 'dark',
  'normal' = 'normal',
}

export enum EyeBrowType {
  'svg' = 'svg',
  'pure-text' = 'pure-text',
  'gradient-text' = 'gradient-text',
}

export enum HeadlineType {
  'svg' = 'svg',
  'pure-text' = 'pure-text',
  'gradient-text' = 'gradient-text',
}

export enum VideoPlayType {
  'loop' = 'loop',
  'once' = 'once',
  'replayWhenRevisible' = 'replayWhenRevisible',
  'withController' = 'withController',
}

export enum IconLayout {
  'vertical' = 'vertical',
  'horizontal' = 'horizontal',
}

export enum CardLayout {
  'media-text' = 'media-text',
  'text-media' = 'text-media',
}

export enum CardMedia {
  'picture' = 'picture',
  'video' = 'video',
}

export enum VerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum tabPresentation {
  'carousel' = 'carousel',
  'manual-switch' = 'manual-switch',
}

export enum HorizontalAlign {
  'left-align' = 'left-align',
  'center-align' = 'center-align',
  'right-align' = 'right-align',
}

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum ContentSize {
  'xxxxxl' = 'xxxxxl',
  'xxxxl' = 'xxxxl',
  'xxxl' = 'xxxl',
  'xxl' = 'xxl',
  'xl' = 'xl',
  'l' = 'l',
  'm' = 'm',
  's' = 's',
  'xs' = 'xs',
  'xxs' = 'xxs',
}

export enum PrimaryButtonType {
  'button' = 'button',
  'external-button' = 'external-button',
  'store-button' = 'store-button',
  'video-play' = 'video-play',
}

export enum PrimaryButtonColor {
  'dark' = 'dark',
  'light' = 'light',
  'primary' = 'primary',
}

export enum SecondaryButtonType {
  'button' = 'button',
  'external-button' = 'external-button',
  'video-play' = 'video-play',
  'internal-link' = 'internal-link',
  'external-link' = 'external-link',
  'download-link' = 'download-link',
  'no-icon-link' = 'no-icon-link',
}

export enum SecondaryButtonColor {
  'secondary-dark' = 'secondary-dark',
  'secondary-light' = 'secondary-light',
}

export enum ComponentLinkType {
  'internal-link' = 'internal-link',
  'external-link' = 'external-link',
  'download-link' = 'download-link',
  'no-icon-link' = 'no-icon-link',
}

export enum JumpType {
  'internal' = 'internal',
  'external' = 'external',
}

export enum ForegroundParallax {
  'whole-item' = 'whole-item',
  'inner-content' = 'inner-content',
}

export enum ForegroundLayout {
  'one' = 'one',
  'leftRight' = 'leftRight',
  'upDown' = 'upDown',
  'up1down2' = 'up1down2',
  'up2down1' = 'up2down1',
  'left2right1' = 'left2right1',
}

export enum LeftCols {
  '0' = '0',
  '1' = '1',
  '2' = '2',
  '3' = '3',
  '4' = '4',
  '5' = '5',
  '6' = '6',
  '7' = '7',
  '8' = '8',
  '9' = '9',
  '10' = '10',
  '11' = '11',
  '12' = '12',
}

export enum DarkButtonColor {
  'dark' = 'dark',
  'primary' = 'primary',
  'secondary-light' = 'secondary-light',
}

export interface CardLink {
  linkType: ComponentLinkType;
  linkText: string;
  text: string;
}

export interface FontSetting {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
  color?: string;
}

export interface ModalContentItem {
  title?: string;
  desc?: string;
  titleFont?: FontSetting;
  descFont?: FontSetting;
}

export interface Icon {
  hasTitle: boolean;
  hasPic: boolean;
  hasDesc: boolean;
  hasNumber: boolean;
  hasUnit: boolean;
  iconAlt?: string;
  iconHeight: string;
  title?: string;
  titleFontSize?: TextFontSize;
  titleFontWeight?: string;
  titleLineHeight?: TextLineHeight;
  titleLetterSpacing?: string;
  titleMargin?: string;
  pic?: StoryblokAsset;
  desc?: string;
  descFontSize?: TextFontSize;
  descFontWeight?: string;
  descLineHeight?: TextLineHeight;
  descLetterSpacing?: string;
  descMargin?: string;
  number?: string;
  unit?: string;
  titleColor?: string;
  descColor?: string;
}

export interface StoryblokLink {
  linktype: 'story' | 'url';
  cached_url?: string; // 内部链接
  url?: string; // 绝对链接
  id: string;
  fieldtype: string;
}

export interface StoryblokAsset {
  filename: string; // 资源地址
  id: string;
  alt: string;
  name: string;
  focus: object;
  title: string;
  meta_data: object;
  fieldtype: string;
  copyright: string;
}