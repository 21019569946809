/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import {
  TextFontWeight, TextLineHeight, TextFontSize, GetLineHeight,
} from '../../../basic/Text';
import VideoPlayer from '../../../video';
import ParallaxPicture from '../../../ParallaxPicture';
import LazyWrapper from '../../../utils/LazyWrapperScroll';
import ButtonRow from '../../../../blocks/button_row';
import './style.scss';
import { filterHtmlTag, getAsset, LinkFix } from '../../../../../utils/helper';
import { ElementLabel } from '../../../basic/Text'
import TagText from '../../../basic/Text'
import { StoryblokAsset, StoryblokLink } from '../../../pc/InfoCardHorizontal/card/type';

export enum CardHeadlineSize {
  'h1' = 'h1',
  'h2' = 'h2',
  'h3' = 'h3',
  'h4' = 'h4',
  'p' = 'p',
}

export enum CardContentSize {
  'xxxxxl' = 'xxxxxl',
  'xxxxl' = 'xxxxl',
  'xxxl' = 'xxxl',
  'xxl' = 'xxl',
  'xl' = 'xl',
  'l' = 'l',
  'm' = 'm',
  's' = 's',
  'xs' = 'xs',
  'xxs' = 'xxs'
}

export enum CardVerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum CardHorizonalAlign {
  'left-align' = 'left-align',
  'center-align' = 'center-align',
  'right-align' = 'right-align',
}

export enum ForeContentType {
  'image' = 'image',
  'video' = 'video',
}

export enum foreVideoColor {
  'light' = 'light',
  'dark' = 'dark',
}

export enum ForeVideoPlay {
  'repeat' = 'repeat',
  'once' = 'once',
  'every' = 'every',
  'withController' = 'withController',
}

export enum ForeContentPosition {
  'image-text' = 'image-text',
  'text-image' = 'text-image',
}

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum EyeBrowType {
  'svg' = 'svg',
  'pure-text' = 'pure-text',
  'gradient-text' = 'gradient-text',
}

export enum HeadlineType {
  'svg' = 'svg',
  'pure-text' = 'pure-text',
  'gradient-text' = 'gradient-text',
}

export enum PrimaryButtonType {
  'button' = 'button',
  'external-button' = 'external-button',
  'store-button' = 'store-button',
  'video-play' = 'video-play',
}

export enum PrimaryButtonColor {
  'dark' = 'dark',
  'light' = 'light',
  'primary' = 'primary',
}

export enum PrimaryButtonSize {
  'x-large' = 'x-large',
  'large' = 'large',
  'medium' = 'medium',
  'small' = 'small',
}

export enum LinkColor {
  'black' = 'black',
  'white' = 'white',
  'dark' = 'dark',
  'normal' = 'normal',
}

export enum SecondaryButtonType {
  'button' = 'button',
  'external-button' = 'external-button',
  'store-button' = 'store-button',
  'video-play' = 'video-play',
  'internal-link' = 'internal-link',
  'external-link' = 'external-link',
  'download-link' = 'download-link',
  'no-icon-link' = 'no-icon-link',
}

export enum SecondaryButtonColor {
  'secondary-dark' = 'secondary-dark',
  'secondary-light' = 'secondary-light',
}

export enum SecondaryButtonSize {
  'x-large' = 'x-large',
  'large' = 'large',
  'medium' = 'medium',
  'small' = 'small',
}

export enum ComponentLinkType {
  'internal-link' = 'internal-link',
  'external-link' = 'external-link',
  'download-link' = 'download-link',
  'no-icon-link' = 'no-icon-link',
}

export enum IconsLayout {
  'image-text' = 'image-text',
  'text-image' = 'text-image',
  'horizontal' = 'horizontal',
}

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'none' = 'none',
}

export enum JumpType {
  'internal' = 'internal',
  'external' = 'external',
}

export enum ForegroundParallax {
  'whole-item' = 'whole-item',
  'inner-content' = 'inner-content',
}

interface Icons {
  title: string;
  titleFontSize?: TextFontSize;
  titleFontWeight?: string;
  titleLineHeight?: TextLineHeight;
  pic: StoryblokAsset;
  iconAlt?: string;
  desc: string;
  descFontSize?: TextFontSize;
  descFontWeight?: string;
  descLineHeight?: TextLineHeight;
  number?: string;
  unit?: string;
  titleColor?: string;
  descColor?: string;
  hasPic?: boolean;
  hasTitle?: boolean;
  hasDesc?: boolean;
  hasNumber: boolean;
  hasUnit: boolean;
}

interface FontSetting {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
  color?: string;
}

interface ModalContentItem {
  title?: string;
  desc?: string;
  titleFont?: FontSetting;
  descFont?: FontSetting;
}

export interface Props {
  gaSend: () => {};

  cardHeadlineSize: CardHeadlineSize;
  cardContentSize: CardContentSize;

  cardVerticalAlign: CardVerticalAlign;
  cardHorizonalAlign: CardHorizonalAlign;

  hasCardLink: boolean;
  cardLink: StoryblokLink;
  cardLinkGaLabel?: string;
  jumpType: JumpType;

  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;

  cardMinHeight: string;
  pureBackgroundColor: string;
  backgroundGradient: string;
  cardRadius: string;

  // 背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundImageWidth?: string;
  backgroundImageHeight?: string;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundParallax?: boolean;

  // 背景视频
  hasBackgroundVideo: boolean;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideoHoverPlay?: boolean;
  backgroundVideo?: StoryblokAsset;
  backgroundVideoPoster?: StoryblokAsset;
  backgroundVideoMaskColor?: string;
  backgroundVideoMaskOpacity?: string;

  // 前景内容
  hasForeContent: boolean;
  foreContentType: ForeContentType;
  foreVideoColor: foreVideoColor;
  foreVideo?: StoryblokAsset;
  foreVideoWebm?: StoryblokAsset;
  foreImage?: StoryblokAsset;
  foreImageAlt?: string;
  foregroundHasParallax?: boolean;
  foregroundParallax?: ForegroundParallax;
  foregroundHeight?: string;
  foreVideoPlay: ForeVideoPlay;
  foreVideoPoster: StoryblokAsset;
  foreContentGap: string;
  foreContentPosition: ForeContentPosition;
  foreContentRadius: string;
  contentPaddingTop: string;
  contentPaddingBottom: string;
  contentPaddingLeft: string;
  contentPaddingRight: string;

  // 顶部图
  hasToppingImage: boolean;
  toppingImage?: StoryblokAsset;
  toppingImageHeight?: string;
  toppingAlt?: string;

  // 眉头
  hasEyeBrow: boolean;
  eyeBrowType?: EyeBrowType;
  eyeBrowTag?: ElementLabel;
  eyeBrowMaxWidth?: string;
  eyeBrowSVG?: StoryblokAsset;
  eyeBrowSVGHeight?: string;
  eyeBrowText?: string;
  eyeBrowPureColor?: string;
  eyeBrowGradientColor?: string;
  eyeBrowFontSize?: TextFontSize;
  eyeBrowFontWeight?: string;
  eyeBrowLineHeight?: TextLineHeight;

  // 主标题
  hasHeadline: boolean;
  headlineType: HeadlineType;
  headlineTag?: ElementLabel;
  headlineSVG?: StoryblokAsset;
  headlineSVGHeight?: string;
  headlineText?: string;
  headlineMaxWidth?: string;
  headlinePureColor?: string;
  headlineGradientColor?: string;
  headlineFontSize?: TextFontSize;
  headlineFontWeight?: string;
  headlineLineHeight?: TextLineHeight;

  // 副标题
  hasSubHeadline: boolean;
  subHeadlineType: HeadlineType;
  subHeadlineTag?: ElementLabel;
  subHeadlineColor?: string;
  subHeadlineGradientColor?: string;
  subHeadlineText?: string;
  subHeadlineMaxWidth?: string;
  subHeadlineFontSize?: TextFontSize;
  subHeadlineFontWeight?: string;
  subHeadlineLineHeight?: TextLineHeight;

  // 分割线
  hasDividedLine: boolean;
  dividedBlockHeight?: string;
  dividedLineWidth?: string;
  dividedLineHeight?: string;
  dividedLineRadius?: string;
  dividedLineColor?: string;
  dividedLineGradientColor?: string;

  // 详细介绍
  hasDescription: boolean;
  descriptionMinHeight?: string;
  descriptionColor?: string;
  descriptionText?: string;
  descriptionMaxWidth?: string;
  descriptionFontSize?: TextFontSize;
  descriptionFontWeight?: string;
  descriptionLineHeight?: TextLineHeight;

  // 链接
  hasLinks: boolean;
  links: Array<object>;
  linkColor: LinkColor;
  linkFontSize?: TextFontSize;
  linkLineHeight?: TextLineHeight;

  // icon
  hasIconsFirstRow: boolean;
  iconsFirstMaxWidth: string;
  iconsFirstHeight: string;
  iconsLayoutFirstRow: IconsLayout;
  iconsFirstRow: Array<Icons>;

  hasIconsSecondRow: boolean;
  iconsSecondMaxWidth: string;
  iconsSecondHeight: string;
  iconsLayoutSecondRow: IconsLayout;
  iconsSecondRow: Array<Icons>;

  // 备注
  hasNote: boolean;
  noteMarginTop?: string;
  noteText?: string;
  noteColor?: string;
  noteFontSize?: TextFontSize;
  noteFontWeight?: string;
  noteLineHeight?: TextLineHeight;

  // video
  videoLazyLoadDistance?: boolean;

  getFadeTransiation?: Function;
  getMediaTransiation?: Function;
  index: number;
  getCardsStretch?: Function;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export interface State {
  hovering?: boolean;
}

export default class Card extends React.Component<Props, State> {

  static defaultProps = {
    index: 0,
    cardContentSize: CardContentSize.xl,
    cardVerticalAlign: CardVerticalAlign['center-align'],
    cardHorizonalAlign: CardHorizonalAlign['center-align'],

    hasCardLink: false,
    cardLink: {},
    jumpType: JumpType.external,

    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '',
    paddingRight: '',

    cardMinHeight: '',
    pureBackgroundColor: '',
    backgroundGradient: '',
    cardRadius: '',

    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundImageWidth: '',
    backgroundImageHeight: '',
    backgroundPosition: 'center',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundParallax: false,

    hasBackgroundVideo: false,
    backgroundVideoLoop: false,
    backgroundVideoHoverPlay: false,
    backgroundVideoVisiblePlay: false,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',

    hasForeContent: false,
    foreContentType: ForeContentType.image,
    foreVideoColor: foreVideoColor.light,
    foreVideo: {},
    foreVideoWebm: {},
    foreImage: {},
    foreImageAlt: '',
    foregroundHasParallax: false,
    foregroundParallax: ForegroundParallax['inner-content'],
    foregroundHeight: '300px',
    foreVideoPoster: {},
    foreVideoPlay: ForeVideoPlay.repeat,
    foreContentGap: '48px',
    foreContentPosition: ForeContentPosition['image-text'],
    foreContentRadius: '',
    contentPaddingTop: '',
    contentPaddingBottom: '',
    contentPaddingLeft: '',
    contentPaddingRight: '',

    hasToppingImage: false,
    toppingImage: {},
    toppingImageHeight: '96px',

    hasEyeBrow: false,
    eyeBrowType: EyeBrowType['pure-text'],
    eyeBrowMaxWidth: '693px',
    eyeBrowSVG: {},
    eyeBrowSVGHeight: '',
    eyeBrowText: 'Eyebrow',
    eyeBrowPureColor: 'rgba(0, 0, 0, 0.85)',
    eyeBrowGradientColor: '',
    eyeBrowFontSize: TextFontSize.default,
    eyeBrowFontWeight: '',
    eyeBrowLineHeight: TextLineHeight.default,

    hasHeadline: true,
    headlineType: HeadlineType['pure-text'],
    headlineSVG: {},
    headlineText: 'Headline',
    headlineMaxWidth: '693px',
    headlineSVGHeight: '32px',
    headlinePureColor: 'rgba(0, 0, 0, 0.85)',
    headlineGradientColor: '',
    headlineFontSize: TextFontSize.default,
    headlineFontWeight: '',
    headlineLineHeight: TextLineHeight.default,

    hasSubHeadline: false,
    subHeadlineType: HeadlineType['pure-text'],
    subHeadlineColor: 'rgba(0, 0, 0, 0.85)',
    subHeadlineText: 'Subhead',
    subHeadlineGradientColor: '',
    subHeadlineMaxWidth: '693px',
    subHeadlineFontSize: TextFontSize.default,
    subHeadlineFontWeight: '',
    subHeadlineLineHeight: TextLineHeight.default,

    hasDescription: true,
    descriptionColor: 'rgba(0, 0, 0, 0.65)',
    descriptionText: 'Description',
    descriptionMaxWidth: '693px',
    descriptionFontSize: TextFontSize.default,
    descriptionFontWeight: '',
    descriptionLineHeight: TextLineHeight.default,

    hasDividedLine: false,
    dividedBlockHeight: '48px',
    dividedLineWidth: '48px',
    dividedLineHeight: '1px',
    dividedLineRadius: '0',
    dividedLineColor: '',
    dividedLineGradientColor: '',

    hasLinks: false,
    links: [],
    linkColor: LinkColor.normal,
    linkFontSize: TextFontSize.default,
    linkLineHeight: TextLineHeight.default,

    hasNote: false,
    noteMarginTop: '',
    noteText: 'note',
    noteFontSize: TextFontSize.default,
    noteFontWeight: '',
    noteLineHeight: TextLineHeight.default,
    noteColor: 'rgba(0, 0, 0, 0.45)',

    hasIconsFirstRow: false,
    iconsFirstHeight: '48px',
    iconsFirstMaxWidth: 'none',
    iconsLayoutFirstRow: IconsLayout['image-text'],
    iconsFirstRow: [{
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      pic: {},
      title: 'title1',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      desc: 'desc1',
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }, {
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      pic: {},
      title: 'title2',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      desc: 'desc2',
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }, {
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      pic: {},
      title: 'title3',
      desc: 'desc3',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }],

    hasIconsSecondRow: false,
    iconsSecondMaxWidth: 'none',
    iconsLayoutSecondRow: IconsLayout['image-text'],
    iconsSecondHeight: '48px',
    iconsSecondRow: [{
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      icon: 'https://www.hasselblad.com/assets/icons/globe-black.svg',
      title: 'title1',
      desc: 'desc1',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }, {
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      icon: 'https://www.hasselblad.com/assets/icons/globe-black.svg',
      title: 'title2',
      desc: 'desc2',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }, {
      hasIcon: true,
      hasTitle: true,
      hasDesc: true,
      hasNumber: false,
      hasUnit: false,
      number: '99',
      unit: 'm/s',
      titleColor: 'rgba(0, 0, 0, 0.85)',
      descColor: 'rgba(0, 0, 0, 0.65)',
      icon: 'https://www.hasselblad.com/assets/icons/globe-black.svg',
      title: 'title3',
      desc: 'desc3',
      titleFontSize: TextFontSize.default,
      titleFontWeight: '',
      titleLineHeight: TextLineHeight.default,
      descFontSize: TextFontSize.default,
      descFontWeight: '',
      descLineHeight: TextLineHeight.default,
    }],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      hovering: false,
    };
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  componentDidMount(): void {
    
  }

  handleCardClick(e: any): void {
    const { cardLink, jumpType } = this.props;
    if (!e || !e.target) {
      return;
    }
    let target = e.target as any;
    if (target.nodeName) {
      if (target.nodeName === 'A'
        || target.nodeName === 'BUTTON'
        || target.className.includes('media-modal-container')
        || target.getAttribute('data-link')) {
        return;
      }
    }
    while (target.offsetParent) {
      target = target.offsetParent;
      if (target.nodeName === 'A'
        || target.nodeName === 'BUTTON'
        || target.className.includes('media-modal-container')
        || target.getAttribute('data-link')) {
        return;
      }
    }
    if (typeof window !== 'undefined') {
      const targetLink = LinkFix(cardLink);
      if (jumpType === JumpType.external) {
        window.open(targetLink);
      } else {
        window.location.href = targetLink;
      }
    }
  }

  renderCardInnerContent(): JSX.Element {
    const { props } = this;
    const verticalAlignStyleName = `cardv-vertical-${props.cardVerticalAlign}`;
    const horizontalAlignStyleName = `cardv-horizontal-${props.cardHorizonalAlign}`;
    const contentSizeStyleName = `cardv-size-${props.cardContentSize}`;
    const { visibleByDefault } = props;
    const aosFade = props.getFadeTransiation();
    const mediaFade = props.getMediaTransiation();

    const componentWrapperStyle: {
      minHeight: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
    } = {
      minHeight: props.cardMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
    };

    const textContentStyle: {
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
    } = {
      paddingTop: props.contentPaddingTop,
      paddingBottom: props.contentPaddingBottom,
      paddingLeft: props.contentPaddingLeft,
      paddingRight: props.contentPaddingRight,
    };

    const renderDivider = (): JSX.Element => {
      const linerStyle: {
        height?: string;
        borderRadius?: string;
        width?: string;
        backgroundColor?: string;
        backgroundImage?: string;
      } = {
        height: props.dividedLineHeight,
        borderRadius: props.dividedLineRadius,
        width: props.dividedLineWidth,
        backgroundColor: props.dividedLineColor,
        backgroundImage: props.dividedLineGradientColor,
      };

      return (
        <div data-aos={aosFade} data-aos-once={true} className="cardv-divider-container" style={{ height: props.dividedBlockHeight }}>
          <div className="cardv-divider" style={linerStyle} />
        </div>
      );
    };

      const renderLink = (gaTextPrefix: string, linkType?: string, linkText?: string, link?: string, linkGaLabel?: string,): JSX.Element => {
      let gaText = (linkGaLabel ? `${linkGaLabel}-` : gaTextPrefix);

      const linkStyle: {
        fontSize?: string;
        lineHeight?: string;
        color?: string;
      } = {
        fontSize: props.linkFontSize || '16px',
        lineHeight: GetLineHeight(props.linkFontSize, props.linkLineHeight),
        color: props.linkColor,
      };

      return (
        <div className="cardv-i-link" key={linkText} data-aos={aosFade} data-aos-once={true} data-ga-label={gaText} />
      );
    };

    const renderLinks = (): JSX.Element => (
      <div className="cardv-links-container">
        {
          props.links.map((linkItem, idx) => {
            const gaTextPrefix = `card-${props.index}-link-${idx}-link-`;
            return renderLink(gaTextPrefix, linkItem.linkType, linkItem.linkText, linkItem.linkUrl, linkItem.linkGaLabel,);
          })
        }
      </div>
    );

    const renderIcons = (icons: Array<Icons>, layout: string, height: string, maxWidth: string): JSX.Element => (
      <div className="cardv-icon-row-container">
        {
          icons.map((iconItem, iconIndex) => {
            const number = iconItem.hasNumber || iconItem.hasUnit
              ? (
                <div className="cardv-icon-number-container">
                  {
                iconItem.hasNumber ? <span key="number" className="cardv-icon-number" style={{ color: iconItem.titleColor }} dangerouslySetInnerHTML={{ __html: iconItem.number || '' }} /> : null
              }
                  {
                iconItem.hasUnit ? <span key="unit" className="cardv-icon-unit" style={{ color: iconItem.titleColor }} dangerouslySetInnerHTML={{ __html: iconItem.unit || '' }} /> : null
              }
                </div>
              ) : null;

            const titleStyle: {
              color?: string;
              fontWeight?: any;
              fontSize?: string;
              lineHeight?: string;
              letterSpacing?: string;
              margin?: string;
            } = {
              color: iconItem.titleColor,
              fontWeight: iconItem.titleFontWeight && TextFontWeight[iconItem.titleFontWeight] ? TextFontWeight[iconItem.titleFontWeight] : undefined,
              fontSize: iconItem.titleFontSize,
              lineHeight: GetLineHeight(iconItem.titleFontSize, iconItem.titleLineHeight),
              letterSpacing: iconItem.titleLetterSpacing,
              ...iconItem.titleMargin ? { margin: iconItem.titleMargin } : {},
            };

            const descStyle: {
              color?: string;
              fontWeight?: any;
              fontSize?: string;
              lineHeight?: string;
              letterSpacing?: string;
              margin?: string;
            } = {
              color: iconItem.descColor,
              fontWeight: iconItem.descFontWeight && TextFontWeight[iconItem.descFontWeight] ? TextFontWeight[iconItem.descFontWeight] : undefined,
              fontSize: iconItem.descFontSize,
              lineHeight: GetLineHeight(iconItem.descFontSize, iconItem.descLineHeight),
              letterSpacing: iconItem.descLetterSpacing,
              ...iconItem.descMargin ? { margin: iconItem.descMargin } : {},
            };

            const iconAlt = iconItem.iconAlt || (iconItem.hasTitle && iconItem.title) || (iconItem.hasDesc && iconItem.desc) || '';
            const pic = iconItem.hasPic && getAsset(iconItem.pic) ? <LazyWrapper alt={filterHtmlTag(iconAlt)} className="cardv-icon-pic" visibleByDefault={visibleByDefault} key="icon" type="img" src={getAsset(iconItem.pic)} style={{ height }} /> : null;
            const title = iconItem.hasTitle ? <p key="title" className="cardv-icon-title" style={titleStyle} dangerouslySetInnerHTML={{ __html: iconItem.title || '' }} /> : null;
            const desc = iconItem.hasDesc ? <p key="desc" className="cardv-icon-desc" style={descStyle} dangerouslySetInnerHTML={{ __html: iconItem.desc || '' }} /> : null;
            const getItem = (): Array<JSX.Element | null> => {
              switch (layout) {
                case IconsLayout['image-text']:
                  return [number, pic, title, desc];
                case IconsLayout['text-image']:
                  return [title, desc, number, pic];
                case IconsLayout.horizontal:
                  return [
                    <div className="cardv-left cardv-part" key="left">
                      { number }
                      { pic }
                    </div>,
                    <div className="cardv-right cardv-part" key="right">
                      { title }
                      { desc }
                    </div>,
                  ];
                default:
                  return [number, pic, title, desc];
              }
            };
            return (
              <div data-aos={aosFade} data-aos-once key={iconIndex} className="cardv-icon-col-container">
                <div
                  style={{ maxWidth: layout === IconsLayout.horizontal ? maxWidth : 'none' }}
                  className={`cardv-icon-item cardv-${layout}`}
                >
                  {
                    getItem()
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    );

    const renderButtons = (): JSX.Element => props.links ? (
      <div className="cardv-buttons-container">
        <ButtonRow content={{ buttons: props.links }} />
      </div>
    ) : null;

    const renderSubHeadline = (): JSX.Element => {
      const subheadStyle: {
        color?: string;
        maxWidth?: string;
        fontWeight?: any;
        fontSize?: string;
        lineHeight?: string;
        backgroundImage?: string;
        WebkitBackgroundClip?: string;
        paddingBottom?: string;
        marginBottom?: string;
        opacity?: number;
        letterSpacing?: string;
        margin?: string;
      } = {
        color: props.subHeadlineColor,
        maxWidth: props.subHeadlineMaxWidth,
        fontWeight: props.subHeadlineFontWeight && TextFontWeight[props.subHeadlineFontWeight] ? TextFontWeight[props.subHeadlineFontWeight] : undefined,
        fontSize: props.subHeadlineFontSize,
        lineHeight: GetLineHeight(props.subHeadlineFontSize, props.subHeadlineLineHeight),
        letterSpacing: props.subHeadlineLetterSpacing,
        ...props.subHeadlineMargin ? { margin: props.subHeadlineMargin } : {},
      };

      if (props.subHeadlineType === HeadlineType['gradient-text']) {
        subheadStyle.backgroundImage = props.subHeadlineGradientColor;
        subheadStyle.WebkitBackgroundClip = 'text';
        subheadStyle.color = 'transparent';
        subheadStyle.paddingBottom = '0.1em';
        subheadStyle.marginBottom = '-0.1em';
      } else {
        subheadStyle.color = props.subHeadlineColor;
      }

      return <TagText tagType={props.subHeadlineTag || 'h3'} data-aos={aosFade} data-aos-once={true} className="cardv-subhead" style={subheadStyle} dangerouslySetInnerHTML={{ __html: props.subHeadlineText || '' }} />;
    };

    const renderEyebrow = (): JSX.Element | JSX.Element[] => {
      const eyeBrowStyleName = `cardv-eyebrow ${props.hasToppingImage ? 'cardv-connect-pic' : ''}`;
      if (props.eyeBrowType === EyeBrowType.svg) {
        let placeholderText = <h1 className="cardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
        if (props.eyeBrowTag) {
          placeholderText = <TagText tagType={props.eyeBrowTag} className="cardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
        }

        return [(
          <img
            data-aos={aosFade}
            data-aos-once={true}
            alt={filterHtmlTag(props.eyeBrowText) || ''}
            src={getAsset(props.eyeBrowSVG)}
            className={`cardv-eyebrow-pic ${eyeBrowStyleName}`}
            style={{ height: props.eyeBrowSVGHeight }}
          />
        ), placeholderText];
      }
      const eyeBrowStyle: {
        background?: string;
        backgroundClip?: string;
        color?: string;
        backgroundImage?: string;
        WebkitBackgroundClip?: string;
        maxWidth?: string;
        fontWeight?: any;
        fontSize?: string;
        lineHeight?: string;
        opacity?: number;
        paddingBottom?: string;
        marginBottom?: string;
        letterSpacing?: string;
        margin?: string;
      } = {
        fontWeight: props.eyeBrowFontWeight && TextFontWeight[props.eyeBrowFontWeight] ? TextFontWeight[props.eyeBrowFontWeight] : undefined,
        fontSize: props.eyeBrowFontSize,
        lineHeight: GetLineHeight(props.eyeBrowFontSize, props.eyeBrowLineHeight),
        letterSpacing: props.eyeBrowLetterSpacing,
        ...props.eyeBrowMargin ? { margin: props.eyeBrowMargin } : {},
      };

      if (props.eyeBrowType === EyeBrowType['pure-text']) {
        eyeBrowStyle.color = props.eyeBrowPureColor;
      } else {
        eyeBrowStyle.color = 'transparent';
        eyeBrowStyle.backgroundImage = props.eyeBrowGradientColor;
        eyeBrowStyle.WebkitBackgroundClip = 'text';
        eyeBrowStyle.paddingBottom = '0.1em';
        eyeBrowStyle.marginBottom = '-0.1em';
      }

      eyeBrowStyle.maxWidth = props.eyeBrowMaxWidth;
      return <TagText tagType={props.eyeBrowTag || 'h1'} data-aos={aosFade} data-aos-once={true} className={`cardv-eyebrow-text ${eyeBrowStyleName}`} style={eyeBrowStyle} dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
    };

    const renderHeadline = (): JSX.Element | JSX.Element[] => {
      const connectToppingImage = props.hasToppingImage && !props.hasEyeBrow;
      const connectEyebrowImage = props.hasEyeBrow && props.eyeBrowType === EyeBrowType.svg;
      const headlineStyleName = `cardv-headline ${connectToppingImage || connectEyebrowImage ? 'cardv-connect-pic' : ''}`;
      if (props.headlineType === HeadlineType.svg) {
        let replaceholderText = <h2 className="cardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
        if (props.headlineTag) {
          replaceholderText = <TagText tagType={props.headlineTag} className="cardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
        }

        return [(
          <img
            data-aos={aosFade}
            data-aos-once={true}
            src={getAsset(props.headlineSVG)}
            className={`${headlineStyleName} cardv-headline-pic`}
            style={{ height: props.headlineSVGHeight }}
            alt={filterHtmlTag(props.headlineText) || ''}
          />), replaceholderText];
      }
      const headlineStyle: {
        background?: string;
        backgroundClip?: string;
        color?: string;
        backgroundImage?: string;
        WebkitBackgroundClip?: string;
        maxWidth?: string;
        fontWeight?: any;
        fontSize?: string;
        lineHeight?: string;
        opacity?: number;
        paddingBottom?: string;
        marginBottom?: string;
        letterSpacing?: string;
        margin?: string;
      } = {
        fontWeight: props.headlineFontWeight && TextFontWeight[props.headlineFontWeight] ? TextFontWeight[props.headlineFontWeight] : undefined,
        fontSize: props.headlineFontSize,
        lineHeight: GetLineHeight(props.headlineFontSize, props.headlineLineHeight),
        letterSpacing: props.headlineLetterSpacing,
        ...props.headlineMargin ? { margin: props.headlineMargin } : {},
      };

      if (props.headlineType === HeadlineType['pure-text']) {
        headlineStyle.color = props.headlinePureColor;
      } else {
        headlineStyle.backgroundImage = props.headlineGradientColor;
        headlineStyle.WebkitBackgroundClip = 'text';
        headlineStyle.color = 'transparent';
        headlineStyle.paddingBottom = '0.1em';
        headlineStyle.marginBottom = '-0.1em';
      }

      headlineStyle.maxWidth = props.headlineMaxWidth;
      return <TagText tagType={props.headlineTag || 'h2'} data-aos={aosFade} data-aos-once={true} className={`${headlineStyleName} cardv-headline-text`} style={headlineStyle} dangerouslySetInnerHTML={{ __html: props.headlineText || '' }} />;
    };

    const renderImageContent = (): JSX.Element => {
      const wholeParallax = props.foregroundHasParallax && props.foregroundParallax === ForegroundParallax['whole-item'];
      const innerParallax = props.foregroundHasParallax && props.foregroundParallax === ForegroundParallax['inner-content'];
      const image = (
        <LazyWrapper
          parallax={innerParallax}
          visibleByDefault={visibleByDefault}
          type={innerParallax ? 'bg' : 'img'}
          src={getAsset(props.foreImage)}
          className={`cardv-fore-image${innerParallax ? ' cardv-parallax-fore-pic' : ''}`}
          alt={filterHtmlTag(props.foreImageAlt)}
        />
      );
      let video = (
        <div className={`cardv-video-content${innerParallax ? ' cardv-parallax-fore-video' : ''}`}>
          {/* 如果前景视频配置为可控制播放，添加视频背景图在视频底部，防止视频第一次播放闪屏 */}
          {props.foreVideoPlay === ForeVideoPlay.withController && getAsset(props.foreVideoPoster) ? (
            <div
              style={{
                backgroundImage: `url(${getAsset(props.foreVideoPoster)})`,
              }}
              className="cardv-fore-controller-video-poster-bg"
            />
          ) : null}

          <video
            id="fore-video-bin"
            muted
            data-layzr-poster={getAsset(props.foreVideoPoster)}
            loop={props.foreVideoPlay === ForeVideoPlay.repeat}
          >
            {getAsset(props.foreVideoWebm) && (
              <source type="video/webm" data-layzr-src={getAsset(props.foreVideoWebm)} />
            )}
            {getAsset(props.foreVideo) && (
              <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.foreVideo)} />
            )}
          </video>
          {
            innerParallax ? null : [
              <div data-link="ture" className={`replay-button cardv-video-buttom cardv-replay-buttom ${props.foreVideoColor === 'dark' ? 'cardv-dark' : '' }`} />,
              <div data-link="ture" className={`pause-button cardv-video-buttom cardv-pause-buttom ${props.foreVideoColor === 'dark' ? 'cardv-dark' : '' }`} />,
              <div data-link="ture" className={`play-button cardv-video-buttom cardv-play-buttom ${props.foreVideoColor === 'dark' ? 'cardv-dark' : '' }`} />,
            ]
          }
        </div>
      );
      video = (
        <VideoPlayer
          style={{
            height: innerParallax ? '100%' : '',
            width: innerParallax ? '100%' : '',
          }}
          replayWhenRevisible={props.foreVideoPlay !== ForeVideoPlay.once && props.foreVideoPlay !== ForeVideoPlay.withController}
          visiblePlay={props.foreVideoPlay !== ForeVideoPlay.withController}
          lazyLoad
          lazyLoadThreshold={2000}
          replayClassName={props.foreVideoPlay === ForeVideoPlay.repeat ? '' : 'replay-button'}
          pauseClassName={props.foreVideoPlay === ForeVideoPlay.withController ? 'pause-button' : ''}
          playClassName={props.foreVideoPlay === ForeVideoPlay.withController ? 'play-button' : ''}
          videoLazyLoadDistance={props.videoLazyLoadDistance}
        >
          {
            innerParallax ? (
              <ParallaxPicture offset="-100" style={{ height: 'calc(100% + 100px)', marginTop: '-100px' }}>
                {video}
              </ParallaxPicture>
            ) : video
          }
          {
            innerParallax ? [
              <div data-link="ture" className="replay-button cardv-video-buttom cardv-replay-buttom" />,
              <div data-link="ture" className="pause-button cardv-video-buttom cardv-pause-buttom" />,
              <div data-link="ture" className="play-button cardv-video-buttom cardv-play-buttom" />,
            ] : null
          }
        </VideoPlayer>
      );
      let mediaHeight =  {};
      mediaHeight = props.foregroundHeight === '100%' ? { 'align-self': 'stretch' } : { height: props.foregroundHeight };
      mediaHeight = innerParallax ? mediaHeight : {};

      const foreContent = props.foreContentType === ForeContentType.image ? image : video;

      return (
        <div
          className="cardv-image-content"
          data-aos={mediaFade || aosFade}
          data-aos-once={true}
          key="image-content"
          style={{
            ...mediaHeight,
            marginBottom: props.foreContentPosition === ForeContentPosition['image-text'] ? props.foreContentGap : '',
            marginTop: props.foreContentPosition === ForeContentPosition['text-image'] ? props.foreContentGap : '',
            borderRadius: props.foreContentRadius,
            overflow: wholeParallax ? 'visible' : 'hidden',
          }}
        >
          { wholeParallax ? (
            <ParallaxPicture offset="-50">
              { foreContent }
            </ParallaxPicture>
          ) : foreContent}
        </div>
      );
    };

    const eyeBrow = props.hasEyeBrow ? renderEyebrow() : null;

    const headline = props.hasHeadline ? renderHeadline() : null;

    const subHeadline = props.hasSubHeadline ? renderSubHeadline() : null;

    const divider = props.hasDividedLine ? renderDivider() : null;

    const buttons = props.hasCardLinks ? renderButtons() : null;

    const noteStyle: {
      color?: string;
      fontWeight?: any;
      fontSize?: string;
      lineHeight?: string;
      marginTop?: string;
      letterSpacing?: string;
      margin?: string;
    } = {
      fontWeight: props.noteFontWeight && TextFontWeight[props.noteFontWeight] ? TextFontWeight[props.noteFontWeight] : undefined,
      fontSize: props.noteFontSize,
      lineHeight: GetLineHeight(props.noteFontSize, props.noteLineHeight),
      color: props.noteColor,
      marginTop: props.noteMarginTop || undefined,
      letterSpacing: props.noteLetterSpacing,
      ...props.noteMargin ? { margin: props.noteMargin } : {},
    };

    const note = props.hasNote ? (
      <p
        data-aos={aosFade}
        data-aos-once={true}
        className="cardv-note"
        style={noteStyle}
        dangerouslySetInnerHTML={{ __html: props.noteText || '' }}
      />
    ) : null;

    const descriptionStyle: {
      color?: string;
      fontWeight?: any;
      fontSize?: string;
      lineHeight?: string;
      marginTop?: string;
      minHeight?: string;
      maxWidth?: string;
      letterSpacing?: string;
      margin?: string;
    } = {
      fontWeight: props.descriptionFontWeight && TextFontWeight[props.descriptionFontWeight] ? TextFontWeight[props.descriptionFontWeight] : undefined,
      fontSize: props.descriptionFontSize,
      lineHeight: GetLineHeight(props.descriptionFontSize, props.descriptionLineHeight),
      color: props.descriptionColor,
      minHeight: props.descriptionMinHeight,
      marginTop: props.hasDividedLine ? '0' : '',
      maxWidth: props.descriptionMaxWidth,
      letterSpacing: props.descriptionLetterSpacing,
      ...props.descriptionMargin ? { margin: props.descriptionMargin } : {},
    };

    const description = props.hasDescription ? (
      <p
        data-aos={aosFade}
        data-aos-once={true}
        className="cardv-description"
        style={descriptionStyle}
        dangerouslySetInnerHTML={{ __html: props.descriptionText || '' }}
      />
    ) : null;

    const toppingImage = props.hasToppingImage ? (
      <LazyWrapper
        visibleByDefault={visibleByDefault}
        data-aos={mediaFade}
        data-aos-once={true}
        src={getAsset(props.toppingImage)}
        alt={getAsset(props.toppingImage) ? filterHtmlTag(props.toppingAlt) : ''}
        className="cardv-topping-img"
        style={{ height: props.toppingImageHeight }}
        type="img"
      />
    ) : null;


    const imageContent = props.hasForeContent ? renderImageContent() : null;

    const icons = props.hasIconsFirstRow || props.hasIconsSecondRow ? (
      <div className="cardv-icons-container">
        {props.hasIconsFirstRow ? renderIcons(props.iconsFirstRow, props.iconsLayoutFirstRow, props.iconsFirstHeight, props.iconsFirstMaxWidth) : null}
        {props.hasIconsSecondRow ? renderIcons(props.iconsSecondRow, props.iconsLayoutSecondRow, props.iconsSecondHeight, props.iconsSecondMaxWidth) : null}
      </div>
    ) : null;

    const textContent = (
      <div
        key="text-content"
        className="cardv-text-content-container"
        style={textContentStyle}
      >
        {toppingImage}
        {eyeBrow}
        {headline}
        {subHeadline}
        {divider}
        {description}
        {buttons}
        {note}
        {icons}
      </div>
    );
    return (
      <div
        role="button"
        tabIndex={0}
        className={`cardv-card-wrapper ${verticalAlignStyleName} ${horizontalAlignStyleName}`}
        style={{ ...componentWrapperStyle, cursor: props.hasCardLink ? 'pointer' : '', outline: 'none' }}
        onClick={props.hasCardLink ? this.handleCardClick : () => {}}
        onKeyDown={props.hasCardLink ? this.handleCardClick : () => {}}
        data-ga-label={props.hasCardLink ? (props.cardLinkGaLabel ? props.cardLinkGaLabel : `card-${props.index}-link`) : ''}
      >
        <div className={`cardv-card-container ${contentSizeStyleName}`}>
          {
            props.foreContentPosition === ForeContentPosition['image-text']
              ? [imageContent, textContent] : [textContent, imageContent]
          }
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    const { props } = this;
    const { hovering } = this.state;
    let backgroundImage;
    const { visibleByDefault } = props;

    // 卡片模块样式
    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
      borderRadius?: string;
      overflow?: any;
      height?: string;
    } = {
      backgroundColor: props.pureBackgroundColor,
      borderRadius: props.cardRadius,
      overflow: props.cardRadius !== '' ? 'hidden' : '',
    };

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = getAsset(props.backgroundImage);
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;

      if (props.backgroundGradient) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    if (props.getCardsStretch()) {
      componentStyle.height = '100%';
    }
    return (
      <LazyWrapper
        type="bg"
        visibleByDefault={visibleByDefault}
        src={backgroundImage}
        bgWidth={props.backgroundImageWidth}
        bgHeight={props.backgroundImageHeight}
        parallax={props.hasBackgroundImage && props.backgroundParallax}
        className="atom-design-v1 cardv-hero-vertical-card"
        threshold={1000}
        style={componentStyle}
        onMouseEnter={() => {
          this.setState({
            hovering: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            hovering: false,
          });
        }}
      >
        {props.hasBackgroundVideo ? (
          <div className="background-video-wrapper cardv-i-background-video">
            <VideoPlayer
              visiblePlay={props.backgroundVideoVisiblePlay}
              replayWhenRevisible={props.backgroundVideoVisiblePlay}
              triggerPlay={props.backgroundVideoHoverPlay && hovering}
              triggerPause={props.backgroundVideoHoverPlay && !hovering}
              videoLazyLoadDistance={props.videoLazyLoadDistance}
              lazyLoad
              lazyLoadThreshold={2000}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <video muted data-layzr-poster={getAsset(props.backgroundVideoPoster)} loop={props.backgroundVideoLoop} autoPlay={!props.backgroundVideoVisiblePlay && !props.backgroundVideoHoverPlay}>
                {getAsset(props.backgroundVideo) && (
                  <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.backgroundVideo)} />
                )}
              </video>
            </VideoPlayer>

            <div className="cardv-i-video-mask" style={{ backgroundColor: props.backgroundVideoMaskColor, opacity: props.backgroundVideoMaskOpacity }} />

          </div>
        ) : null}
        {this.renderCardInnerContent()}
      </LazyWrapper>
    );
  }
}
