/* eslint-disable complexity */
import classNames from 'classnames';
import React from 'react';
import LazyLoad from 'react-lazyload';
import ParallaxPicture from '../../../ParallaxPicture';
import Image from '../../../../../components/blocks/image';
import VideoPlayer from "../../../video";
import LazyWrapper from '../../../utils/LazyWrapper';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import ButtonRow from '../../../../blocks/button_row';
import {
  PropsWithAos,
  CardLayout,
  CardMedia,
  ForegroundParallax,
  VideoPlayType,
  ForegroundLayout,
} from './type';
import './style.scss';

const CardMediaComp = React.forwardRef(
  (props: PropsWithAos, ref: React.ForwardedRef<any>) => {
    const {
      aosFade,
      visibleByDefault,
      mediaFade,
      videoAllDowngrade,
      foregroundList,
      canZoomImage = false,
      showZoomIconInMedia = false,
      zoomImageConfig = [],
    } = props;

    const hasZoomImageConfig = !!zoomImageConfig.length && !!zoomImageConfig[0].zoom_image_id;
    const {
      cardForegroundVideo,
      cardForegroundVideoReplay,
      cardForegroundVideoPause,
      cardForegroundVideoPlay,
    } = ref;

    const parallaxable = props.foregroundLayout === ForegroundLayout.one;
    // 整体视差
    const wholeParallax
      = parallaxable
      && props.foregroundHasParallax
      && props.foregroundParallax === ForegroundParallax['whole-item'];
    // 内部视差
    const innerParallax
      = parallaxable
      && props.foregroundHasParallax
      && props.foregroundParallax === ForegroundParallax['inner-content'];

    const foreItem = (item) => {
      if (!item) {
        return null;
      }

      const videoPosterAttr = {
        [visibleByDefault ? 'poster' : 'data-layzr-poster']:
          getAsset(item.foregroundVideoPoster),
      };

      const videoStyle = {
        borderRadius: item.mediaBorderRadius,
        overflow: 'hidden',
      };

      const videoWrapper = (
        <div
          className={`mcardv-media-video${innerParallax ? ' mcardv-parallax-fore-video' : ''}`}
        >
          <video
            style={videoStyle}
            ref={cardForegroundVideo}
            muted
            {...videoPosterAttr}
            loop={item.foregroundVideoPlayType === VideoPlayType.loop}
          >
            {getAsset(item.foregroundVideoWebm) && (
              <source
                type="video/webm"
                data-layzr-src={getAsset(item.foregroundVideoWebm)}
              />
            )}
            {getAsset(item.foregroundVideo) && (
              <source
              type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'
              data-layzr-src={getAsset(item.foregroundVideo)}
            />
            )}
          </video>
          <div
            data-link="ture"
            className={`replay-button mcardv-video-buttom mcardv-replay-buttom ${item.foreVideoColor === 'dark' ? 'mcardv-dark' : ''}`}
            ref={cardForegroundVideoReplay}
          />
          <div
            data-link="ture"
            className={`pause-button mcardv-video-buttom mcardv-pause-buttom ${item.foreVideoColor === 'dark' ? 'mcardv-dark' : ''
            }`}
            ref={cardForegroundVideoPause}
          />
          <div
            data-link="ture"
            className={`play-button mcardv-video-buttom mcardv-play-buttom ${item.foreVideoColor === 'dark' ? 'mcardv-dark' : ''
            }`}
            ref={cardForegroundVideoPlay}
          />
        </div>
      );
      const downgradeDom = (
        <div className="mcardv-downgrade-img">
          <LazyWrapper
            type="img"
            visibleByDefault={visibleByDefault}
            className="mcardv-downgrade-pic"
            style={videoStyle}
            src={getAsset(item.foregroundVideoHoldPoster) || getAsset(item.foregroundVideoPoster)}
            alt=""
          />
          <ButtonRow content={{ buttons: item.downgradeVideoPopupBtn }} />
        </div>
      );
      const video = videoAllDowngrade ? (
        downgradeDom
      ) : (
        <VideoPlayer
          style={{
            height: !parallaxable || innerParallax ? '100%' : '',
            width: !parallaxable || innerParallax ? '100%' : '',
          }}
          replayWhenRevisible={
            item.foregroundVideoPlayType !== VideoPlayType.once
            && item.foregroundVideoPlayType !== VideoPlayType.withController
          }
          visiblePlay={
            item.foregroundVideoPlayType !== VideoPlayType.withController
          }
          lazyLoad
          lazyLoadThreshold={1200}
          replayClassName={
            item.foregroundVideoPlayType === VideoPlayType.loop
              ? ''
              : 'replay-button'
          }
          pauseClassName={
            item.foregroundVideoPlayType === VideoPlayType.withController
              ? 'pause-button'
              : ''
          }
          playClassName={
            item.foregroundVideoPlayType === VideoPlayType.withController
              ? 'play-button'
              : ''
          }
          downgradeDom={downgradeDom}
          videoDowngradeInObsoleteDevice={props.videoDowngradeInObsoleteDevice}
          videoDowngradeInMini={props.videoDowngradeInMini}
          videoPerformanceMode={props.videoPerformanceMode}
        >
          {innerParallax ? (
            <ParallaxPicture
              offset="-100"
              style={{ height: 'calc(100% + 100px)', marginTop: '-100px' }}
            >
              {videoWrapper}
            </ParallaxPicture>
          ) : (
            videoWrapper
          )}
        </VideoPlayer>
      );
      let picture = hasZoomImageConfig && canZoomImage ? 
        <LazyLoad offset={1000} once>
          <div style={{ borderRadius: item.mediaBorderRadius, overflow: 'hidden', width: item.foregroundImageWidth }}>
            <Image blok={zoomImageConfig[0]} uid={props._uid} modalModeOn/>
          </div>
        </LazyLoad>
      : (
        <LazyWrapper
          visibleByDefault={visibleByDefault}
          parallax={innerParallax}
          type={innerParallax ? 'bg' : 'img'}
          src={getAsset(item.foregroundImage)}
          className={`mcardv-media-pic${innerParallax ? ' mcardv-parallax-fore-pic' : ''}`}
          alt={filterHtmlTag(item.foregroundImageAlt)}
          style={{ borderRadius: item.mediaBorderRadius, overflow: 'hidden', width: item.foregroundImageWidth }}
        />
      );

      return item.media === CardMedia.picture ? picture : video;
    };

    const foreContentMap = {
      [ForegroundLayout.one]: foreItem(props),
      [ForegroundLayout.leftRight]: (
        <div className="mcardv-card-media-multi mcardv-card-media-multi-leftRight">
          {
            foregroundList.map((item, index) => {
              const gapStyle = (props.foregroundListGap && index !== (foregroundList.length - 1))
                ? { marginRight: props.foregroundListGap } : {};
              return (<div key={index} className="mcardv-card-media-part mcardv-card-media-item" style={gapStyle}>{foreItem(item)}</div>);
            })
          }
        </div>
      ),
      [ForegroundLayout.upDown]: (
        <div className="mcardv-card-media-multi mcardv-card-media-multi-updown">
          {foregroundList.map((item, index) => {
            const gapStyle = (props.foregroundListGap && index !== (foregroundList.length - 1))
              ? { marginBottom: props.foregroundListGap } : {};
            return (
              <div key={index} className="mcardv-card-media-part mcardv-card-media-item" style={gapStyle}>
                {foreItem(item)}
              </div>
            );
          })}
        </div>
      ),
      [ForegroundLayout.left2right1]: (
        <div className="mcardv-card-media-multi mcardv-card-media-multi-left2right1">
          <div className="mcardv-card-media-part" style={{ marginRight: props.foregroundListGap }}>
            <div className="mcardv-card-media-item" style={{ marginBottom: props.foregroundListGap }}>{foreItem(foregroundList[0])}</div>
            <div className="mcardv-card-media-item">{foreItem(foregroundList[1])}</div>
          </div>
          <div className="mcardv-card-media-part">
            <div className="mcardv-card-media-item">{foreItem(foregroundList[2])}</div>
          </div>
        </div>
      ),
      [ForegroundLayout.up1down2]: (
        <div className="mcardv-card-media-multi mcardv-card-media-multi-up1down2">
          <div className="mcardv-card-media-part" style={{ marginBottom: props.foregroundListGap }}>
            <div className="mcardv-card-media-item">{foreItem(foregroundList[0])}</div>
          </div>
          <div className="mcardv-card-media-part">
            <div className="mcardv-card-media-item" style={{ marginRight: props.foregroundListGap }}>{foreItem(foregroundList[1])}</div>
            <div className="mcardv-card-media-item">{foreItem(foregroundList[2])}</div>
          </div>
        </div>
      ),
      [ForegroundLayout.up2down1]: (
        <div
          className="mcardv-card-media-multi mcardv-card-media-multi-up2down1"
          style={{ flexDirection: 'column' }}
        >
          <div className="mcardv-card-media-part" style={{ marginBottom: props.foregroundListGap }}>
            <div className="mcardv-card-media-item" style={{ marginRight: props.foregroundListGap }}>{foreItem(foregroundList[0])}</div>
            <div className="mcardv-card-media-item">{foreItem(foregroundList[1])}</div>
          </div>
          <div className="mcardv-card-media-part">
            <div className="mcardv-card-media-item">{foreItem(foregroundList[2])}</div>
          </div>
        </div>
      ),
    };

    const foreContent = foreContentMap[props.foregroundLayout];

    let mediaHeight = {};
    mediaHeight
      = props.foregroundHeight === '100%'
        ? { 'align-self': 'stretch' }
        : { height: props.foregroundHeight };
    mediaHeight = innerParallax ? mediaHeight : {};

    return (
      <div
        key="media"
        data-aos={mediaFade || aosFade}
        data-aos-once
        className={classNames('mcardv-card-media', !showZoomIconInMedia && 'without-zoom-icon')}
        style={{
          ...mediaHeight,
          borderRadius: props.mediaBorderRadius,
          marginBottom:
            props.cardLayout === CardLayout['media-text']
              ? props.mediaDistance
              : '',
          marginTop:
            props.cardLayout === CardLayout['text-media']
              ? props.mediaDistance
              : '',
          overflow: wholeParallax ? 'visible' : 'hidden',
        }}
      >
        {wholeParallax ? (
          <ParallaxPicture offset="-50">{foreContent}</ParallaxPicture>
        ) : (
          foreContent
        )}
      </div>
    );
  },
);

export default CardMediaComp;
