import React from "react";
import SbEditable from "storyblok-react";
import Components from "../components";
import ResizeObserver from 'resize-observer-polyfill';
import { hasDocument } from '../../utils/helper';
import _debounce from 'lodash.debounce';
import "./editorial.css"

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    }
    this.myObserver = null;
  }

  componentDidMount() {
    if (!hasDocument) return;
    const targetDom = document.body;
    const obj = targetDom.getBoundingClientRect();
    this.setState({
      isMobile: obj.width < 768
    })
    this.myObserver = new ResizeObserver(_debounce((entries) => {
      const ele = entries[0];
      if (!ele) return;
      this.setState({
        isMobile: ele.contentRect.width < 768
      })
    }, 200));
    this.myObserver.observe(targetDom);
  }


  componentWillUnmount() {
    this.myObserver.disconnect();
  }

  render() {
    const { blok, lang } = this.props
    const { isMobile } = this.state;
    let background = null;
    if (blok.background_color && blok.background_color.color !== '#f40000'){
      background = blok.background_color.color
    }
    if (background && blok.background_color_fade && blok.background_color_fade.color && blok.background_color_fade.color !== '#f40000'){
      background = `linear-gradient(${background}, ${blok.background_color_fade.color})`
    }
    const paddingValue = isMobile ? blok.padding_value_m : blok.padding_value_pc;
    const secStyle = {
      background: background,
      alignItems : blok.vertical_align || null,
      padding: paddingValue || null,
    }
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div className={`editorial ${blok.layout}`} style={secStyle}>
          { blok.blocks && blok.blocks.map((blok) => {
              return ( React.createElement(Components(blok.component), { key: blok._uid, blok: blok, lang: lang }))
          })} 
        </div>
      </SbEditable>
    )
  }
}
