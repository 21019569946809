import * as React from 'react';
import { checkInView } from '../../utils/checkElementInView';
import { isDowngrade, isObsoleteMobileDevice, checkIsIphoneMini } from '../../utils/helper';
export interface Props {
  reset?: boolean;
  replayWhenRevisible?: boolean;
  visiblePlay?: boolean;
  showWholeElmPlay?: boolean;
  lazyLoad?: boolean;
  lazyLoadThreshold?: number;
  replayClassName?: string;
  pauseClassName?: string;
  playClassName?: string;
  loadingClassName?: string;
  downgradeDom?: JSX.Element;
  style?: object;
  triggerPlay?: boolean; // 触发播放
  triggerPause?: boolean; // 触发暂停
  videoLazyLoadDistance?: boolean;
  videoDowngradeInObsoleteDevice?: boolean;
  videoDowngradeInMini?: boolean;
  isFirstScreen?: boolean;
  videoPerformanceMode?: boolean;
}

export interface State {
  downgrade?: boolean | number;
  isWeixin?: boolean;
  isMobile?: boolean;
  visibleBorderOffset: number;
  iPhoneVersion: boolean | number;
}

export default class VideoPlayer extends React.Component<Props, State> {
  static bindVideoEvent(video: any, func: Function): void {
    function onLoadMetadata(): void {
      func();
      video.removeEventListener('canplay', onLoadMetadata);
    }

    func(video);
  }

  videoContainerDom: any;

  video: any;

  videoInView: boolean;

  listenersToRemove: Array<Function>;

  autoPlayRetryTimes: number;

  playerTimeout;

  visiblePast = false;

  videoPlayed = false;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    reset: false, // 可选，播放完是否跳到第一帧
    replayWhenRevisible: false, // 可选，是否做到回屏再次自动播放
    showWholeElmPlay: false, // 可选，是否显示整个视频后自动播放
    visiblePlay: false, // 可选，是否显示视频后（不用完全显示整个视频）自动播放
    lazyLoad: false, // 可选，是否启用懒加载
    videoLazyLoadDistance: false, // 可选 是否开启超长懒加载范围，true：lazyLoadThreshold设为5000；false：lazyLoadThreshold设为1200
    lazyLoadThreshold: 1200, // 可选，懒加载视频的范围,
    replayClassName: '', // 可选，重播点击按钮类名，不填的话没有重播功能
    loadingClassName: '', // 可选，loading icon 类名，不填的话没有展示视频 loading 态功能
    downgradeDom: null,
    pauseClassName: '',
    playClassName: '',
    style: {},
    triggerPlay: {},
    triggerPause: {},
    videoDowngradeInObsoleteDevice: false,
    videoDowngradeInMini: false,
    isFirstScreen: false,
    videoPerformanceMode: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      downgrade: false,
      isWeixin: false,
      isMobile: false,
      visibleBorderOffset: 100,
      iPhoneVersion: false,
    };
    this.listenersToRemove = [];
    this.autoPlayRetryTimes = 3;
  }

  componentDidMount(): void {
    if (!window) {
      return;
    }

    const ua = window.navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
    const isWeixin = !!ua.match(/MicroMessenger/ig);
    const iPhoneVersion = !!ua.match(/iPhone/i) && ua.match(/OS (\d+)_(\d+)_?(\d+)?/i)?.[1];

    const {
      videoDowngradeInObsoleteDevice,
      videoDowngradeInMini,
    } = this.props;
    const downgrade = isDowngrade() || (videoDowngradeInObsoleteDevice && isObsoleteMobileDevice()) || (videoDowngradeInMini && checkIsIphoneMini());

    this.setState({
      downgrade,
      isWeixin,
      isMobile,
      visibleBorderOffset: isMobile ? 48 : 100,
      iPhoneVersion: iPhoneVersion ? +iPhoneVersion : false,
    }, this.init);
  }

  UNSAFE_componentWillUpdate(nextProps: Props): void {
    if (nextProps.triggerPlay && !this.props.triggerPlay) {
      this.triggerVideoPlay(this.video);
    }

    if (nextProps.triggerPause && !this.props.triggerPause) {
      if (this.video) {
        this.video.pause();
        if (!Number.isNaN(this.video.duration)) {
          this.video.currentTime = 0;
        }
      }
    }
  }

  componentWillUnmount(): void {
    this.listenersToRemove.forEach((item) => {
      if (typeof item === 'function') {
        item();
      }
    });
    if ((window as any).videoPlayerLoadCount) { /* eslint-disable-line no-undef */
      (window as any).videoPlayerLoadCount -= 1;/* eslint-disable-line no-undef */
    }
  }

  init(): void {
    const {
      lazyLoad,
      visiblePlay,
      showWholeElmPlay,
      reset,
      replayClassName,
      playClassName,
      pauseClassName,
      loadingClassName,
      isFirstScreen,
      replayWhenRevisible,
      videoPerformanceMode,
    } = this.props;
    const {
      downgrade, isWeixin, isMobile,
    } = this.state;
    const self = this;

    if (downgrade) {
      return;
    }

    this.video = this.videoContainerDom.querySelector('video');
    if (this.video) {
      this.video.style.opacity = 1;
    }

    function videoLoadFun(): void {
      if (window) {
        if (!(window as any).videoPlayerLoadCount) { /* eslint-disable-line no-undef */
          (window as any).videoPlayerLoadCount = 1; /* eslint-disable-line no-undef */
        } else {
          (window as any).videoPlayerLoadCount += 1; /* eslint-disable-line no-undef */
        }
      }
    }

    this.video.addEventListener('loadstart', videoLoadFun);
    this.listenersToRemove.push(() => {
      this.video.removeEventListener('loadstart', videoLoadFun);
    });

    if (isMobile) {
      this.videoWithControl();
    }

    if (lazyLoad) {
      this.initLazyLoad();
    }

    function wxbFun(): void {
      // 可视播放
      if (visiblePlay || showWholeElmPlay) {
        // 白名单: 对开启性能模式且不属于首屏的自动重播视频启用
        if (videoPerformanceMode && (!isFirstScreen && replayWhenRevisible)) {
          self.initStrictVisiblePlay();
        } else {
          self.initVisiblePlay();
        }
      }

      if (playClassName) {
        self.initPlay();
      }

      if (pauseClassName) {
        self.initPause();
      }

      // 重播
      if (replayClassName) {
        self.initReplay();
      }

      // 播放完后是否回到第一帧
      if (reset) {
        self.bindEndReset();
      }

      // 显示视频 loading 状态
      if (loadingClassName) {
        self.initLoadingState();
      }
    }

    if (isWeixin) {
      document.addEventListener('WeixinJSBridgeReady', wxbFun);
      this.listenersToRemove.push(() => {
        document.removeEventListener('WeixinJSBridgeReady', wxbFun);
      });
    } else {
      // 可视播放
      if (visiblePlay || showWholeElmPlay) {
        // 白名单: 对开启性能模式且不属于首屏的自动重播视频启用
        if (videoPerformanceMode && (!isFirstScreen && replayWhenRevisible)) {
          self.initStrictVisiblePlay();
        } else {
          self.initVisiblePlay();
        }
      }

      if (playClassName) {
        this.initPlay();
      }

      if (pauseClassName) {
        this.initPause();
      }

      // 重播
      if (replayClassName) {
        this.initReplay();
      }

      // 播放完后是否回到第一帧
      if (reset) {
        this.bindEndReset();
      }

      // 显示视频 loading 状态
      if (loadingClassName) {
        self.initLoadingState();
      }
    }
  }

  bindEndReset(): void {
    const self = this;
    function videoEndFun(): void {
      if (!Number.isNaN(self.video.duration)) {
        self.video.currentTime = 0;
      }
    }
    this.video.addEventListener('ended', videoEndFun);
    this.listenersToRemove.push(() => {
      this.video.removeEventListener('ended', videoEndFun);
    });
  }

  initVisiblePlay(): void {
    const {
      showWholeElmPlay, replayWhenRevisible, replayClassName,
    } = this.props;
    let visiblePast = false;
    let videoPlayed = false;
    const self = this;
    const needAutoReplay = replayWhenRevisible;

    const callback = ({ inView: visibleNow, observer }) => {
      // 离屏停止视频的播放
      if (!visibleNow && self.video && !self.video.paused) {
        self.video.pause();
      }

      if (needAutoReplay) { // 重新可视时自动播放
        if (!videoPlayed) {
          // hack code: 提前触发播放，防止闪屏
          // eslint-disable-next-line no-restricted-globals
          if (self.video && !isNaN(self.video.duration)) {
            VideoPlayer.bindVideoEvent(self.video, () => {
              self.triggerVideoPlay(self.video);
              self.video.pause();
            });
            videoPlayed = true;
          }
        }
        const isPlaying = !self.video.paused && !self.video.ended && self.video.readyState > 2;
        const loopVideoWithoutReplayButton = !replayClassName;
        const revisit = visibleNow && !visiblePast;
        const pauseWhenVisit = visibleNow && !isPlaying && loopVideoWithoutReplayButton;
        if (revisit || pauseWhenVisit) {
          if (!isPlaying) {
            VideoPlayer.bindVideoEvent(self.video, () => {
              if (!Number.isNaN(self.video.duration)) {
                self.video.currentTime = 0;
              }
              self.triggerVideoPlay(self.video);
              if (replayClassName) {
                const replayBtn = self.videoContainerDom.querySelector(`.${replayClassName}`);
                replayBtn.style.display = 'none';
              }
              videoPlayed = true;
            });
          }
        }
        visiblePast = visibleNow;
      } else {
        // 只自动播放一次
        if (videoPlayed || !visibleNow) {
          return;
        }
        VideoPlayer.bindVideoEvent(self.video, () => {
          if (!Number.isNaN(self.video.duration)) {
            self.video.currentTime = 0;
          }
          self.triggerVideoPlay(self.video);
          videoPlayed = true;
        });
        observer.disconnect();
      }
      // 保留 Observer
      return true;
    };

    if (showWholeElmPlay) {
      checkInView({
        elm: this.video,
        threshold: 1,
        callback,
      });
    } else {
      checkInView({
        elm: self.videoContainerDom,
        offset: this.state.visibleBorderOffset,
        callback,
      });
    }
  }

  initStrictVisiblePlay(): void {
    const {
      showWholeElmPlay, replayWhenRevisible, replayClassName,
    } = this.props;
    const self = this;
    const needAutoReplay = replayWhenRevisible;

    const callback = () => {
      // 离屏停止视频的播放
      if (!self.videoInView) {
        return;
      }

      if (needAutoReplay) { // 重新可视时自动播放
        if (!self.videoPlayed) {
          // hack code: 提前触发播放，防止闪屏
          // eslint-disable-next-line no-restricted-globals
          if (self.video && !isNaN(self.video.duration)) {
            VideoPlayer.bindVideoEvent(self.video, () => {
              self.triggerVideoPlay(self.video);
              self.video.pause();
              self.video.currentTime = 0;
            });
            self.videoPlayed = true;
          }
        }
        const isPlaying = !self.video.paused && !self.video.ended && self.video.readyState > 2;
        const loopVideoWithoutReplayButton = !replayClassName;
        const revisit = self.videoInView && !self.visiblePast;
        const pauseWhenVisit = self.videoInView && !isPlaying && loopVideoWithoutReplayButton;
        if (revisit || pauseWhenVisit) {
          if (!isPlaying) {
            VideoPlayer.bindVideoEvent(self.video, () => {
              if (!Number.isNaN(self.video.duration)) {
                self.video.currentTime = 0;
              }
              self.triggerVideoPlay(self.video);
              if (replayClassName) {
                const replayBtn = self.videoContainerDom.querySelector(`.${replayClassName}`);
                replayBtn.style.display = 'none';
              }
              self.videoPlayed = true;
            });
          }
        }
        self.visiblePast = self.videoInView;
      } else {
        // 只自动播放一次
        if (self.videoPlayed || !self.videoInView) {
          return;
        }
        VideoPlayer.bindVideoEvent(self.video, () => {
          if (!Number.isNaN(self.video.duration)) {
            self.video.currentTime = 0;
          }
          self.triggerVideoPlay(self.video);
          self.videoPlayed = true;
        });
      }
    };

    const inViewCallback = ({ inView: visibleNow }) => {
      if (visibleNow) {
        self.videoInView = true;
        clearTimeout(this.playerTimeout);
        this.playerTimeout = setTimeout(() => {
          callback();
        }, 800);
      } else {
        clearTimeout(this.playerTimeout);
        self.video.pause();
        self.video.currentTime = 0;
        self.videoInView = false;
      }
      return true;
    };

    if (showWholeElmPlay) {
      checkInView({
        elm: this.video,
        threshold: 1,
        callback: inViewCallback,
      });
    } else {
      checkInView({
        elm: self.videoContainerDom,
        offset: this.state.visibleBorderOffset,
        callback: inViewCallback,
      });
    }
  }

  initPlay(): void {
    const self = this;
    const { playClassName, pauseClassName } = this.props;
    const videoContainer = self.videoContainerDom;
    const playBtn = videoContainer.querySelector(`.${playClassName}`);
    let pauseBtn;
    if (pauseClassName) {
      pauseBtn = videoContainer.querySelector(`.${pauseClassName}`);
    }
    playBtn.style.display = 'block';
    function btnFun(): void {
      VideoPlayer.bindVideoEvent(self.video, () => {
        self.triggerVideoPlay(self.video);
      });
      playBtn.style.display = 'none';
      if (pauseClassName && pauseBtn) {
        pauseBtn.style.display = 'block';
      }
    }
    playBtn.addEventListener('click', btnFun);
    this.listenersToRemove.push(() => {
      playBtn.removeEventListener('click', btnFun);
    });
  }

  initPause(): void {
    const self = this;
    const { playClassName, pauseClassName } = this.props;
    const videoContainer = self.videoContainerDom;
    const playBtn = videoContainer.querySelector(`.${playClassName}`);
    const pauseBtn = videoContainer.querySelector(`.${pauseClassName}`);
    function btnFun(): void {
      self.video.pause();
      playBtn.style.display = 'block';
      pauseBtn.style.display = 'none';
    }
    pauseBtn.addEventListener('click', btnFun);
    this.listenersToRemove.push(() => {
      pauseBtn.removeEventListener('click', btnFun);
    });
  }

  initReplay(): void {
    const self = this;
    const {
      replayClassName, playClassName, pauseClassName,
    } = this.props;
    const videoContainer = self.videoContainerDom;
    const replayBtn = videoContainer.querySelector(`.${replayClassName}`);
    const playBtn = playClassName && videoContainer.querySelector(`.${playClassName}`);
    const pauseBtn = pauseClassName && videoContainer.querySelector(`.${pauseClassName}`);
    function endFun(): void {
      replayBtn.style.display = 'block';
      if (playBtn && pauseBtn) {
        playBtn.style.display = 'none';
        pauseBtn.style.display = 'none';
      }
      self.video.pause();
    }
    function replyFun(): void {
      VideoPlayer.bindVideoEvent(self.video, () => {
        if (!Number.isNaN(self.video.duration)) {
          self.video.currentTime = 0;
        }
        self.triggerVideoPlay(self.video);
        if (pauseBtn) {
          pauseBtn.style.display = 'block';
        }
      });
      replayBtn.style.display = 'none';
    }
    self.video.addEventListener('ended', endFun);
    if (replayBtn) {
      replayBtn.addEventListener('click', replyFun);
    }
    this.listenersToRemove.push(() => {
      self.video.removeEventListener('ended', endFun);
      replayBtn.removeEventListener('click', replyFun);
    });
  }

  initLoadingState(): void {
    const self = this;
    const {
      loadingClassName, playClassName, pauseClassName, replayClassName,
    } = this.props;
    const videoContainer = self.videoContainerDom;
    const loadingBtn = videoContainer.querySelector(`.${loadingClassName}`);
    const playBtn = videoContainer.querySelector(`.${playClassName}`);
    const pauseBtn = videoContainer.querySelector(`.${pauseClassName}`);
    const replayBtn = replayClassName ? videoContainer.querySelector(`.${replayClassName}`) : null;

    const show = (ele, isShow) => {
      if (!ele) { return; }
      if (isShow) {
        ele.style.opacity = '';
        ele.style.pointerEvents = 'auto';
      } else {
        ele.style.opacity = 0;
        ele.style.pointerEvents = 'none';
      }
    };
    const showLoading = () => {
      loadingBtn.style.opacity = 1;
      show(playBtn, false);
      show(pauseBtn, false);
      show(replayBtn, false);
    };
    const hideLoading = () => {
      loadingBtn.style.opacity = 0;
      show(playBtn, true);
      show(pauseBtn, true);
      show(replayBtn, true);
    };
    const onLoadStart = () => {
      showLoading();
      self.video.removeEventListener('loadstart', onLoadStart);
    };
    const onWaiting = () => {
      showLoading();
    };
    const onCanPlay = () => {
      hideLoading();
    };
    self.video.addEventListener('loadstart', onLoadStart);
    self.video.addEventListener('waiting', onWaiting);
    self.video.addEventListener('canplay', onCanPlay);
    this.listenersToRemove.push(() => {
      self.video.removeEventListener('waiting', onWaiting);
      self.video.removeEventListener('canplay', onCanPlay);
    });
  }

  initLazyLoad(): void {
    const self = this;
    const { lazyLoadThreshold, videoLazyLoadDistance } = this.props;
    const videoLazyLoadThreshold = videoLazyLoadDistance ? 5000 : lazyLoadThreshold;

    const callback = ({ inView }) => {
      if (!inView) {
        return;
      }
      const poster = self.video.dataset.layzrPoster;
      if (poster) {
        self.video.setAttribute('poster', poster);
      }
      const source = self.video.querySelectorAll('source');
      for (const element of source) {
        const src = element.dataset.layzrSrc;
        element.setAttribute('src', src);
      }
      self.video.load();
    };

    checkInView({
      elm: this.video,
      offset: videoLazyLoadThreshold - this.state.visibleBorderOffset,
      callback,
    });
  }

  triggerVideoPlay(video: any): void {
    const self = this;
    const { isWeixin } = this.state;
    if (isWeixin && (window as any).WeixinJSBridge) { /* eslint-disable-line no-undef */
      (window as any).WeixinJSBridge.invoke('getNetworkType', {}, () => { /* eslint-disable-line no-undef */
        setTimeout(() => {
          const playPromise = video.play();
          if (playPromise && playPromise.then && typeof playPromise.then === 'function') {
            playPromise.then(() => null, () => null).catch(() => null);
          }
        }, 0);
      }, false);
    } else {
      let playPromise;
      setTimeout(() => {
        playPromise = video.play();
        if (playPromise && playPromise.then && typeof playPromise.then === 'function') {
          playPromise.then(() => null, () => {
            this.autoPlayRetryTimes--;
            // 失败重试
            this.autoPlayRetryTimes && setTimeout(() => {
              self.triggerVideoPlay(self.video);
            }, 100);
          }).catch(() => null);
        }
      }, 0);
    }
  }

  throttle(func: Function, limit: number, ...args: any[]): any {
    let inThrottle = false;
    return (): any => {
      const self = this;
      if (!inThrottle) {
        func.apply(self, args);
        inThrottle = true;
        return setTimeout(() => {
          inThrottle = false;
          return inThrottle;
        }, limit);
      }
      return false;
    };
  }

  videoWithControl(): void {
    const iPhoneVersion = this.state.iPhoneVersion;
    const jumpControls = iPhoneVersion && iPhoneVersion >= 14;
    // 兼容iOS高版本（15.x/14.x）视频黑一下的问题
    !jumpControls && (this.video.style.opacity = 0);
    this.video.removeAttribute('autoplay');
    this.video.setAttribute('muted', true);
    this.video.setAttribute('playsinline', true);
    this.video.setAttribute('x5-playsinline', true);
    this.video.setAttribute('webkit-playsinline', true);
    // controls: 针对iOS、webkit等浏览器的播放样式做处理
    // jumpControls: iPhone iOS>=14 controls时会导致首屏视频闪现播放按钮，故去掉
    !jumpControls && this.video.setAttribute('controls', true);
    setTimeout(() => {
      if (!jumpControls) {
        this.video.removeAttribute('controls');
        this.video.style.opacity = 1;
      }
    }, 0);
  }

  render(): JSX.Element | undefined {
    const {
      children, downgradeDom, style,
    } = this.props;
    const { downgrade } = this.state;
    // if (downgrade === -1) {
    //   return <div style={style} />;
    // }
    return downgrade && downgradeDom
      ? downgradeDom : (
        <div className="video-player-box" style={style} ref={(elem) => { this.videoContainerDom = elem; }}>
          {
            children
          }
        </div>
      );
  }
}