import React from "react";
import SbEditable from "storyblok-react";
import "./button.scss";
import "video.js/dist/video-js.css";
import t from "../../utils/languages";
import Modal from "react-modal";
import VideoPlayer from "../atoms/videoPlayer";
import ResizeObserver from 'resize-observer-polyfill';
import { hasDocument } from '../../utils/helper';
import _debounce from 'lodash.debounce';


export default class extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showModal : false,
      isM: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.myObserver = null;
  }

  handleClick(e) {
    const newState = {
      showModal: true,
    }

    this.setState(newState);
    if(document.getElementsByClassName('ln__cta').length) {
      document.getElementsByClassName('ln__cta')[document.getElementsByClassName('ln__cta').length-1].classList.add('eventDisable')
    }
  }
  componentDidMount() {
    if (!hasDocument) return;
    const targetDom = document.body;
    const obj = targetDom.getBoundingClientRect();
    this.setState({
      isM: obj.width < 768
    })
    this.myObserver = new ResizeObserver(_debounce((entries) => {
      const ele = entries[0];
      if (!ele) return;
      this.setState({
        isM: ele.contentRect.width < 768
      })
    }, 200));
    this.myObserver.observe(targetDom);
  }

  componentWillUnmount() {
    this.myObserver.disconnect();
  }

  closeModal(){
    var timer = setTimeout(() => {
      if(document.getElementsByClassName('ln__cta').length) {
      document.getElementsByClassName('ln__cta')[document.getElementsByClassName('ln__cta').length-1].classList.remove('eventDisable')
      }
      clearTimeout(timer);
    }, 50);
  }
  render() {
    const { content, lang } = this.props
    const url = (content.file && content.file.filename) || (content.url && content.url.url);
    const { isM } = this.state;
    const videoOptions = {
      controls: true,
      fluid: true,
      aspectRatio: content.applyMobileVideoAspectRatio && isM ? '9:16' : '16:9',
      sources: [{
        src: url,
        type: 'video/mp4'
      }]
    }

    return (
      <SbEditable content={content}>
        {
          <>
            <button className="video-link" onClick={this.handleClick} >
            <img src={content.play_icon?.filename || "/assets/icons/play.svg"} alt="play" />
              {
                content.hideText ? null : (
                  <h3>{ content.text || t('video.button', lang)}</h3>
                )
              }
            </button>
            <Modal className="modal black fullscreen video-btn-modal" overlayClassName="overlay" isOpen={this.state.showModal} contentLabel="" shouldFocusAfterRender={false} onAfterClose={this.closeModal}>
              <button className="close" onClick={() => this.setState({ showModal : false })}>
                <img alt="close" src="/assets/icons/close-white.svg" />
              </button>
              <div className="modal__content">
                <div className="modal__wrapper">
                  <VideoPlayer { ...videoOptions }/>
                </div>
              </div>

            </Modal>
          </>
        }
      </SbEditable>
    )
  }
}
