/** 对 react-modal 进行扩展，modal 打开时禁用滚动，防止滚动穿透，关闭弹窗时恢复到滚动前位置 */
import React, { useEffect, useRef } from "react";
import Modal from "react-modal";

const preventDefault = (e) => e.preventDefault();
const CustomModal = (props) => {
  const { children, ...restProps } = props;
  const scrollTopRef = useRef(0);

  const { isOpen } = restProps;

  useEffect(() => {
    if (isOpen) {
      // 弹窗打开时禁止页面滚动
      document.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
      document.addEventListener("mousemove", preventDefault, {
        passive: false,
      });
      scrollTopRef.current = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      return () => {
        document.removeEventListener("touchmove", preventDefault, {
          passive: false,
        });
        document.removeEventListener("mousemove", preventDefault, {
          passive: false,
        });
        // 恢复到滚动前的位置
        setTimeout(() => {
          window.scrollTo({ top: scrollTopRef.current});
        }, 0);
      }
    }
  }, [isOpen]);

  return <Modal {...restProps}>{children}</Modal>;
};
export default CustomModal;
