import React from 'react';
import classNames from 'classnames';

import './style.scss';
import { StoryblokAsset } from '../../../mobile/HeroVertical/card/type';
import { styles } from '../../../../../utils/helper';

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
}

export interface Props {
  // 基础配置
  tabTheme?: tabTheme; // tab标题颜色
  activeKey?: string;
  tabAlign?: tabAlign;
  tabContent?: Array<TabPanelItem>;
  tabContentStyle?: Array<{ styleKey: string; styleValue: string }>;
}

export interface State {
  minHeight: number;
}

export default class TabContent extends React.PureComponent<Props, State> {
  content: Array<HTMLElement | null>;

  constructor(props: Props) {
    super(props);
    this.renderTabContent = this.renderTabContent.bind(this);
    this.state = {
      minHeight: 0,
    };
    this.content = [];
  }

  componentDidMount(): void {
    let minHeight = 0;
    if (this.content && this.content.length > 0) {
      this.content.forEach((item) => {
        if (item && item.offsetHeight > minHeight) {
          minHeight = item.offsetHeight;
        }
      });
    }
    this.setState({
      minHeight,
    });
  }

  renderTabContent(): JSX.Element {
    const { props, state } = this;
    const desc = props.tabContent && props.tabContent.map((item, index) => (
      <div
        className={classNames(
          'mtabh-tab-desc-item',
          `mtabh-tab-desc-align-${props.tabAlign}`,
          {
            'mtabh-tab-desc-active': props.activeKey === item.tabKey,
          },
        )}
        key={item.tabKey || index}
      >
        <div
          style={styles(props.tabContentStyle)}
          ref={(elm) => { this.content[index] = elm; }}
          dangerouslySetInnerHTML={{ __html: item.tabDesc }}
        />
      </div>
    ));

    return (
      <ul className="mtabh-tab-desc-content" style={{ minHeight: state.minHeight }}>{desc}</ul>
    );
  }

  render(): JSX.Element {
    return (
      <div className="mtabh-tab-item-content">
        {this.renderTabContent()}
      </div>
    );
  }
}
