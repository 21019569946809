import { backgroundImageSrcHandler } from '../../../../utils/helper';
import { ScrollManager } from '../../../../utils/scrollManager';
import * as React from 'react';
import { CSSProperties } from 'react';
import LazyLoadComponent from './LazyLoad/LazyLoadComponent';
import LazyLoadImage from './LazyLoad/LazyLoadImage';

export enum Type {
  'bg' = 'bg',
  'img' = 'img',
  'component' = 'component',
}

export interface Props {
  src: string;
  tagType?: string;
  style?: CSSProperties;
  type?: string;
  parallax?: boolean;
  [key: string]: any;
}

export interface State {
  backgroundImage?: string;
}

const defaultOptions = { threshold: 2000 };

/**
 * 懒加载组件, 重构中
 * 20221229 版本: 解决组件未到屏时执行动画循环的性能消耗问题
 */
class LazyWrapper extends React.Component<Props, State> {
  targetDom: HTMLDivElement | null;

  animationFrame: any;

  listenersToRemove: Array<Function>;

  loop: boolean = false;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: {
    tagType: string;
    style: CSSProperties;
    type: string;
    parallax: boolean;
  };

  constructor(defaultProps: Props) {
    super(defaultProps);

    this.state = {};
    this.listenersToRemove = [];
    this.setBackgroundImageAfterLoad = this.setBackgroundImageAfterLoad.bind(this);
  }

  componentDidMount(): void {
    const { parallax, visibleByDefault } = this.props;
    if (visibleByDefault) {
      if (parallax) {
        this.parallaxScroll();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.animationFrame && window) {
      window.cancelAnimationFrame(this.animationFrame);
    }

    this.listenersToRemove.forEach((item) => {
      if (typeof item === 'function') {
        item();
      }
    });
  }

  private setBackgroundImageAfterLoad(): void {
    const { src, parallax } = this.props;
    if (!src) {
      return;
    }
    const imageSrc = src;

    const backgroundImage = backgroundImageSrcHandler(imageSrc);
    this.setState({ backgroundImage });

    // 有视差的话增加视差效果
    if (parallax) {
      this.parallaxScroll();
    }
  }

  private parallaxScroll(): void {
    const factor = 0.1;
    let indicatorPosition = 0;
    const elm = this.targetDom;
    const self = this;
    if (!elm || typeof window === 'undefined') {
      return;
    }

    // 计算偏移 修复全屏背景视差图片没有撑满的bug
    let { backgroundSize } = this.computeBgSize();
    let offset = this.computeBgSize().backgroundOffset;

    function listenEvent(): void {
      backgroundSize = self.computeBgSize().backgroundSize;
      offset = self.computeBgSize().backgroundOffset >= 200 ? 200 : self.computeBgSize().backgroundOffset;
    }
    listenEvent();
    window.addEventListener('resize', listenEvent);
    self.listenersToRemove.push(() => {
      window.removeEventListener('resize', listenEvent);
    });

    function loop(): void {
      if (!self.loop) {
        window.cancelAnimationFrame(self.animationFrame);
        return;
      }
      const dimension = ScrollManager.getInstance().getDimension();
      if (dimension.isFastScroll) {
        // 快速滚动时, 只循环不计算, 避免短时间大量样式计算导致页面闪白
        self.animationFrame = requestAnimationFrame(loop);
        return;
      }
      const scrollOffset = dimension.pageOffsetY;
      const windowHeight = dimension.innerHeight;
      if (!elm || typeof window === 'undefined') {
        return;
      }

      let temp = elm;
      let { offsetTop } = elm;
      while (temp.offsetParent) {
        temp = temp.offsetParent as HTMLDivElement;
        offsetTop += temp.offsetTop;
      }
      const elHeight = elm.offsetHeight;
      if (scrollOffset >= offsetTop - windowHeight && scrollOffset <= offsetTop + elHeight) {
        const scrollPercent = (offsetTop - windowHeight - scrollOffset) / (windowHeight + elHeight);
        indicatorPosition += (scrollPercent - indicatorPosition) * factor;
        const calcOffset = indicatorPosition * offset;
        const styleValue = `${calcOffset}px`;
        elm.style['background-position-y'] = styleValue;
        elm.style['background-size'] = backgroundSize;
        elm.style['background-position-x'] = 'center';
      }
      self.animationFrame = requestAnimationFrame(loop);
    }

    ScrollManager.getInstance().addNode(this.targetDom,
      {
        scrollIntoView() {
          self.loop = true;
          loop();
        },
        scrollOutOfWiew() {
          self.loop = false;
        },
      });
  }

  // 计算视差背景图片的size
  // eslint-disable-next-line class-methods-use-this
  computeBgSize(): { backgroundSize?: string, backgroundOffset?: number } {
    const elm = this.targetDom;
    let backgroundSize = 'auto calc(100% + 200px)';
    let backgroundOffset = 200;
    if (!elm || typeof window === 'undefined') {
      return {};
    }
    const {
      bgWidth,
      bgHeight,
    } = this.props;
    if ((!bgWidth && !bgHeight) || (bgWidth === '' && bgHeight === '')) {
      return {
        backgroundSize,
        backgroundOffset,
      };
    }
    const backgroundImageWidth = parseInt(bgWidth, 10);
    const backgroundImageHeight = parseInt(bgHeight, 10);
    const bgRadio = backgroundImageWidth / backgroundImageHeight;
    const domWidth = elm.clientWidth;
    const domHeight = elm.clientHeight;
    const domRadio = domWidth / domHeight;
    if (bgRadio > domRadio) {
      // 当图片高度小于容器高度，撑满高度计算宽度，没有上下视差
      const backgroundWidth = domHeight * bgRadio;
      const backgroundSizeOffsetX = backgroundWidth - domWidth;
      backgroundSize = `calc(100% + ${backgroundSizeOffsetX}px) 100%`;
      backgroundOffset = 0;
    } else {
      // 当图片高度大于容器高度，撑满宽度计算高度，有上下视差浮动
      const backgroundHeight = domWidth / bgRadio;
      const backgroundSizeOffsetY = backgroundHeight - domHeight;
      backgroundSize = `100% calc(100% + ${backgroundSizeOffsetY}px)`;
      backgroundOffset = backgroundSizeOffsetY;
    }
    return { backgroundSize, backgroundOffset };
  }

  public render(): JSX.Element {
    const {
      children, type, src, tagType = 'div', visibleByDefault, forwardRef, ...componentProp
    } = this.props;

    if (type === 'img') {
      const placeholderIStyle = {
        color: 'transparent',
        display: 'inline-block',
        minWidth: '1px',
      };
      const { placeholder = <i style={placeholderIStyle} /> } = this.props;
      const restProps = {
        ...defaultOptions,
        ...componentProp,
      };
      return (
        <LazyLoadImage
          visibleByDefault={visibleByDefault}
          {...restProps}
          placeholder={placeholder}
          src={src}
        />
      );
    }

    if (type === 'bg') {
      // 如果是懒加载wrapper, 这懒加载bg
      const { style = {}} = componentProp;

      const { backgroundImage } = this.state;

      const lazyDom = (
        <LazyLoadComponent
          afterLoad={this.setBackgroundImageAfterLoad}
          threshold={componentProp.threshold || defaultOptions.threshold}
        >
          <span style={{ display: 'none' }} />
        </LazyLoadComponent>
      );

      let Dom;

      if (visibleByDefault) {
        Dom = React.createElement(tagType, {
          ...componentProp,
          ref: (elem: HTMLDivElement) => { this.targetDom = elem; },
          style: {
            ...style,
            backgroundImage: backgroundImageSrcHandler(src),
          },
        }, children);
      } else {
        Dom = React.createElement(tagType, {
          ...componentProp,
          ref: (elem: HTMLDivElement) => { this.targetDom = elem; },
          style: {
            ...style,
            backgroundImage,
          },
        }, lazyDom, children);
      }
      return Dom;
    }

    return (<LazyLoadComponent visibleByDefault={visibleByDefault} {...componentProp}>{children}</LazyLoadComponent>);
  }
}

LazyWrapper.defaultProps = {
  tagType: 'div',
  style: {},
  type: 'component',
  parallax: false,
};

export default LazyWrapper;
