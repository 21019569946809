import React from "react"
import API from "../../../utils/api"
import "./downloads_block.css"
import "../filters.css"

import SbEditable from "storyblok-react"
import _ from 'lodash'
import t from "../../../utils/languages"

class Downloads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: '',
            type: '',
            language: '',
            firmware: [],
            manuals: [],
            software: [],
            allProducts: [],
            isLoading: true
        };

        this.getDownloads = this.getDownloads.bind(this);
    }

    getDownloads = (newState) =>{

        var self = this;
        // newState["isLoading"] = true;
        this.setState(newState, () => {
            if (self.state.product){
                var url = '/' + self.state.product;

                if (self.state.type !== ''){
                    url += '/' + self.state.type;
                } else{
                    url += '/all';
                }
                if (self.state.language){
                    url += '/' + self.state.language;
                }
                window.history.replaceState(null, null, '#' + self.state.product )

                API.get('/products/downloads' + url)
                    .then(function(response){
    
                        var firmware =
                            _.chain(response.data)
                                .filter({artifact_type : 'firmware'})
                                .groupBy('name')
                                .map(function(list, name){
    
                                    var rel = _.chain(list).groupBy('version').map(function(val, key){
                                        return {
                                            version : key,
                                            alt_version: _.first(val).alt_version,
                                            files : _.sortBy(val, ["type", "language === 'en", "name"]),
                                            additional : _.filter(val, { type : 'additional' } )
                                        }
                                    }).value();
    
                                    return {
                                        name : name,
                                        latest : _.chain(rel).first().value(),
                                        releases : rel
                                    }
                                }).value();
    
    
                        
                        var manuals =
                            _.chain(response.data)
                                .filter({artifact_type : 'manual'})
                                .sortBy(["name", "language === 'en", "language"])
                                .value();
    
                        var software =
                            _.chain(response.data)
                                .filter({artifact_type : 'software'})
                                .groupBy('name')
                                .map(function(list, name){
    
                                    var rel = _.chain(list).groupBy('version').map(function(val, key){
                                        return {
                                            version : key,
                                            files : _.sortBy(val, ["type", "name", "language === 'en", "language"]),
                                            additional : _.filter(val, { type : 'additional' } )
                                        }
                                    }).value();
    
                                    return {
                                        name : name,
                                        latest : _.chain(rel).first().value(),
                                        releases : rel
                                    }
                                }).value();
                                
                                self.setState({ firmware : firmware, software: software, manuals:manuals, isLoading : false})
                    })
                .catch(function(error){
                    console.log('ERROR:', error);
                    self.setState({isLoading : false})
    
                });
    
            }
        });

        

    }

    getTitleWithLanguage = (file) =>{

        if (file.type === 'download'){
            return file.alt_text || 'Download'
        }
        if (file.type === 'additional'){
            return file.alt_text || file.name
        }
        else{
            var text = '';
            if (file.alt_text){
                text = file.alt_text;
            }
            else{
    
                switch (file.type) {
                    case 'readme':
                        text = 'Read me - '
                        break;
                    case 'manual':
                        text = file.name + ' - ';
                        break;
                    default:
    
                        break;
                }
    
                var languages = {
                    'en' : 'English',
                    'kr' : 'Korean',
                    'fr' : 'French',
                    'de' : 'German',
                    'ct' : 'Traditional Chinese',
                    'cs' : 'Simplified Chinese',
                    'jp': 'Japanese',
                    'it': 'Italian',
                    'es': 'Spanish',
                    'ru': 'Russian',
                    'lv': 'Latvian',
                    'pt': 'Portugese',
                    'se': 'Swedish',
                    'ar': 'Arabic',
                    'nl': 'Dutch'
    
                }
    
                var lang = languages[file.language];
                text += lang || file.language;
            }
    
            return text;
    
        }
    }


    render = () => {
        return (
            <SbEditable content={this.props.blok}>
                <div className="filters">
                    <div className="filters__container">
                        <label htmlFor="filterProducts">
                            <div className="show">{t('profile.product', this.props.lang)}</div>
                            <select id="filterProducts" onChange={(e) => {this.getDownloads({ product: e.target.value }); }} value={this.state.product} >
                                <option disabled="disabled" value="" defaultChecked={true}>{ t('profile.selectProduct', this.props.lang) }</option>
                                { this.state.allProducts.map((group) => 
                                    <optgroup key={group.name} label={group.name}>
                                        { group.products.map((product, key) => 
                                            <option value={product.id} key={key}>{product.name}</option>

                                        )}
                                    </optgroup>
                                )}
                            </select></label>
                        <label htmlFor="filterType">
                            <div className="show">{ t('profile.type', this.props.lang)}</div>
                            <select id="filterType" onChange={(e) => {this.getDownloads({ type: e.target.value }); }} value={this.state.type}>
                                <option defaultChecked={true} value="">{ t('profile.allTypes', this.props.lang)}</option> 
                                <option value="firmware">{ t('profile.firmware', this.props.lang)}</option> 
                                <option value="software">{ t('profile.software', this.props.lang)}</option> 
                                <option value="manual">{ t('profile.manuals', this.props.lang)}</option>
                            </select>
                        </label>

                        <label htmlFor="filterLanguage">
                            <div className="show">{ t('profile.language', this.props.lang)}</div> 
                            <select id="filterLanguage" onChange={(e) => {this.getDownloads({ language: e.target.value }); }} value={this.state.language}>
                                <option defaultChecked={true} value="">{ t('profile.allLanguages', this.props.lang)}</option>
                                <option value="en">English</option> 
                                <option value="fr">French</option> 
                                <option value="de">German</option> 
                                <option value="ct">Traditional Chinese</option> 
                                <option value="cs">Simplified Chinese</option> 
                                <option value="jp">Japanese</option> 
                                <option value="it">Italian</option> 
                                <option value="es">Spanish</option> 
                                <option value="kr">Korean</option> 
                                <option value="ru">Russian</option> 
                                <option value="lv">Latvian</option> 
                                <option value="pt">Portugese</option> 
                                <option value="se">Swedish</option> 
                                <option value="ar">Arabic</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div className="downloads-listing">
                    { this.state.firmware && this.state.firmware.length > 0 && 
                        <div className="dl__group">
                            <h2 style={{ padding:`0 20px`}}>{ t('profile.firmware', this.props.lang)}</h2> 
                            { this.state.firmware.map((art) => 
                                <>
                                    <div className="dl__release">
                                        <div className="dl__release__downloads">
                                            <h3>{art.latest.name} {art.latest.version}</h3>
                                            { art.latest.files.map((file) => 
                                                file.type !== 'additional' &&
                                                    <a href={file.url} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>    
                                            ) }
                                        </div>
                                        { art.latest.additional && art.latest.additional.length > 0 && 
                                            <div className="dl__release__additional">
                                                <Panel>
                                                    <h3 style={{ background: 'url(/assets/icons/arrow-down-active.svg) right no-repeat'}}>{ t('profile.downloads.additional', this.props.lang)}</h3>
                                                    <div className="panel">
                                                        { art.latest.additional.map((file) => 
                                                            <a href={file.url} title={this.getTitleWithLanguage(file) } download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                                        ) }
                                                    </div>
                                                </Panel>    
                                            </div>
                                        }
                                    </div> 
                                    <div className="dl__release__previous">
                                        <Panel>
                                            <h3 style={{ background: 'url(/assets/icons/arrow-down-active.svg) right no-repeat'}}>{ t('profile.downloads.previous', this.props.lang)}</h3> 
                                            <div className="panel">
                                                { art.releases.map((release, rIndex) => 
                                                    rIndex > 0 && 
                                                        <div key={rIndex} className="dl__release__downloads">
                                                            <h3>{art.name} { release.alt_version || release.version }</h3>
                                                            { release.files.map((file, fIndex) => 
                                                                
                                                                file.type !== 'additional' &&
                                                                    <a href={file.url} key={fIndex} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                                            ) }
                                                        </div>           
                                                                        
                                                ) }
                                            </div>
                                        </Panel>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    { this.state.software && this.state.software.length > 0 && 

                        <div className="dl__group">
                            <h2 style={{ padding:`0 20px`}}>{ t('profile.software', this.props.lang)}</h2> 
                            { this.state.software.map((art) => 
                                <>
                                <div className="dl__release">
                                    <div className="dl__release__downloads">
                                        <h3>{art.name} {art.latest.version}</h3>
                                        { art.latest.files.map((file) => 

                                            file.type !== 'additional' &&
                                                <a href={file.url} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                        
                                        ) }
                                    </div>
                                </div> 
                                { art.latest.additional && art.latest.additional.length > 0 && 
                                    <div className="dl__release__additional">
                                        <Panel>
                                            <h3 style={{ background: 'url(/assets/icons/arrow-down-active.svg) right no-repeat'}}>
                                                { t('profile.downloads.additional', this.props.lang)}
                                            </h3>
                                            <div className="panel">
                                                { art.latest.additional.map((file) => 
                                                    <a href={file.url} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                                ) }
                                            </div>
                                        </Panel>    
                                    </div>
                                }
                                <div className="dl__release__previous">
                                    <Panel>
                                    <h3 style={{ background: 'url(/assets/icons/arrow-down-active.svg) right no-repeat'}}>{ t('profile.downloads.previous', this.props.lang)}</h3> 
                                            <div className="panel">
                                                { art.releases.map((release, rIndex) => 
                                                    rIndex > 0 && 
                                                        <div key={rIndex} className="dl__release__downloads">
                                                            <h3>{art.name} { release.alt_version || release.version }</h3>
                                                            { release.files.map((file, fIndex) => 
                                                                
                                                                file.type !== 'additional' &&
                                                                    <a href={file.url} key={fIndex} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                                            ) }
                                                        </div>           
                                                                        
                                                ) }
                                            </div>
                                    </Panel>
                                </div>
                                </>
                            )}
                        </div>
                    }
                    
                    { this.state.manuals && this.state.manuals.length > 0 && 
                        <div className="dl__group">
                            <h2 style={{ padding:`0 20px`}}>{ t('profile.manuals', this.props.lang)}</h2> 
                            <div className="dl__release">
                                <div className="dl__release__downloads">                                    
                                    <h3>{this.state.manuals[0].name}</h3>
                                    { this.state.manuals.map((file) => 
                                        <a href={file.url} title={this.getTitleWithLanguage(file)} download="download" target="_blank" rel="noreferrer">{ this.getTitleWithLanguage(file) }</a>
                                    ) }
                                </div>
                            </div> 
                        </div>
                    }
                    { !this.state.firmware.length && !this.state.software.length && !this.state.manuals.length && !this.state.isLoading &&
                        <div className="dl__group"> 
                            <h2 className="downloads-listing-noproduct">{t('profile.Product.NoFoundProduct', this.props.lang)}</h2>   
                        </div>
  		            }
                </div>
            </SbEditable>
        )
    }

    componentDidMount = () => {
        API.get('/products')
            .then((response) => {

                var products =
                _.chain(response.data)
                    .groupBy('group_name')
                    .map(function(products, group){
                        return {
                            name : group,
                            products : products
                        }
                    }).value();

                this.setState({ allProducts: products })
                this.selectProductByURL();   
            })
    }

    selectProductByURL = () => {
        if (window.location.hash){
            this.getDownloads({ product: window.location.hash.replace('#','') })
        }
    }
}
export default Downloads


class Panel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
        }
    }

    render = () => (
        <div className={this.state.show ? 'toggle show' : 'toggle' } onClick={(e) => { this.setState({show:!this.state.show}) }} >
            {this.props.children}
        </div>
    )
}
