
import { useState, useLayoutEffect } from "react";
import ResizeObserver from 'resize-observer-polyfill';
import { hasDocument } from '../utils/helper';
import _debounce from 'lodash.debounce';


export const DeviceInfo = (options) => {
  const { border: propsBorder } = options || {};
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const widthBorder = propsBorder || 768;
  useLayoutEffect(() => {
    if (!hasDocument) return;
    const targetDom = document.body;
    const obj = targetDom.getBoundingClientRect();
    setIsMobile(obj.width < widthBorder);
    setWidth(obj.width);
    setHeight(obj.height);
    const ro = new ResizeObserver(_debounce((entries) => {
      const ele = entries[0];
      if (!ele) return;
      setIsMobile(ele.contentRect.width < widthBorder);
      setWidth(ele.contentRect.width);
      if (!isMobile) {
        setHeight(ele.contentRect.height);
      }
    }, 200));
    ro.observe(targetDom);
    return () => {
      ro.disconnect();
    }
  })

  return {
    width,
    height,
    isMobile
  };
};