import cachedUrlFix from "./links";

export function setWillChange(dom, change) {
  if (dom) {
    dom.style.willChange = change;
  }
}

export function removeWillChange(dom) {
  if (dom) {
    dom.style.willChange = 'auto';
  }
}

export function removeClass (elem, className = 'active') {
  if (elem && elem.classList) {
    elem.classList.remove(className);
  }
}

export function addClass(elem, className = 'active') {
  if (elem && elem.classList) {
    elem.classList.add(className);
  }
}

export function checkIsIE() {
  if (!hasWindow) {
    return;
  }
  let isIE = false;
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  // const edge = ua.indexOf('Edge/');
  // if (msie > 0 || trident > 0 || edge > 0) {
  if (msie > 0 || trident > 0) {
    isIE = true;
  }
  return isIE;
}

export function shouldMobileVideoDowngrade() {
  if (typeof window === 'undefined') {
    return true;
  }
  const ua = window.navigator.userAgent;
  const isWeixin = !!ua.match(/MicroMessenger/ig);
  const isIOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const isUC = !!ua.match(/UCBrowser/ig);
  const isAndroid = !!ua.match(/android/ig);
  const isQQBrowser = !!ua.match(/\bm?qqbrowser\/([0-9.]+)/ig);

  return isAndroid || (isIOS && (isUC || isQQBrowser || isWeixin));
}

/**
 * window onload 之后执行方法
 * @param afterWindowLoaded
 */
 export const runAfterWindowLoaded = (afterWindowLoaded) => {
  if (typeof document === 'undefined' || typeof window === 'undefined' || typeof afterWindowLoaded !== 'function') {
    return;
  }
  if (document.readyState !== 'complete') {
    window.addEventListener('load', afterWindowLoaded);
  } else {
    afterWindowLoaded();
  }
};

export function bindVideoEvent(video, func) {
  function onLoadMetadata() {
    func();
    video.removeEventListener('canplay', onLoadMetadata);
  }

  const isIE11 = typeof window === 'undefined' ? false : window && !!window.MSInputMethodContext && !!document.documentMode;
  if (isIE11) {
    if (video.load) {
      video.load();
    }
    video.addEventListener('canplay', onLoadMetadata);
  } else {
    func(video);
  }
}

export function playVideo(video) {
  const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
  if (!isPlaying) {
    const playPromise = video.play();
    if (playPromise && playPromise.then && typeof playPromise.then === 'function') {
      playPromise.then(() => {}, () => {});
    }
  }
}

export function videoWithControl(video) {
  video.removeAttribute('autoplay');
  video.setAttribute('muted', true);
  video.setAttribute('playsinline', true);
  video.setAttribute('x5-playsinline', true);
  video.setAttribute('webkit-playsinline', true);
  video.setAttribute('controls', true);
  setTimeout(() => {
    video.removeAttribute('controls');
  }, 0);
}

export function showWholeElm(elm) {
  if (!elm) { return false; }
  const windowTop = window.scrollY || window.pageYOffset;
  const windowBottom = windowTop + window.innerHeight;
  let elmTop = elm.offsetTop;
  let elmBottom = elm.offsetHeight;
  let temp = elm;
  while (temp.offsetParent) {
    temp = temp.offsetParent;
    elmTop += temp.offsetTop;
  }
  elmBottom += elmTop;
  return (elmTop >= windowTop && windowBottom >= elmBottom)
    || (elmTop <= windowTop && windowBottom <= elmBottom);
}

export const hasWindow = typeof window !== 'undefined';

export const hasDocument = typeof document !== 'undefined';

export const onGlobalModalOpen = () => {
  const preventModalReturnToTop = () => {
    const lnCtaDoms = document.getElementsByClassName('ln__cta');
    if(lnCtaDoms.length) {
      lnCtaDoms[lnCtaDoms.length-1].classList.add('eventDisable');
    }
  };
  preventModalReturnToTop();
};

export const onGlobalModalClose = () => {
  const preventModalReturnToTop = () => {
    setTimeout(() => {
      const lnCtaDoms = document.getElementsByClassName('ln__cta');
      if(lnCtaDoms.length) {
        lnCtaDoms[lnCtaDoms.length-1].classList.remove('eventDisable')
      }
    }, 50);
  };
  preventModalReturnToTop();
};

const shouldIOSdowngrade = ({
  isUC, isQQBrowser, isWeixin,
}) => {
  return isUC || isQQBrowser || isWeixin;
};
const shouldDowngrade = ({
  isAndroid, isIOS,
  isFirefox, isChrome, isUC, isQQBrowser, isWeixin,
  isHuaweiBrowser,
}) => {
  return (isAndroid && (!isFirefox && !isChrome))
    || (isIOS && shouldIOSdowngrade({
      isUC, isQQBrowser, isWeixin,
    }))
    || isHuaweiBrowser;
};

export const isDowngrade = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const ua = window.navigator.userAgent;
  const version = window.navigator.appVersion;
  const isWeixin = !!ua.match(/MicroMessenger/ig);
  const isIOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const isUC = !!ua.match(/UCBrowser/ig);
  const isAndroid = !!ua.match(/android/ig);
  const isQQBrowser = !!ua.match(/\bm?qqbrowser\/([0-9.]+)/ig);
  const isFirefox = !!ua.match(/\bfirefox\/([0-9.ab]+)/ig);
  const isChrome = window.navigator && window.navigator.permissions && version.indexOf('(KHTML, like Gecko) Chrome') !== -1;
  const isHuaweiBrowser = !!ua.match(/HuaweiBrowser/ig); // 华为浏览器稳定性极差, 容易崩溃, 做降级处理
  return shouldDowngrade({
    isAndroid,
    isIOS,
    isFirefox,
    isChrome,
    isUC,
    isQQBrowser,
    isWeixin,
    isHuaweiBrowser,
  });
};

/**
 * 判断是否过时的设备
 * 收录 7 年以上的老旧机器, 用于对复杂动画进行降级
 * iPhone 8+ (8+, 7+, 6S+, 6+) 414x736
 * iPhone 8 (8, 7, 6S, 6) 375x667
 * iPhone SE（SE, 5S, 5C）320x568
 */
const checkIsIphone678 = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  const { width, height } = window.screen;
  const isIphone = !!ua.match(/iPhone/i);
  return isIphone && ((width === 375 && height === 667) ||
  (width === 414 && height === 736) ||
  (width === 320 && height === 568));
}

/**
* 判断是否过时的移动端设备
* 收录 7 年以上的老旧手机, 用于对复杂动画进行降级
*/
export const isObsoleteMobileDevice = () => {
 return checkIsIphone678();
};

/**
* 判断是否 iPhone mini 系列
* mini 机器性能较差, 用于对复杂动画进行降级
* iPhone 13 mini 360x780
* iPhone 12 mini 360x780
*/
export const checkIsIphoneMini = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  const { width, height } = window.screen;
  const isIphone = !!ua.match(/iPhone/i);
  return isIphone && (width === 360 && height === 780);
};

/**
 * 获取 Storyblok Asset 类型的资源地址
 * @param {*} assetValue 
 * @returns 
 */
export const getAsset = (assetValue) => assetValue && assetValue.filename ? assetValue.filename : '';

/**
 * 获取 Storyblok Link 类型的实际链接
 */
export const LinkFix = cachedUrlFix;

/** 过滤所有的HTML */
export const filterHtmlTag = (str) => {
  if (!str) { return; }
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const MOBILE_MAX_WIDTH = 768;

export function backgroundImageSrcHandler(imageSrc) {
  if (!imageSrc) {
    return imageSrc;
  }
  let backgroundImage;
  if (!/\burl\s*\(/i.test(imageSrc) && /^\s*(\.|(\/)|http)/.test(imageSrc)) { // 如果src为 url('xxx.img')则不需要帮忙加上url
    backgroundImage = `url("${imageSrc}")`;
  } else {
    backgroundImage = imageSrc;
  }
  return backgroundImage;
}

/**
 * 将 CSS 属性-值 列表转换为 CSS Style Object
 * 
 * styleKey: 默认支持的属性列表，提供 label 供设计师选择
 * styleCustomKey: 补充上述列表中没有的属性
 * styleValue：样式值
 * 
 * eg: [{ styleKey: 'color', styleValue: 'black' }, { styleCustomKey: 'opacity', styleValue: '0.6' }] 
 *     => { color: 'black', opacity: '0.6' }
 * 
 * @param {*} stylesList 
 * @returns 
 */
export const styles = (stylesList) => {
  const styleObj = {};

  Array.isArray(stylesList) && stylesList.forEach((s) => {
    if (s.styleKey && s.styleValue) {
      styleObj[s.styleKey] = s.styleValue;
    }
    if (s.styleCustomKey && s.styleValue) {
      styleObj[s.styleCustomKey] = s.styleValue;
    }
  });

  return styleObj;
};

/**
 * 生成根据某个屏宽节点显示 PC/M 组件的 CSS（用于组件可配置响应节点）
 * 
 * border: 如 768px 响应，则设置 border=768
 * uid: 该 CSS 生效的唯一id，一般使用组件 UID 以避免互相干扰
 * clsPrefix: 组件类名前缀，PC/M 组件 wrapper classname 应为 ”clsPrefix-pc/m-shortUid“ 格式
 * 
 * @returns 
 */
export const genResponsiveCSS = ({ border, componentSlug, uid }) => `
  @media(min-width: ${border}px) {
    .${componentSlug}-pc-${uid} { display: block; }
    .${componentSlug}-mobile-${uid} { display: none; }
  }

  @media(max-width: ${border - 1}px) {
    .${componentSlug}-pc-${uid} { display: none; }
    .${componentSlug}-mobile-${uid} { display: block; }
  }
`;

/** 检查是否移动端设备 */
export const checkIsMobile = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  const isM = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
  return isM;
}

/**
 * 判断表单传入的是不是空字符串（包括空格）
 * @param {string} input 
 * @returns 
 */
export const isEmptyString = (input) => {
  return !input || (typeof input === 'string' && !(input.trim()))
};
