import { useStaticQuery, graphql } from "gatsby"

export const GetEvents = () => {

    const { allListing } = useStaticQuery(graphql`
        query EventsQuery {
            allListing(filter: {type: {eq: "event"}}) {
                nodes {
                    id
                    url
                    title
                    type
                    b_img
                    lang
                    img
                }
            }
        }
    `)
    return allListing.nodes
}