import React, { Component } from 'react'
import t from "../../../utils/languages"
import Modal from "../../../components/CustomModal"

        
const domain = "https://uploads.hasselblad.com/";
class FileUpload extends Component {

    state = {
        drag: false,
        backgroundImage : null,
        showModal: false,
        backgroundImage1000px: null,
    }

    dropRef = React.createRef()
    constructor() {
        super();
        this.watchImage = this.watchImage.bind(this);
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true })
        }
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        this.handleFiles(e.dataTransfer.files , e)
        e.dataTransfer.clearData()
    }

    handleFiles = (files, e) =>{
        if (files && files.length > 0) {
            let self = this;
            let file = files[0]

            if (!file || !file.type.match('image/jpeg')) {
                alert(t('competition.badFileFormat', this.props.lang))
            }
            else if (file.size > 10 * 1024 * 1024 ){
                // 大于 10MB 无法上传
                alert(t('competition.fileTooLarge', this.props.lang))
            } 
            else{
                if(e.target && e.target.getAttribute('tag') === 'replace') {
                    file.replaced = true;
                    this.props.handleDrop(file, this.props.position)
                } else {
                    this.props.handleDrop(file, this.props.position)
                }
                // 清空input的value 解决不能上传同一张图片的bug
                e.target.value = "";
                if(window.FileReader) {
                  var reader = new FileReader();
                  if (file && file.type.match('image.*')) {
                      reader.readAsDataURL(file);
                  } 
      
                  reader.onloadend = function (e) {
                      let imageBuffer = reader.result;
                      self.setState({backgroundImage : imageBuffer})
                  }
                }
            }
            
        }
    }

    onChange = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.handleFiles(e.target.files, e)
    }

    deletePhoto = () =>{
        this.setState({backgroundImage : null})
        this.props.handleDrop(null, this.props.position)
    }


    // 替换图片
    replacePhoto = (e) => {
        e.stopPropagation()
        e.preventDefault()

        this.handleFiles(e.target.files, e)
    }

    componentDidMount = () => {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }


    componentWillReceiveProps(nextProps) {
        const { file } = nextProps;
        if (file === undefined || this.props.file === file) { return; }
        
        const id = file.replace(/(.*\/)*([^.]+).*/ig,"$2");
        const obj = {
            replaced: false,
            id,
        };
        this.setState({
            backgroundImage: `${domain}community/${file}`,
            backgroundImage1000px:  `${domain}1000x-community/${file}`,
        });
        this.props.handleDrop(obj, this.props.position)
    }

    componentWillUnmount = () => {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    watchImage() {
        const { showModal } = this.state;
        if (this.state.backgroundImage === null) {return;}
        this.setState({
            showModal: !showModal,
        });
    }


    render = () =>{
        const { id, registered, blok, coverText } = this.props
        const {
            backgroundImage,
            backgroundImage1000px,
            showModal,
        } = this.state;
        return (
            <>
                <div className={`image-upload ${backgroundImage === null ? '' : 'hover-glass'}`} ref={this.dropRef} >
                    <div className={`upload-container ${this.state.drag ? 'is-dragover' : null} ` } style={ { backgroundImage : `url('${this.state.backgroundImage}')` } } >
                        <div
                            className="upload-area"
                            style={{
                                opacity: this.state.backgroundImage === null ? 1 : 0,
                            }}
                            onClick={this.watchImage}
                        >
                            <div
                                className="watch-image"
                                style={{
                                    opacity: this.state.backgroundImage === null ? 0: 1,
                                    zIndex: this.state.backgroundImage === null ? '-1': '9',
                                }}
                            />
                            <input id={id} type="file" accept="image/jpeg" ref={this.dropRef} onChange={this.onChange.bind(this) } disabled={this.props.disabled}></input>
                            <label htmlFor={id} className="img-upload-area">
                            <div className="img-upload-wrap">
                                <img src="/assets/icons/upload.svg" className="img-upload-icon" />
                                <span className="img-upload-text">{blok.uploadImagePrompt || 'click here or drag image to upload'}</span>
                            </div>
                            </label>
                        </div> 
                           
                    </div>
                </div>
                    <div style={{ textAlign:'center', width:'100%', display: 'flex', justifyContent: 'center' }}>
                        <div className="replace-img-wrap">
                            { coverText && <span className="cover-label">{coverText}</span> }
                            { this.state.backgroundImage !== null &&
                                <label htmlFor={`replace-${id}`} className="img-upload-area">
                                    <input tag="replace" className="replace-img-input" id={`replace-${id}`} name={`replace-${id}`} type="file" accept="image/jpeg" onChange={this.replacePhoto.bind(this) } disabled={this.props.disabled}></input>
                                    <button className="replace-img" ><span>{(blok.replace_button_text) || 'REPLACE'}</span><img src="/assets/icons/upload.svg" /></button>
                                </label>
                            }
                        </div>
                        
                    </div>
                <Modal className="modal watch-image-modal" overlayClassName="overlay" isOpen={showModal} contentLabel="" style={{overlay: { zIndex: '99', top: '0', outline: 'none'}}}>
                    <div className="editorial row">
                        <div className="modal__content watch-image-modal-content">
                            <div className="watch-image-modal-wrap">
                                <div className="modal-image-box">
                                    <img className="zoomout-img" src={backgroundImage} alt="" />
                                </div>
                                <img onClick={this.watchImage} className="close-icon" src="/assets/icons/close-black.svg" alt="close" />
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default FileUpload