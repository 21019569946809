import React from 'react';
import LazyWrapper from '../../../utils/LazyWrapper';
import { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import { PropsWithAos, Icon, IconLayout } from './type';
import './style.scss';

const Icons = (props: PropsWithAos) => {
  const { aosFade } = props;

  // eslint-disable-next-line complexity
  const renderIcon = (icon: Icon, maxWidth: string, height: string, layout: IconLayout, width: string, iconIndex: number): JSX.Element => {
    const number = icon.hasNumber || icon.hasUnit
      ? (
        <div className="cardh-icon-number-container">
          {
            icon.hasNumber ? <span key="number" className="cardh-icon-number" style={{ color: icon.titleColor }} dangerouslySetInnerHTML={{ __html: icon.number || '' }} /> : null
          }
          {
            icon.hasUnit ? <span key="unit" className="cardh-icon-unit" style={{ color: icon.titleColor }} dangerouslySetInnerHTML={{ __html: icon.unit || '' }} /> : null
          }
        </div>
      ) : null;
    const iconAlt = filterHtmlTag(icon.iconAlt || (icon.hasTitle && icon.title) || (icon.hasDesc && icon.desc) || '');
    const pic = icon.hasPic && getAsset(icon.pic) ? <LazyWrapper visibleByDefault={props.visibleByDefault} alt={iconAlt} type="img" key="pic" src={getAsset(icon.pic)} className="cardh-icon-pic" style={{ height }} /> : null;

    const titleStyle: {
      color?: string;
      fontWeight?: any;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
      margin?: string;
    } = {
      color: icon.titleColor,
      fontWeight: icon.titleFontWeight && TextFontWeight[icon.titleFontWeight] ? TextFontWeight[icon.titleFontWeight] : undefined,
      fontSize: icon.titleFontSize,
      lineHeight: GetLineHeight(icon.titleFontSize, icon.titleLineHeight),
      letterSpacing: icon.titleLetterSpacing,
      ...icon.titleMargin ? { margin: icon.titleMargin } : {},
    };

    const descStyle: {
      color?: string;
      fontWeight?: any;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
      margin?: string;
    } = {
      color: icon.descColor,
      fontWeight: icon.descFontWeight && TextFontWeight[icon.descFontWeight] ? TextFontWeight[icon.descFontWeight] : undefined,
      fontSize: icon.descFontSize,
      lineHeight: GetLineHeight(icon.descFontSize, icon.descLineHeight),
      letterSpacing: icon.descLetterSpacing,
      ...icon.descMargin ? { margin: icon.descMargin } : {},
    };

    const title = icon.hasTitle ? <p key="title" className="cardh-icon-title" style={titleStyle} dangerouslySetInnerHTML={{ __html: icon.title || '' }} /> : null;
    const desc = icon.hasDesc ? <p key="desc" className="cardh-icon-desc" style={descStyle} dangerouslySetInnerHTML={{ __html: icon.desc || '' }} /> : null;
    return (
      <div
        key={icon.title || icon.desc || iconIndex}
        className={`cardh-card-icon-container${layout === IconLayout.horizontal ? ' cardh-horizontal' : ''}`}
        style={{ maxWidth: layout === IconLayout.horizontal ? maxWidth : 'none', width }}
        data-aos={aosFade}
        data-aos-once
      >
        {
          layout === IconLayout.horizontal ? [
            <div className="cardh-left cardh-part" key="left">
              {number}
              {pic}
            </div>,
            <div className="cardh-right cardh-part" key="right">
              {title}
              {desc}
            </div>,
          ] : [number, pic, title, desc]
        }
      </div>
    );
  };

  const renderIcons = (icons: Array<Icon>, maxWidth: string, height: string, layout: IconLayout, width: string, margin: string): JSX.Element => (
    <div className="cardh-icons-container" style={{ margin }}>
      {
        icons.map((item, itemIndex) => renderIcon(item, maxWidth, height, layout, width, itemIndex))
      }
    </div>
  );

  const icons1 = props.hasIcons1 && props.icons1 && props.icons1.length > 0
    && renderIcons(props.icons1, props.icons1MaxWidth || '', props.icons1Height, props.icons1Layout, props.icons1width, props.icons1Margin);
  const icons2 = props.hasIcons2 && props.icons2 && props.icons2.length > 0
    && renderIcons(props.icons2, props.icons2MaxWidth || '', props.icons2Height, props.icons2Layout, props.icons2width, props.icons2Margin);

  return (
    <div className="cardh-card-icons-container">
      {icons1}
      {icons2}
    </div>
  );
};

export default Icons;
