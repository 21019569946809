import React from "react"
import SbEditable from "storyblok-react"
import { Link } from "gatsby"
import LinkFix from "../../utils/links"
import {
  removeClass,
  addClass,
} from '../../utils/helper'
import "./button.scss"

const BUTTON_THEME = {
  black: 'black',
  white: 'white',
};

export default class extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      hoverIcon: null,
    }
  }
  
  componentDidMount() {
    const { content } = this.props;
    let icon = '';
    let hoverIcon = '';
    switch (content.iconType) {
      case 'download':
        icon = '/assets/icons/download-black.svg';
        hoverIcon = '/assets/icons/download-white.svg';
        break;
      case 'learn-more':
        icon = '/assets/icons/learn-more.svg';
        hoverIcon = '/assets/icons/learn-more-white.svg';
        break;
      case 'customize':
        icon = content.icon || '';
        hoverIcon = content.hoverIcon || '';
        break;
      case 'add':
        icon = '/assets/icons/add.svg';
        hoverIcon = '/assets/icons/add-white.svg';
        break;
      default:
        break;
    }

    const nextIcon = content.theme === BUTTON_THEME.white ? hoverIcon : icon;
    const nextHoverIcon = content.theme === BUTTON_THEME.white ? icon : hoverIcon;

    this.setState({
      icon: nextIcon,
      hoverIcon: nextHoverIcon,
    })
  }

  render() {
    const { content } = this.props
    const { icon, hoverIcon } = this.state;
    const imageClass = content.iconType !== 'customize' ? `${content.iconType} btn-icon` : 'btn-icon';
    // 当配置了button文案，应对多语言下button显示与否场景
    if (content.text){
      return (
        <SbEditable content={content}>
            { content.button_type ? 
            <a href={ LinkFix(content.link) + (content.link.anchor ? '#' + content.link.anchor : '') } className={`cta ${content.button_type} cta-theme-${content.theme}` } target="_blank" rel="noopener noreferrer">
              <span>{content.text}</span>
              {
                icon ? (
                  <div className={imageClass}>
                    <img src={icon} className='normal-icon' />
                    <img className='hover-icon' src={hoverIcon}/>
                  </div>
                ) : null
              }
            </a>
            :
            <a href={ LinkFix(content.link) + (content.link?.anchor ? '#' + content.link?.anchor : '') } className={`cta ${content.button_type} cta-theme-${content.theme}` } target="_blank" rel="noopener noreferrer">
              {content.text}
              {
                icon ? (
                  <div className={imageClass}>
                    <img src={icon} className='normal-icon' />
                    <img src={hoverIcon} className='hover-icon' />
                  </div>
                ) : null
              }
            </a>

          }
        </SbEditable>
      )
    }
    else{
      return (<></>)
    }
  }
}
