/* eslint-disable complexity */
import React from 'react';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import Eyebrow from './eyebrow';
import Headline from './headline';
import SubHeadline from './subHeadline';
import Divider from './divider';
import Description from './description';
import Buttons from './buttons';
import Note from './note';
import Icons from './icons';
import Tag from './tag';
import {
  PropsWithAos,
  CardLayout,
} from './type';
import './style.scss';

interface Props extends PropsWithAos {
  contentWidth: string;
}

export default React.memo((props: Props) => {
  const {
    hasToppingImage,
    hasEyeBrow,
    hasHeadline,
    hasSubHeadline,
    hasDividedLine,
    hasDescription,
    hasCardLinks,
    hasNote,
    hasIcons1,
    hasIcons2,
  } = props;
  const cardHorizontalAlignStyleName = `cardh-horizontal-${props.horizontalAlign}`;
  const aosFade = props.getFadeTransiation();
  const mediaFade = props.getMediaTransiation();

  const contentStyle: {
    paddingTop: string;
    paddingBottom: string;
    paddingLeft: string;
    paddingRight: string;
    width?: string;
    marginRight: string;
  } = {
    paddingTop: props.contentPaddingTop,
    paddingBottom: props.contentPaddingBottom,
    paddingLeft: props.contentPaddingLeft,
    paddingRight: props.contentPaddingRight,
    marginRight: '',
  };

  if (props.cardLayout === CardLayout['text-media']) {
    contentStyle.marginRight = props.mediaDistance;
  }
  contentStyle.width = props.contentWidth;

  const textColorStyleName = `cardh-content-text-color-${props.textColor}`;

  const hasLegalTag = props.hasTag && props.tagText;
  const hasIcons = hasIcons1 || hasIcons2;

  return (
    <div
      key="content"
      style={contentStyle}
      className={`cardh-card-content cardh-size-${props.contentSize} ${textColorStyleName} ${cardHorizontalAlignStyleName}`}
    >
      {hasToppingImage && (
        <img
          data-aos={mediaFade}
          data-aos-once
          src={getAsset(props.toppingImage)}
          className="cardh-topping-img"
          style={{ height: props.toppingImageHeight }}
          alt={getAsset(props.toppingImage) ? filterHtmlTag(props.toppingAlt) : ''}
        />
      )}
      {hasLegalTag && <Tag {...props} aosFade={aosFade} />}
      {hasEyeBrow && <Eyebrow {...props} aosFade={aosFade} />}
      {hasHeadline && <Headline {...props} aosFade={aosFade} />}
      {hasSubHeadline && (
        <SubHeadline {...props} aosFade={aosFade} />
      )}
      {hasDividedLine && <Divider {...props} aosFade={aosFade} />}
      {hasDescription && <Description {...props} aosFade={aosFade} />}
      {hasCardLinks && <Buttons {...props} aosFade={aosFade} />}
      {hasNote && <Note {...props} aosFade={aosFade} />}
      {hasIcons && <Icons {...props} aosFade={aosFade} />}
    </div>
  );
});
