import React from "react";
import SbEditable from "storyblok-react";
import { Controller, Scene } from '../../../lib/ScrollMagic';
import { Tween } from 'react-gsap';
import LazyLoad from 'react-lazyload';
import "./viewfinder.css";
import { hasDocument, runAfterWindowLoaded, shouldMobileVideoDowngrade, hasWindow } from '../../utils/helper';
import _debounce from 'lodash.debounce';
import { prefetchPathname } from "gatsby";
import Visibility from "react-visibility-sensor";
import VideoPlayer from "../atoms/video";
import ButtonRow from "./button_row";
import MarkDown from "./markdown";


export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      descBoxHeight: 200,
      framewidth: 0,
      navHeight: 104,
      isDowngrade: false,
      screenHeight: 580,
      marginLeftOfVideo: 0,
      marginTopOfVideo: 0,
      widthOfVideo: 0,
      heightOfVideo: 0,
      replayBtnMarginleft: 0,
    }
    this.imageDom = null;
    this.vtImageDom = null;
    this.onResize = this.onResize.bind(this);
    this.textBox = null;
    this.marginLeftRate = 0.1;
    this.marginTopRate = 0.445;
    this.videoWidthRate = 0.49;
    this.videoHeightRate = 0.57;
    this.scaleOfImage = 1.4789;
    this.replayMarginleft = 0.52;
  }

  componentDidMount() {        
    this.onResize();
    this.debounceResize = _debounce(this.onResize, 300);
    if(typeof window !== 'undefined'){
      window.addEventListener('resize', this.debounceResize);
    }
    this.setState({
      isDowngrade: shouldMobileVideoDowngrade(),
    })
    this.handleContainerFadeIn();
  }

  // 判断组件顶部是否进入视窗
  handleContainerFadeIn() {
    if (!hasDocument && !hasWindow) return;
      const scrollListener = () =>{
        if(window.pageYOffset + window.innerHeight> this.imageDom?.offsetTop){          
          const stickyHeight = typeof document != 'undefined' ? document.getElementById('local-navigation') : null;      
          this.setState({
            navHeight: stickyHeight?.clientHeight || (this.state.isMobile ? 104 : 64)
          })
          const windowInnerHeight = typeof window != 'undefined' ? (window.innerHeight - this.state.navHeight) : 607;
          const imagedom = document.getElementById('imageOfVideo');
          const widthOfImage = this.scaleOfImage * windowInnerHeight * 0.8;
          this.setState((prevState) => {
            return {
              descBoxHeight: this.textBox?.clientHeight,
              widthOfVideo: widthOfImage * this.videoWidthRate,
              heightOfVideo: windowInnerHeight * 0.8 * this.videoHeightRate,
              marginLeftOfVideo: widthOfImage * this.marginLeftRate,
              marginTopOfVideo: windowInnerHeight * 0.8 * this.marginTopRate,
              screenHeight: this.state.isMobile ? Math.max(windowInnerHeight,prevState.screenHeight) : windowInnerHeight,
              replayBtnMarginleft: widthOfImage * this.replayMarginleft,
            }
          })
          window.removeEventListener("scroll", scrollListener);
        }
      }
      window.addEventListener("scroll", scrollListener)
  }
  

  onResize() {
    if (!hasWindow) {
      return;
    }
    const targetDom = typeof document === "undefined" ? '' : document.body;
    const windowInnerHeight = typeof window != 'undefined' ? (window.innerHeight - this.state.navHeight) : 607;
    const obj = targetDom.getBoundingClientRect();
    const { framewidth } = this.state;
    const newFramewidth = typeof document != 'undefined' ? document.body.clientWidth : 0;
    if (framewidth !== newFramewidth) {
      this.setState({
        framewidth: newFramewidth,
      });
    }
    const widthOfImage = this.scaleOfImage * windowInnerHeight * 0.8;
    this.setState((prevState) => {
      return {
        isMobile: obj.width <= 768,
        descBoxHeight: this.textBox?.clientHeight,
        widthOfVideo: widthOfImage * this.videoWidthRate,
        heightOfVideo: windowInnerHeight * 0.8 * this.videoHeightRate,
        marginLeftOfVideo: widthOfImage * this.marginLeftRate,
        marginTopOfVideo: windowInnerHeight * 0.8 * this.marginTopRate,
        screenHeight: this.state.isMobile ? Math.max(windowInnerHeight,prevState.screenHeight) : Math.max(windowInnerHeight, 580),
        replayBtnMarginleft: widthOfImage * this.replayMarginleft,
      }
    })
    
  }

  // 计算裁切ClipPath的函数
  renderClipPath(progress, cameraDisappear, text08Appear, text08Loaded, text09Appear, text10Appear,icon08, icon09,icon10) {
    let clipPath, clipPathMobile;
    let icon08Opacity,icon09Opacity,icon10Opacity;
    const framewidth = typeof document != 'undefined' ? document.body.clientWidth : 0;
    const frameheight = this.state.screenHeight;
    const frameheightMobile = this.state.screenHeight;
    const originalHeight = 7680 * frameheight / framewidth;
    let leny = 742.5 / originalHeight * frameheight;
    const leny2 = 742.5 / 4320 * frameheight;
    // 获取较大值作为裁切值
    leny = Math.max(leny, leny2);
    const lenx = leny * 921 / 742.5;
    const originalHeightMobile = 414 * frameheight / framewidth;
    let lenxMobile = 98 / 414 * framewidth;
    const lenxMobile2 = 98 / 793 * frameheight;
    lenxMobile = Math.max(lenxMobile, lenxMobile2);
    const lenyMoblie = lenxMobile * 78 / 98;
    if (progress >= text08Appear && progress <= text09Appear) {
      // 在第一幅的时候，采用当前0.8x的clippath
      icon08Opacity = 'block';
      icon09Opacity = 'none';
      icon10Opacity = 'none';
    } else if (progress >= text09Appear && progress <= text10Appear) {
      icon08Opacity = 'none';
      icon09Opacity = 'block';
      icon10Opacity = 'none';
    } else if (progress >= text10Appear) {
      icon08Opacity = 'none';
      icon09Opacity = 'none';
      icon10Opacity = 'block';
    } else {
      icon08Opacity = 'none';
      icon09Opacity = 'none';
      icon10Opacity = 'none';
    }

    // PC + M clippath计算
    if (progress <= cameraDisappear) {
      clipPathMobile = `inset(${(frameheightMobile - ((1 + 10 * progress) * lenyMoblie)) / 2}px ${(framewidth - ((1 + 10 * progress) * lenxMobile)) / 2}px round 1%)`;
      clipPath = `inset(${(frameheight - ((1 + 10 * progress) * leny)) / 2}px ${(framewidth - ((1 + 10 * progress) * lenx)) / 2}px round 1%)`;
    } else if (progress <= text08Appear) {
      clipPathMobile = `inset(${((frameheightMobile - ((1 + 10 * 0.07) * lenyMoblie)) / 2) * (text08Appear - progress) / 0.02 + 50 * (progress - cameraDisappear) / 0.02}px ${((framewidth - ((1 + 10 * progress) * lenxMobile)) / 2) * (text08Appear - progress) / 0.02 + 40 * (progress - cameraDisappear) / 0.02}px round 0%)`;
      clipPath = `inset(${((frameheight - ((1 + 10 * progress) * leny)) / 2) * (text08Appear - progress) / 0.02 + 35 * (progress - cameraDisappear) / 0.02}px ${((framewidth - ((1 + 10 * progress) * lenx)) / 2) * (text08Appear - progress) / 0.02 + 60 * (progress - cameraDisappear) / 0.02}px round 0%)`;
    } else if (progress <= text08Loaded) {
      clipPathMobile = `inset(50px 40px round 0%)`;
      clipPath = `inset(35px 60px round 0%)`;
    } else if (progress <= text09Appear) {
      clipPathMobile = `inset(${50 * (text09Appear - progress) / 0.02 + 30 * (progress - text08Loaded) / 0.02}px ${40 * (text09Appear - progress) / 0.02 + 20 * (progress - text08Loaded) / 0.02}px round 0%)`;
      clipPath = `inset(${35 * (text09Appear - progress) / 0.02 + 20 * (progress - text08Loaded) / 0.02}px ${60 * (text09Appear - progress) / 0.02 + 50 * (progress - text08Loaded) / 0.02}px round 0%)`;
    } else if (progress <= text10Appear) {
      clipPathMobile = `inset(${30 * (text10Appear - progress) / 0.02}px ${20 * (text10Appear - progress) / 0.02}px round 0%)`;
      clipPath = `inset(${20 * (text10Appear - progress) / 0.02}px ${50 * (text10Appear - progress) / 0.02}px round 0%)`;
    } else {
      clipPathMobile = `inset(0px 0px round 0%)`;
      clipPath = `inset(0px 0px round 0%)`;
    }
    return { clipPath, clipPathMobile, icon08Opacity, icon09Opacity, icon10Opacity };
  }

  // 计算最后文案结束时 画面（背景图片） 的opacity
  renderOpacity(progress, landDisappearStar, landDisappearEnd) {
    let opacity;
    if (progress <= landDisappearStar) {
      opacity = 1;
    } else if (progress <= landDisappearEnd) {
      opacity = 1 - (progress - landDisappearStar) / (landDisappearEnd - landDisappearStar);
    } else {
      opacity = 0;
    }
    return opacity;
  }

  // 计算PC video-text文字的translate 和 opacity
  renderVideoText(progress, landDisappearStar, landDisappearEnd) {
    let opacity;
    let translateY;
    if (progress < landDisappearStar) {
      opacity = 0;
      translateY = 0;
    } else if (progress <= landDisappearEnd) {
      opacity = (progress - landDisappearStar) / (landDisappearEnd - landDisappearStar);
      translateY = `${(1 - ((progress - landDisappearStar) / (landDisappearEnd - landDisappearStar))) * 30}px`;
    } else {
      opacity = 1;
      translateY = 0;
    }
    return { opacity, translateY };
  }

  // 计算文字的translateY,opacity,z-index
  renderText(progress, text08Appear, text08Loaded, text09Appear, text10Disappear) {
    let translateY;
    let opacity;
    if (progress <= text08Appear) {
      translateY = 0;
      opacity = 0;
    } else if (progress <= text08Loaded) {
      translateY = `${(1 - ((progress - text08Appear) / (text08Loaded - text08Appear))) * 30}px`;
      opacity = (progress - text08Appear) / (text08Loaded - text08Appear);
    } else if (progress <= text09Appear) {
      translateY = 0;
      opacity = 1;
    } else if (progress <= text10Disappear) {
      translateY = 0;
      opacity = 1;
    } else {
      // 0.47 到0.51 之间让所有文字消失
      // translateY = `-${((progress - text10Disappear)/0.04) * 50}px`;
      opacity = (1 - (progress - text10Disappear) / 0.04);
    }
    return { opacity };
  }

  // 视频文字切换的第一段文字
  renderText1(blok) {
    return (
      <div className="ec1706--viewfiner-render-text1">
        <div className="vtBox-1-title" dangerouslySetInnerHTML={MarkDown(blok.descTitle)} />
        <p className="vtBox-1-desc" dangerouslySetInnerHTML={MarkDown(blok.descContent1)}></p>
        <div className="pct-box">
          <div>
            <div className="viewfiner-title-icon">
              <LazyLoad offset={2000} once>
                <img src={blok.pencent1 && blok.pencent1?.filename} />
              </LazyLoad>
            </div>
            <div className="vtBox-1-icon-desc-1" style={{ fontFamily: 'AvenirNextLTW01-Regular', fontSize: '16px', lineHeight: '24px',fontWeight:'600' }} dangerouslySetInnerHTML={MarkDown(blok.pencent1Desc)}></div>
          </div>
          <div style={{ paddingLeft: '10px' }}>
            <div className="viewfiner-title-icon">
              <LazyLoad offset={2000} once>
                <img src={blok.pencent2 && blok.pencent2?.filename} />
              </LazyLoad>
            </div>
            <div className="vtBox-1-icon-desc-2" style={{ fontFamily: 'AvenirNextLTW01-Regular', fontSize: '16px', lineHeight: '24px',fontWeight:'600' }} dangerouslySetInnerHTML={MarkDown(blok.pencent2Desc)}></div>
          </div>
        </div>
      </div>
    )
  }

  // 视频文字切换第一段文字的动画
  renderText1Animation(progress, videoText1Disappear, videoText2Loaded) {
    let opacity = 1, translateY = 0;
    if (progress < videoText1Disappear) {
      translateY = 0;
      opacity = 1;
    } else if (progress < videoText2Loaded) {
      translateY = `-${((progress - videoText1Disappear) / (videoText2Loaded - videoText1Disappear)) * 50}px`;
      opacity = (1 - (progress - videoText1Disappear) / (videoText2Loaded - videoText1Disappear));
    } else {
      translateY = 0;
      opacity = 0;
    }
    return { opacity, translateY };
  }

  // 视频文字切换的第一段文字
  renderText2(blok) {
    return (
      <div className="ec1706--viewfiner-render-text2" dangerouslySetInnerHTML={MarkDown(blok.descContent2)}>
      </div>
    )
  }

  // 视频文字切换第二段文字的动画
  renderText2Animation(progress, videoText2Loaded, video2Appear) {
    let opacity = 0, translateY = 0;
    if (progress < videoText2Loaded) {
      opacity = 0;
    } else if (progress <= video2Appear) {
      opacity = (progress - videoText2Loaded) / (video2Appear - videoText2Loaded);
    } else opacity = 1;
    return { opacity, translateY };
  }

  // M端title出现并划走动画
  renderTitleMobileAnimation(progress, landDisappearStar, landDisappearEnd, videTitleDisappear, videoText1MoibleLoaded) {
    let opacity = 0;
    if (progress <= landDisappearEnd) {
      opacity = 0;
    } else if (progress <= Number(landDisappearEnd) + 0.06) {
      opacity = (progress - landDisappearEnd) / 0.06;
    } else if (progress <= videTitleDisappear) {
      opacity = (videTitleDisappear - progress) / (videTitleDisappear - landDisappearEnd - 0.06);
    } else {
      opacity = 0;
    }
    return { opacity };
  }

  // 文案1单独出现的动画
  renderText1MobileAnimation(progress, videoText1MoibleAppear, videoText1MoibleLoaded, Text1MoibleDisappearStar, Text2MoibleAppearStar, Text1MoibleDisappearEnd) {
    let opacity = 0,translateY = 0 ;
    if (progress <= videoText1MoibleAppear) {
      opacity = 0;
    } else if (progress <= videoText1MoibleLoaded) {
      opacity = (progress - videoText1MoibleAppear) / 0.02;
    } else if (progress <= Text1MoibleDisappearStar) {
      opacity = 1;
    } else if (progress <= Text1MoibleDisappearEnd - 0.05) {
      opacity = 1;
      translateY = `-${((progress - Text1MoibleDisappearStar)/0.05) * 10}px`;
    } else if(progress <= Text1MoibleDisappearEnd) {
      opacity = (Text1MoibleDisappearEnd - progress) / 0.05;
      translateY = `-${((progress - Text1MoibleDisappearStar)/0.05) * 10}px`;
    }else {
      opacity = 0;
      translateY = 0;
    }
    return { opacity, translateY };
  }

  // 文案2单独出现的动画
  renderText2MobileAnimation(progress, Text2MoibleAppearStar, Text2MoibleAppearEnd) {
    let opacity = 0, translateY = 0;
    if (progress < Text2MoibleAppearStar) {
      opacity = 0;
      // 出现
    } else if (progress <= Text2MoibleAppearEnd) {
      opacity = (progress - Text2MoibleAppearStar) / 0.02;
      // 保持
    } else if (progress <= 0.66) {
      opacity = 1;
      // 消失
    } else {
      opacity = 1;
    }
    return { opacity,translateY };
  }

  render() {
    const { blok, lang } = this.props;
    const cameraDisappear = 0.07, text08Appear = 0.09, text08Loaded = 0.11, text09Appear = 0.13,
    text10Appear = 0.15, desc10Appear = 0.17;
    const { text10Disappear = 0.21, landDisappearStar = 0.25,
      landDisappearEnd = 0.50, videoText1Disappear = 0.60, videoText2Loaded = 0.70, videTitleDisappear = 0.70, videoText1MoibleAppear = 0.58, videoText1MoibleLoaded = 0.75,
      video2Appear = 0.80, Text1MoibleDisappearStar = 0.80, Text1MoibleDisappearEnd = 0.85, Text2MoibleAppearStar = 0.85, Text2MoibleAppearEnd = 0.90 ,
      animationLengthPC, animationLengthMobile, mobileTitleTop , icon08, icon09, icon10 } = blok;   
    
    const replayBtn = (blok?.replayButton && blok?.replayButton?.filename) || '/assets/icons/play.svg';
    const showVideo = blok.videoLink && blok.videoLink.filename && !this.state.isDowngrade;
    const { isMobile } = this.state;
    const ImgWidth = typeof document != "undefined" ? document.body.clientWidth : 0;
    const ImgHeight = ImgWidth / 7680 * 4320;
    const ImgWidth2 = ImgWidth / 3;
    const ImgWidth3 = ImgWidth / 414 * 320;
    const ImgHeightMoblie = ImgWidth / 414 * 793;  
      
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div id="ec1706-viewfinder">
          <Controller>
            <Scene
              offset={0}
              triggerHook={0}
              duration={3000}
              triggerElement="#ec1706-viewfinder"
            >
              {
                (progress) => {                                    
                  const obj = this.renderClipPath(progress, cameraDisappear, text08Appear, text08Loaded, text09Appear, text10Appear, icon08, icon09, icon10);
                  const clipPath = obj.clipPath;
                  const clipPathMobile = obj.clipPathMobile;
                  const icon08Opacity = obj.icon08Opacity;
                  const icon09Opacity = obj.icon09Opacity;
                  const icon10Opacity = obj.icon10Opacity;

                  return (
                    <div className="bg-box" 
                      style={{
                        height:this.state.isMobile ? (animationLengthMobile ? animationLengthMobile + 'px' : '3900px') : (animationLengthPC ? animationLengthPC + 'px': '4000px'),
                        backgroundColor:!progress ? '' : (progress >= landDisappearStar ? '#FFF' : '#000')
                      }}>
                      <div className="outsite-box" ref={(elm) => { this.imageDom = elm; }} style={{ height: this.state.screenHeight, top: this.state.isMobile ? this.state.navHeight-1 : this.state.navHeight }}>
                        {/* 相机图片 */}
                        <div className="ec-1706-viewfinder"
                          style={{
                            backgroundColor:!progress ? '' : (progress >= landDisappearStar ? '#FFF' : '#000')
                          }}
                        >
                          <Tween
                            duration={0}
                            ease="none"
                            to={{
                              opacity: (cameraDisappear - progress) / cameraDisappear,
                              scale: 1 + progress * 10,
                            }}
                          >
                            <div style={{ height: '100%' }}>
                              <LazyLoad offset={3000} once className="lazyloadstyle">
                                <img className="bgimg" alt="" src={blok.bgimgMobile.filename}  style={{display: this.state.isMobile ? 'block' : 'none'}} />
                                <img className="bgimg" alt="" src={blok.bgimg.filename} style={{opacity: this.state.isMobile ? 'none' : 'block'}} />
                              </LazyLoad>
                            </div>
                          </Tween>
                        </div>

                        {/* 风景图片裁切 */}
                        <div className="land-box" >
                          <Tween
                            duration={0}
                            ease="none"
                            from={{ filter: `blur(20px)` }}
                            to={{
                              clipPath: this.state.isMobile ? clipPathMobile : clipPath,
                              filter: `blur(0px)`
                            }}>
                            {/* 风景图片 */}
                            <div style={{ height: '100%' }}>
                              <Tween
                                duration={0}
                                ease="none"
                                to={{
                                  // 风景图片渐变消失
                                  // scale: 1 - ((0.343 - progress) / 0.343) ,
                                  opacity: this.renderOpacity(progress, landDisappearStar, landDisappearEnd),
                                }}
                              >
                                {/* 风景图片 landscape */}
                                <div style={{ height: '100%' }}>
                                  <LazyLoad offset={3000} once className="lazyloadstyle">
                                    <img className="landscape" src={this.state.isMobile ? blok.landMobile.filename : blok.land.filename} />
                                  </LazyLoad>
                                </div>
                              </Tween>
                            </div>
                          </Tween>
                          {/* 文字 */}
                          <div style={{ marginTop: `-${this.state.screenHeight}px`}} className="text-outside-box">
                            <Tween
                              duration={0}
                              ease="none"
                              to={this.renderText(progress, text08Appear, text08Loaded, text09Appear, text10Disappear)}
                            >
                              <div className="text-box" >
                                {/* 第一段文字的box */}
                                <div className="box-first" style={{ paddingTop: progress >= desc10Appear ? 0 : `${this.state.descBoxHeight}px` }}>
                                  {/* 第一段文字为0.x 第二段文字为EVF */}
                                  <div className="text-1-box">
                                    <LazyLoad offset={2000} once>
                                      <img src={icon08?.filename} style={{display: icon08Opacity}} />
                                      <img src={icon09?.filename} style={{display: icon09Opacity}}/>
                                      <img src={icon10?.filename} style={{display: icon10Opacity}} />
                                    </LazyLoad>
                                  </div>
                                  <span className="text-2"  dangerouslySetInnerHTML={MarkDown(blok.text)}></span>
                                </div>
                                {/* 描述文字的box */}
                                <div className="box-desc" style={{ width: this.state.isMobile ? ImgWidth3 : ImgWidth2 }} ref={(elm) => { this.textBox = elm; }}>
                                  <Tween
                                    duration={0}
                                    ease='none'
                                    to={{
                                      // 让desc在0.43 到 0.45渐变出现
                                      opacity: progress >= desc10Appear ? (progress - desc10Appear) / 0.02 : 0,
                                    }}
                                  >
                                    {/* 计算其高度 */}
                                    {this.state.isMobile ? <p dangerouslySetInnerHTML={MarkDown(blok.descMobile)}></p> : <p dangerouslySetInnerHTML={MarkDown(blok.desc)}></p>}
                                  </Tween>
                                </div>
                              </div>
                            </Tween>
                          </div>
                        </div>

                        {/* pc端视频文字切换 */}
                        {
                          this.state.isMobile ?
                            <></>
                            :
                            // 视频文字切换 
                            <div className="videoText"
                              style={{
                                zIndex: 2,
                              }
                              }>
                              <Tween
                                duration={0}
                                ease="none"
                                to={{
                                  // 假设从0,52 到 0.54之间去实现opacity的变化
                                  opacity: progress >= landDisappearStar ? (progress - landDisappearStar) / 0.02 : 0,
                                  zIndex: progress >= landDisappearEnd ? 2 : 0,
                                }}
                              >
                                <div className="text-video-box">
                                  <Tween
                                    duration={0}
                                    ease="none"
                                    to={this.renderVideoText(progress, landDisappearStar, landDisappearEnd)}
                                  >
                                    <div className="vtBox">
                                      <div className="vtBox-1" >
                                        <div style={{display: progress <= videoText2Loaded ? 'block' : 'none'}}>
                                          <Tween
                                            duration={0}
                                            ease="none"
                                            to={this.renderText1Animation(progress, videoText1Disappear, videoText2Loaded)}
                                          >
                                            {this.renderText1(blok)}
                                          </Tween>
                                        </div>
                                        <div style={{display: progress <= videoText2Loaded ? 'none' : 'block'}}>
                                          <Tween
                                            duration={0}
                                            ease="none"
                                            to={this.renderText2Animation(progress, videoText2Loaded, video2Appear)}
                                          >
                                            {this.renderText2(blok)}
                                          </Tween>
                                        </div>
                                      </div>
                                      {/* 视频替换 */}
                                      {/* style={{ backgroundImage: `url(${blok.caremaBg.filename})` }} */}
                                      <div className="vtBox-2" >
                                        <div className="ec1706-viewfinder-imgbg">
                                          <LazyLoad offset={3000} once className="lazyLoad-videotext-imgbg" style={{position: "absolute"}}>
                                            <img src={blok.caremaBg.filename} alt=""  id="imageOfVideo"  />
                                          </LazyLoad>
                                          <LazyLoad offset={3000} once style={{height: "100%", position: "relative"}}>                                          
                                          <VideoPlayer
                                            style={{ 
                                              height: '100%', 
                                              width: '100%',
                                              opacity: progress < videoText2Loaded - (videoText2Loaded - videoText1Disappear) / 2 ? 1 : 0,
                                              position: "absolute",
                                            }}
                                            // showWholeElmPlay
                                            triggerPlay={progress > landDisappearEnd}
                                            replayWhenRevisible
                                            replayClassName='video-replay-btn'
                                          >
                                            <div className="VT-video">
                                              <video 
                                                style={{marginLeft : this.state.marginLeftOfVideo, marginTop: this.state.marginTopOfVideo, height: this.state.heightOfVideo, width: this.state.widthOfVideo }}
                                                muted 
                                                poster={blok?.headerImage?.filename} 
                                                src={blok?.videoLink?.filename} 
                                              >
                                              </video>
                                              <button className="video-replay-btn" 
                                                style={{
                                                  backgroundImage:`url(${replayBtn})`,
                                                  backgroundRepeat:'no-repeat',
                                                  backgroundSize:'cover',
                                                  display:progress < landDisappearEnd ? 'none' : '',
                                                  marginLeft:this.state.replayBtnMarginleft,
                                                  bottom: this.state.heightOfVideo * 0.1,
                                                }}>
                                                <div style={{height:'100%'}}></div>
                                              </button>
                                            </div>
                                          </VideoPlayer>
                                          <VideoPlayer
                                            style={{ 
                                              height: '100%', 
                                              width: '100%',
                                              opacity: progress < videoText2Loaded - (videoText2Loaded - videoText1Disappear) / 2 ? 0 : 1,
                                              position: "absolute",
                                            }}
                                            // showWholeElmPlay
                                            triggerPlay={progress >= videoText2Loaded}
                                            replayWhenRevisible
                                            replayClassName='video-replay-btn'
                                          >
                                            <div className="VT-video">
                                              <video 
                                              style={{marginLeft : this.state.marginLeftOfVideo, marginTop: this.state.marginTopOfVideo, height: this.state.heightOfVideo, width: this.state.widthOfVideo }}
                                              muted 
                                              autoPlay 
                                              poster={blok?.headerImage2?.filename} 
                                              src={blok?.videoLink2?.filename} 
                                              >
                                              </video>
                                              <button className="video-replay-btn" 
                                                style={{
                                                  backgroundImage:`url(${replayBtn})`,
                                                  backgroundRepeat:'no-repeat',
                                                  backgroundSize:'cover',
                                                  display:progress < videoText2Loaded ? 'none' : '',
                                                  marginLeft:this.state.replayBtnMarginleft,
                                                  bottom: this.state.heightOfVideo * 0.1,
                                                }}>
                                                <div style={{height:'100%'}}></div>
                                              </button>
                                            </div>
                                          </VideoPlayer>
                                          </LazyLoad>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </Tween>
                                </div>
                              </Tween>
                            </div>
                        }
                      </div>

                      {/* M端title */}
                      {
                        this.state.isMobile ?
                          (
                            <div style={{backgroundColor: "#000"}}>
                              <Tween
                                duration={0}
                                ease="none"
                                to={this.renderTitleMobileAnimation(progress, landDisappearStar, landDisappearEnd, videTitleDisappear, videoText1MoibleAppear, videoText1MoibleLoaded)}
                              >
                                <div className="mobile-title" style={{marginTop:`${(mobileTitleTop || 1750) - this.state.screenHeight}px`}} dangerouslySetInnerHTML={MarkDown(blok.descTitleMobile)}></div>
                              </Tween>
                            </div>
                          ) : null
                      }

                      {/* M端视频文字切换 */}
                      {
                        this.state.isMobile ?
                          (
                            <div className="outsite-box2" style={{ height: this.state.screenHeight, top: this.state.navHeight }}>
                              <div style={{ height: '100%' }}>
                                <div className="videoTextMobile"
                                  style={{
                                    zIndex: progress >= landDisappearEnd ? 2 : 0,
                                  }}
                                >
                                  <Tween
                                    duration={0}
                                    ease="none"
                                    to={{
                                      opacity: progress >= landDisappearEnd ? (progress - landDisappearEnd) / 0.06 : 0,
                                      zIndex: progress >= landDisappearEnd ? 2 : 0,
                                    }}
                                  >
                                    <div className="text-video-box-mobile">
                                      <div className="vtBox-mobile">
                                        {/* 视频和desc */}
                                        <div className="vtBox-mobile-box">
                                          <div className="vtBox-mobile-1" style={{ backgroundImage: `url(${blok.caremaBgMobile.filename})` }}>
                                            {
                                              showVideo ? (
                                                <LazyLoad offset={3000} once>
                                                  <VideoPlayer
                                                    style={{ 
                                                      height: '100%', 
                                                      width: '100%',
                                                      display: progress <= Text2MoibleAppearStar ? '' : 'none',
                                                    }}
                                                    replayClassName='video-replay-btn-mobile'
                                                    triggerPlay={progress > landDisappearStar}
                                                    replayWhenRevisible
                                                  >
                                                    <div className="video-mobile">
                                                      <video
                                                        muted
                                                        poster={blok?.headerImageMobile?.filename} 
                                                        src={blok?.videoLink?.filename}
                                                      >
                                                      </video>
                                                      <button className="video-replay-btn-mobile" 
                                                        style={{
                                                          backgroundImage:`url(${replayBtn})`,
                                                          backgroundRepeat:'no-repeat',
                                                          backgroundSize:'cover',
                                                          display:progress < landDisappearEnd ? 'none' : '',
                                                        }}></button>
                                                    </div>
                                                  </VideoPlayer>
                                                  <VideoPlayer
                                                    style={{ 
                                                      height: '100%', 
                                                      width: '100%',
                                                      display: progress <= Text2MoibleAppearStar ? 'none' : '',
                                                    }}
                                                    replayClassName='video-replay-btn-mobile'
                                                    triggerPlay={progress > Text2MoibleAppearStar}
                                                    replayWhenRevisible
                                                  >
                                                    <div className="video-mobile">
                                                      <video
                                                        muted
                                                        poster={blok?.headerImageMobile2?.filename} 
                                                        src={blok?.videoLink2?.filename}
                                                      >
                                                      </video>
                                                      <button className="video-replay-btn-mobile" 
                                                        style={{
                                                          backgroundImage:`url(${replayBtn})`,
                                                          backgroundRepeat:'no-repeat',
                                                          backgroundSize:'cover',
                                                          display:progress < Text2MoibleAppearStar ? 'none' : '',
                                                        }}></button>
                                                    </div>
                                                  </VideoPlayer>
                                                </LazyLoad>
                                              ) : (blok.popupVideoBtn && blok.popupVideoBtn.length > 0 ? (
                                                <>
                                                  <div className="video-mobile" style={{display: progress <= Text2MoibleAppearStar ? 'block' : 'none'}}><img  src={blok.headerImageMobile?.filename} /></div>
                                                  <div className="video-mobile" style={{display: progress <= Text2MoibleAppearStar ? 'none' : 'block'}}><img  src={blok.headerImageMobile2?.filename} /></div>
                                                  <div className="play-icon-video-downgrade">
                                                    {progress <= Text2MoibleAppearStar ? <ButtonRow content={{ buttons: blok.popupVideoBtn.slice(0, 1) }} /> : <ButtonRow content={{ buttons: blok.popupVideoBtn.slice(1) }} />}
                                                  </div>
                                                </>
                                              ) : null)
                                            }
                                          </div>
                                          <div className="vtBox-mobile-2">
                                            <div style={{display: progress < Text2MoibleAppearStar ? 'block' : 'none'}}>
                                              <Tween
                                                duration={0}
                                                ease="none"
                                                to={this.renderText1MobileAnimation(progress, videoText1MoibleAppear, videoText1MoibleLoaded, Text1MoibleDisappearStar, Text2MoibleAppearStar,Text1MoibleDisappearEnd)}
                                              >
                                                <div>
                                                  <div className="vtBox-mobile-2-desc" style={{ lineHeight: '24px' }} dangerouslySetInnerHTML={MarkDown(blok.descContentMobile1)}></div>
                                                  <div className="pct-box">
                                                    <div>
                                                      <div className="viewfiner-title-icon-mobile">
                                                      <LazyLoad offset={2000} once>
                                                        <img src={blok.pencent1 && blok.pencent1?.filename} />
                                                      </LazyLoad>
                                                      </div>
                                                      <div className="viewfiner-icon-text-mobile" dangerouslySetInnerHTML={MarkDown(blok.pencent1Desc)} />
                                                    </div>
                                                    <div style={{ paddingLeft: '16px' }}>
                                                      <div className="viewfiner-title-icon-mobile">
                                                        <LazyLoad offset={2000} once>
                                                          <img src={blok.pencent1 && blok.pencent2?.filename} />
                                                        </LazyLoad>
                                                      </div>
                                                      <div className="viewfiner-icon-text-mobile" dangerouslySetInnerHTML={MarkDown(blok.pencent2Desc)} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </Tween>
                                            </div>
                                            <div style={{display: progress < Text2MoibleAppearStar ? 'none' : 'block'}}>
                                              <Tween
                                                duration={0}
                                                ease="none"
                                                to={this.renderText2MobileAnimation(progress, Text2MoibleAppearStar, Text2MoibleAppearEnd)}
                                              >
                                                <div>
                                                  <div className="vtBox-mobile-2-desc-2" style={{ lineHeight: '24px' }} dangerouslySetInnerHTML={MarkDown(blok.descContentMobile2)}></div>
                                                </div>
                                              </Tween>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tween>
                                </div>
                              </div>
                            </div>) : null
                      }
                    </div>
                  )
                }
              }
            </Scene>
          </Controller>
        </div>
      </SbEditable>
    );
  }
}

