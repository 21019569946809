import Dimension from './dimension';
import {
  handleNodeIntersection, handleNodeInView, isInView, isSupportIntersectionObserver,
} from './isInView';
import ScrollNode, { ScrollListener, IScrollNode } from './scrollNode';

/**
 * 滚动管理器
 * 已实现：
 * 1.记录全局滚动位置
 * 2.缓存判断 ElementInView
 * 3.任务调度
 * 4.入屏离屏状态管理
 * 待实现：
 * 1.记录动画位置
 * 2.进度计算
 * 4.【重要】滚动节点刷新位置后重新排序, 跟懒加载一起做
 * 5.动态帧率控制，例如垂直同步
 */
export class ScrollManager {
  private static instance: ScrollManager;

  private scrollNodes: ScrollNode[] = []; // 所有需要检查 ElementInView 的节点

  private dimension: Dimension; // 视口位置、大小信息

  private isSupportIntersectionObserver = false;

  private checkElementTaskRunning = false; // 如果不支持 InterSectionObserver, 需要定时检查节点位置

  private performanceExp;

  constructor() {
    this.isSupportIntersectionObserver = isSupportIntersectionObserver();
    this.dimension = new Dimension();
  }

  /**
   * 懒单例
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ScrollManager();
    }
    return this.instance;
  }

  /**
   * 添加一个滚动元素
   * @param element 页面上的任意元素
   * @param callback 入/离屏回调
   * @param task 屏内定时任务
   * @param margin 入/离屏判定的间隔距离
   * @param allowRedundant 是否允许重复添加同一节点（动效组件存在这种情况）
   * @returns 是否成功添加
   */
  public addNode(element: HTMLElement, callback?: ScrollListener, task?: { (): void; }[], margin?: number, allowRedundant?: boolean): IScrollNode | null {
    if (!element) {
      console.warn('ScrollManager::addNode attempt adding an illegal element');
      return null;
    }
    const scrollNode = this.scrollNodes[this.findScrollNode(element)];
    if (!scrollNode || allowRedundant) {
      const nodeIndex = this.addScrollNode(element, callback, task);
      const node = this.scrollNodes[nodeIndex];
      if (!this.isSupportIntersectionObserver) {
        const inView = isInView(node, this.dimension);
        handleNodeInView(node, inView, this.dimension);
        if (task && !this.checkElementTaskRunning) {
          this.checkElementTaskRunning = true;
          this.runCheckElementTask();
        }
      } else {
        handleNodeIntersection(node, this.dimension, margin);
      }
      return node;
    }
    // 已经存在对应节点了, 刷新位置
    scrollNode.refreshPosition();
    return scrollNode;
  }

  public removeNode(element: HTMLElement): boolean {
    const nodeIndex = this.findScrollNode(element);
    if (nodeIndex !== -1) {
      this.scrollNodes.splice(nodeIndex, 1);
      return true;
    }

    return false;
  }

  /**
   * 为滚动元素添加待执行任务
   * @param element 已添加的滚动元素
   * @param task 待执行任务
   * @returns 是否成功添加
   */
  public addTask(element: HTMLElement, task: { (): void; }[]): boolean {
    const scrollNode = this.scrollNodes[this.findScrollNode(element)];
    if (scrollNode) {
      scrollNode.addTask(task);
      if (!this.checkElementTaskRunning && !this.isSupportIntersectionObserver) {
        this.checkElementTaskRunning = true;
        this.runCheckElementTask();
      }
      return true;
    }
    console.warn('ScrollManager::addTask cannot find target node');
    return false;
  }

  public getDimension(): Dimension {
    return this.dimension;
  }

  /**
   * 检查元素是否处于视口位置
   * @deprecated 不推荐直接使用该方法，应当调用 addNode + addTask 将滚动节点纳入任务管理
   * @param element 页面上的任意元素
   * @param useCache 是否使用缓存位置，默认 true，如果组件出现增删节点等位置必然变化的情况，需要传 false
   */
  public checkElementInView(element?: HTMLElement, useCache: boolean = true) {
    if (!element) {
      console.warn('ScrollManager::checkElementInView attempt checking an empty target element');
      return false;
    }

    const scrollNode = this.scrollNodes[this.findScrollNode(element)];
    if (!scrollNode) {
      console.warn('ScrollManager::checkElementInView attempt checking an illegal target element');
    }

    if (!useCache) {
      scrollNode.refreshPosition();
      const inView = isInView(scrollNode, this.dimension);
      handleNodeInView(scrollNode, inView, this.dimension);
    }

    return scrollNode.inView;
  }

  /**
   * 性能 AB 实验
   * A组: 无性能优化
   * B组: 有性能优化
   */
  public getPerformanceExpVersion() {
    if (!this.performanceExp && window) {
      if (window?.ABTest?.mapKeyToVar['performance-ab-exp-oa3']) {
        this.performanceExp = window?.ABTest?.mapKeyToVar['performance-ab-exp-oa3'];
      } else {
        this.performanceExp = 'B';
      }
    }
    return this.performanceExp;
  }

  /**
   * 如果不支持 IntersectionObserver, 需要实时获取滚动位置
   */
  private runCheckElementTask() {
    setTimeout(() => {
      const dimensionChanged = !this.dimension.isOffsetValid;
      if (dimensionChanged) {
        this.dimension.updateOffset();
      }
      for (let index = 0; index < this.scrollNodes.length; index++) {
        const node = this.scrollNodes[index];

        // 不支持 IntersectionObserver, 用滚动坐标判断是否处于可视范围
        const inView = isInView(node, this.dimension);
        handleNodeInView(node, inView, this.dimension);
      }
      this.runCheckElementTask();
    }, 150);
  }

  /**
   * 节点列表中新增一个节点
   * @return 节点的坐标
   */
  private addScrollNode(element: HTMLElement, callback?: ScrollListener, task?: { (): void; }[]): number {
    const node = new ScrollNode(element, callback, task);

    if (this.scrollNodes.length === 0) {
      this.scrollNodes.push(node);
      return 0;
    }
    let nodeIndex = -1;
    // 按 top 值从小到大排列节点
    this.scrollNodes.every((ele: ScrollNode, index: number) => {
      if (ele.top >= node.top) {
        this.scrollNodes.splice(index, 0, node);
        nodeIndex = index;
        return false;
      }
      const count = this.scrollNodes.length;
      if (index === count - 1) {
        // 遍历完仍然未插入，说明节点在最下面，直接 push
        nodeIndex = count;
        this.scrollNodes.push(node);
      }
      return true;
    });

    return nodeIndex;
  }

  /**
   * 查找节点列表中是否包含当前节点
   */
  private findScrollNode(element: HTMLElement): number {
    let scrollNodeIndex = -1;
    this.scrollNodes.every((node: ScrollNode, index: number) => {
      if (node.element.isSameNode(element)) {
        scrollNodeIndex = index;
        return false;
      }
      return true;
    });
    return scrollNodeIndex;
  }
}
