import React from "react"
import SbEditable from "storyblok-react"
import MarkDown from './markdown'

import "./table.css"

export default class extends React.Component {
  render() {
    const { blok } = this.props
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div className={`editorial`} >
            { blok.rows && blok.rows.map((row) => {
                return (  
                    React.createElement(Row, { key: blok._uid, row: row, width: blok.width })
                )
            })} 
        </div>
      </SbEditable>
    )
  }
}

const Row = class extends React.Component {
    render(){
        const { row, width } = this.props
        return (
            <SbEditable content={row}>

            <div className={`${width}`}>
                { row.title && 
                    <h3 className="title">{ row.title }</h3>            
                }
                <div className={ `tr`}>
                    
                {  row.cells && row.cells.map((cell) => {
                    return React.createElement(Cell, { key: row._uid, cell: cell }) 
                    })
                }
                </div>
            </div>
            </SbEditable>
        )
    }
}

const Cell = class extends React.Component {

    render(){
        const { cell, key } = this.props  
        return (
            <SbEditable content={cell}>
                <div key={key} className={`cell`} >
                    { cell.icon === 'check' &&
                        <img className="icon" src="/assets/icons/checkmark-black.svg" alt="Checkmark" />
                    }
                    { cell.icon === 'info' &&
                        <img className="icon" src="/assets/icons/info-black.svg" alt="Info" />
                    }
                    <div dangerouslySetInnerHTML={ MarkDown(cell.body) } ></div>
                </div>
            </SbEditable>
        )
    }

}