/* eslint-disable */
import * as React from 'react';
import SbEditable from "storyblok-react"
import VideoPlayer from '../../video';
import LazyWrapper from '../../utils/LazyWrapperScroll';
import { checkInView } from '../../../../utils/checkElementInView';
import { hasWindow, getAsset } from '../../../../utils/helper';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Card from './card';
import './style.scss';
import { StoryblokAsset } from '../InfoCardHorizontal/card/type';

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum ComponentVerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export interface Props {
  // 基础
  cardGapWidth: string;
  gridWidth: string;
  componentMinHeight: string;
  backgroundColor: string;
  backgroundGradient: string;
  anchorId?: string;

  // video
  videoLazyLoadDistance: boolean;

  // 背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundImageWidth?: string;
  backgroundImageHeight?: string;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundFixed?: BackgroundFixed;
  backgroundParallax?: boolean;

  // 背景视频
  hasBackgroundVideo: boolean;
  hasBannerVideo?: boolean;
  bannerVideoReplayOff?: boolean;
  bannerVideoWidth?: string;
  bannerVideoHeight?: string;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideoHoverPlay?: boolean;
  backgroundVideo?: StoryblokAsset;
  backgroundVideoPoster?: StoryblokAsset;
  backgroundVideoMaskColor?: string;
  backgroundVideoMaskOpacity?: string;
  backgroundVideoBackgroundSize?: string;

  // 内容对齐
  componentVerticalAlign: ComponentVerticalAlign;
  cardsStretch: boolean;

  // 内边距
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;

  // 模块过渡
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;

  // 卡片
  cardConfigList?: Array<Record<string, any>>;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;

  eventAction?: any;
}

export interface State {
  hovering?: boolean;
  videoWidth?: number;
  videoHeight?: number;
}

export default class InfoCard extends React.Component<Props, State> {

  videoDom: any;

  videoWrapper: any;

  static defaultProps = {
    cardGapWidth: '16px',
    gridWidth: '1200px',
    componentMinHeight: '',
    backgroundColor: '',
    backgroundGradient: '',

    // video 配置
    videoLazyLoadDistance: false,

    // 背景图片
    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundImageWidth: '',
    backgroundImageHeight: '',
    backgroundPosition: '',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,

    // 背景视频
    hasBackgroundVideo: false,
    hasBannerVideo: false,
    bannerVideoReplayOff: false,
    backgroundVideoLoop: false,
    backgroundVideoVisiblePlay: false,
    backgroundVideoHoverPlay: false,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',
    backgroundVideoBackgroundSize: '',

    // 内容对齐
    componentVerticalAlign: ComponentVerticalAlign['center-align'],
    cardsStretch: false,

    // 内边距
    paddingTop: '128px',
    paddingBottom: '128px',
    paddingLeft: '',
    paddingRight: '',

    // 模块过渡
    fadeTransiation: FadeTransiation.none,
    mediaTransiation: FadeTransiation.none,

    // 卡片
    cardConfigList: [Card.defaultProps],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      hovering: false,
      videoWidth: null,
      videoHeight: null,
    };
    this.getProps = this.getProps.bind(this);
    this.onResize = this.onResize.bind(this);

  }

  componentDidMount(): void {

    if (this.videoDom) {
      const source = this.videoDom.querySelectorAll('source');
      for (const element of source) {
        const src = element.dataset.layzrSrc;
        element.setAttribute('src', src);
      }
      this.videoDom.load();
      this.videoDom.currentTime = 0;
      this.videoDom.play();
      const {
        bannerVideoReplayOff, eventAction, hasBannerVideo,
      } = this.props;

      if (hasBannerVideo) {
        this.onResize();
        eventAction?.addCustomEventListener('resize', this.onResize);
      }
      // 首屏视频回屏自动播放
      if (hasBannerVideo && bannerVideoReplayOff) {
        checkInView({
          elm: this.videoDom,
          offset: 20,
          callback: ({ inView }) => {
            if (this.videoDom) {
              if (inView) {
                this.videoDom.play();
              } else {
                this.videoDom.currentTime = 0;
              }
            }
            return true;
          },
        });
      }
    }
  }

  componentWillUnmount() {
    const { eventAction, hasBannerVideo } = this.props;
    if (hasBannerVideo) {
      eventAction?.removeCustomEventListener('resize', this.onResize);
    }
  }

  getProps(prop: string): any {
    return this.props[prop];
  }

  onResize(): any {
    if (!hasWindow) {
      return;
    }
    const windowWidth = document.documentElement.clientWidth || window.innerWidth;
    const windowHeight = window.innerHeight;
    const {
      bannerVideoWidth = 2560,
      bannerVideoHeight = 1440,
    } = this.props;

    const videoRatio = +bannerVideoWidth / +bannerVideoHeight;
    const videoRatioWidth = windowHeight * videoRatio;
    const videoRatioHeight = windowWidth / videoRatio;
    const videoWidth = Math.max(windowWidth, videoRatioWidth);
    const videoHeight = Math.max(windowHeight, videoRatioHeight);

    this.setState({
      videoWidth,
      videoHeight,
    });
  }

  render(): JSX.Element {
    const { props } = this;
    const {
      hovering, videoWidth, videoHeight,
    } = this.state;
    const verticalAlignStyleName = `cardv-e-vertical-${props.componentVerticalAlign}`;
    const aosFade = props.fadeTransiation;
    const { visibleByDefault } = props;
    let backgroundImage;

    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
      backgroundAttachment?: string;
    } = { backgroundColor: props.backgroundColor };

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = getAsset(props.backgroundImage);
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;
      componentStyle.backgroundAttachment = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';
      if (props.backgroundGradient) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}, ${props.backgroundGradient}')`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    const componentWrapperStyle: {
      minHeight: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
      width: string;
    } = {
      minHeight: props.componentMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
      width: props.gridWidth,
    };

    const videoPosterAttr = {};
    videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster'] = getAsset(props.backgroundVideoPoster);
    const videoBgSizeAttr: React.HTMLAttributes<HTMLElement> = {};
    videoBgSizeAttr.style = props.backgroundVideoBackgroundSize === 'cover'
      ? {
        width: '100%', height: '100%', objectFit: 'cover',
      }
      : {};

    const cards = this.props.cardConfigList
      ? this.props.cardConfigList.map((card, index) => (
          <div
            key={index}
            style={{
              marginLeft: index === 0 ? 0 : this.props.cardGapWidth,
              height: props.cardsStretch ? "100%" : "",
            }}
            className="cardv-e-vertical-card-col"
          >
            <SbEditable content={card} key={(card && card._uid) || ""}>
              <Card
                {...card}
                visibleByDefault={this.getProps("visibleByDefault")}
                gaSend={this.getProps("gaSend")}
                getFadeTransiation={() => this.getProps("fadeTransiation")}
                getMediaTransiation={() => this.getProps("mediaTransiation")}
                getCardsStretch={() => this.getProps("cardsStretch")}
                key={`card-${index}`}
                index={index}
                videoLazyLoadDistance={props.videoLazyLoadDistance}
              />
            </SbEditable>
          </div>
        ))
      : null;
      
    return (
      <LazyWrapper
        type="bg"
        src={backgroundImage}
        visibleByDefault={visibleByDefault}
        bgWidth={props.backgroundImageWidth}
        bgHeight={props.backgroundImageHeight}
        parallax={props.hasBackgroundImage && props.backgroundParallax}
        className="atom-design-v1 cardv-hero-vertical-component"
        style={componentStyle}
        onMouseEnter={() => {
          this.setState({ hovering: true });
        }}
        onMouseLeave={() => {
          this.setState({ hovering: false });
        }}
      >
        {
          props.hasBackgroundVideo ? (
            props.hasBannerVideo ? (
              <div
                ref={(elm) => { this.videoWrapper = elm; }}
                className="background-video-wrapper cardv-background-video cardv-video-wrapper"
                style={{
                  backgroundImage: `url(${getAsset(props.backgroundVideoPoster)})`,
                  backgroundPosition: videoHeight ? 'center' : 'top left',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  width: videoWidth || '100%',
                  height: videoHeight || '100vh',
                  minHeight: props.componentMinHeight,
                }}
              >
                <video
                  loop={props.backgroundVideoLoop}
                  style={{
                    width: videoWidth || '100%',
                    height: videoHeight || '100vh'
                  }}
                  muted
                  playsInline
                  x5-playsinline="true"
                  ref={(elm) => { this.videoDom = elm; }}
                >
                  {getAsset(props.backgroundVideo) && <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.backgroundVideo)} />}
                </video>
                <div className="cardv-outer-video-mask" style={{ backgroundColor: props.backgroundVideoMaskColor, opacity: props.backgroundVideoMaskOpacity }} />
              </div>
            ) : (
              <div className="background-video-wrapper cardv-background-video">
                <VideoPlayer
                  visiblePlay={props.backgroundVideoVisiblePlay}
                  replayWhenRevisible={props.backgroundVideoVisiblePlay}
                  triggerPlay={props.backgroundVideoHoverPlay && hovering}
                  triggerPause={props.backgroundVideoHoverPlay && !hovering}
                  videoLazyLoadDistance={props.videoLazyLoadDistance}
                  lazyLoad
                  lazyLoadThreshold={2000}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <video muted {...videoPosterAttr} {...videoBgSizeAttr} loop={props.backgroundVideoLoop} autoPlay={!props.backgroundVideoVisiblePlay && !props.backgroundVideoHoverPlay}>
                    {getAsset(props.backgroundVideo) && (
                      <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.backgroundVideo)} />
                    )}
                  </video>
                </VideoPlayer>

                <div className="cardv-outer-video-mask" style={{ backgroundColor: props.backgroundVideoMaskColor, opacity: props.backgroundVideoMaskOpacity }} />
              </div>
            )
          ) : null
        }
        <div id={props.anchorId || ''} className="cardv-hero-vertical-wrapper" style={componentWrapperStyle}>
          <div className={`cardv-e-vertical-card-list ${verticalAlignStyleName}`} data-aos={aosFade} data-aos-once={true}>
            {cards}
          </div>
        </div>
      </LazyWrapper>
    );
  }
}
