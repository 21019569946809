import React, { useState, useEffect, useRef } from "react";
import SbEditable from "storyblok-react";
import { Controller, Scene } from '../../../../../lib/ScrollMagic';
import { Tween } from 'react-gsap';
import LazyLoad from 'react-lazyload';
import Image from '../../image';
import ButtonPlayInformation  from "../../button_play_information";
import MarkDown from '../../markdown';
import {
  hasWindow,
  removeClass,
  addClass,
  hasDocument,
  checkIsIE,
  shouldMobileVideoDowngrade,
  setWillChange,
  removeWillChange,
} from '../../../../utils/helper'
import Visibility from 'react-visibility-sensor'
import Button from '../../button'
import ButtonRow from "../../button_row";
import IconDescSec from '../IconDescSection';
import _debounce from 'lodash.debounce';
import VideoPlayer from "../../../atoms/video";
import { DeviceInfo } from '../../../../hooks/device';
import { checkInView } from '../../../../utils/checkElementInView'
import "./style.scss";

const lazyLoadOffset = 1000;
let willChangeSet = false;

const handleContainerFadeIn = (progress, uniqueId, maskListImage, isM) => {
  if (hasDocument) {
    const wrapperDom = document.getElementById(`${uniqueId}`);
    if (wrapperDom) {
      const containerDom = wrapperDom.querySelector('.container-wrapper');
      if (progress === 1) {
        addClass(containerDom)
        // 判断图片的中心是否超过屏幕的中心超过就渐渐展示网格。
        if(typeof window !== 'undefined' && maskListImage.current !== null){
          const scrollListener2 = () =>{
            if((window.pageYOffset + window.innerHeight/2 - maskListImage.current?.clientHeight/2 > maskListImage.current?.offsetTop) || 
            (isM && window.pageYOffset + window.innerHeight/2 > maskListImage.current?.offsetTop ) ||
            (!isM && window.pageYOffset + 120 > maskListImage.current?.offsetTop)
            ){
              maskListImage.current.classList.add("maskImageShow");
              window.removeEventListener("scroll", scrollListener2)
            }
          }
          window.addEventListener("scroll", scrollListener2)
        }
      } else {
        removeClass(containerDom)
      }
    }
  }
}

const renderStaticDom = (headerImage, title, screenHeight) => {
  return (
    <div className="sticky-inner-wrapper">
      <LazyLoad offset={lazyLoadOffset} once>
        <div className="header-image-wrapper" style={{ backgroundImage: `url(${headerImage})`, height:screenHeight }}></div>
      </LazyLoad>
      <div className="title-wrapper" style={{height: screenHeight}}>
        <div className="title" dangerouslySetInnerHTML={ MarkDown(title) } ></div>
      </div>
    </div>
  )
}

const removeChange = (uniqueId) => {
  if (!willChangeSet) {
    return;
  }

  const maskImage = document.querySelector(`#${uniqueId} .header-image-wrapper.mask`)
  const lockImage = document.querySelector(`#${uniqueId} .lock-image-wrapper`)
  const titleWrapper = document.querySelector(`#${uniqueId} .title-wrapper`)
  
  removeWillChange(maskImage);
  removeWillChange(lockImage);
  removeWillChange(titleWrapper);
  
  willChangeSet = false;
}

const setChange = (uniqueId) => {
  if (!hasWindow || !hasDocument || willChangeSet) {
    return;
  }

  const maskImage = document.querySelector(`#${uniqueId} .header-image-wrapper.mask`)
  const lockImage = document.querySelector(`#${uniqueId} .lock-image-wrapper`)
  const titleWrapper = document.querySelector(`#${uniqueId} .title-wrapper`)
  
  
  setWillChange(maskImage, 'opacity');
  setWillChange(lockImage, 'opacity');
  setWillChange(titleWrapper, 'opacity');

  willChangeSet = true;
}

const toggleWillChange = (progress, uniqueId) => {
  if (progress === 1 || progress === 0) {
    removeChange(uniqueId);
  } else {
    setChange(uniqueId);
  }
}

const LockScreenWithMediaSection = (props) => {
  const { blok, lang } = props;
  const {
    _uid,
    title = '',
    imageSecList = [],
    descList = [],
  } = blok;
  
  const [isActive, setActive] = useState(true);
  const [isIE, setIsIE] = useState(false)
  const [containerDomMarginTopValue, setContainerDomMarginTop] = useState(0)
  const [isDowngrade, setDowngrade] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  let { isMobile: isM, height: windowHeight } = DeviceInfo();
  const maskEle = useRef(null);
  const focusDom1 = useRef(null);
  const focusDom2 = useRef(null);
  const focusDom3 = useRef(null);
  const repalyBtn = useRef(null);
  const maskListImage = useRef(null);
  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;

    const wrapperDom = document.getElementById(`lock-screen-with-media-wrapper-${_uid}`);
    if (!wrapperDom) return;

    const windowInnerHeight = typeof window != 'undefined' ? (window.innerHeight) : 603;
    if(isM) {
      setScreenHeight((prevState) => {
        return Math.max(windowInnerHeight, prevState);
      });
    }else {
      setScreenHeight(windowInnerHeight);
    }
  
    const scrollListener = () => {
      if(repalyBtn.current === null) return;
      const clientTop = maskEle.current?.getBoundingClientRect().top;
      const offsetTop = windowHeight * 0.2;
      if (clientTop < offsetTop && clientTop >= 0) {
        repalyBtn.current.style.position = 'fixed';
        repalyBtn.current.style.visibility = '';
      } else {
        repalyBtn.current.style.position = '';
        repalyBtn.current.style.visibility = 'hidden';
      }
    }
    
    const callback = ({inView}) => {
      if(!inView) return;
      animationOpacity();
      isM && window.addEventListener('scroll', scrollListener);
    }


    checkInView({
      elm: maskEle.current,
      threshold: 1,
      callback
    })

    const titleEle = wrapperDom.querySelector('.title-wrapper .title');
    // 视频是否降级
    setDowngrade(shouldMobileVideoDowngrade());
    // 是否是ie
    setIsIE(checkIsIE())
    // 计算标题下方的内容区域的margin值
    const marginTop = isM ? 48 : 64;
    titleEle && setContainerDomMarginTop(screenHeight - titleEle.offsetTop - titleEle?.clientHeight - marginTop)

    onResize();
    const debounceResize = _debounce(onResize, 300);
    window.addEventListener('resize', debounceResize);
    return () => {
      // ro.disconnect();
      isM && window.removeEventListener('scroll', scrollListener);
    }
  })

  const uniqueId = `lock-screen-with-media-wrapper-${_uid}`;

  // 判断视频是否可见
  const isVisible = (visible) => {
    if (visible) {
      setPlaying(true);
      setActive(false);
    }
  }

  const className = isIE ? 'lock-screen-with-media-wrapper static' : 'lock-screen-with-media-wrapper';

  const headerImage = isM ? blok.headerImageMobile : blok.headerImage;
  const videoLink = isM ? blok.videoLinkMobile : blok.videoLink;
  const mask = isM ? blok.maskMobile : blok.mask;
  const focus_img_1 = isM ? blok.mobile_focus_img_1 : blok.focus_img_1;
  const focus_img_2 = isM ? blok.mobile_focus_img_2 : blok.focus_img_2;
  const focus_img_3 = isM ? blok.mobile_focus_img_3 : blok.focus_img_3;
  const replayBtnImg = (blok.replayBtn && blok.replayBtn.filename) || '/assets/icons/play.svg';

  // 是否展示视频
  const showVideo = videoLink && videoLink.filename && !isDowngrade;

  const stickyWrapperStyle = {
    height:`${mask && !isIE ? 2.5 * screenHeight : 2 * screenHeight}px`  
  }    

  const onResize = () => {
    if(!hasWindow) return;
    const windowInnerHeight = hasWindow ? (window.innerHeight) : 603;
    const targetDom = hasDocument ? document.body : '';
    const obj = targetDom.getBoundingClientRect();
    isM = obj.width <= 768;
    if(isM) {
      setScreenHeight((prevState) => {
        return Math.max(windowInnerHeight, prevState);
      });
    }else {
      setScreenHeight(windowInnerHeight);
    }
  }

  const replayAnimation = () => {
    // 重置：隐藏播放按钮、显示模糊图层、隐藏对焦框 3（绿色）
    if(repalyBtn.current === null) return;
    repalyBtn.current.style.display = 'none'
    removeClass(focusDom3.current);
    addClass(maskEle.current);
    addClass(focusDom1.current);
    removeClass(focusDom2.current);
    animationOpacity()
  }

  const animationOpacity = (offset = 0) => {
    // 对焦动画
    setWillChange(focusDom1.current, 'opacity');
    setWillChange(focusDom2.current, 'opacity');
    setWillChange(focusDom3.current, 'opacity');

    setTimeout(() => {
      // 0.2: 开始对焦,背景图模糊,对焦框 2（灰色）
      if(maskEle.current === null || focusDom3.current === null) return;
      removeClass(focusDom1.current);
      addClass(focusDom2.current);
      maskEle.current.style.transition = '';
      focusDom3.current.style.transition = '';
    }, 200 + offset)

    setTimeout(() => {
      // 0.5: 完成对焦,背景图清晰,对焦框 3（绿色）
      removeClass(maskEle.current);
      addClass(focusDom3.current);
    }, 500 + offset)

    setTimeout(() => {
      // 1: 结束动画,显示重播按钮
      if(repalyBtn.current === null || maskEle.current === null || focusDom3.current === null) return;
      repalyBtn.current.style.display = 'block'
      // 重置模糊场景
      addClass(focusDom1.current);
      removeClass(focusDom2.current);
      maskEle.current.style.transition = 'none';
      focusDom3.current.style.transition = 'none';
      removeWillChange(focusDom1.current);
      removeWillChange(focusDom2.current);
      removeWillChange(focusDom3.current);
    }, 1000 + offset);
  };

  const opacityChangeAnimation = (progress) => {
    let opacity = 1;
    if(progress < 0.5) opacity = 1;
    else opacity = 1 - progress;
    return {opacity};
  };

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div id={uniqueId} className={className}>
        <div className="sticky-wrapper" style={{height:`${mask && !isIE ? 2.5 * screenHeight : 2 * screenHeight}px`}}>
          {
            isIE ? renderStaticDom(headerImage, title ,screenHeight) : (
              <div className="sticky-inner-wrapper">
                <Controller>
                  <Scene
                    offset={-2000}
                    triggerHook={0}
                    duration={1500 + windowHeight * 2}
                    triggerElement={`#${uniqueId}`}
                  >
                    {
                      (progress) => {
                        toggleWillChange(progress, uniqueId);
                        return (<React.Fragment />);
                      }
                    }
                  </Scene>
                  <Scene
                    duration={mask ? windowHeight : windowHeight * 0.5}
                    triggerHook="0"
                    triggerElement={`#${uniqueId}`}
                  >
                    {
                      (progress) => {                        
                        return (
                          <Tween
                            duration={0.4}
                            ease="power4.out"
                            to={opacityChangeAnimation(progress)}
                          >
                            <div className="lock-image-wrapper">
                              <Visibility active={isActive} onChange={isVisible} partialVisibility={'bottom'} offset={{bottom:0}}>
                                <LazyLoad offset={2000} once>
                                  {
                                    mask ? (
                                      <div className="header-image-wrapper mask active" style={{ backgroundImage: `url(${mask})`, height:screenHeight - 1 }} ref={maskEle}>
                                        <div className="header-image-wrapper mask focus-img focus-img-1 active" style={{ backgroundImage: `url(${focus_img_1})`, height:screenHeight }}  ref={focusDom1}></div>
                                        <div className="header-image-wrapper mask focus-img focus-img-2" style={{ backgroundImage: `url(${focus_img_2})`, height:screenHeight }}  ref={focusDom2}></div>
                                      </div>
                                    ) : null
                                  }
                                  <div className="header-image-wrapper" style={{ backgroundImage: `url(${headerImage})`, height:screenHeight }}>
                                    {
                                      showVideo ? (
                                        <div className="video-play-box">
                                          <VideoPlayer
                                            style={{ height: '100%', width: '100%' }}
                                            triggerPlay={playing}
                                          >
                                            <div className="video">
                                              <video muted poster={headerImage} loop>
                                                <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' src={videoLink.filename} />
                                              </video>
                                            </div>
                                          </VideoPlayer>
                                        </div>
                                      ) : (
                                        blok.popupVideoBtn && blok.popupVideoBtn.length > 0 ? (
                                          <div className="play-icon" style={{ backgroundImage: `url(${headerImage})`}}>
                                            <ButtonRow content={{buttons: blok.popupVideoBtn}} />
                                          </div>
                                        ) : null
                                      )
                                    }
                                    { mask ? (
                                      <div className="header-image-wrapper mask focus-img focus-img-3" style={{ backgroundImage: `url(${focus_img_3})`, height:screenHeight }} ref={focusDom3}>
                                        <div className="replay-btn" style={{ backgroundImage: `url(${replayBtnImg})`, opacity: !(progress < 0.5) ? '0' : '1'}} onClick={() => replayAnimation()} ref={repalyBtn}/>
                                      </div>
                                    ) : null }
                                  </div>
                                </LazyLoad>
                              </Visibility>
                            </div>
                          </Tween>
                        )
                      }
                    }
                  </Scene>
                  <Scene
                    duration={windowHeight * 0.5}
                    triggerHook="0"
                    triggerElement={`#${uniqueId}`}
                    offset={mask ? windowHeight * 0.95 : windowHeight * 0.45}
                  >
                    {
                      (progress) => {
                        handleContainerFadeIn(progress, uniqueId, maskListImage, isM);
                        const zIndex = progress > 0 ? 1 : -1;
                        return (
                          <Tween
                            duration={0.4}
                            ease="power4.out"
                            to={{
                              opacity: progress,
                            }}
                          >
                            <div style={{zIndex, height:screenHeight}} className="title-wrapper">
                              <div className="title" dangerouslySetInnerHTML={ MarkDown(title) } ></div>
                            </div>
                          </Tween>
                        )
                      }
                    }
                  </Scene>
                </Controller>
              </div>
            )
          }
        </div>
        <div className="container-wrapper" style={{ marginTop: -containerDomMarginTopValue + 'px' }}>
          <div className="image-section">
            {
              imageSecList.length > 0 && imageSecList.map((item, index) => {                
                let isModalbtn = item.btn && item.btn.length > 0 && item.btn.some((btnItem)=>{
                  return btnItem.component === "button_paly_information"
                })
                return <div className={`image-section-inner-wrapper ${isModalbtn > 0 ? 'image-section-inner-wrapper-have-btn' : ''}`} key={item?._uid}>
                  <LazyLoad offset={lazyLoadOffset} once>
                    <div className="image-section-list" ref={maskListImage}>
                      {
                        isM ? (
                          item.imgListMobile && item.imgListMobile.length > 0 && item.imgListMobile.map((img, innerIndex) => (
                            <div className="image-wraper-m" key={item?._uid}>
                              <Image blok={img} uid={item?._uid} />
                              {
                                item.MobileImageMask && 
                                <img className="maskImage" src={item.MobileImageMask} alt="" key={item?._uid}/>
                              }
                            </div>
                          ))
                        ) : (
                          item.imgList && item.imgList.length > 0 && item.imgList.map((img, innerIndex) => (
                            <div key={img?._uid} style={{ width: `${100/item.imgList.length}%` }} className={`image-wraper ${img?.zoom_image_id && 'with-cursor'} ${item.PcImageMask ? 'image-wraper-with-mask' : null}`}>
                              <Image blok={img} uid={img?._uid} />
                              {
                                item.PcImageMask && 
                                <img className="maskImage" src={item.PcImageMask} alt="" key={item?._uid}/>
                              }
                            </div>
                          ))
                        )
                      }
                    </div>
                  </LazyLoad>
                  <div className="content">
                    <div className="desc" dangerouslySetInnerHTML={ MarkDown(item.desc) } ></div>
                    <div className="btn-wrapper-group">
                      {
                        item.btn && item.btn.length > 0 && item.btn.map(item => (
                          <div className="btn-wrapper" key={item._uid}>
                            { item.component === "button_link" ?
                              <Button content={item} key={item._uid} lang={lang} />
                              : <ButtonPlayInformation content={item}  key={item._uid} lang={lang}/>
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              })
            }
          </div>
          <IconDescSec content={descList} />
        </div>
      </div>
    </SbEditable>
  );
}

export default LockScreenWithMediaSection;
