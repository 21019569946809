import { IDimension } from './dimension';

/**
 * 滚动事件回调
 */
export interface ScrollListener {
  onScroll?: (dimension: IDimension) => void;
  scrollIntoView?: (dimension: IDimension) => void;
  scrollOutOfWiew?: (dimension: IDimension) => void;
}

export interface IScrollNode {
  top: number;
  left: number;
  width: number;
  height: number;
  inView: boolean;
}

/**
 * 滚动节点
 */
export default class ScrollNode {
  private scrollElement: HTMLElement;

  private scrollTaskList: { (): void; }[];

  private scrollCallback: ScrollListener;

  private nodeInfo: IScrollNode;

  private frameTaskRunning = false;

  constructor(srcElement: HTMLElement, callback?: ScrollListener, task?: { (): void; }[]) {
    this.scrollElement = srcElement;
    this.nodeInfo = {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      // 初始值必须为 false 以便滚动管理器处理进入/退出
      inView: false,
    };

    this.scrollTaskList = [];
    if (task) {
      this.addTask(task);
    }

    // let element = this.scrollElement.offsetParent as HTMLElement;
    // while (element) {
    //   this.nodeInfo.top += element.offsetTop;
    //   this.nodeInfo.left += element.offsetLeft;
    //   element = element.offsetParent as HTMLElement;
    // }

    if (callback) {
      this.scrollCallback = callback;
    }
  }

  public get element() : HTMLElement {
    return this.scrollElement;
  }

  public get callback(): ScrollListener {
    return this.scrollCallback;
  }

  public get IScrollNode(): IScrollNode {
    return this.nodeInfo;
  }

  public get top() : number {
    return this.nodeInfo.top;
  }

  public get left() : number {
    return this.nodeInfo.left;
  }

  public get width() : number {
    return this.nodeInfo.width;
  }

  public get height() : number {
    return this.nodeInfo.height;
  }

  public get inView() : boolean {
    return this.nodeInfo.inView;
  }

  public set inView(inview: boolean) {
    this.nodeInfo.inView = inview;
  }

  /**
   * 判断 HTML 元素是否属于当前滚动节点
   */
  public equals(scrollNode: HTMLElement) {
    return this.scrollElement.isSameNode(scrollNode);
  }

  /**
   * 刷新滚动节点位置信息
   */
  public refreshPosition() {
    this.nodeInfo.top = this.scrollElement.offsetTop;
    this.nodeInfo.left = this.scrollElement.offsetLeft;
    this.nodeInfo.width = this.scrollElement.offsetWidth;
    this.nodeInfo.height = this.scrollElement.offsetHeight;

    let element = this.scrollElement.offsetParent as HTMLElement;
    while (element) {
      this.nodeInfo.top += element.offsetTop;
      this.nodeInfo.left += element.offsetLeft;
      element = element.offsetParent as HTMLElement;
    }
  }

  public addTask(task?: { (): void; }[]) {
    this.scrollTaskList = this.scrollTaskList.concat(task);
  }

  private runFrameTask() {
    if (!this.frameTaskRunning) {
      return;
    }
    setTimeout(() => {
      // 执行节点回调
      if (this.inView && this.scrollTaskList.length > 0) {
        // 远离视口的节点不执行滚动任务, 节点进入视口后开始执行滚动任务
        this.scrollTaskList.forEach((task, index, taskList) => {
          const destroy = task();
          // 任务返回 true 表示一次性执行完成, 不保留任务
          if (Boolean(destroy) === true) {
            taskList.splice(index, 1);
          }
        });
      }

      this.runFrameTask();
    }, 60);
  }

  public startFrameTask() {
    if (!this.frameTaskRunning && this.scrollTaskList.length > 0) {
      this.frameTaskRunning = true;
      this.runFrameTask();
    }
  }

  public stopFrameTask() {
    this.frameTaskRunning = false;
  }
}
