import React from "react"
import SbEditable from "storyblok-react"
import DatePicker from "react-datepicker"
import "../button_row.css"
import "./competition_registration.css"
import API from "../../../utils/api_auth"
import t from "../../../utils/languages"
import { isEmptyString } from '../../../utils/helper';
import LinkFix from "../../../utils/links"
import { checkIsMobile } from "../../../utils/helper"
import { navigate } from 'gatsby';
import * as xss from 'xss';
import "react-datepicker/dist/react-datepicker.css"

const DATA_TYPE = {
    USER: 'USER',
    CONTENT: 'CONTENT',
};

const REQUIRED_MAP = {
    photography_length_of_time: { requiredBy: 'whether_professional_photographer', requiredValue: 'yes' },
    personal_website: { requiredBy: 'whether_professional_photographer', requiredValue: 'yes' },
    organization_name: { requiredBy: 'whether_recommended_by_org', requiredValue: 'yes' },
    organization_website: { requiredBy: 'whether_recommended_by_org', requiredValue: 'yes' },
};

const isError = (dataSource, key) => {
    /* 默认每一项都必填 */
    if (!REQUIRED_MAP[key]) return isEmptyString(dataSource[key]);

    /* 只有关联项命中必填条件时，该项才必填 */
    const { requiredBy, requiredValue } = REQUIRED_MAP[key];
    const isRequired = dataSource[requiredBy] === requiredValue;
    return isRequired && isEmptyString(dataSource[key]);
};

const filterRequiredData = (dataSource) => {
    if (!dataSource) return {};

    /* 筛选出必填的字段 */
    const keepKeys = Object.keys(dataSource).filter(key => !REQUIRED_MAP[key] || (dataSource[REQUIRED_MAP[key].requiredBy] === REQUIRED_MAP[key].requiredValue));
    /* 返回筛选后的数据 */
    return keepKeys.reduce((prevData, dataKey) => ({ ...prevData, [dataKey]: dataSource[dataKey] }), {});
};

export default class extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            allCorrect: false,
            checked : false,
            loading : true,
            registered: false,
            ready: false,
            user: {
                email : '', /* 邮箱 */
                firstname: '', /* 名 */
                surname: '', /* 姓 */
                city: '', /* 城市 */
                zip_code: '', /* 邮编 */
                country_code: '', /* 国家地区 */
                phone: '', /* 电话号码 */
                birth_date: '', /* 出生日期 */
            },
            content: {
                whether_professional_photographer: '', /* 是否是职业摄影师 */
                photography_length_of_time: '', /* 职业摄影生涯长度 */
                personal_website: '', /* 个人网站地址 */
                whether_recommended_by_org: '', /* 是否是机构推荐 */
                organization_name: '', /* 机构名称 */
                organization_website: '', /* 机构网站地址 */
            },
            error:{
                email : false,
            },
            countries : [],
            customButton : props.blok.custom_save_button && props.blok.custom_save_button.length > 0 ? props.blok.custom_save_button[0] : null,
            hasSubmitError: false,
        }

        this.handle = this.handle.bind(this);
    }

    handle = (event, type) => {
        const target = event.target
        const value = target.value
        const name = target.name
        let { error, user, content, checked  } = this.state;

        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (type === DATA_TYPE.CONTENT) {
            content[name] = value;
            error[name] = isError(content, name);
        } else {
            error[name] = name === 'email' ? !emailRegex.test(value) : isEmptyString(value);
            user[name] = value;
        }

        let noError = true;
        if (checked) {
            Object.keys(user).forEach((key) => {
                if (isEmptyString(user[key])) {
                    noError = false;
                    error[key] = true;
                }
            });
            Object.keys(content).forEach((key) => {
                if (isError(content, key)) {
                    noError = false;
                    error[key] = true;
                }
            });
        }

        this.setState({
            error,
            user,
            content,
            allCorrect: checked && noError
        })
    }

    componentDidMount = () => {
        const { user } = this.state;
        API.get('/competitions/' + this.props.blok.competition_id + '/entity')
        .then((data) => {
            if (data && data.data && data.data.registered) {
                this.setState({
                    registered: true,
                })
                // 已注册的可以继续填写，送到分类页
                // return navigate(LinkFix(this.state.customButton.link))
            }
            if (data && data.data && typeof data.data.content === 'string') {
                let content = {};
                try {
                    content = JSON.parse(data.data.content);
                } catch (e) {
                    content = {};
                }
                this.setState({ content });
            }
            return  API.get('/countries')
        })
        .then((response) => { 
            this.setState({ countries : response.data.countries })
            return  API.get('/me')
        })
        .then((response) => {
            if (response.data && response.data.user) {
                Object.keys(response.data.user).forEach((key) => {
                    if (response.data.user[key]) {
                        if (key === 'birth_date') {
                            user[key] = new Date(response.data.user[key]);
                        } else {
                            user[key] = response.data.user[key];
                        }
                    }
                })
            }

            this.setState({ 
                user,
                loading : false,
                ready: true
            })
        })
        .catch((err) => {
            this.setState({ 
                loading : false,
                ready: true
            })
        })
        this.setDatePickerInputReadonlyIfNecessary();
    }

    setDatePickerInputReadonlyIfNecessary = () => {
        /** 
          移动端禁用日期选择器输入框的输入操作，以免出现键盘遮挡日期选择面板问题
          参考文档：https://stackoverflow.com/questions/48805053/hide-native-keyboard-on-mobile-when-using-react-date-picker
        */
        if (checkIsMobile()) {
            const datePickers = document.getElementsByClassName("react-datepicker__input-container");
            Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
        }
    }

    toggleConsent = () =>{
        const { user, content, error } = this.state;
        const checked = !this.state.checked;
        let noError = true;
        if (checked) {
            Object.keys(user).forEach((key) => {
                if (isEmptyString(user[key])) {
                    noError = false;
                    error[key] = true;
                }
            });
            Object.keys(content).forEach((key) => {
                if (isError(content, key)) {
                    noError = false;
                    error[key] = true;
                }
            });
        }

        this.setState({ 
            checked,
            allCorrect: checked && noError,
            error
        })
    }

    register = () => {
        const { loading, content, user } = this.state;
        if (loading) return;
        this.setState({ loading : true});

        const contentData = filterRequiredData(content);
        const contentString = xss(JSON.stringify(contentData));

        const userData = Object.keys(user).reduce((prevData, dataKey) => ({ ...prevData, [dataKey]: typeof user[dataKey] === 'string' ? xss(user[dataKey]) : user[dataKey] }), {});

        API.put('/me', userData).then(() => {
            /* 已注册的也可以继续填写 */
            // if (registered) {
                // return {};
            // }
            return  API.post('/competitions/' + this.props.blok.competition_id + '/entity', { content: contentString })
        }).then(() => {
            this.setState({ loading : false })
            if (this.state.customButton && this.state.customButton.link){
                navigate(LinkFix(this.state.customButton.link))
            }
        }).catch(()=> {
            this.setState({ loading : false })
        })
    }

    render() {
        const { blok, lang } = this.props;
        let { customButton, error, user, countries, ready, content, hasSubmitError, loading } = this.state;
        const { 
            birth_date,
            whether_professional_photographer,
            photography_length_of_time,
            personal_website,
            whether_recommended_by_org,
            organization_name,
            organization_website,
            /* 历史翻译 key 的错误提示 */
            error_tip_firstname,
            error_tip_surname,
            error_tip_email,
            error_tip_phone,
            error_tip_city,
            error_tip_zip_code,
            error_tip_country_code,
            error_tip_birth_date,
            error_when_submit,
            form_title,
         } = blok;

        return (
            <SbEditable content={blok}>
                <div className={`registration-wrapper ${ready ? '' : 'not-ready'}`}>
                    <div className="editorial row show-when-ready form-wrapper-row">
                        <div className="et fifty registration-form-title">{form_title}</div>
                        <div className="et fifty">
                            <label htmlFor="firstname">
                                <div className="form-label">
                                    <span className="show"> 
                                        {t('profile.firstNamePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="firstname" id="firstname" type="text" placeholder={ t('profile.firstNamePlaceHolder', lang)} value={user.firstname} onChange={this.handle} disabled={loading} />
                                <span className={`errorTip ${error.firstname ? "errorShow" : ""} `}>{error_tip_firstname}</span>
                            </label> 

                        </div>
                        <div className="et fifty">

                            <label htmlFor="surname">
                                <div className="form-label">
                                    <span className="show"> 
                                        {t('profile.lastNamePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="surname" type="text" placeholder={ t('profile.lastNamePlaceHolder', lang)} value={user.surname} onChange={this.handle} disabled={loading} />
                                <span className={`errorTip ${error.surname ? "errorShow" : ""} `}>{error_tip_surname}</span>
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="emailAddress">
                                <div className="form-label">
                                    <span className="show"> 
                                        { t('profile.emailPlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="emailAddress" disabled={true} type="email" placeholder={ t('profile.emailPlaceHolder', lang)} value={user.email} onChange={this.handle} />
                                <span className={`errorTip ${ error.email ? "errorShow" : ''} `}>{user.email !== '' ? t('profile.emailInvalid', lang) : error_tip_email}</span>
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="phone">
                                <div className="form-label">
                                    <span className="show"> 
                                        {t('profile.phonePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="phone" type="text" placeholder={ t('profile.phonePlaceHolder', lang)} value={user.phone} onChange={this.handle.bind(this)} disabled={loading} />
                                <span className={`errorTip ${error.phone ? "errorShow" : ""} `}>{error_tip_phone}</span>
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="city">
                                <div className="form-label">
                                    <span className="show"> 
                                        {t('profile.cityPlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="city" type="text" placeholder={ t('profile.cityPlaceHolder', lang)} value={user.city} onChange={this.handle} disabled={loading} />
                                <span className={`errorTip ${error.city ? "errorShow" : ""} `}>{error_tip_city}</span>
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="zip_code">
                                <div className="form-label">
                                    <span className="show"> 
                                        {t('profile.zipPlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="zip_code" type="text" placeholder={ t('profile.zipPlaceHolder', lang)} value={user.zip_code} onChange={this.handle} disabled={loading} />
                                <span className={`errorTip ${error.zip_code ? "errorShow" : ""} `}>{error_tip_zip_code}</span>
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="country_code">
                                <div className="form-label">
                                    <span className="show">{ t('newsletter.selectYourCountry', lang) }</span>
                                </div> 
                                <select name="country_code" value={user.country_code} onBlur={this.handle} onChange={this.handle}  disabled={loading}>
                                    <option key="country_code" value="">{ t('profile.selectPlease', lang) }</option>
                                    {countries.map((c, index) => <option key={index} value={c.id}>{c.t}</option>)}
                                </select>
                                <span className={`errorTip ${error.country_code ? "errorShow" : ""} `}>{error_tip_country_code}</span>
                            </label> 
                        </div>

                        <div className="et left fifty">
                            <label htmlFor="birth_date">
                                <div className="form-label">
                                    <span className="show"> 
                                        {birth_date[0].form_item_title}
                                    </span>
                                </div>
                                <DatePicker
                                    selected={user.birth_date}
                                    onChange={(date) => {
                                        this.handle({
                                            target: {
                                                value: date,
                                                name: 'birth_date',
                                            }
                                        })
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={new Date()}
                                    name="birth_date"
                                    calendarClassName="calendar"
                                    className="birth_date"
                                    disabled={loading}
                                 /> 
                                 <span className={`errorTip ${error.birth_date ? "errorShow" : ""} `}>{error_tip_birth_date}</span>
                            </label>
                        </div>

                        {/* Group -> is professional photographer  */}
                        <div className="et left fifty">
                            <label htmlFor="whether_professional_photographer">
                                <div className="form-label">
                                    <span className="show"> 
                                        {whether_professional_photographer[0].form_item_title}
                                    </span>
                                </div>
                                <select name="whether_professional_photographer" value={content && content.whether_professional_photographer || ''} onBlur={(e) => this.handle(e, DATA_TYPE.CONTENT)} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading}>
                                    <option key="whether_professional_photographer" value="">{whether_professional_photographer[0].form_item_placeholder}</option>
                                    {whether_professional_photographer[0].select_options.map((c, index) => <option key={index} value={c.value || ''}>{c.text}</option>)}
                                </select>
                                <span className={`errorTip ${error.whether_professional_photographer ? "errorShow" : ""} `}>{whether_professional_photographer[0].form_empty_error}</span>
                            </label>
                        </div>

                        <div className="et left fifty" />
                        {content && content.whether_professional_photographer === 'yes' ? (
                            <>
                                <div className="et left fifty">
                                    <label htmlFor="photography_length_of_time">
                                        <div className="form-label">
                                            <span className="show">{photography_length_of_time[0].form_item_title}</span>
                                        </div>
                                        <input name="photography_length_of_time" type="text" placeholder={photography_length_of_time[0].form_item_placeholder} value={content.photography_length_of_time} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading}/>
                                        <span className={`errorTip ${error.photography_length_of_time ? "errorShow" : ""} `}>{photography_length_of_time[0].form_empty_error}</span>
                                    </label> 
                                </div>
                                <div className="et left fifty">
                                    <label htmlFor="personal_website">
                                        <div className="form-label">
                                            <span className="show">{personal_website[0].form_item_title}</span>
                                        </div>
                                        <input name="personal_website" type="text" placeholder={personal_website[0].form_item_placeholder} value={content.personal_website} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading}/>
                                        <span className={`errorTip ${error.personal_website ? "errorShow" : ""} `}>{personal_website[0].form_empty_error}</span>
                                    </label> 
                                </div>
                            </>
                        ) : null}
                        
                        {/* Group -> recommended by  organization  */}

                        <div className="et left fifty">
                            <label htmlFor="whether_recommended_by_org">
                                <div className="form-label">
                                    <span className="show"> 
                                        {whether_recommended_by_org[0].form_item_title}
                                    </span>
                                </div>
                                <select name="whether_recommended_by_org" value={content && content.whether_recommended_by_org || ''} onBlur={(e) => this.handle(e, DATA_TYPE.CONTENT)} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading} >
                                    <option key="whether_recommended_by_org" value="">{whether_recommended_by_org[0].form_item_placeholder}</option>
                                    {whether_recommended_by_org[0].select_options.map((c, index) => <option key={index} value={c.value || ''}>{c.text}</option>)}
                                </select>
                                <span className={`errorTip ${error.whether_recommended_by_org ? "errorShow" : ""} `}>{whether_recommended_by_org[0].form_empty_error}</span>
                            </label>
                        </div>
                        <div className="et left fifty" />
                        {content && content.whether_recommended_by_org === 'yes' ? (
                        <>
                            <div className="et left fifty">
                            <label htmlFor="organization_name">
                                <div className="form-label">
                                    <span className="show">{organization_name[0].form_item_title}</span>
                                </div>
                                <input name="organization_name" type="text" placeholder={organization_name[0].form_item_placeholder} value={content.organization_name} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading} />
                                <span className={`errorTip ${error.organization_name ? "errorShow" : ""} `}>{organization_name[0].form_empty_error}</span>
                            </label> 
                            </div>
                            <div className="et left fifty">
                                <label htmlFor="organization_website">
                                    <div className="form-label">
                                        <span className="show">{organization_website[0].form_item_title}</span>
                                    </div>
                                    <input name="organization_website" type="text" placeholder={organization_website[0].form_item_placeholder} value={content.organization_website} onChange={(e) => this.handle(e, DATA_TYPE.CONTENT)} disabled={loading} />
                                    <span className={`errorTip ${error.organization_website ? "errorShow" : ""} `}>{organization_website[0].form_empty_error}</span>
                                </label> 
                            </div>
                        </>
                        ) : null}            
                    </div>
                    <div className="editorial row no-padding-row">
                        <div className="et left hundred show-when-ready">
                            <div className="terms-box">
                                <input id="5cf8db738a" type="checkbox" checked={this.state.checked} onChange={this.toggleConsent} disabled={loading}/>
                                    <label htmlFor="5cf8db738a" dangerouslySetInnerHTML={{__html: this.props.blok.terms_checkbox}} >
                                    </label>
                            </div>
                        </div>
                        <div className="et left hundred">
                            <div className="cta-box">
                                <button className={`cta ${this.state.allCorrect  ? '' : 'disabled' } ${this.state.loading  ? 'loading' : '' } `} disabled={ !this.state.allCorrect } onClick={ this.register } >{ customButton ? customButton.text : t('profile.save', lang)}</button>
                            </div>
                            <span className={`errorTip error-center ${hasSubmitError ? "errorShow" : ""} `}>{error_when_submit}</span>
                        </div>
                    </div>
                </div>
            </SbEditable>
        )
    }
}
