import React from 'react';
import LazyLoadComponent from './LazyLoadComponent';

interface Props {
  afterLoad?: () => void;
  beforeLoad?: () => void;
  delayMethod: string;
  delayTime: number;
  effect: string;
  placeholder?: Element | null;
  placeholderSrc: string;
  threshold?: number;
  visibleByDefault: boolean;
  className?: string;
  wrapperClassName?: string;
  wrapperProps: any;
  style?: React.CSSProperties;
  height?: string | number;
  width?: string | number;
}

interface State {
  loaded: boolean;
}

class LazyLoadImage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  onImageLoad() {
    if (this.state.loaded) {
      return null;
    }

    return () => {
      this.props.afterLoad();

      this.setState({ loaded: true });
    };
  }

  getImg() {
    const {
      afterLoad,
      beforeLoad,
      delayMethod,
      delayTime,
      effect,
      placeholder,
      placeholderSrc,
      threshold,
      visibleByDefault,
      wrapperClassName,
      wrapperProps,
      ...imgProps
    } = this.props;

    return <img alt="" onLoad={this.onImageLoad()} {...imgProps} />;
  }

  getLazyLoadImage() {
    const {
      beforeLoad,
      className,
      delayMethod,
      delayTime,
      height,
      placeholder,
      style,
      threshold,
      visibleByDefault,
      width,
    } = this.props;

    return (
      <LazyLoadComponent
        beforeLoad={beforeLoad}
        className={className}
        delayMethod={delayMethod}
        delayTime={delayTime}
        height={height}
        placeholder={placeholder}
        style={style}
        threshold={threshold}
        visibleByDefault={visibleByDefault}
        width={width}
      >
        {this.getImg()}
      </LazyLoadComponent>
    );
  }

  getWrappedLazyLoadImage(lazyLoadImage) {
    const {
      effect,
      height,
      placeholderSrc,
      width,
      wrapperClassName,
      wrapperProps,
    } = this.props;
    const { loaded } = this.state;

    const loadedClassName = loaded ? ' lazy-load-image-loaded' : '';
    const wrapperBackground
      = loaded || !placeholderSrc
        ? {}
        : {
          backgroundImage: `url(${placeholderSrc})`,
          backgroundSize: '100% 100%',
        };

    return (
      <span
        className={
          `${wrapperClassName
          } lazy-load-image-background ${
            effect
          }${loadedClassName}`
        }
        style={{
          ...wrapperBackground,
          color: 'transparent',
          display: 'inline-block',
          height,
          width,
        }}
        {...wrapperProps}
      >
        {lazyLoadImage}
      </span>
    );
  }

  render() {
    const {
      effect,
      placeholderSrc,
      visibleByDefault,
      wrapperClassName,
      wrapperProps,
    } = this.props;

    const lazyLoadImage = this.getLazyLoadImage();
    const needsWrapper = (effect || placeholderSrc) && !visibleByDefault;

    if (!needsWrapper && !wrapperClassName && !wrapperProps) {
      return lazyLoadImage;
    }

    return this.getWrappedLazyLoadImage(lazyLoadImage);
  }
}

// @ts-ignore
LazyLoadImage.defaultProps = {
  afterLoad: () => ({}),
  beforeLoad: () => ({}),
  placeholder: null,
  threshold: 100,
  className: '',
  wrapperClassName: '',
  style: {},
  height: undefined,
  width: undefined,
};

export default LazyLoadImage;
