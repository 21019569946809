import * as React from 'react';
import { checkInView } from '../../../utils/checkElementInView'

export interface Props {
  background?: boolean;
  tabIndex?: number;
  factor?: number;
  offset?: number | string;
  style?: object;
  styleName?: string;
  children?: React.ReactNode;
  className?: string;
  attr?: object;
  firstSlideDisabled?: boolean;
}

export default class ParallaxPicture extends React.Component<Props> {
  targetDom: HTMLDivElement | null;
  animationFrame: any;
  prevCalcOffset: number;
  indicatorPosition: number;

  public static defaultProps: {
    factor: 0.1;
    offset: 200;
    tabIndex: '';
    styleName: '';
    style: {};
    children: '';
    background: false;
    backgroundSrc: '';
    className: '';
    attr: {};
  };

  componentDidMount(): void {
    this.parallaxScroll();

    checkInView({
      elm: this.targetDom,
      offset: 2000,
      callback: ({ inView }) => {
        if(inView) {
          this.parallaxScroll();
        } else {
          if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
          }
        }
        return true;
      },
    });
  }

  parallaxScroll(): void {
    if (this.animationFrame) {
      return;
    }
    let scrollInited = false;
    const { factor: propsFactor = 0.1, offset = 200, background, firstSlideDisabled } = this.props;

    if (typeof this.indicatorPosition !== 'number') {
      this.indicatorPosition = 0;
    }
    const elm = this.targetDom;
    const self = this;
    if (!elm || typeof window === 'undefined') {
      return;
    }

    const windowHeight = window.innerHeight;

    function loop(): void {
      const scrollOffset = window.scrollY || window.pageYOffset;
      let factor = propsFactor || 0.1;
      if (firstSlideDisabled && !scrollInited)  {
        // props.firstSlideDisabled = true 时，第一次不滑动
        factor = 1;
        scrollInited = true;
      }
      if (!elm || typeof window === 'undefined') {
        return;
      }
      let temp = elm;
      let { offsetTop } = elm;
      while (temp.offsetParent) {
        temp = temp.offsetParent;
        offsetTop += temp.offsetTop;
      }
      const elHeight = elm.offsetHeight;
      if (scrollOffset >= offsetTop - windowHeight && scrollOffset <= offsetTop + elHeight) {
        const scrollPercent = (offsetTop - windowHeight - scrollOffset) / (windowHeight + elHeight);
        self.indicatorPosition += (scrollPercent - self.indicatorPosition) * factor;
        const calcOffset = +((self.indicatorPosition * offset).toFixed(2));
        if (calcOffset !== self.prevCalcOffset) {
          self.prevCalcOffset = calcOffset;
          if (background) {
            elm.style['background-position-y'] = `${calcOffset}px`;
          } else {
            elm.style.transform = `translateY(${calcOffset}px)`;
          }
        }
      }
      self.animationFrame = requestAnimationFrame(loop);
    }
    loop();
  }

  render(): JSX.Element {
    const {
      children,
      style,
      className,
      attr,
    } = this.props;
    return (
      <div
        ref={(elem) => { this.targetDom = elem; }}
        style={style}
        className={className}
        {...attr}
      >
        {
        children
      }
      </div>
    );
  }
}
