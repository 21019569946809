import React from "react"
import SbEditable from "storyblok-react"
import Link from "gatsby-link"
import LinkFix from "../../utils/links"
import LazyLoad from "react-lazyload"

import "./listing.css"
import { useStaticQuery, graphql } from "gatsby"
import ButtonRow from './button_row'
import MarkDown from './markdown'
import t from "../../utils/languages"


const ChapterHeading = props => {

    const data = useStaticQuery(graphql`
        query ListingQuery{
            allListing(sort: {fields: published, order: DESC}) {
                 nodes {
                  img
                  title
                  url
                  b_text
                  b_img
                  b_mimg
                  b_color
                  tags
                }
              }
        }   
    `)

    

    const allItems = data.allListing.nodes;
    const { path, blok } = props;
    let url = null;
    if (blok.root_page && blok.root_page.cached_url){
        url = blok.root_page.cached_url.startsWith('/') ? blok.root_page.cached_url.substr(1) : blok.root_page.cached_url;
    } 
    
    const filteredItems = allItems.filter((item) => { return item.url.startsWith( '/' + url ) && item.url !==  '/' + url && item.url !== (path[0] === '/' ? path :  '/' + path) })
    
    let items = [];
    if (blok.tag){
        let tagitems = filteredItems.filter((item) => {
            return item.tags && item.tags.indexOf(blok.tag) >= 0
        })
        items = tagitems.map((item) => {

            let i = {
                title: item.title,
                url: item.url,
                content: {},
                img: item.img,
                sort: item.title,
                b_image : item.b_img,
                b_image_mobile : item.b_mimg,
                b_text : item.b_text,
                b_color : item.b_color
            }
            return i
    
        })
    }
    else {
        items = filteredItems.map((item) => {

            let i = {
                title: item.title,
                url: item.url,
                content: {},
                img: item.img,
                sort: item.title,
                b_image : item.b_img,
                b_image_mobile : item.b_mimg,
                b_text : item.b_text,
                b_color : item.b_color
            }
            return i
    
        })
    }
   
    

    items.sort((a, b) => ( a.sort - b.sort))
    if (blok.offset && blok.offset > 0){
        items = items.slice(blok.offset)
    }

    if (blok.max_items > 0){
        items = items.slice(0,blok.max_items)
    }

    

    if (blok.layout  === 'banner'){
        var item = items[0];   
        return (
            <SbEditable content={blok}>           

                <div className="banner" >
                    <div className='banner__bg'>
                        <picture>
                            <source media="(max-width: 767px)" srcSet={item.b_image_mobile} />
                            <source media="(min-width: 768px)" srcSet={item.b_image} />
                            <img src={item.b_image} alt="" />
                        </picture>
                        <div className="b__content__wrapper">
                            <aside className={`b__content`}>
                            <div className={`b__content__message ${item.b_color}`} > 
                                <div dangerouslySetInnerHTML={ MarkDown(item.b_text)} ></div>
                                <div class="cta-row" >
                                    <a class="cta button" href={item.url} >
                                        <span>{ t('stories.readMore', props.lang)}</span>   
                                    </a>
                                    {
                                        blok.more_text && 
                                        <Link className="cta button" to={ LinkFix(blok.root_page) }>{blok.more_text}</Link>
                                    }
                                </div>
                            </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </SbEditable>
        )        
    }
    else {
        return (

            <SbEditable content={blok}>           
                <div className="page-listing" >
                    <div className="pl-wrapper" >
                        <div className="pl__header">
                            <h2>{blok.listing_header}</h2>
                            { blok.more_text &&
                            <Link to={ LinkFix(blok.root_page) }>{blok.more_text} ⟶</Link>
                            }
                        </div>
                        { items.map((item, key) => {
                            let button = { component: 'button_link', link : { cached_url : item.url }, text: 'Read more' }
                            let buttons = { buttons : [button] };

                                if (blok.layout  === 'table' ){
                                    return(
                                        <div className="pl__item row" key={key}>
                                            <Link to={item.url} activeClassName="active" >
                                                <h5>{item.title}</h5>
                                            </Link>                                
                                        </div>
                                    )
    
                                }                                   
                                else if (blok.layout  && blok.layout !== 'normal' ){
                                    return (<div className="pl__item-large">
                                        <Link to={item.url} activeClassName="active" >
                                            { item.b_image && 
                                              <LazyLoad offset={1000} once>
                                                <img src={item.b_image} alt={ item.title } />
                                              </LazyLoad>
                                            }
                                            { !item.b_image && 
                                                <div className="placeholder"></div>
                                            }
                                        </Link>
                                        <div className="pl__item__text" dangerouslySetInnerHTML={ MarkDown(item.b_text)  } ></div>
                                        <ButtonRow content={buttons} />
                                    </div>)
                                }
                                else{
                                    return (<div className="pl__item" key={key} style={{ padding: (blok.padding || 0) + 'px'}}>
                                        <Link to={item.url} activeClassName="active" >
                                            { (item.img || item.b_image) &&
                                              <LazyLoad offset={1000} once>
                                                <img src={item.img || item.b_image} alt={item.title} />
                                              </LazyLoad>
                                            }
                                            { (!item.img && !item.b_image) &&
                                                <div className="placeholder"></div>
                                            }
                                            <h5>{item.title}</h5>
                                            <div className="pl__item__text" dangerouslySetInnerHTML={ MarkDown(item.text)  } ></div>
    
                                        </Link>                                
                                    </div>)
                                }
                        })}
                    </div>
                </div>
            </SbEditable>
        )
    }

   
}
export default ChapterHeading
