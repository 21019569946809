import React from 'react';
import { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import { PropsWithAos } from './type';

import './style.scss';

const Note = (props: PropsWithAos) => {
  const { aosFade } = props;

  const noteStyle: {
    color?: string;
    fontWeight?: any;
    fontSize?: string;
    lineHeight?: string;
    marginTop?: string;
    letterSpacing?: string;
    margin?: string;
  } = {
    fontWeight: props.noteFontWeight && TextFontWeight[props.noteFontWeight] ? TextFontWeight[props.noteFontWeight] : undefined,
    fontSize: props.noteFontSize,
    lineHeight: GetLineHeight(props.noteFontSize, props.noteLineHeight),
    color: props.noteColor,
    marginTop: props.noteMarginTop || undefined,
    letterSpacing: props.noteLetterSpacing,
    ...props.noteMargin ? { margin: props.noteMargin } : {},
  };
  return (
    <p
      className="cardh-note"
      data-aos={aosFade}
      data-aos-once
      style={noteStyle}
      dangerouslySetInnerHTML={{ __html: props.noteText || '' }}
    />
  );
};

export default Note;
