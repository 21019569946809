import React, { useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import LazyLoad from 'react-lazyload';
import ParallaxPicture from '../../../atoms/ParallaxPicture';
import _debounce from 'lodash.debounce';
import { DeviceInfo } from '../../../../hooks/device';
import './style.scss'

const lazyLoadOffset = 2500;

const ParallaxHeaderImage = ({blok}) => {
  const {
    headerImage,
    mobileHeaderImage,
    parallaxImage,
    mobileParallaxImage,
    parallaxOffset,
    parallaxFactor,
    mobileParallaxOffset,
    mobileParallaxFactor,
  } = blok;

  const { isMobile, height } = DeviceInfo();
  
  
  const parallax_offset = isMobile ? (mobileParallaxOffset || 80) : (parallaxOffset || 200);
  const parallax_factor = isMobile ? (mobileParallaxFactor || 0.1) : (parallaxFactor || 0.2);
  const bottom = isMobile ? parallax_offset*0.4 - (896 - height)*0.15 : parallax_offset*0.5;
  return (
    <SbEditable content={blok}>
      <div className="ec1706-parallax-header-image-wrapper">
        <div className="header-image-wrapper">
          <LazyLoad offset={lazyLoadOffset} once>
            <picture>
              <source media="(max-width: 767px)" srcSet={mobileHeaderImage || headerImage} />
              <source media="(min-width: 768px)" srcSet={headerImage} />
              <img className="image" src={headerImage} alt="" />
            </picture>
            <div className="parallax-wrapper" style={{ bottom:  `${-(bottom)}px`}}>
              <ParallaxPicture offset={parallax_offset} factor={parallax_factor}>
                <picture>
                  <source media="(max-width: 767px)" srcSet={mobileParallaxImage || parallaxImage} />
                  <source media="(min-width: 768px)" srcSet={parallaxImage} />
                  <img className="parallax-image" src={parallaxImage} alt="" />
                </picture>
              </ParallaxPicture>
            </div>
          </LazyLoad>
        </div>
      </div>
    </SbEditable>
  )
}

export default ParallaxHeaderImage;

