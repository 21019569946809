import React, { Component } from "react";
import SbEditable from "storyblok-react";
import Modal from "react-modal"
import DescItem from "./descItem";
import DescItemWithImg from "./descItemWithImg";
import DescItemWithSubscribe from "./descItemWithSuscribe";
import "./style.css";
import { isArguments } from "lodash";
import { isArray } from "lodash";
import { lock,unlock } from "./lock";
import {isIOS}from "../../../../helper/utils"
export default class ButtonPlayInformation extends Component {
    constructor(props){
      super(props)
      this.state = {
        showModal : false,
        scollbar: 0,
      }
    }

    componentDidMount(){
       this.setState({scollbar:document.documentElement.scrollWidth-document.documentElement.clientWidth})
    }
    handleCloseModal(){
       unlock(this.contentRef);
       this.setState({showModal:false})
       document.documentElement.style.marginRight=null;
    }
    handleOpenModal(){
       this.setState({showModal:true})
    }
    handleAfterOpen(){
        const {scollbar}=this.state;
        lock(this.contentRef);
        document.documentElement.style.marginRight=`${scollbar}px`
    }
    componentDidUpdate(){
        
    }
    render(){
        const { showModal } =this.state
        const { content, lang, buttonType} = this.props
        const { descItem, ModalType } = content        
                
        return (
            <SbEditable content={this.props} lang={lang}>
                <> 
                    <div className="modal-button-content">
                        <p className={`${content.buttonType || "modal-button-title"}`} onClick={()=>{this.handleOpenModal()}}><span  className="modal-button-title-wrap" dangerouslySetInnerHTML={{ __html: content.buttonText}}>
                        </span>
                        <span className="modal-button-icon"></span>
                        </p>
                    </div>
                    <Modal
                    isOpen={showModal}
                    overlayClassName="button-play-infomation-overlay"
                    className={ModalType === "teaser" ? "button-play-infomation-modal-teaser" : "button-play-infomation-modal"}
                    onRequestClose={()=>{this.handleCloseModal()}}
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
                    preventScroll={false}
                    shouldReturnFocusAfterClose={false}
                    bodyOpenClassName="button-play-infomation-open-body"
                    htmlOpenClassName="button-play-infomation-open-html"
                    contentRef={node=>{this.contentRef=node}}
                    ariaHideApp={false}
                    onAfterOpen={()=>{this.handleAfterOpen()}}
                    >
                        {/* <div className="modal-hidden-top"></div> */}
                        <div className="infomation-modal-closs-outsideBox" onClick={()=>{this.handleCloseModal()}}>
                            <div className="infomation-modal-closs"></div>
                        </div>
                        {/* 主标题 */}
                        <div className={ModalType === "teaser" ? "infomation-modal-content-teaser" : "infomation-modal-content"}> 
                            <h3 className="infomation-modal-title" dangerouslySetInnerHTML={{ __html: content.mainTitle}}></h3>
                            {/* 副标题及其描述   */}
                            {
                                descItem && isArray(descItem) &&
                                descItem.map((item)=>{
                                    if(item.component === 'desc_item')
                                    return(
                                      <DescItem content={item} lang={lang} key={item}></DescItem>
                                    )
                                    if(item.component === "descItemWithImg")
                                    return(
                                      <DescItemWithImg content={item} lang={lang} key={item}></DescItemWithImg>
                                    )
                                    if(item.component === "desc_item_with_subscribe")
                                    return(
                                      <DescItemWithSubscribe content={item} lang={lang} key={item} handleCloseModal={(e) => {this.handleCloseModal(e)}}></DescItemWithSubscribe>
                                    )
                                })
                            }
                        </div>
                        {/* <div className="modal-hidden-buttom"></div> */}
                    </Modal>
                </>
            </SbEditable>
        )
    }
}