import React from "react"
import SbEditable from "storyblok-react"
import Link from "gatsby-link"
import LinkFix from "../../utils/links"

import "./icon-listing.css"
import MarkDown from './markdown'

const ChapterHeading = props => (
  <SbEditable content={props.blok}>
    <div className="icon-list-wrapper" style={{ backgroundColor : props.blok.wrapper_background_color ? (props.blok.wrapper_background_color.color === '#f40000' ? null : props.blok.wrapper_background_color.color) : null  }}>
      <div className={`icon-listing ${props.blok.component} ${props.blok.icon_size} ${props.blok.text_color}`   }  style={{ backgroundColor : props.blok.background_color ? (props.blok.background_color.color === '#f40000' ? null : props.blok.background_color.color) : null  }}>
        {props.blok.items.map((item, key) => {        
          return (
            <SbEditable content={item}>
              <div key={key} className={`il__item ${item.text_width || ''}`}  style={{ padding : props.blok.icon_padding ? props.blok.icon_padding + 'px' : null }}>            
                {
                item.link.cached_url && (
                  <Link to={ LinkFix(item.link) }>
                    <img src={item.image} alt="" />
                    <h4>{item.title}</h4>
                  </Link>
                )}
                {item.image && !item.link.cached_url && (
                  <>
                    <img src={item.image} alt={item.title} />
                    <h4>{item.title}</h4>
                  </>
                )}
                { item.text && (
                  <div className="il__item__text" dangerouslySetInnerHTML={ MarkDown(item.text)  } ></div>
                )}
              </div>
            </SbEditable>

          )
        })}
      </div>
    </div>
  </SbEditable>
)

export default ChapterHeading
