import React, { useState } from "react";
import SbEditable from "storyblok-react";
import "./lens_comparison.css";
const ClientSideOnlyLazy = React.lazy(() => import("./lens_zoom"));

const ChapterHeading = (props) => {
  const { blok } = props;
  const data = blok.lens_listing;
  const isSSR = typeof window === "undefined";
  const [selectedIndex, changeImage] = useState(0);
  var zoomData = data[selectedIndex];

  return (
    <SbEditable content={blok}>
      <div className="lens-comparison">
        <article>
          <div className="container">
            {/* list-warp */}
            <div
              className={`arrow previous ${selectedIndex > 0 ? "show" : ""}`}
              onClick={() => changeImage(selectedIndex - 1)}
            ></div>
            <div className="items">
              {data.map((item, key) => (
                <div
                  data-image={item.id}
                  className={`item ${selectedIndex === key ? " selected" : ""}`}
                  style={{ transform: `translateX(${selectedIndex * -100}px)` }}
                  key={key}
                  onClick={() => changeImage(key)}
                >
                  <img src={item.icon} />
                  <p>
                    <strong>
                      {item.type} {item.focallength}
                    </strong>
                    <br />
                    <span>{item.zoom}&nbsp;</span>
                  </p>
                </div>
              ))}
            </div>
            <div
              className={`arrow next ${
                data.length - selectedIndex > 2 ? "show" : ""
              }`}
              onClick={() => changeImage(selectedIndex + 1)}
            ></div>
          </div>

          {zoomData && zoomData.id && !isSSR && (
            <React.Suspense fallback={<div />}>
              <ClientSideOnlyLazy
                data={data}
                blok={zoomData}
                selectedIndex={selectedIndex}
              />
            </React.Suspense>
          )}
        </article>
      </div>
    </SbEditable>
  );
};
export default ChapterHeading;
