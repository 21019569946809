import React from 'react';
import Placeholder from './PlaceHolder';

interface Props {
  afterLoad?: () => void;
  beforeLoad?: () => void;
  visibleByDefault?: boolean;
  className?: string;
  placeholder?: Element | null;
  style?: React.CSSProperties;
  threshold?: number;
  height?: string | number;
  width?: string | number;
}

interface State {
  visible: boolean;
}

class LazyLoadComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const {
      afterLoad,
      beforeLoad,
      visibleByDefault,
    } = props;

    this.state = { visible: visibleByDefault };

    if (visibleByDefault) {
      beforeLoad();
      afterLoad();
    }

    this.onVisible = this.onVisible.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
      this.props.afterLoad();
    }
  }

  onVisible() {
    this.props.beforeLoad();
    this.setState({ visible: true });
  }

  render() {
    if (this.state.visible) {
      return this.props.children;
    }

    const {
      className,
      height,
      placeholder,
      style,
      threshold,
      width,
    } = this.props;

    return (
      <Placeholder
        className={className}
        height={height}
        onVisible={this.onVisible}
        placeholder={placeholder}
        style={style}
        threshold={threshold}
        width={width}
      />
    );
  }
}

// @ts-ignore
LazyLoadComponent.defaultProps = {
  afterLoad: () => ({}),
  beforeLoad: () => ({}),
  visibleByDefault: false,
  threshold: 100,
  className: '',
  placeholder: null,
  style: {},
  height: undefined,
  width: undefined,
};

export default LazyLoadComponent;
