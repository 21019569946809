import 'intersection-observer';

export function checkElementInView(el) {
  if (!el) {
    return false;
  }
  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}

/**
 * 检查元素是否在可视范围内
 *
 * @param elm
 * @param offset (threshold)
 * @param viewOffset (相对于视口判断边界的 offset)
 * @returns {boolean|boolean}
 */
export function checkVisible(elm, offset, viewOffset) {
  if (!elm || typeof elm.getBoundingClientRect !== 'function') {
    return;
  }
  const windowTop = window.scrollY || window.pageYOffset;
  const windowBottom = windowTop + window.innerHeight;

  const borderOffset = viewOffset || 0;
  const borderTop = windowTop + borderOffset;
  const borderBottom = windowBottom - borderOffset;

  const visibleOffset = offset || 0;
  const offsetTop = elm.getBoundingClientRect().top + windowTop;
  const elmTop = offsetTop - visibleOffset;
  const elmBottom = offsetTop + elm.offsetHeight + visibleOffset;

  return elmTop < borderBottom && elmBottom > borderTop;
}

let isIE11 = false;
if (typeof window !== 'undefined') {
  isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
}
/*
 * 基于 IntersectionObserver 检查元素是否在可视范围内
 * 传统方案监听 onScroll 再获取 getBoundingClientRect() 会频繁触发强制回流，导致页面卡顿，基于 IntersectionObserver 进行检测不会出现此问题。
 * 注意：虽然此方案在现代浏览器下有性能提升，但 IE11 下仍然是通过传统方案进行 Polyfill，此方案不宜滥用。
 *
 * @param { elm } 需要检查的元素
 * @param { offset }  相对于可视区域的距离
 * @param { callback } 当可视状态变化时的回调
 * @param { threshold } 被检查元素交叉占比达到 threshold 时触发此回调
 * @param { defaultInViewOnIE } IE11是否默认直接到屏，由于 IE11 下仍然是通过传统方案进行 Polyfill，此方案不宜滥用，如果使用后出现 IE11 卡顿现象，请将此参数设置为 true
 * @returns null
 */
export function checkInView({
  elm,
  offset = 0,
  callback,
  threshold = 0,
  defaultInViewOnIE = false,
}) {
  if (!elm) {
    return;
  }

  if (isIE11 && defaultInViewOnIE) {
    return setTimeout(() => {
      // 模拟到屏对象
      const mockCallbackParams = {
        inView: true,
        lastEntry: {
          isIntersecting: true,
        },
        observer: {
          disconnect: () => {},
        },
        entries: [
          {
            isIntersecting: true,
          },
        ],
      };

      callback(mockCallbackParams);
    }, 10);
  }

  const options = {
    rootMargin: `${offset}px`,
    threshold,
  };

  const _callback = (entries, observer) => {
    const length = entries.length;
    const lastEntry = entries[length - 1];
    const inView = lastEntry.isIntersecting;
    const preserveObserver = callback({
      inView,
      lastEntry,
      observer,
      entries,
    });

    // 默认在进入交叉后，就关闭Observer，如果需要持续保留此观测者，callback函数需要返回 true
    if (lastEntry.isIntersecting && !preserveObserver) {
      observer.disconnect();
    }
  };

  const observer = new IntersectionObserver(_callback, options);
  observer.observe(elm);
}
/**
 * 检查视频是否在可播放范围内
 * 视频入屏时在video顶部距离屏幕底部 (pc端100px, m端48px) 时开始播放
 * 视频离屏时在video底部距离屏幕顶部 (pc端100px, m端48px) 时停止
 *
 * @param elm
 * @param offset
 * @param isMobile
 * @returns {boolean}
 */
export function checkVideoVisible(elm, offset, isMobile) {
  const viewOffset = isMobile ? 48 : 100;
  return checkVisible(elm, offset, viewOffset);
}
