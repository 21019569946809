/* eslint-disable complexity */
import * as React from 'react';
import VideoPlayer from '../../../video';
import LazyWrapper, { Props as LazyWrapperProps } from '../../../utils/LazyWrapper';
import { TextLineHeight, TextFontSize } from '../../../basic/Text';
import { getAsset, LinkFix } from '../../../../../utils/helper';

import {
  Props,
  TextColor,
  LinkColor,
  EyeBrowType,
  HeadlineType,
  VideoPlayType,
  IconLayout,
  CardLayout,
  CardMedia,
  VerticalAlign,
  HorizontalAlign,
  BackgroundSize,
  BackgroundRepeat,
  BackgroundFixed,
  ContentSize,
  ComponentLinkType,
  JumpType,
  ForegroundParallax,
  LeftCols,
  ForegroundLayout,
} from './type';
import CardMediaComp from './cardMedia';
import CardContentComp from './cardContent';

import './style.scss';

export interface State {
  hovering?: boolean;
}

export default class Card extends React.PureComponent<Props, State> {
  cardBgVideoDom: HTMLElement | null;

  cardForegroundVideo: React.RefObject<HTMLElement | null>;

  cardForegroundVideoReplay: React.RefObject<HTMLElement | null>;

  cardForegroundVideoPlay: React.RefObject<HTMLElement | null>;

  cardForegroundVideoPause: React.RefObject<HTMLElement | null>;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    leftCols: LeftCols['3'],
    contentSize: ContentSize.xl,
    verticalAlign: VerticalAlign['top-align'],
    horizontalAlign: HorizontalAlign['center-align'],
    canZoomImage: false,
    showZoomIconInMedia: false,
    zoomImageConfig: [],
    minHeight: '',
    marginTop: '0px',
    marginBottom: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    borderRadius: '0px',
    hasJumpLink: false,
    jumpType: JumpType.external,
    jumpLink: {},
    textColor: TextColor.black,
    linkColor: LinkColor.normal,
    linkFontSize: TextFontSize.default,
    linkLineHeight: TextLineHeight.default,
    backgroundColor: '',
    backgroundGradient: '',
    cardLayout: CardLayout['media-text'],
    hasBackgroundImage: false,
    backgroundImageWidth: '',
    backgroundImageHeight: '',
    backgroundImage: {},
    backgroundPosition: 'center',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,
    hasBackgroundVideo: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: false,
    backgroundVideoHoverPlay: true,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',
    hasMedia: true,
    media: CardMedia.picture,
    mediaborderRadius: '0px',
    mediaDistance: '32px',
    foregroundImage: {},
    foregroundImageAlt: '',
    foregroundHasParallax: false,
    foregroundParallax: ForegroundParallax['inner-content'],
    foregroundHeight: '100%',
    foregroundVideo: {},
    foregroundVideoWebm: {},
    foregroundVideoPoster: {},
    foregroundVideoPlayType: VideoPlayType.loop,
    foregroundLayout: ForegroundLayout.one,
    foregroundList: [],
    foregroundCredit: '',
    downgradeVideo: '',
    contentPaddingTop: '',
    contentPaddingBottom: '',
    contentPaddingLeft: '',
    contentPaddingRight: '',
    hasToppingImage: false,
    toppingImage: {},
    toppingImageHeight: '64px',
    hasTag: false,
    tagText: 'Tag',
    tagHeight: '24px',
    tagWidth: '45px',
    tagColor: '#BE4E04',
    tagRadius: '4px',
    hasEyeBrow: false,
    eyeBrowType: EyeBrowType['pure-text'],
    eyeBrowSVG: {},
    eyeBrowSVGHeight: '96px',
    eyeBrowText: 'Eyebrow',
    eyeBrowPureColor: 'rgba(0, 0, 0, 0.85)',
    eyeBrowGradientColor: '',
    eyeBrowFontSize: TextFontSize.default,
    eyeBrowFontWeight: '',
    eyeBrowLineHeight: TextLineHeight.default,
    hasHeadline: true,
    headlineType: HeadlineType['pure-text'],
    headlineSVG: {},
    headlineText: 'Headline',
    headlineSVGHeight: '32px',
    headlinePureColor: 'rgba(0, 0, 0, 0.85)',
    headlineGradientColor: '',
    headlineFontSize: TextFontSize.default,
    headlineFontWeight: '',
    headlineLineHeight: TextLineHeight.default,
    hasSubHeadline: false,
    subHeadlineType: HeadlineType['pure-text'],
    subHeadlineText: 'Subhead',
    subHeadlineColor: 'rgba(0, 0, 0, 0.85)',
    subHeadlineGradientColor: '',
    subHeadlineFontSize: TextFontSize.default,
    subHeadlineFontWeight: '',
    subHeadlineLineHeight: TextLineHeight.default,
    hasDescription: true,
    descriptionColor: 'rgba(0, 0, 0, 0.65)',
    descriptionText: 'Description',
    descriptionFontSize: TextFontSize.default,
    descriptionFontWeight: '',
    descriptionLineHeight: TextLineHeight.default,
    hasDividedLine: false,
    dividedBlockHeight: '48px',
    dividedLineWidth: '48px',
    dividedLineHeight: '1px',
    dividedLineRadius: '0',
    dividedLineColor: '',
    dividedLineGradientColor: '',
    hasCardLinks: false,
    buttonListTypeColumn: false,
    secondaryButtonClickID: '',
    thirdlyButtonClickID: '',
    links: [],
    hasNote: false,
    noteMarginTop: '',
    noteText: 'note',
    noteFontSize: TextFontSize.default,
    noteFontWeight: '',
    noteLineHeight: TextLineHeight.default,
    noteColor: 'rgba(0, 0, 0, 0.45)',
    hasIcons1: false,
    icons1MaxWidth: 'none',
    icons1Layout: IconLayout.vertical,
    icons1Height: '48px',
    icons1: [
      {
        hasTitle: true,
        hasPic: false,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        unit: 'm/s',
        title: 'title',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
        pic: {},
        desc: 'desc',
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
        pic: {},
        desc: 'desc',
      },
    ],
    hasIcons2: false,
    icons2MaxWidth: 'none',
    icons2Layout: IconLayout.vertical,
    icons2Height: '48px',
    icons2: [
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
        pic: {},
        desc: 'desc',
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
        pic: {},
        desc: 'desc',
      },
    ],
  };

  constructor(props: Props) {
    super(props);
    this.state = { hovering: false };
    this.handleCardClick = this.handleCardClick.bind(this);
    this.cardBgVideoDom = null;
    this.cardForegroundVideo = React.createRef();
    this.cardForegroundVideoReplay = React.createRef();
    this.cardForegroundVideoPlay = React.createRef();
    this.cardForegroundVideoPause = React.createRef();
  }

  componentDidMount(): void {}

  colsToWidth(col: any): number {
    const cardGapWidth = this.props.getGapWidth();
    const gridWidth = this.props.getGridWidth();
    const GAP_WIDTH = parseInt(cardGapWidth, 10);
    const GAP_COUNT = 11;
    let TOTAL_WIDTH = gridWidth;
    if (TOTAL_WIDTH.match(/vw|%/)) {
      TOTAL_WIDTH = parseInt(TOTAL_WIDTH, 10) * window.innerWidth;
    } else {
      TOTAL_WIDTH = parseInt(TOTAL_WIDTH, 10);
    }

    const colCount = GAP_COUNT + 1;
    const colWidth = (TOTAL_WIDTH - GAP_WIDTH * GAP_COUNT) / colCount;
    return colWidth * col + GAP_WIDTH * (col - 1);
  }

  handleCardClick(e: any): void {
    const { jumpLink, jumpType } = this.props;
    if (!e || !e.target || !jumpLink) {
      return;
    }
    let target = e.target as any;

    if (target.nodeName) {
      if (
        target.nodeName === 'A'
        || target.nodeName === 'BUTTON'
        || target.className.includes('media-modal-container')
        || target.getAttribute('data-link')
      ) {
        return;
      }
    }
    while (target.offsetParent) {
      target = target.offsetParent;
      if (
        target.nodeName === 'A'
        || target.nodeName === 'BUTTON'
        || target.className.includes('media-modal-container')
        || target.getAttribute('data-link')
      ) {
        return;
      }
    }
    if (typeof window !== 'undefined') {
      const targetLink = LinkFix(jumpLink);
      if (jumpType === JumpType.external) {
        window.open(targetLink);
      } else {
        window.location.href = targetLink;
      }
    }
  }

  renderCardInnerContent(): JSX.Element {
    const { props } = this;
    const aosFade = props.getFadeTransiation();
    const wrapperStyle: {
      minHeight?: string;
      marginTop: string;
      marginBottom: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
      overflow: string;
    } = {
      marginTop: props.marginTop,
      marginBottom: props.marginBottom,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
      overflow: 'hidden',
    };

    if (props.minHeight) {
      wrapperStyle.minHeight = props.minHeight;
    }
    const leftWidth = this.colsToWidth(props.leftCols);
    const rightWidth = `calc(100% - ${props.mediaDistance} - ${leftWidth}px)`;
    const cardVerticalAlignStyleName = `cardh-vertical-${props.verticalAlign}`;

    const { leftCols } = this.props;
    const hasMedia
      = props.hasMedia
      && !(props.cardLayout === CardLayout['media-text'] && leftCols === '0')
      && !(props.cardLayout === CardLayout['text-media'] && leftCols === '12');
    const hasText
      = !(props.cardLayout === CardLayout['media-text'] && leftCols === '12')
      && !(props.cardLayout === CardLayout['text-media'] && leftCols === '0');

    let contentWidth
      = props.cardLayout === CardLayout['text-media']
        ? `${leftWidth}px`
        : rightWidth;
    let mediaWidth
      = props.cardLayout === CardLayout['media-text']
        ? `${leftWidth}px`
        : rightWidth;
    if (!hasMedia) {
      contentWidth = '100%';
    }
    if (!hasText) {
      mediaWidth = '100%';
    }

    const mediaRefs = {
      cardForegroundVideo: this.cardForegroundVideo,
      cardForegroundVideoReplay: this.cardForegroundVideoReplay,
      cardForegroundVideoPlay: this.cardForegroundVideoPlay,
      cardForegroundVideoPause: this.cardForegroundVideoPause,
    };
    const media = hasMedia ? (
      <CardMediaComp
        {...props}
        ref={mediaRefs}
        aosFade={aosFade}
      />
    ) : null;
    const content = hasText ? (
      <CardContentComp
        {...props}
        aosFade={aosFade}
        contentWidth={contentWidth}
      />
    ) : null;

    const renderCredit = () => {
      const mediaSide = (
        <div
          className="cardh-credit-container"
          style={{
            width: mediaWidth,
            marginRight:
              props.cardLayout === CardLayout['media-text']
                ? props.mediaDistance
                : '',
          }}
        >
          <span
            className="cardh-credit-content"
            dangerouslySetInnerHTML={{ __html: props.foregroundCredit || '' }}
          />
        </div>
      );
      const contentSide = (
        <div
          style={{
            width: contentWidth,
            marginRight:
              props.cardLayout === CardLayout['text-media']
                ? props.mediaDistance
                : '',
          }}
        />
      );

      return (
        <div className="cardh-credit-wrapper">
          {
            props.cardLayout === CardLayout['media-text']
              ? [mediaSide, contentSide]
              : [contentSide, mediaSide]
          }
        </div>
      );
    };

    return (
      <>
        <div
          className={`cardh-card-wrapper ${cardVerticalAlignStyleName}`}
          style={wrapperStyle}
        >
          {props.cardLayout === CardLayout['media-text']
            ? [media, content]
            : [content, media]}
        </div>
        {props.foregroundCredit && renderCredit()}
      </>
    );
  }

  render(): JSX.Element {
    const { props } = this;
    let backgroundImage;

    const { visibleByDefault } = props;
    const containerStyle: {
      borderRadius: string;
      backgroundColor: string;
      backgroundGradient?: string;
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundAttachment?: string;
      cursor?: string;
      outline?: string;
    } = {
      borderRadius: props.borderRadius,
      backgroundColor: props.backgroundColor,
    };

    const { hovering } = this.state;

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = getAsset(props.backgroundImage);
      containerStyle.backgroundPosition = props.backgroundPosition;
      containerStyle.backgroundSize
        = props.backgroundSize === BackgroundSize['auto-fit']
          ? 'cover'
          : props.backgroundSizeCustom;
      containerStyle.backgroundRepeat = props.backgroundRepeat;
      containerStyle.backgroundAttachment
        = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';
      if (props.backgroundGradient) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    const videoPosterAttr = {};
    videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster']
      = getAsset(props.backgroundVideoPoster);
    const renderCardBackgroundVideo = (): JSX.Element => (
      <div className="cardh-card-background-video">
        <VideoPlayer
          visiblePlay={props.backgroundVideoVisiblePlay}
          replayWhenRevisible={props.backgroundVideoVisiblePlay}
          triggerPlay={props.backgroundVideoHoverPlay && hovering}
          triggerPause={props.backgroundVideoHoverPlay && !hovering}
          lazyLoad
          lazyLoadThreshold={2000}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <video
            muted
            ref={(i) => {
              this.cardBgVideoDom = i;
            }}
            {...videoPosterAttr}
            loop={props.backgroundVideoLoop}
            autoPlay={
              !props.backgroundVideoVisiblePlay
              && !props.backgroundVideoHoverPlay
            }
          >
            {getAsset(props.backgroundVideo) && <source
              type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'
              data-layzr-src={getAsset(props.backgroundVideo)}
            />}
          </video>
        </VideoPlayer>

        <div
          className="cardh-card-video-mask"
          style={{
            backgroundColor: props.backgroundVideoMaskColor,
            opacity: props.backgroundVideoMaskOpacity,
          }}
        />
      </div>
    );

    const backgroundVideo = props.hasBackgroundVideo
      ? renderCardBackgroundVideo()
      : null;

    let compProps: LazyWrapperProps = {
      type: 'bg',
      visibleByDefault,
      parallax: props.hasBackgroundImage && props.backgroundParallax,
      src: backgroundImage,
      bgWidth: props.backgroundImageWidth,
      bgHeight: props.backgroundImageHeight,
      style: containerStyle,

      onMouseEnter: () => {
        (props.hasBackgroundVideo && props.backgroundVideoHoverPlay)
          && this.setState({ hovering: true });
      },
      onMouseLeave: () => {
        (props.hasBackgroundVideo && props.backgroundVideoHoverPlay)
          && this.setState({ hovering: false });
      },
    };

    if (props.hasJumpLink) {
      compProps = {
        ...compProps,
        role: 'button',
        tabIndex: 0,
        style: {
          ...containerStyle,
          cursor: 'pointer',
          outline: 'none',
        },
        'data-ga-label': props?.jumpLinkGaLabel || `card-${props.index}-link`,
        onClick: this.handleCardClick,
        onKeyDown: this.handleCardClick,
      };
    }

    return (
      <LazyWrapper
        className="cardh-card-container"
        {...compProps}
      >
        {backgroundVideo}
        {this.renderCardInnerContent()}
      </LazyWrapper>
    );
  }
}
