import React from 'react';
import { PropsWithAos } from './type';

import './style.scss';

const Tag = (props: PropsWithAos) => {
  const { aosFade } = props;

  const tagStyle = {
    minWidth: props.tagWidth,
    height: props.tagHeight,
    backgroundColor: props.tagColor,
    borderRadius: props.tagRadius,
  };

  return (
    <div data-aos={aosFade} data-aos-once className="cardh-tag-container">
      <p style={tagStyle} className="cardh-tag-content" dangerouslySetInnerHTML={{ __html: props.tagText || '' }} />
    </div>
  );
};

export default Tag;
