import LazyLoad from "react-lazyload";
import React, { useEffect, useRef } from "react";
import SbEditable from "storyblok-react";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../button";
import { getAsset } from "../../../utils/helper";
import { DeviceInfo } from "../../../hooks/device";
import "./style.scss";

interface ExtraInfo {
  /** 组件 unique id */
  _uid: string;
  /** 组件名称 */
  component: string;
}
interface BgImage {
  /** 图片id */
  id: number;
  /** 文件名 */
  filename: string;
}
interface CategoryItem extends ExtraInfo {
  /** 分类标题 */
  category: string;
  /** 描述 */
  description?: string;
  /** pc端背景图片 */
  bgImage?: BgImage;
  /** m端背景图片 */
  mobileBgImage?: BgImage;
  /** 按钮 */
  button: any[];
}
interface Blok extends ExtraInfo {
  /** 标题 */
  title?: string;
  /** 描述 */
  description?: string;
  /** 分类列表 */
  categoryList?: CategoryItem[];
  /** pc 端组件上方预留空间 */
  sectionPaddingTop: string;
  /** m 端组件上方预留空间 */
  sectionPaddingTopMobile: string;
  /** pc 端组件下方预留空间 */
  sectionPaddingBottom: string;
  /** m 端组件下方预留空间 */
  sectionPaddingBottomMobile: string;
}
interface CompetitionCategoryProps {
  blok: Blok;
}

// 最多支持配置10个，超过10个的内容不渲染
const MAX_CATEGORY_COUNT = 10;
// FIXME: 由于@types/react版本较旧，使用 React.FC<CompetitionCategoryProps> 会报错，临时使用 any 解决
const PcCompetitionCategory: any = ({ blok }: CompetitionCategoryProps) => {
  const {
    title,
    description,
    categoryList,
    sectionPaddingTop,
    sectionPaddingBottom,
  } = blok;
  return (
    <div
      className="pc-content"
      style={{
        paddingTop: sectionPaddingTop,
        paddingBottom: sectionPaddingBottom,
      }}
    >
      {title && <h1 className="title">{title}</h1>}
      {description && <p className="description">{description}</p>}
      {categoryList && categoryList.length && (
        <ul className="category-list">
          {categoryList.slice(0, MAX_CATEGORY_COUNT).map((item) => {
            const hasButton = !!item.button.length;
            return (
              <li className="category-item" key={item._uid}>
                <div
                  className="picture-block"
                  style={{
                    backgroundImage: `url(${getAsset(item.bgImage)})`,
                  }}
                >
                  {/* 有按钮或描述时有遮罩 */}
                  {(hasButton || item.description) && (
                    <div className="mask">
                      <div className="detail">
                        {item.description && (
                          <div
                            className={`text ${hasButton && "text-with-space"}`}
                          >
                            {item.description}
                          </div>
                        )}
                        {/* 只支持配置一个按钮 */}
                        {hasButton && <Button content={item.button[0]} />}
                      </div>
                    </div>
                  )}
                </div>
                {item.category && (
                  <div className="category-title">{item.category}</div>
                )}
              </li>
            );
          })}
          {/* 奇数项新增一个空 li */}
          {categoryList.length % 2 === 1 && <li className="category-item" />}
        </ul>
      )}
    </div>
  );
};

// FIXME: 由于@types/react版本较旧，使用 React.FC<CompetitionCategoryProps> 会报错，临时使用 any 解决
const MobileCompetitionCategory: any = ({ blok }: CompetitionCategoryProps) => {
  const { isMobile, width: deviceWidth } = DeviceInfo();
  const carouselDescWrapperRef = useRef<HTMLDivElement>(null);
  const carouselControlBoxRef = useRef<HTMLDivElement>(null);
  const {
    title,
    description,
    categoryList,
    sectionPaddingTopMobile,
    sectionPaddingBottomMobile,
    _uid: id,
  } = blok;
  const descList: (HTMLDivElement | null)[] = [];
  const carouselList: (HTMLDivElement | null)[] = [];
  const hasSwiperList = Array.isArray(categoryList) && categoryList.length > 0;
  const hasSwiperController =
    Array.isArray(categoryList) && categoryList.length > 1;
  const params = {
    initialSlide: 0,
    speed: 600,
    spaceBetween: 8,
    slidesPerView: "auto",
    centeredSlides: true,
    preventClicks: false,
    slideToClickedSlide: true,
    preventClicksPropagation: true, // 防止拖拽 swiper 时触发 zoomImage 弹窗
    touchMoveStopPropagation: true,
    pagination: {
      el: `.carousel-pagination-${id}`,
      clickable: true,
    },
    onSlideChange: (swiper) => {
      const { realIndex } = swiper;
      /* 描述文字显隐  */
      for (let i = 0; i < descList.length; i += 1) {
        if (descList[i]?.classList.contains("active")) {
          descList[i]?.classList.remove("active");
        }
      }
      descList[realIndex]?.classList.add("active");
    },
  };
  const showList = (categoryList || []).slice(0, MAX_CATEGORY_COUNT);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    // 小屏文案超出时处理
    const carouselDescWrapperDom = carouselDescWrapperRef.current;
    if (carouselDescWrapperDom) {
      let maxHeight = 0;
      // 取出最高的子元素高度作为父容器高度
      Array.from(carouselDescWrapperDom.children).forEach((childNode) => {
        maxHeight = Math.max(maxHeight, childNode.clientHeight || 0);
      });
      carouselDescWrapperDom.style.height = `${maxHeight + 1}px`;
    }
  }, [isMobile, deviceWidth]);

  return (
    <div
      className="m-content"
      style={{
        paddingTop: sectionPaddingTopMobile,
        paddingBottom: sectionPaddingBottomMobile,
      }}
    >
      {title && <h1 className="title">{title}</h1>}
      {description && <p className="description">{description}</p>}
      <div className="section-carousel-groups-content">
        <Swiper {...params}>
          {hasSwiperList &&
            showList.map((item, index) => (
              <SwiperSlide key={`${id}-carousel-${index}`}>
                <div
                  className="carousel-image"
                  ref={(c) => {
                    carouselList[index] = c;
                  }}
                >
                  <LazyLoad offset={1000} once>
                    <div
                      className="section-carousel-img"
                      style={{
                        backgroundImage: `url(${getAsset(item.mobileBgImage)})`,
                      }}
                    />
                  </LazyLoad>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="carousel-control-wrapper">
          <div
            className="carousel-desc-wrapper"
            ref={carouselDescWrapperRef}
            style={{ height: 0 }}
          >
            {hasSwiperList &&
              showList.map((item, index) => {
                const hasButton = !!item.button.length;
                return (
                  <div
                    key={index}
                    className={`carousel-item-desc ${
                      index === 0 ? "active" : ""
                    }`}
                    ref={(c) => {
                      descList[index] = c;
                    }}
                  >
                    <div
                      className="category-title"
                      style={{
                        width: carouselControlBoxRef.current
                          ? `calc(100% - 8px - ${carouselControlBoxRef.current.clientWidth}px)`
                          : "100%",
                      }}
                    >
                      {item.category ? item.category : <>&nbsp;</>}
                    </div>
                    {item.description && (
                      <div className="text">{item.description}</div>
                    )}
                    {/* 只支持配置一个按钮 */}
                    {hasButton && <Button content={item.button[0]} />}
                  </div>
                );
              })}
          </div>
          {/* pagination */}
          {hasSwiperController && (
            <div className="carousel-control-box" ref={carouselControlBoxRef}>
              <div
                className={`carousel-pagination-${id} carousel-pagination`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CompetitionCategory: React.FC<CompetitionCategoryProps> = (props) => {
  const { blok } = props;
  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className="competition-category-wrapper">
        {/* 768px 以上的设备展示 pc 端组件，m 端组件隐藏 */}
        <PcCompetitionCategory {...props} />
        {/* 768px 以下的设备展示 m 端组件，pc 端组件隐藏 */}
        <MobileCompetitionCategory {...props} />
      </section>
    </SbEditable>
  );
};

export default React.memo(CompetitionCategory);
