import React from 'react';
import { PropsWithAos } from './type';

import './style.scss';

const Divider = (props: PropsWithAos) => {
  const { aosFade } = props;

  const linerStyle: {
    height?: string;
    borderRadius?: string;
    width?: string;
    backgroundColor?: string;
    backgroundImage?: string;
  } = {
    height: props.dividedLineHeight,
    borderRadius: props.dividedLineRadius,
    width: props.dividedLineWidth,
    backgroundColor: props.dividedLineColor,
    backgroundImage: props.dividedLineGradientColor,
  };

  return (
    <div data-aos={aosFade} data-aos-once className="cardh-divider-container" style={{ height: props.dividedBlockHeight }}>
      <div className="cardh-divider" style={linerStyle} />
    </div>
  );
};

export default Divider;
