/* eslint-disable */
// @ts-nocheck
import React from 'react';
import VideoPlayer from '../../video';
import LazyWrapper from '../../utils/LazyWrapperScroll';
import { getAsset, styles } from '../../../../utils/helper';
import classNames from 'classnames';
import Tabs from './tab';
import Card from '../InfoCardHorizontal/card';

import './style.scss';
import { StoryblokAsset } from '../InfoCardHorizontal/card/type';

export enum tabPosition {
  'top' = 'top',
  'center' = 'center',
}

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
  'right' = 'right',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabPresentation {
  'carousel' = 'carousel',
  'manual-switch' = 'manual-switch',
}

export enum fadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}
export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum componentHorizontalAlign {
  'left-align' = 'left-align',
  'center-align' = 'center-align',
  'right-align' = 'right-align',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}

export interface Props {
  id?: string;
  // base
  gridWidth?: string;
  cardGapWidth?: string; // 槽宽
  componentMinHeight?: string; // 最小高度
  pureBackgroundColor?: string; // 背景颜色
  backgroundGradient?: string; // 背景渐变颜色
  // 背景图片
  hasBackgroundImage: boolean; // 是否需要背景图
  backgroundImage?: StoryblokAsset;
  backgroundPosition?: string; // 背景位置
  backgroundSize?: BackgroundSize; // 背景尺寸
  backgroundSizeCustom?: string; // 背景自定义属性
  backgroundRepeat?: BackgroundRepeat; // 背景重复方式
  backgroundFixed?: BackgroundFixed; // 背景是否固定
  // 背景视频
  hasBackgroundVideo: boolean; // 是否需要背景视频
  backgroundVideoLoop?: boolean; // 视频循环播放
  backgroundVideoVisiblePlay?: boolean; // 页面滚出后播放
  backgroundVideoHoverPlay?: boolean; // 鼠标hover播放
  backgroundVideo?: StoryblokAsset; // 背景视频
  backgroundVideoPoster?: StoryblokAsset; // 视频占位图

  // 内边距
  paddingTop: string; // 上方内边距
  paddingBottom: string; // 下方内边距
  paddingLeft: string; // 左边内边距
  paddingRight: string; // 右边内边距

  // 内容对齐
  componentHorizontalAlign: componentHorizontalAlign;

  // 模块过渡
  fadeTransiation: fadeTransiation;
  mediaTransiation: fadeTransiation;

  // tab 结构
  tabDefaultActiveIndex?: number;
  tabPosition: tabPosition; // tab 位置
  tabAlign: tabAlign; // tab对齐方式
  intervalBisection?: boolean; // 是否间距平分
  tabOverallWidth?: string; // tab 总宽度
  spacingFromTheCard?: string; // tab 与卡片的间距
  tabBarContent?: tabBarContent; // tabbar的内容选中
  tabBarIcon?: StoryblokAsset;
  tabBarIconHeight?: string; // tabBar icon 高度
  tabTheme: tabTheme; // tab标题颜色
  tabDesc?: boolean; // tab 描述
  tabPresentation: tabPresentation; // tab的展示方式
  tabCarouselInterval?: string; // 轮播间隔时长
  hideTabBarWhenSingle?: boolean; // 单个时是否不展示tab

  // Tab bar、desc、卡片内容
  cardConfigList?: Array<Record<string, any>>;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export interface State {
  hovering?: boolean;
  contentHeight: number;
}
export default class TabHorizontal extends React.Component<Props, State> {
  activeCard: HTMLElement | null;

  content: Array<HTMLElement> | Array<null>;

  static defaultProps = {
    cardGapWidth: '16px',
    componentMinHeight: '',
    pureBackgroundColor: '',
    backgroundGradient: '',
    gridWidth: '960px',

    // 背景图片
    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundPosition: '',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,

    // 背景视频
    hasBackgroundVideo: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: true,
    backgroundVideoHoverPlay: false,
    backgroundVideo: {},
    backgroundVideoPoster: {},

    // 内容对齐
    componentHorizontalAlign: componentHorizontalAlign['center-align'],

    // 内边距
    paddingTop: '128px',
    paddingBottom: '128px',
    paddingLeft: '',
    paddingRight: '',

    // 模块过渡
    fadeTransiation: fadeTransiation.none,
    mediaTransiation: fadeTransiation.none,

    // 卡片
    cardConfigList: [{
      ...Card.defaultProps,
      ...{
        tabBarIcon: {},
        tabBarHoverIcon: {},
        tabBarTitle: 'tab name1',
        tabDesc: 'tab desc1',
        tabKey: 'tab1',
      },
    }, {
      ...Card.defaultProps,
      ...{
        tabBarIcon: {},
        tabBarHoverIcon: {},
        tabBarTitle: 'tab name2',
        tabDesc: 'tab desc2',
        tabKey: 'tab2',
      },
    }],

    // 结构
    tabPosition: tabPosition.center,
    tabAlign: tabAlign.center,
    intervalBisection: false,
    tabOverallWidth: '960px',
    spacingFromTheCard: '32px',
    tabBarContent: tabBarContent.headline,
    tabBarIconHeight: '48px',
    tabTheme: 'dark',
    tabDesc: true,
    tabPresentation: tabPresentation['manual-switch'],
    tabCarouselInterval: '5000',
    tabDefaultActiveIndex: 1,
    hideTabBarWhenSingle: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      hovering: false,
      contentHeight: 0,
    };

    this.content = [];
  }

  setContentHeight(height: number): void {
    const { contentHeight } = this.state;
    if (height > contentHeight) {
      this.setState({ contentHeight: height });
    }
  }

  render(): JSX.Element {
    const { TabPanel } = Tabs;
    const { props } = this;
    const { hovering } = this.state;
    const aosFade = props.fadeTransiation;

    const { visibleByDefault, id } = props;
    let backgroundImage;

    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
      backgroundAttachment?: string;
    } = { backgroundColor: props.pureBackgroundColor };

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = `url('${getAsset(props.backgroundImage)}')`;
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;
      componentStyle.backgroundAttachment = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';
      if (props.backgroundGradient) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    const componentWrapperStyle: {
      minHeight?: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight?: string;
      width?: string;
    } = {
      minHeight: props.componentMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
      width: props.gridWidth,
    };

    const tabCls = classNames(
      'tabh-e-tab-horizontal-wrapper',
    );

    const sectionCls = classNames(
      'tabh-atom-horiozntal-tab-component',
      `tabh-e-vertical-${props.componentHorizontalAlign}`,
      'atom-design-v1',
    );

    const videoPosterAttr = {};
    videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster'] = getAsset(props.backgroundVideoPoster);

    const tabContent = (props.cardConfigList || []).map(({
      tabBarIcon, tabBarHoverIcon, tabBarTitle, tabDesc, tabKey,
    }) => ({
      tabBarIcon, tabBarHoverIcon, tabBarTitle, tabDesc, tabKey,
    })) as TabPanelItem[];

    const tabLength = tabContent.length;
    const propsDefaultActiveIndex = isNaN(+props.tabDefaultActiveIndex) ? 0 : parseInt(String(+props.tabDefaultActiveIndex));
    const defaultActiveIndex = propsDefaultActiveIndex <= tabLength && propsDefaultActiveIndex >= 1 ? propsDefaultActiveIndex - 1 : 0;
    tabContent?.forEach((item, index) => {
      item.tabKey = item.tabKey || `tab${index}`;
    });

    return (
      <LazyWrapper
        type="bg"
        visibleByDefault={visibleByDefault}
        src={backgroundImage}
        style={componentStyle}
        className={sectionCls}
      >
        { props.hasBackgroundVideo ? (
          <div className="background-video-wrapper tabh-e-background-video">
            <VideoPlayer
              visiblePlay={props.backgroundVideoVisiblePlay}
              replayWhenRevisible={props.backgroundVideoVisiblePlay}
              triggerPlay={props.backgroundVideoHoverPlay && hovering}
              triggerPause={props.backgroundVideoHoverPlay && !hovering}
              style={{
                postion: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <video muted {...videoPosterAttr} loop={props.backgroundVideoLoop} autoPlay={!props.backgroundVideoVisiblePlay && !props.backgroundVideoHoverPlay}>
                {getAsset(props.backgroundVideo) && (
                  <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' src={getAsset(props.backgroundVideo)} />
                )}
              </video>
            </VideoPlayer>
          </div>
        ) : null}

        <div style={componentWrapperStyle} className={tabCls} data-aos={aosFade} data-aos-once={true}>
          <Tabs
            id={props.id}
            responsiveBorder={props.responsiveBorder}
            tabAlign={props.tabAlign}
            tabTheme={props.tabTheme}
            tabPosition={props.tabPosition}
            spacingFromTheCard={props.spacingFromTheCard}
            tabBarIconHeight={props.tabBarIconHeight}
            tabBarContent={props.tabBarContent}
            intervalBisection={props.intervalBisection}
            tabOverallWidth={props.tabOverallWidth}
            defaultActiveKey={tabContent[defaultActiveIndex].tabKey}
            tabContent={tabContent}
            cardConfigList={props.cardConfigList}
            tabPresentation={props.tabPresentation}
            tabCarouselInterval={props.tabCarouselInterval}
            fadeTransiation={props.fadeTransiation}
            mediaTransiation={props.mediaTransiation}
            cardGapWidth={props.cardGapWidth}
            gridWidth={props.gridWidth}
            hideTabBarWhenSingle={props.hideTabBarWhenSingle}
          >
            {tabContent.map((tabItem, index) => {
              const height = this.content && this.content[index] && this.content[index].offsetHeight;
              return (
                <TabPanel defaultActiveKey={tabContent[defaultActiveIndex].tabKey} height={height} tab={props.tabBarContent !== 'none' ? tabItem.tabBarTitle : null} tabBarIcon={props.tabBarContent !== 'none' ? tabItem.tabBarIcon : null} tabBarHoverIcon={props.tabBarContent !== 'none' ? tabItem.tabBarHoverIcon : null} key={tabItem.tabKey}>
                  {
                    props.tabBarContent !== 'none'
                      ? <div
                        className="tabh-e-tab-content"
                        style={styles(props.tabContentStyle)}
                        ref={(elm) => { this.content[index] = elm; }}
                        dangerouslySetInnerHTML={{ __html: props.tabDesc ? tabItem.tabDesc : null }}
                      /> : null
                  }
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </LazyWrapper>
    );
  }
}
