import {isIOS}from "../../../../helper/utils"

let initialClientY = 0
let initialClientX = 0
let lockedNum = 0
let documentListenerAdded = false
const lockedElements = []
const eventListenerOptions = { passive: false }
const handleScroll = (event, targetElement) => {
    if (targetElement) {
        const {
            scrollTop,
            scrollLeft,
            scrollWidth,
            scrollHeight,
            clientWidth,
            clientHeight,
        } = targetElement
        const clientX = event.targetTouches[0].clientX - initialClientX
        const clientY = event.targetTouches[0].clientY - initialClientY
        const isVertical = Math.abs(clientY) > Math.abs(clientX)

        const isOnTop = clientY > 0 && scrollTop === 0
        const isOnLeft = clientX > 0 && scrollLeft === 0
        const isOnRight = clientX < 0 && scrollLeft + clientWidth + 1 >= scrollWidth
        const isOnBottom = clientY < 0 && scrollTop + clientHeight + 1 >= scrollHeight
        if (
            (isVertical && (isOnTop || isOnBottom)) ||
            (!isVertical && (isOnLeft || isOnRight))
        ) {
            return preventDefault(event)
        }
    }

    event.stopPropagation()
    return true
}
const preventDefault = (event) => {
    if (!event.cancelable) return

    event.preventDefault()
}
const lock = (targetElement) => {
    if (typeof window === 'undefined') return
    if (isIOS().ios) {
        // iOS
        if (targetElement) {
            const elementArray = Array.isArray(targetElement) ? targetElement : [targetElement]

            elementArray.forEach((element) => {
                if (element && lockedElements.indexOf(element) === -1) {
                    element.ontouchstart = (event) => {
                        initialClientY = event.targetTouches[0].clientY
                        initialClientX = event.targetTouches[0].clientX
                    }

                    element.ontouchmove = (event) => {
                        if (event.targetTouches.length !== 1) return
                        handleScroll(event, element)
                    }

                    lockedElements.push(element)
                }
            })
        }

        if (!documentListenerAdded) {
            document.addEventListener('touchmove', preventDefault, eventListenerOptions)
            documentListenerAdded = true
        }
    }
    lockedNum += 1
}

const unlock = (targetElement) => {
    if (typeof window === 'undefined') return
    lockedNum -= 1

    if (lockedNum > 0) return
    if (!isIOS().ios)return
    // iOS
    if (targetElement) {
        const elementArray = Array.isArray(targetElement) ? targetElement : [targetElement]

        elementArray.forEach((element) => {
            const index = lockedElements.indexOf(element)

            if (index !== -1) {
                element.ontouchmove = null
                element.ontouchstart = null
                lockedElements.splice(index, 1)
            }
        })

    }

    if (documentListenerAdded) {
        document.removeEventListener('touchmove', preventDefault, eventListenerOptions)
        documentListenerAdded = false
    }
}

export {lock,unlock}