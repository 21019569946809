import React from "react"
import SbEditable from "storyblok-react"
import ButtonRow from "./button_row"
import MarkDown from './markdown'
import './video_block.css'

export default class extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          isPlaying : false
        }
      }
    playVideo() {
        if (this.state.isPlaying){
            this.setState({ isPlaying: false })
            this.refs.video.pause();
        }
        else{
            this.setState({ isPlaying: true })
            this.refs.video.play();
        }
    }

    render() {

        const { key, blok } = this.props
        return (
            <SbEditable content={blok}>
                <div key={key} className={`et video ${blok.align} ${blok.width} ${blok.text_color}`} style={{ backgroundColor : blok.background_color || null}} >
                    <video src={blok.preview_video} autoPlay={true} muted={true} loop={true} style={{ display : !this.state.isPlaying ? 'block' : 'none' }}></video>
                    <video src={blok.video} ref="video" autoPlay={this.state.isPlaying} muted={false} loop={false} style={{ display : this.state.isPlaying ? 'block' : 'none' }}></video>
                    <div className='video-controls'>
                        <button className='video-play' onClick={this.playVideo.bind(this)} style={{ display : this.state.isPlaying ? 'block' : 'none' }}></button>
                        <button className='video-play paused' onClick={this.playVideo.bind(this)} style={{ display : !this.state.isPlaying ? 'block' : 'none' }}></button>

                    </div>
                    <div dangerouslySetInnerHTML={ MarkDown(blok.text) } ></div>
                    {blok.button_row && blok.button_row.map((row) =>{
                            return (
                                <ButtonRow content={row}  /> 
                            )
                        }
                    )}
                </div>
            </SbEditable>
        )
    }
}

