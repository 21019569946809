import React, { useState } from "react"
import SbEditable from "storyblok-react"
import ButtonRow from "./button_row"
import MarkDown from './markdown'
import Visibility from 'react-visibility-sensor'
import './image.css'
import LazyLoad from "react-lazyload"

const ClientSideOnlyLazy = React.lazy(() =>
  import("./zoom")
)



const Image = ({blok, uid, modalModeOn}) => {
    
    const [visibility, show] = useState(false);
    const [isActive, setActive] = useState(blok.animation && blok.animation !== 'none');
    const isSSR = typeof window === "undefined"    

    const isVisible = (visible) => {
      if (visible === true){
        show(true);
        setActive(false)
      }
    }
    
    const isAnimated = () =>{
      return blok.animation && blok.animation !== 'none' //this.props.blok.video_mode === 'play-visible'
    }
      return (
        <SbEditable content={blok}  key={uid} >
            <div className={`ei ${blok.zoom_image_id ? 'zoom' : null} ${blok.align} ${blok.width}`} style={{ backgroundColor : blok.background_color || null , padding : (blok.padding ? blok.padding + 'px' : null) || null }} >
                { !blok.zoom_image_id && (
                  <Visibility active={isActive} onChange={isVisible} partialVisibility={'bottom'} offset={{bottom:-400}}  >
                    <LazyLoad offset={2000} once>
                      <picture>
                        <source media="(max-width: 767px)" srcSet={ blok.mobile_image || blok.image } />
                        { blok.image && <source media="(min-width: 768px)" srcSet={blok.image} /> }
                        { blok.image && <img style={{ display: blok.image_display || null }} src={blok.image} alt="" className={`${isAnimated ? blok.animation : '' } ${visibility ? 'visible' : ''}` } /> }
                      </picture>
                    </LazyLoad>
                  </Visibility>
                )}
                { blok.zoom_image_id && !isSSR && (
                  <React.Suspense fallback={<div />}>
                    <ClientSideOnlyLazy blok={blok} modalModeOn={modalModeOn} />
                  </React.Suspense>
                  )
                }
                { blok && blok.text && 
                  <div className={`et ${blok.align} `} dangerouslySetInnerHTML={ MarkDown(blok.text) } ></div>
                }
                {blok.button_row && blok.button_row.map((row) =>{
                        return (
                            <ButtonRow content={row}  /> 
                        )
                    }
                )}
            </div>
        </SbEditable>
      )
  }

export default Image

