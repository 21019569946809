import React, { useRef, useEffect, useState } from "react";
import SbEditable from "storyblok-react";
import MarkDown from '../../markdown';
import LazyLoad from 'react-lazyload';
import IconDescSec from '../IconDescSection';
import { checkInView } from '../../../../utils/checkElementInView'
import Visibility from 'react-visibility-sensor'
import VideoPlayer from "../../../atoms/video";
import ButtonPlayInformation  from "../../button_play_information";
import ButtonRow from "../../button_row";
import { 
  addClass,
  removeClass,
  shouldMobileVideoDowngrade,
  setWillChange,
  removeWillChange,
  hasWindow,
  hasDocument,
  checkIsIE,
} from '../../../../utils/helper'
import "./style.scss";
import { isIOS } from "../../../../../helper/utils";

const lazyLoadOffset = 2500;
const AnimationCard = ({content, isM, lang}) => {
  const {
    construct,
    mobileConstruct,
    image,
    replaceImage,
    mobileImage,
    mobileReplaceImage,
    imageWidth,
    title,
    desc,
    iconDescList,
    verticalConstruct,
    video,
    mobile_video,
    contentMarginMobile,
    replayBtn,
    modelButton,
    popupVideoBtn
  } = content;
  const imageDom = useRef(null);
  const originalImageDom = useRef(null);
  const replaceImageDom = useRef(null);
  const replayButton = useRef(null);
  const targetImage = isM ? mobileImage || image : image;
  const targetReplaceImage = isM ? mobileReplaceImage || replaceImage : replaceImage;
  const direction = construct === 'right-image' ? 'row-reverse' : 'row';
  const wrapperAlignItems = verticalConstruct === 'top' ? 'flex-start' : 'center';
  const alignItems = isM ? mobileConstruct : 'normal';
  const contentMargin = {
    margin : isM ? (contentMarginMobile || '16px 0 24px') : ''
  }
  const [isActive, setActive] = useState(true);
  const [isDowngrade, setDowngrade] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const videoLink = isM ? mobile_video || video : video;
  const replayBtnImg = (replayBtn && replayBtn.filename) || '/assets/icons/play.svg';
  let willChangeSet = false;
  const timerRef = useRef();
  
  // 是否展示视频
  const showVideo = videoLink && videoLink.filename;

  useEffect(() => {
    // 视频是否降级
    setDowngrade(shouldMobileVideoDowngrade());
    // 是否是ie
    setIsIE(checkIsIE());

    const callback = ({inView}) => {
      if(!inView) {
        removeChange();
        return;
      }
      if (!replaceImageDom || !originalImageDom) return;
      setChange();
      removeClass(originalImageDom.current);
      addClass(replaceImageDom.current);
    
      timerRef.current = setTimeout(() => {
        addClass(replayButton.current);
      }, 4000);
    }
    checkInView({
      elm: imageDom.current,
      threshold: 1,
      callback
    })
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    }
  }, []);

  const replayAnimation = () => {
    removeClass(replayButton.current);
    removeClass(replaceImageDom.current);
    addClass(originalImageDom.current);
    
    setTimeout(() => {
      removeClass(originalImageDom.current);
      addClass(replaceImageDom.current);
    }, 0);

    timerRef.current = setTimeout(() => {
      addClass(replayButton.current);
    }, 4000);
  }

  // 判断视频是否可见
  const isVisible = (visible) => {
    if (visible) {
      setPlaying(true);
      setActive(false);
    }
  }

  const removeChange = () => {
    if (!willChangeSet) {
      return;
    }
    if (!replaceImageDom || !originalImageDom) return;
    
    removeWillChange(originalImageDom.current);
    removeWillChange(replaceImageDom.current);
    
    willChangeSet = false;
  }
  
  const setChange = () => {
    if (!hasWindow || !hasDocument || willChangeSet) {
      return;
    }
    if (!replaceImageDom || !originalImageDom) return;
  
    setWillChange(originalImageDom.current, 'opacity, transform');
    setWillChange(replaceImageDom.current, 'opacity, transform');
  
    willChangeSet = true;
  }

  return (
    <SbEditable content={content}>
      <div className="ec1706-info-animation-card-item" style={{ flexDirection: direction, alignItems: wrapperAlignItems }}>
        { title && isM && <div className="title"  dangerouslySetInnerHTML={ MarkDown(title) }></div> }
          <div className="image-wrapper" ref={showVideo ? null : imageDom}>
            { 
              showVideo ? (
                <Visibility active={isActive} onChange={isVisible} partialVisibility={'bottom'} offset={{ bottom: 0 }}>
                  <LazyLoad offset={lazyLoadOffset} once>
                    {
                      isDowngrade || isIE ? (
                        popupVideoBtn && popupVideoBtn.length > 0 ? (
                          <div className="play-icon image" style={{ backgroundImage: `url(${targetImage})`}}>
                            <img className="image" style={{ width: imageWidth }} src={targetReplaceImage} alt="" />
                            <ButtonRow content={{buttons: popupVideoBtn}} />
                          </div>
                        ) : <img className="image" style={{ width: imageWidth }} src={targetReplaceImage} alt="" />
                      ) : (
                        <VideoPlayer
                          triggerPlay={playing}
                          replayClassName='video-replay-btn'
                        >
                          <div className="video" style={{ width: imageWidth }}>
                            <video muted 
                            playsInline={true}
                            x5-playsinline="true"
                            webkit-playsinline="true"
                            src={videoLink.filename}
                            style={{position:"absolute"}}
                            className={isIOS && "fix-video-border"}
                            >
                            </video>
                            <img src={targetImage} style={{width: "100%", objectFit:"cover"}} />
                            <button className="video-replay-btn">
                              <img src={replayBtnImg} alt="replay"/>
                            </button>
                          </div>
                        </VideoPlayer>
                      )
                    }
                  </LazyLoad>
                </Visibility>
              ) : (
                <LazyLoad offset={lazyLoadOffset} once>
                  <div className={isM ? "original-image" : "original-image-pc"}>
                    <img className="image active" style={{ width: imageWidth }} src={targetImage} alt="targetImage" ref={originalImageDom}/>
                  </div>
                  {
                    targetReplaceImage ? (
                      <div className={isM ? "replace-image" : "replace-image-pc"}>
                        <img className="image" style={{ width: imageWidth }} src={targetReplaceImage} alt="targetReplaceImage" ref={replaceImageDom}/>
                        <button className="replay-btn" onClick={() => replayAnimation()} ref={replayButton}>
                          <img src={replayBtnImg} alt="replay"/>
                        </button>
                      </div>
                    ) : null
                  }
                </LazyLoad>
              )
            }
        </div>
        <div className="content-wrapper" style={{ alignItems }}>
          { title && !isM && <div className="title"  dangerouslySetInnerHTML={ MarkDown(title) }></div>}
          { desc && <div className="content-desc" style={contentMargin} dangerouslySetInnerHTML={ MarkDown(desc) } ></div> }
           {/* 新添加弹框组件 */}
           {
               modelButton && modelButton.length > 0 ? (
                 <div>{
                   modelButton.map((item)=>(
                    <ButtonPlayInformation content={item}  key={item._uid} lang={lang}/>
                   ))
                   }
                   </div>
               ) : null
            }
          {
            iconDescList && iconDescList.length > 0 ? (
              <IconDescSec content={iconDescList} />
            ) : null
          }
        </div>
      </div>
    </SbEditable>
  )
}

export default AnimationCard;