import React from 'react';
import TagText, { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import {
  Props as ParentProps,
  HeadlineType,
} from './type';
import './style.scss';

interface Props extends ParentProps {
  aosFade: any;
}

// eslint-disable-next-line complexity
const SubHeadline = (props: Props) => {
  const {
    aosFade,
  } = props;

  const subheadStyle: {
    color?: string;
    fontWeight?: any;
    fontSize?: string;
    lineHeight?: string;
    backgroundImage?: string;
    WebkitBackgroundClip?: string;
    opacity?: number;
    paddingBottom?: string;
    marginBottom?: string;
    letterSpacing?: string;
    margin?: string;
  } = {
    color: props.subHeadlineColor,
    fontWeight: props.subHeadlineFontWeight && TextFontWeight[props.subHeadlineFontWeight] ? TextFontWeight[props.subHeadlineFontWeight] : undefined,
    fontSize: props.subHeadlineFontSize,
    lineHeight: GetLineHeight(props.subHeadlineFontSize, props.subHeadlineLineHeight),
    letterSpacing: props.subHeadlineLetterSpacing,
    ...props.subHeadlineMargin ? { margin: props.subHeadlineMargin } : {},
  };

  if (props.subHeadlineType === HeadlineType['gradient-text']) {
    subheadStyle.backgroundImage = props.subHeadlineGradientColor;
    subheadStyle.WebkitBackgroundClip = 'text';
    subheadStyle.color = 'transparent';
    subheadStyle.paddingBottom = '0.1em';
    subheadStyle.marginBottom = '-0.1em';
  } else {
    subheadStyle.color = props.subHeadlineColor;
  }
  return <TagText tagType={props.subHeadlineTag || 'h3'} data-aos={aosFade} data-aos-once className="cardh-subhead" style={subheadStyle} dangerouslySetInnerHTML={{ __html: props.subHeadlineText || '' }} />;
};

export default SubHeadline;
