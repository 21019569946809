const cdnhost = 'https://uploads.hasselblad.com/'
const url = {
  ugcPhoto(path, type) {
    let imageUrl = '';
    switch (type) {
      case 'large':
        imageUrl = `${cdnhost}1000x-community/${path}`;
        break;
      case 'small':
        imageUrl = `${cdnhost}300x-community/${path}`;
        break;
      case 'original':
      default:
        imageUrl = `${cdnhost}community/${path}`
        break;
    }
    return imageUrl
  }

}

export default url;