import React from 'react';
import TagText, { TextFontWeight, GetLineHeight } from '../../../basic/Text';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import {
  PropsWithAos,
  TitleType,
} from './type';
import './style.scss';

// eslint-disable-next-line complexity
const Eyebrow = (props: PropsWithAos) => {
  const { aosFade } = props;

  const eyeBrowStyleName = `mcardv-eyebrow ${props.hasToppingImage ? 'mcardv-connect-pic' : ''}`;
  if (props.eyeBrowType === TitleType.svg) {
    let placeholderText = <h1 className="mcardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
    if (props?.eyeBrowTag) {
      placeholderText = <TagText tagType={props.eyeBrowTag} key="text" className="mcardv-visual-hidden" dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
    }
    return (
      <>
        <img
          key="img"
          alt={filterHtmlTag(props.eyeBrowText || '')}
          src={getAsset(props.eyeBrowSVG)}
          className={eyeBrowStyleName}
          style={{ height: props.eyeBrowSVGHeight }}
        />
        {placeholderText}
      </>
    );
  }
  const eyeBrowStyle: {
    color?: string;
    backgroundImage?: string;
    WebkitBackgroundClip?: string;
    fontWeight?: any;
    fontSize?: string;
    lineHeight?: string;
    paddingBottom?: string;
    marginBottom?: string;
    letterSpacing?: string;
    margin?: string;
  } = {
    fontWeight: props.eyeBrowFontWeight && TextFontWeight[props.eyeBrowFontWeight] ? TextFontWeight[props.eyeBrowFontWeight] : undefined,
    fontSize: props.eyeBrowFontSize,
    lineHeight: GetLineHeight(props.eyeBrowFontSize, props.eyeBrowLineHeight),
    letterSpacing: props.eyeBrowLetterSpacing,
    ...props.eyeBrowMargin ? { margin: props.eyeBrowMargin } : {},
  };

  if (props.eyeBrowType === TitleType['pure-text']) {
    eyeBrowStyle.color = props.eyeBrowPureColor;
  } else {
    eyeBrowStyle.color = 'transparent';
    eyeBrowStyle.backgroundImage = props.eyeBrowGradientColor;
    eyeBrowStyle.WebkitBackgroundClip = 'text';
    eyeBrowStyle.paddingBottom = '0.1em';
    eyeBrowStyle.marginBottom = '-0.1em';
  }

  return <TagText tagType={props.eyeBrowTag || 'h1'} data-aos={aosFade} data-aos-once className={eyeBrowStyleName} style={eyeBrowStyle} dangerouslySetInnerHTML={{ __html: props.eyeBrowText || '' }} />;
};

export default Eyebrow;
