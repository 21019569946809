/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import { getAsset } from '../../../../../utils/helper';
import './style.scss';
import { StoryblokAsset } from '../../../mobile/HeroVertical/card/type';
import _debounce from 'lodash.debounce';

export enum tabAlign {
  'left' = 'left',
  'center' = 'center',
}

export enum tabBarContent {
  'none' = 'none',
  'icon' = 'icon',
  'headline' = 'headline',
  'all' = 'all',
}

export enum tabTheme {
  'dark' = 'dark',
  'light' = 'light',
}

interface TabPanelItem {
  tabBarIcon: StoryblokAsset;
  tabBarHoverIcon: StoryblokAsset;
  tabBarTitle: string;
  tabDesc: string;
  tabKey: string;
}
export interface Props {
  responsiveBorder: number;
  // 基础配置
  tabAlign?: tabAlign;
  tabBarMargin?: string;
  tabOverallWidth?: string;
  spacingFromTheCard?: string;
  tabBarContent?: tabBarContent;
  tabBarIconHeight?: string; // tabBar icon 高度
  tabTheme?: tabTheme; // tab标题颜色

  activeKey?: string;
  tabContent?: Array<TabPanelItem>;
  onTabClick?: (key: string, index: number) => void;
}

export interface State {
  inkBarStyle: React.CSSProperties;
}

export interface Style {
  width: string;
  transform: string;
}

function setTransform(style: Style, v: string): void {
  const styles = style;
  if (style && typeof style === 'object' && v) {
    styles.transform = v;
  }
}

export default class TabBar extends React.PureComponent<Props, State> {
  inkBarStyleUpdated: boolean;
  debounceResize: any;
  tabBar: HTMLElement | null;
  tabBarNav: HTMLElement | null;

  activeTab: HTMLElement | null;

  tabDom: any;

  tabBarItem: any;

  constructor(props: Props) {
    super(props);
    this.state = { inkBarStyle: { transition: 'none' }};
    this.renderTabs = this.renderTabs.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.updateInkBarStyle = this.updateInkBarStyle.bind(this);
    this.renderBar = this.renderBar.bind(this);
    this.getCenter = this.getCenter.bind(this);
    this.onResize = this.onResize.bind(this);
    this.tabDom = [];
  }

  componentDidMount(): void {
    this.updateInkBarStyle({ transition: 'none' });
    this.debounceResize = _debounce(this.onResize, 300);
    window.addEventListener('resize', this.debounceResize);
  }

  componentDidUpdate(prevProps: Props): void {
    const preActiveKey = prevProps.activeKey;
    const { activeKey } = this.props;
    if (preActiveKey === activeKey) {
      return;
    }
    setTimeout(() => {
      this.updateInkBarStyle();
    }, 100);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.debounceResize);
  }

  onResize(): void {
    const windowWidth = document.documentElement.clientWidth || window.innerWidth;
    const isMobile = windowWidth < this.props.responsiveBorder;
    if (isMobile && !this.inkBarStyleUpdated) {
      this.updateInkBarStyle();
      this.inkBarStyleUpdated = true;
      window.removeEventListener('resize', this.debounceResize);
    }
  }

  getCenter(index: number): void {
    if (!this.tabBar || !this.tabBarNav) {
      return;
    }
    const windowOffsetWidth = this.tabBar.clientWidth; // 大于 428 时屏宽不等于 tabbar 宽，居中应以 tabbar 宽度为基准
    const dom = this.tabDom[index];
    const tabBarDom = this.tabBar;
    if (dom) {
      const domoffsetWidth = dom.offsetLeft;
      const diffWidth = (windowOffsetWidth - dom.offsetWidth) / 2; // 中间值 =( 屏幕宽度 - item宽度 ) / 2;
      const targetOffset = -(domoffsetWidth - diffWidth); // 目标值 = offset - 中间值
      const tabWidth = this.tabBarItem.clientWidth; // 开始

      const tarbarCssMarginLeft = (window.getComputedStyle(this.tabBarNav)).marginLeft;
      const tabBarMarginLeft = +(tarbarCssMarginLeft.replace('px', ''));
      const tarbarCssMarginRight = (window.getComputedStyle(this.tabBarNav)).marginRight;
      const tabBarMarginRight = +(tarbarCssMarginRight.replace('px', ''));
      const tabBarMargin = tabBarMarginLeft + tabBarMarginRight;

      // 未超出中间值
      if (-targetOffset < 0 || index === 0) {
        tabBarDom.scroll({ left: 0, behavior: 'smooth' });
        return;
      }
      // 末尾
      if (targetOffset < windowOffsetWidth - tabWidth) {
        tabBarDom.scroll({ left: -(windowOffsetWidth - tabWidth - tabBarMargin), behavior: 'smooth' });
        return;
      }
      // 正常
      tabBarDom.scroll({ left: -targetOffset, behavior: 'smooth' });
    }
  }

  handleTabClick(key: string, index: number): void {
    const { onTabClick } = this.props;
    this.getCenter(index);
    if (onTabClick) {
      onTabClick(key, index);
    }
  }

  updateInkBarStyle(otherStyle?: object): void {
    const { tabBarIconHeight } = this.props;
    if (this.activeTab && this.tabBar) {
      const style = {
        ...otherStyle || {},
        width: '',
      };
      const tabOffset = this.activeTab.offsetLeft;
      const containerOffset = this.tabBar.offsetLeft;
      const left = tabOffset - containerOffset;
      const width = this.activeTab.clientWidth;
      setTransform(style, `translate3d(${left}px,0,0)`);
      style.width = `${width}px`;
      this.setState({ inkBarStyle: style });
    }
  }

  renderBar(): JSX.Element {
    const style = this.state.inkBarStyle;
    return (
      <div
        style={style}
        className="mtabh-aotm-horizontal-tab-inkbar"
      />
    );
  }

  renderTabs(): any {
    const { props } = this;
    const reg = new RegExp('px', 'g');
    const value = props.tabOverallWidth.replace(reg, '');
    const overallwith = Number(value);

    const itemsWidth = overallwith / props.tabContent.length;
    const itemStyl = props.tabBarContent !== 'none' ? {
      width: `${overallwith ? itemsWidth : 'auto'}px`,
      marginRight: overallwith ? 0 : 24,
    } : {};

    const rst = props.tabContent.map((item, index) => {
      const { tabKey } = item;
      const cls = classNames(
        'mtabh-tab-nav-item',
        `mtabh-tab-nav-item-align-${props.tabAlign}`,
        { 'mtabh-tab-item-active': props.activeKey === tabKey },
      );

      const events = { onClick: this.handleTabClick.bind(this, tabKey, index) };

      const iconSty = {
        height: props.tabBarIconHeight,
        display: 'block',
      };
      let TabBarWrap: React.ReactNode | string;
      switch (props.tabBarContent) {
        case 'headline':
          TabBarWrap = (<div className="mtabh-tab-item-text" dangerouslySetInnerHTML={{ __html: item.tabBarTitle }} />);
          break;
        case 'icon':
          TabBarWrap = (
            <div className="mtabh-icon-box" style={iconSty}>
              <img className="mtabh-tab-icon" src={getAsset(item.tabBarIcon)} alt="" style={iconSty} />
              <img className="mtabh-tab-hover-icon" src={getAsset(item.tabBarHoverIcon)} alt="" style={iconSty} />
            </div>
          );
          break;
        case 'none':
          TabBarWrap = (<div className="mtabh-tab-line" />);
          break;
        default:
          TabBarWrap = (
            <div className="mtabh-icon-text">
              <div className="mtabh-icon-box" style={iconSty}>
                <img className="mtabh-tab-icon" src={getAsset(item.tabBarIcon)} alt="" style={iconSty} />
                <img className="mtabh-tab-hover-icon" src={getAsset(item.tabBarHoverIcon)} alt="" style={iconSty} />
              </div>
              <div className="mtabh-icon-title" dangerouslySetInnerHTML={{ __html: item.tabBarTitle }} />
            </div>
          );
      }
      return (
        <div
          className={cls}
          style={itemStyl}
          key={tabKey || index}
          {...events}
          ref={(tab) => { this.tabDom[index] = tab; if (props.activeKey === tabKey) { this.activeTab = tab; } }}
          data-ga-label={`m-all-${index}-tab-button`}
        >
          { TabBarWrap }
        </div>
      );
    });
    return rst;
  }

  render(): JSX.Element {
    const { props } = this;

    const renderTabs = this.renderTabs();
    const renderBar = this.renderBar();

    const tabBarCls = classNames(
      'mtabh-atom-horizontal-tab-bar-wrapper',
      `mtabh-atom-horizontal-tab-bar-${props.tabBarContent}`,
    );

    const tabBarStyl = { width: props.tabBarContent !== 'none' && props.tabOverallWidth };

    const reg = new RegExp('px', 'g');
    const value = props.tabBarIconHeight.replace(reg, '');
    let tabNavHeight;
    switch (props.tabBarContent) {
      case 'icon':
        tabNavHeight = Number(value) + 20;
        break;
      case 'headline':
        tabNavHeight = 48;
        break;
      case 'none':
        tabNavHeight = 18;
        break;
      default:
        tabNavHeight = Number(value) + 52;
    }

    const tabBarNavStyl = { height: `${tabNavHeight}px` };
    const renderBarLine = props.tabBarContent !== 'none' ? renderBar : null;

    return (
      <div
        className={tabBarCls}
        style={tabBarNavStyl}
      >
        <div
          ref={(tabBar) => { this.tabBar = tabBar; }}
          className={classNames(
            'mtabh-tab-bar-nav-box',
            `mtabh-tab-bar-nav-align-${props.tabAlign}`,
          )}
        >
          <div className="mtabh-tab-bar-nav" style={{ margin: props.tabBarMargin }} ref={(t) => { this.tabBarNav = t; }}>
            <div className="mtabh-tab-bar-items" style={tabBarStyl} ref={(t) => { this.tabBarItem = t; }}>
              {renderBarLine}
              {renderTabs}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
