import React, { useState, useEffect, useRef } from "react";
import SbEditable from "storyblok-react";
import "video.js/dist/video-js.css";
import Modal from "react-modal";
import VideoPlayer from "../atoms/videoPlayer";
import _debounce from 'lodash.debounce';
import { DeviceInfo } from '../../hooks/device';
import './button_watch_video.scss';

const ButtonWatchVideo = (props) => {
  const { content, lang } = props
  const url = (content.file && content.file.filename) || (content.url && content.url.url);
  const mobileUrl = (content.mobileFile && content.mobileFile.filename) || (content.mobileUrl && content.mobileUrl.url);  
  const [showModal, setShowModal] = useState(false);
  const [showLoding, setShowLoding] = useState(true);
  const { isMobile: isM } = DeviceInfo();

  const videoOptions = {
    controls: true,
    fluid: true,
    aspectRatio: isM ? '9:16' : '16:9',
    sources: [{
      src: mobileUrl ? mobileUrl : url,
      type: 'video/mp4'
    }]
  }

  const icon = content.theme === 'white' ? "/assets/icons/play-circle-black.svg" : '/assets/icons/play-circle.svg';
  const hoverIcon = content.theme === 'white' ? "/assets/icons/play-circle.svg" : '/assets/icons/play-circle-black.svg';

  const youtubeUrl = content.url && content.url.url;
  let youtubeVideoUrl = youtubeUrl ? youtubeUrl.split("v=").pop() : "";
  youtubeVideoUrl = "https://www.youtube.com/embed/" + youtubeVideoUrl + "?&autoplay=0&feature=oembed&rel=0&enablejsapi=1&version=3&playerapiid=ytplayer";

  const closeModal = () => {
    const timer = setTimeout(() => {
      document.getElementsByClassName('ln__cta') && document.getElementsByClassName('ln__cta')[0] && document.getElementsByClassName('ln__cta')[0].classList.remove('eventDisable')
      clearTimeout(timer)
    }, 50)
  }

  const handleClick = () => {
    setShowModal(true)
    setShowLoding(true)
    document.getElementsByClassName('ln__cta') && document.getElementsByClassName('ln__cta')[0] && document.getElementsByClassName('ln__cta')[0].classList.add('eventDisable')
  }

  return (
    <SbEditable content={content}>
      <>
        <button onClick={() => handleClick()} className={`cta button watch-video-btn ${content.theme === 'white' ? 'white-theme' : ''}` }>
          <span>{content.text}</span>
          <div className="btn-icon">
            <img src={icon} className='normal-icon' />
            <img className='hover-icon' src={hoverIcon}/>
          </div>
        </button>
        <Modal className="modal black fullscreen video-btn-modal" overlayClassName="overlay" isOpen={showModal} contentLabel="" shouldFocusAfterRender={false} onAfterClose={() => closeModal()}>
          <button className="close" onClick={() => { setShowModal(false); setShowLoding(true);}}>
            <img alt="close" src="/assets/icons/close-white.svg" />
          </button>
          { content.component === 'button_play_youtube' ?
            <img className="loading-indicator" src="/assets/icons/loading-indicator.svg" alt="Loading" style={{ display: `${showLoding ? 'block' : 'none'}` }} />
            : null
          }
          <div className="modal__content">
            {
              content.component === 'button_play_youtube' ? (
                <iframe title={youtubeVideoUrl} style={{ height: 'calc(100vh - 70px)', padding: '0 20px', width:'100%', border:'none', visibility: `${showLoding ? 'hidden' : 'visible'}` }} src={youtubeVideoUrl} onLoad={() => setShowLoding(false)}></iframe>
              ) : (
                <div className="modal__wrapper">
                  <VideoPlayer { ...videoOptions }/>
                </div>
              )
            }
          </div>
        </Modal>
      </>
    </SbEditable>
  )
}

export default ButtonWatchVideo;