import React from "react"
import SbEditable from "storyblok-react"
import { genResponsiveCSS } from '../../../utils/helper';
import InfoCardVerticalPC from '../pc/InfoCardVertical';
import InfoCardVerticalMobile from '../mobile/HeroVertical';
import './style.scss'

const INFO_CARD_BORDER = 1008;

const infoCardVertical = ({blok}) => {
  const { component: componentSlug, pcBlock, pcShow, mobileBlock, mobileShow, borderBetweenPcM } = blok;
  const border = borderBetweenPcM ? +borderBetweenPcM : INFO_CARD_BORDER;

  const pcBlockProps = Array.isArray(pcBlock) && pcBlock[0] ? pcBlock[0] : {};
  const mobileBlockProps = Array.isArray(mobileBlock) && mobileBlock[0] ? mobileBlock[0] : {};

  const PCBlock = pcShow ? <InfoCardVerticalPC {...pcBlockProps} /> : null;
  const MobileBlock = mobileShow ? <InfoCardVerticalMobile {...mobileBlockProps} /> : null;

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div>
        <SbEditable content={pcBlockProps} key={pcBlockProps && pcBlockProps._uid || `${blok._uid}_pc`}>
          <div className={`${componentSlug}-pc-${blok._uid}`}>
            {PCBlock}
          </div>
        </SbEditable>
        <SbEditable content={mobileBlockProps} key={mobileBlockProps && mobileBlockProps._uid || `${blok._uid}_mobile`}>
          <div className={`${componentSlug}-mobile-${blok._uid}`}>
            {MobileBlock}
          </div>
        </SbEditable>
        <style>{genResponsiveCSS({ border, componentSlug, uid: blok._uid })}</style>
      </div>
    </SbEditable>
  );
}

export default infoCardVertical;

