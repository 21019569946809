import React from "react"
import SbEditable from "storyblok-react"
import MarkDown from '../../markdown'
import LazyLoad from 'react-lazyload';
import './style.scss'

const lazyLoadOffset = 1500;

const ec1706MaterialDesignBackupSection = ({blok}) => {
  const textStyle = {
    textAlign: blok.align || null,
    color: blok.text_color && blok.text_color.color || null,
  }
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="ec1706-material-design-backup-wrapper" style={{ backgroundColor: blok.bg_color && blok.bg_color.color || null }}>
        { blok && blok.text && 
          <div style={textStyle} className="text-wrapper" dangerouslySetInnerHTML={ MarkDown(blok.text) } ></div>
        }
        <div className="image-wrapper">
          <LazyLoad offset={lazyLoadOffset} once>
            <picture>
              <source media="(max-width: 767px)" srcSet={ blok.mobile_image || blok.image } />
              <source media="(min-width: 768px)" srcSet={blok.image} />
              <img className="image" src={blok.image} alt="" />
            </picture>
          </LazyLoad>
        </div>
      </div>
    </SbEditable>
  )
}

export default ec1706MaterialDesignBackupSection;

