import React, { useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import {
  hasWindow,
  hasDocument,
  checkIsIE,
  shouldMobileVideoDowngrade,
} from '../../../../utils/helper';
import ResizeObserver from 'resize-observer-polyfill';
import { DeviceInfo } from '../../../../hooks/device';
import _debounce from 'lodash.debounce';
import LazyLoad from 'react-lazyload';
import Visibility from "react-visibility-sensor";
import ButtonRow from "../../button_row";

import "./style.scss";



const VideoBanner = (props) => {
  const { blok, lang } = props;
  const {
    _uid,
    videoLink,
    videoLinkMobile,
  } = blok;


  
  let { isMobile: isM } = DeviceInfo();
  const navHeight = isM ? 104 : 64;
  const [isIE, setIsIE] = useState(false);
  const [isDowngrade, setDowngrade] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  const uniqueId = `video-banner-${_uid}`;
  const videoLinkLast = isM ? videoLinkMobile : videoLink;
  const headerImage = isM ? blok.headerImageMobile : blok.headerImage;
  // 是否展示视频
  const showVideo = videoLink && videoLink.filename && !isDowngrade;
  const stickyWrapperStyle = {
    height: !isIE ? 1.8 * screenHeight : 2 * screenHeight,
  }  

  const onResize = () => {
    if(!hasWindow) return;
    const windowInnerHeight = typeof window != 'undefined' ? (window.innerHeight) : 603;
    const targetDom = hasDocument ? document.body : '';
    const obj = targetDom.getBoundingClientRect();
    isM = obj.width <= 768;
    if(isM) {
      setScreenHeight((prevState) => {
        return Math.max(windowInnerHeight, prevState);
      });
    }else {
      setScreenHeight(windowInnerHeight);
    }
    
  }

  // dom渲染开始监听
  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;    

    // 视频是否降级
    setDowngrade(shouldMobileVideoDowngrade());
    // 是否IE
    setIsIE(checkIsIE())
    // dom渲染时更新组件高度
    const windowInnerHeight = typeof window != 'undefined' ? (window.innerHeight) : 603;
    if(isM) {
      setScreenHeight((prevState) => {
        return Math.max(windowInnerHeight, prevState);
      });
    }else {
      setScreenHeight(windowInnerHeight);
    }
    onResize();
    const debounceResize = _debounce(onResize, 300);
    window.addEventListener('resize', debounceResize);
  });

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div id={uniqueId} className="video-banner"> 
        <div className="sticky-wrapper" style={{ height: !isIE ? 1.8 * screenHeight : 2 * screenHeight }}>
          <div className="sticky-inner-wrapper" style={{height: screenHeight - navHeight, top:`${navHeight}px`}}>
            {
              showVideo ? (
                <div className="video-block">
                  <div className="vb__background" style={{minHeight: screenHeight - navHeight}}>
                    <LazyLoad offset={2000} once>
                        <video
                          loop="loop"
                          autoPlay
                          muted="muted"
                          x5-playsinline="true"
                          playsInline={true}
                          webkit-playsinline="true"
                          // poster={headerImage?.filename}
                          src={videoLinkLast?.filename}
                          width="100%"
                          style={{backgroundImage: `url(${headerImage?.filename})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
                        ></video>
                    </LazyLoad>
                  </div>
                </div>
              )
              : (
                // TODO 做m端安卓降级
                blok.popupVideoBtn && blok.popupVideoBtn.length > 0 ? (
                  <div className="play-icon" style={{ backgroundImage: `url(${headerImage?.filename})`, height: screenHeight - navHeight}}>
                    <ButtonRow content={{buttons: blok.popupVideoBtn}} />
                  </div>
                ) : null
              )
            }
          </div>
        </div>
      </div>
    </SbEditable>
  );
}

export default VideoBanner;
