import React from "react";
import SbEditable from "storyblok-react"
import LazyLoad from 'react-lazyload';
const SlicerItem = props => {
 const { content } = props;
return (
    <SbEditable content={content}>
        <LazyLoad offset={1000} once>
        <picture>
            <source media="(max-width: 767px)" srcSet={content.slider_mobile_image} />
            <source media="(min-width: 767px)" srcSet={content.slider_pc_image} />
            <img src={content.slider_pc_image} alt="" />
        </picture>
        </LazyLoad>
    </SbEditable> )
}
export default SlicerItem
