import React from "react";
import SbEditable from "storyblok-react";
import API from "../../../utils/api";
import marked from "marked";
import t from "../../../utils/languages";
import { FULL_LANG_CODE_TO_SIMPLE_LANGS_CODE } from '../../../utils/languages_map';
import LazyLoad from "react-lazyload";
import './style.css';
import '../warm_up_banner.css';

const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*\.){1,63}[a-z0-9]+$/i;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;    
    this.state = {
      email: "",
      errors: {
        email: false,
      },
      submitted: false,
      isSubmittedScussce: true,
      isLoad: false,
      timeout: true,
      successed: false,
      language: FULL_LANG_CODE_TO_SIMPLE_LANGS_CODE[this.lang],
      subscribeBtnActive: false,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if(reg.test(value)){
      this.setState({
        subscribeBtnActive: true,
      });
    } else {
      this.setState({
        subscribeBtnActive: false,
      });
    }  
  };

  handleOnblur = (event) => {
    const target = event.target;
    const value = target.value;
    let errors = this.state.errors;
    errors.email = !reg.test(value);
    this.setState({
      errors,
    }); 
  };

  handleSubscribeFocus = () => {
    let errors = this.state.errors;
    errors.email = false;
    this.setState({
      errors,
    })
  };

  handleSubscribeKeyUp = (event) => {
    const target = event.target;
    const value = target.value;
    let errors = this.state.errors;
    if(reg.test(value)){
      this.setState({
        subscribeBtnActive: true,
      });
    } else {
      this.setState({
        subscribeBtnActive: false,
      });
    }
    
  };

  submit = (event) => {
    let _this = this;
    this.setState({ isLoad: true });
    const params = {};
    params.email = this.state.email;
    params.language = this.state.language;
    API.post("/newsletter/subscribetrailer", params)
      .then((result) => {
        this.setState({
          submitted: true,
          isSubmittedScussce: true,
          isLoad: false,
          timeout: false,
          successed: true,
        })
        setTimeout(()=>{
          _this.setState({timeout:true})
      },3000)  
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          submitted: false,
          isSubmittedScussce: false,
          isLoad: false,
          timeout: false,
        });
        setTimeout(() => {
          _this.setState({ timeout: true });
        }, 3000);
      });
  };

  render() {
    const { content, lang, showModal } = this.props;
    const { isSubmittedScussce, isLoad, timeout } = this.state;    

    const {
      email_error_text,subscribe_error_text,subscribe_success_text,button_text,placeholder, email_address, success_button_text, success_title
    } = content;       
    
    return (
      <SbEditable content={content}>
        <div>
          {
            !this.state.successed ? (content.title &&
            <div className="infomation-modal-subtitle-subscibe" dangerouslySetInnerHTML={{ __html:content.title}}></div>) :
            (content.success_title && 
            <div className="infomation-modal-subtitle-subscibe-success" dangerouslySetInnerHTML={{ __html:content.success_title}}></div>)
          }
          <section className={`${this.state.successed ? "footer__newssubscription__success" : "footer__newssubscription"} center`}>
            <div className={`${!this.state.successed ? "fnl__wrapper_height" : ""} fnl__wrapper`}>
              <label htmlFor="newsletterField" style={{display:`${this.state.successed ? 'none' : ''}`}}>
                <div style={{ maxWidth: "325px" }}>
                  <span className={`show address__text`} style={{display:`${this.state.successed ? 'none' : ''}`}}>
                    {email_address || t("newsletter.emailPlaceHolder", this.lang)}
                  </span>
                </div>
                <input
                  id="newsletterField"
                  name="email"
                  maxLength="255"
                  className={this.state.errors.email ? "error" : null }
                  type="text"
                  placeholder={placeholder || t("newsletter.emailPlaceHolder", this.lang)}
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  style={{ maxWidth: "325px", textAlign: "center"}}
                  onBlur={this.handleOnblur}
                  onFocus={this.handleSubscribeFocus}
                  onKeyUp={this.handleSubscribeKeyUp}
                />
              </label>
              {/* 添加成功提示的信息 */}
              <div
                className={`newssubscription-message-content ${
                  this.state.successed ? "newssubscription-message-show" : ""
                }`}
              >
                <span className="success">
                  {subscribe_success_text || t("subscribe.success", this.lang)}
                </span>
              </div>
              {/* 正则匹配错误的提示信息 */}
              <div
                className={`newssubscription-message-content ${
                  (this.state.errors.email && !this.state.successed) ? "newssubscription-message-show" : ""
                }`}
              >
                <span
                  style={{color: '#d95e00'}}
                >
                  {(email_error_text || t("newsletter.emailInvalid", this.lang))}
                </span>
              </div>
              <div className="newssubscription__cta-row">
                <button
                  className={`${
                    this.state.errors.email ||
                    isLoad ||
                    this.state.email.length === 0 ||
                    !this.state.subscribeBtnActive
                      ? "disabled newssubscription__cta"
                      : "newssubscription__cta"
                  } ${isLoad ? "loading" : null}
                  `}
                  disabled={
                    this.state.errors.email ||
                    isLoad ||
                    this.state.email.length === 0 ||
                    !this.state.subscribeBtnActive
                  }
                  onClick={this.state.successed ? () => {this.props.handleCloseModal(false)} : this.submit}
                >
                  {!this.state.successed ? (button_text || t("newsletter.subscribe", this.lang)) : (success_button_text) }
                </button>
              </div>
              {/* 添加错误提示的信息 */}
              <div
                className={`newssubscription-message-content ${
                  (!timeout && !isSubmittedScussce) ? "newssubscription-message-show" : ""
                }`}
              >
                <span className="filed">
                 {subscribe_error_text || t("subscribe.failed", this.lang)}
                </span>
              </div>
            </div>
          </section>

          <div>
            <div className="suscribe_live_platform">{content.live_platform}</div>   
              <div className="newssubscription_outer">
               {
                 content.hasselbladIcon && content.hasselbladIcon.filename && (
                  <LazyLoad offset={2000} once>
                    <div className="newssubscription_inner_hasselblad">
                      {
                        content?.hasselblad_address ?
                        ( <a href={content?.hasselblad_address} target="_blank"><div className="newssubscription_inner_hasselblad_img"><img src={content.hasselbladIcon?.filename} /></div></a>
                        ) :
                        (<div className="newssubscription_inner_hasselblad_img_noHover"><img src={content.hasselbladIcon?.filename} /></div>)
                      }
                      <div className="newssubscription_inner_text" dangerouslySetInnerHTML={{ __html:content?.hasselblad_text}}></div>
                    </div>
                  </LazyLoad>
                 )
               }
               {
                  content.youtubeIcon && content.youtubeIcon.filename && (
                    <LazyLoad offset={2000} once>
                      <div className="newssubscription_inner_youtube">
                        {
                          content?.youtube_address ?
                          ( <a href={content?.youtube_address} target="_blank"><div className={this.lang === 'zh-cn' ? "newssubscription_inner_video_img" : "newssubscription_inner_youtube_img"} ><img src={content.youtubeIcon?.filename} /></div></a>
                          ):
                          (<div className="newssubscription_inner_youtube_img_noHover" ><img src={content.youtubeIcon?.filename} /></div>)
                        }
                        <div className="newssubscription_inner_text" dangerouslySetInnerHTML={{ __html:content?.youtube_text}}></div>
                      </div>
                    </LazyLoad>
                  )
                }
              </div>
          </div>
        </div>
      </SbEditable>
    )
  }
}