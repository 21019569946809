/* eslint-disable */
import React from 'react';
import classNames from 'classnames';

import './style.scss';
import { StoryblokAsset } from '../../../pc/InfoCardHorizontal/card/type';

export interface Props {
  active?: boolean;
  tab?: React.ReactNode | string;
  tabBarIcon?: StoryblokAsset;
  tabBarHoverIcon?: StoryblokAsset;
  children: React.ReactNode | string;
  height?: number | undefined | null;
}

export default class TabPanel extends React.PureComponent<Props, {}> {
  render(): JSX.Element {
    const {
      active,
      children,
    } = this.props;

    const cls = classNames(
      'tabh-tab-panel',
      { 'tabh-tab-panel-active': active },
    );

    return (
      <div
        className={cls}
      >
        {children}
      </div>
    );
  }
}
