import React from "react"
import './survey.css'

export default class extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: true
        }
    }

    render() {
        //const { blok } = this.props

        return null 
        /*(
        <SbEditable content={blok}>

            <div className="editorial">
                <h1>survy</h1>
            </div>
        </SbEditable>
      )*/
  }
}
