import React from 'react';
import withHydrationOnDemand from '../react-hydration-on-demand';
import ScrollMagic from './lib/scrollmagic';

class Controller extends React.Component {
  constructor() {
    super();
    this.state = {
      controller: null,
    };
  }

  componentDidMount() {
    const { children, forceIndividual, ...controllerProps } = this.props;
    let controller;

    // if (!controllerProps.container && !controllerProps.refreshInterval) {
    //   controllerProps.refreshInterval = 0;
    // }

    if (!window || forceIndividual || controllerProps.container) {
      controller = new ScrollMagic.Controller(controllerProps);
    } else if (window._ScrollMagicController) {
      controller = window._ScrollMagicController;
    } else {
      controller = new ScrollMagic.Controller(controllerProps);
      window._ScrollMagicController = controller;
    }
    
    this.setState({
      controller,
    });
  }

  componentWillUnmount() {
    this.controller = null;
  }

  render() {
    const { children } = this.props;
    const { controller } = this.state;

    if (!controller) {
      return children;
    }

    return React.Children.map(children, (child) => {
      if (child.type.displayName !== 'Scene') {
        return child;
      }
      const props = { ...child.props, controller };
      return <child.type {...props} />;
    });
  }
}

const withInViewHydration = withHydrationOnDemand({
  on: [
    ['visible', () => ({ rootMargin: '900000px 2000px 2000px' })],
    ['delay', 5000],
  ], 
  removeWrapperOnHydration: true,
});


/*
 * 默认使用带有到屏检测的 Controller 组件。
 * 大多数情况下，调用者无需关心动画组件初始化时机，但某些情况下上层组件需要获取已渲染完成的组件，此时需要引入 Origin 版本。
 */

const ControllerWithInView = withInViewHydration(Controller);
export {
  ControllerWithInView,
  Controller,
};
