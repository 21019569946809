/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import SbEditable from "storyblok-react"
import VideoPlayer from '../../video';
import LazyWrapper from '../../utils/LazyWrapper';
import { getAsset } from '../../../../utils/helper';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Card from './card';
import './style.scss';
import { StoryblokAsset } from './card/type';

export enum BackgroundSize {
  'auto-fit' = 'auto-fit',
  'custom-css' = 'custom-css',
}

export enum BackgroundRepeat {
  'repeat' = 'repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y',
  'no-repeat' = 'no-repeat',
}

export enum BackgroundFixed {
  'fix' = 'fix',
  'normal' = 'normal',
}

export enum ComponentVerticalAlign {
  'top-align' = 'top-align',
  'center-align' = 'center-align',
  'bottom-align' = 'bottom-align',
}

export enum FadeTransiation {
  'fade-in' = 'fade-in',
  'fade-up' = 'fade-up',
  'zoom-in-up' = 'zoom-in-up',
  'none' = 'none',
}

export interface Props {
  id?: string;
  // 基础
  cardGapWidth: string;
  gridWidth: string;
  componentMinHeight: string;
  pureBackgroundColor: string;
  backgroundGradient: string;
  anchorId?: string;
  customStyle?: string;

  // 背景图片
  hasBackgroundImage: boolean;
  backgroundImage?: StoryblokAsset;
  backgroundImageWidth?: string;
  backgroundImageHeight?: string;
  backgroundPosition?: string;
  backgroundSize?: BackgroundSize;
  backgroundSizeCustom?: string;
  backgroundRepeat?: BackgroundRepeat;
  backgroundFixed?: BackgroundFixed;
  backgroundParallax?: boolean;

  // 背景视频
  hasBackgroundVideo: boolean;
  backgroundVideoLoop?: boolean;
  backgroundVideoVisiblePlay?: boolean;
  backgroundVideoHoverPlay?: boolean;
  backgroundVideo?: string;
  backgroundVideoPoster?: string;
  backgroundVideoMaskColor?: string;
  backgroundVideoMaskOpacity?: string;

  // 内容对齐
  componentVerticalAlign: ComponentVerticalAlign;

  // 内边距
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;

  // 模块过渡
  fadeTransiation: FadeTransiation;
  mediaTransiation: FadeTransiation;

  // 卡片
  cardConfigList?: Array<Record<string, any>>;

  // 是否直接渲染dom(ssr)
  visibleByDefault?: boolean;
}

export interface State {
}

export default class InfoCardHorizontal extends React.Component<Props, State> {

  static defaultProps = {
    cardGapWidth: '16px',
    gridWidth: '960px',
    componentMinHeight: '',
    pureBackgroundColor: '',
    backgroundGradient: '',
    customStyle: '',

    // 背景图片
    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundImageWidth: '',
    backgroundImageHeight: '',
    backgroundPosition: '',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,

    // 背景视频
    hasBackgroundVideo: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: true,
    backgroundVideoHoverPlay: false,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',

    // 内容对齐
    componentVerticalAlign: ComponentVerticalAlign['center-align'],

    // 内边距
    paddingTop: '128px',
    paddingBottom: '128px',
    paddingLeft: '',
    paddingRight: '',

    // 模块过渡
    fadeTransiation: FadeTransiation.none,
    mediaTransiation: FadeTransiation.none,

    // 卡片
    cardConfigList: [Card.defaultProps],
  };

  constructor(props: Props) {
    super(props);
    this.getProps = this.getProps.bind(this);
  }

  getProps(prop: string): any {
    return this.props[prop];
  }

  render(): JSX.Element {
    const { props } = this;
    const { visibleByDefault, id } = props;

    const verticalAlignStyleName = `cardh-e-vertical-${props.componentVerticalAlign}`;
    const aosFade = props.fadeTransiation;
    let backgroundImage;

    const componentStyle: {
      backgroundImage?: string;
      backgroundPosition?: string;
      backgroundSize?: string;
      backgroundRepeat?: string;
      backgroundColor?: string;
      backgroundGradient?: string;
      backgroundAttachment?: string;
    } = { backgroundColor: props.pureBackgroundColor };

    if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
      backgroundImage = getAsset(props.backgroundImage);
      componentStyle.backgroundPosition = props.backgroundPosition;
      componentStyle.backgroundSize = props.backgroundSize === BackgroundSize['auto-fit'] ? 'cover' : props.backgroundSizeCustom;
      componentStyle.backgroundRepeat = props.backgroundRepeat;
      componentStyle.backgroundAttachment = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';
      if (props.backgroundGradient) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
      }
    } else if (props.backgroundGradient) {
      backgroundImage = props.backgroundGradient;
    }

    const componentWrapperStyle: {
      minHeight?: string;
      paddingTop: string;
      paddingBottom: string;
      paddingLeft: string;
      paddingRight: string;
      width: string;
    } = {
      minHeight: props.componentMinHeight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
      width: props.gridWidth,
    };

    const cards = this.props.cardConfigList ? this.props.cardConfigList.map((card, index) => (
      <div key={index} style={{ marginLeft: index === 0 ? 0 : this.props.cardGapWidth }} className="cardh-horizontal-card-col">
        <SbEditable content={card} key={card && card._uid || ''}>
          <Card
            visibleByDefault={this.getProps('visibleByDefault')}
            gaSend={this.getProps('gaSend')}
            getFadeTransiation={() => this.getProps('fadeTransiation')}
            getMediaTransiation={() => this.getProps('mediaTransiation')}
            getGapWidth={() => this.getProps('cardGapWidth')}
            getGridWidth={() => this.getProps('gridWidth')}
            getTabPresentation={() => this.getProps('tabPresentation')}
            {...card}
            key={`card-${index}`}
            index={index}
            id={id}
          />
        </SbEditable>
      </div>
      )) : null;
    const videoPosterAttr = {};
    videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster'] = props.backgroundVideoPoster;
    const componentChild = [props.hasBackgroundVideo && getAsset(props.backgroundVideo) ? (
      <div key="bgVideo" className="cardh-background-video background-video-wrapper">
        <VideoPlayer
          visiblePlay={props.backgroundVideoVisiblePlay}
          replayWhenRevisible={props.backgroundVideoVisiblePlay}
          lazyLoad
          lazyLoadThreshold={2000}
          style={{
            postion: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <video muted {...videoPosterAttr} loop={props.backgroundVideoLoop} autoPlay={!props.backgroundVideoVisiblePlay && !props.backgroundVideoHoverPlay}>
            <source type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' data-layzr-src={getAsset(props.backgroundVideo)} />
          </video>
        </VideoPlayer>

        <div className="cardh-video-mask" style={{ backgroundColor: props.backgroundVideoMaskColor, opacity: props.backgroundVideoMaskOpacity }} />

      </div>
    ) : null,
    <div id={props.anchorId || ''} key="cards" className={`cardh-hero-horizontal-wrapper ${verticalAlignStyleName}`} style={componentWrapperStyle}>
      <div className="cardh-horizontal-card-list" data-aos={aosFade} data-aos-once>
        {cards}
      </div>
    </div>];
    return (
      <LazyWrapper
        type="bg"
        visibleByDefault={visibleByDefault}
        src={backgroundImage}
        bgWidth={props.backgroundImageWidth}
        bgHeight={props.backgroundImageHeight}
        parallax={props.hasBackgroundImage && props.backgroundParallax}
        className={`cardh-hero-horizontal-component atom-design-v1 ${props.customStyle}`}
        style={componentStyle}
      >
        {componentChild}
      </LazyWrapper>
    );
  }
}
