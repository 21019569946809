import marked from 'marked'

export default (text) => {
    if (text){
      let rawMarkup = marked(text)
      return { __html:  rawMarkup}  
    }
    else{
      return null
    }
}