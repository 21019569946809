import React from "react"
import API from "../../../utils/api_auth"
import "./profile_block.css"
import SbEditable from "storyblok-react"
import t from "../../../utils/languages"
import { navigate } from 'gatsby';
import LinkFix from "../../../utils/links"


class ProfileBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : {
                email : '',
                firstname: '',
                surname: '',
                city: '',
                zip_code: '',
                country_code: '',
                phone: ''

            },
            error:{
                email : false,
            },
            isLoading : false,
            timeout:true,
            isSubmitted : false,
            initedMe:'loading',
            initedSubscriptions:'loading',
            countries : [],
            newsletters : {
                '5cf8db738a' : false,
                '7e9b5c36d7' : false,
                'dc791c8caa' : false,
                '0f88d3ad9f' : false,
                'd267a143d2' : false
            },
            newsletterLanguage : '',
            newsletterStatus : '',
            newsletterLoading : {
                '5cf8db738a' : false,
                '7e9b5c36d7' : false,
                'dc791c8caa' : false,
                '0f88d3ad9f' : false,
                'd267a143d2' : false
            },
            customButton : props.blok.custom_save_button && props.blok.custom_save_button.length > 0 ? props.blok.custom_save_button[0] : null
        }

        this.updateSubscription = this.updateSubscription.bind(this);
    }

    handle = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        let errors = this.state.errors;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        switch (name){
            case 'email':
                errors.email = !emailRegex.test(value);
                break;
            default:
                break;
        }
        let user = this.state.user;
        user[name] = value;

        this.setState({
            errors,
            user: user
        })
    }

    updateProfile = () =>{
        let _this=this
        this.setState({ isLoading : true})
        API.put('/me', this.state.user).then(() => {
            this.setState({ isLoading : false, isSubmitted:true, timeout:false})
            if (this.state.customButton && this.state.customButton.link){
                navigate(LinkFix(this.state.customButton.link))
            }
            setTimeout(() => {
                _this.setState({timeout:true})
            }, 3000);
        }).catch((error)=>{
            this.setState({ isLoading : false, isSubmitted:false, timeout:false})
            console.log(error)
            setTimeout(() => {
                _this.setState({timeout:true})
            }, 3000);
        })
    }

    updateSubscription = (event) => {
        const target = event.target
        const id = target.id
        let edms = this.state.newsletters
        edms[id] = !edms[id];
        let loadState = this.state.newsletterLoading;
        loadState[id] = true;
        this.setState({ newsletterLoading : loadState });

        let newsletter = {
            subscriptions : edms,
            language : this.state.newsletterLanguage,
            status : this.state.newsletterStatus
        }
        API.put('/me/subscriptions', {newsletter : newsletter, user : this.state.user})
            .then(() => {
                this.setState({ newsletters : edms})
                loadState[id] = false;
                this.setState({ newsletterLoading : loadState });
            })
            .catch(e => console.log(e))

    }

    componentDidMount = () => {

        API.get('/me').then(response => {
            this.setState({ user : response.data.user,initedMe:"loaded"})
            API.get('/me/subscriptions').then(response => {
                var edms = this.state.newsletters;
                for (let index = 0; index < response.data.interests.length; index++) {
                    edms[response.data.interests[index].id] = response.data.interests[index].value;
                }
                this.setState({ newsletters : edms, newsletterStatus : response.data.status, newsletterLanguage : response.data.language, initedSubscriptions:'loaded' })
            })
        })
        API.get('/countries').then(response => { this.setState({ countries : response.data.countries })})
    }

    render = () => {
      const { blok, lang } = this.props;
      let { customButton, error, user, countries, newsletters, newsletterLoading, isLoading,isSubmitted,timeout,initedMe,initedSubscriptions} = this.state;


        return (
            <SbEditable content={blok}>
            { initedMe === 'loaded'?
              <div className="profile-wrapper">
                    <div className="editorial row">
                        <div className="et fifty">
                            <label htmlFor="firstname">
                                <div>
                                    <span className={!error.firstname ? "show" : null}> 
                                        {t('profile.firstNamePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="firstname" id="firstname" className={error.firstname ? "error" : null} type="text" maxLength='255' placeholder={ t('profile.firstNamePlaceHolder', lang)} value={user.firstname} onChange={this.handle.bind(this)} />
                            </label> 

                        </div>
                        <div className="et fifty">

                            <label htmlFor="surname">
                                <div>
                                    <span className={!error.surname ? "show" : null}> 
                                        {t('profile.lastNamePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="surname" className={error.surname ? "error" : null} type="text" maxLength='255' placeholder={ t('profile.lastNamePlaceHolder', lang)} value={user.surname} onChange={this.handle.bind(this)} />
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="emailAddress">
                                <div>
                                    <span className={ (!error.email || user.email === '') ? "show" : null}> 
                                        { t('profile.emailPlaceHolder', lang)}
                                    </span>
                                    <span className={`error ${ (error.email && user.email !== '') ? "show" : null} `}>{ t('profile.emailInvalid', lang) }</span>
                                </div>
                                <input name="emailAddress" disabled={true} className={error.email ? "error" : null} type="email" placeholder={ t('profile.emailPlaceHolder', lang)} value={user.email} onChange={this.handle.bind(this)} />
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="phone">
                                <div>
                                    <span className={!error.phone ? "show" : null}> 
                                        {t('profile.phonePlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="phone" className={error.phone ? "error" : null} type="text" maxLength='255' placeholder={ t('profile.phonePlaceHolder', lang)} value={user.phone} onChange={this.handle.bind(this)} />
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="city">
                                <div>
                                    <span className={!error.city ? "show" : null}> 
                                        {t('profile.cityPlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="city" className={error.city ? "error" : null} type="text" maxLength='255' placeholder={ t('profile.cityPlaceHolder', lang)} value={user.city} onChange={this.handle.bind(this)} />
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="zip_code">
                                <div>
                                    <span className={!error.zip_code ? "show" : null}> 
                                        {t('profile.zipPlaceHolder', lang)}
                                    </span>
                                </div>
                                <input name="zip_code" className={error.zip_code ? "error" : null} type="text" maxLength='255' placeholder={ t('profile.zipPlaceHolder', lang)} value={user.zip_code} onChange={this.handle.bind(this)} />
                            </label> 
                        </div>
                        <div className="et left fifty">
                            <label htmlFor="country_code">
                                <div>{ t('newsletter.selectYourCountry', lang) }</div> 
                                <select name="country_code" value={user.country_code} onBlur={this.handle.bind(this)} onChange={this.handle.bind(this)} >
                                    {countries.map((c, index) => <option key={index} value={c.id}>{c.t}</option>)}
                                </select>
                            </label> 
                        </div>
                        <div className="et left fifty"></div>
                        <div className="et left hundred">
                            <div className="cta-row cta-row-content">
                                <button 
                                className={`cta ${isLoading ? 'loading' : ''}`} 
                                onClick={ this.updateProfile } 
                                disabled={isLoading}
                                >
                                { customButton ? customButton.text : t('profile.save', lang)}
                                </button>
                            </div>
                            <div className={timeout ? 'profile-block-tip-hidden':'profile-block-tip-show'}>
                                <span className={isSubmitted ? 'profile-block-tip-sucussed' : 'profile-block-tip-foild'}>{isSubmitted ? t('profile.save.scussce.tip',lang):t('profile.save.failed.tip',lang)}</span>
                            </div>
                        </div>
                    </div>
                { !blok.hide_subscriptions && 
                <div className="editorial row">
                    <div className="et left hundred">
                        <h2>{ t('profile.newsletters.title', lang)}</h2>
                        <p>{t('profile.newsletter.description', lang)}</p>
                    </div>
                    <div className="et left hundred">
                        <input id="5cf8db738a" type="checkbox" className={ newsletterLoading['5cf8db738a'] ? 'loading' : null } checked={newsletters['5cf8db738a']} onChange={this.updateSubscription} />
                        <label htmlFor="5cf8db738a">
                            <span>{ t('profile.newsletter.xsystem', lang)}</span>
                        </label>
                    </div>
                    <div className="et left hundred">
                        <input id="7e9b5c36d7" type="checkbox" className={ newsletterLoading['7e9b5c36d7'] ? 'loading' : null } checked={newsletters['7e9b5c36d7']} onChange={this.updateSubscription}  />
                        <label htmlFor="7e9b5c36d7">
                            <span>{ t('profile.newsletter.hsystem', lang)}</span>
                        </label>
                    </div>
                    <div className="et left hundred">
                        <input id="dc791c8caa" type="checkbox" className={ newsletterLoading['dc791c8caa'] ? 'loading' : null } checked={newsletters['dc791c8caa']} onChange={this.updateSubscription} />
                        <label htmlFor="dc791c8caa">
                            <span>{ t('profile.newsletter.inspirational', lang)}</span>
                        </label>
                    </div>
                    <div className="et left hundred">
                        <input id="0f88d3ad9f" type="checkbox" className={ newsletterLoading['0f88d3ad9f'] ? 'loading' : null } checked={newsletters['0f88d3ad9f']} onChange={this.updateSubscription}  />
                        <label htmlFor="0f88d3ad9f">
                            <span>{ t('profile.newsletter.industrial', lang)}</span>
                        </label>
                    </div>
                    <div className="et left hundred">
                        <input id="d267a143d2" type="checkbox" className={ newsletterLoading['d267a143d2'] ? 'loading' : null } checked={newsletters['d267a143d2']} onChange={this.updateSubscription}  />
                        <label htmlFor="d267a143d2">
                            <span>{ t('profile.newsletter.store', lang)}</span>
                        </label>
                    </div>
                </div>
                 }
              </div>
              :
              <div className="profile-user-loading"></div>
            }
            </SbEditable>
        )
        }
 
}

export default ProfileBlock

