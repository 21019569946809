/* eslint-disable complexity */
import classNames from 'classnames';
import React from 'react';
import LazyLoad from 'react-lazyload';
import ParallaxPicture from '../../../ParallaxPicture';
import Image from '../../../../../components/blocks/image';
import VideoPlayer from '../../../video';
import LazyWrapper from '../../../utils/LazyWrapper';
import { filterHtmlTag, getAsset } from '../../../../../utils/helper';
import {
  PropsWithAos,
  CardLayout,
  CardMedia,
  ForegroundParallax,
  VideoPlayType,
  ForegroundLayout,
  tabPresentation,
} from './type';

import './style.scss';

const CardMediaComp = React.forwardRef((props: PropsWithAos, ref: React.ForwardedRef<any>) => {
  const {
    aosFade, visibleByDefault, leftCols, foregroundList,
    canZoomImage = false,
    showZoomIconInMedia = false,
    zoomImageConfig = [],
  } = props;

  const hasZoomImageConfig = !!zoomImageConfig.length && !!zoomImageConfig[0].zoom_image_id;
  const {
    cardForegroundVideo,
    cardForegroundVideoReplay,
    cardForegroundVideoPlay,
    cardForegroundVideoPause,
  } = ref;

  const mediaFade = props.getMediaTransiation();
  const curTabPresentation = props.getTabPresentation();

  const colsToWidth = (col: any): number => {
    const cardGapWidth = props.getGapWidth();
    const gridWidth = props.getGridWidth();
    const GAP_WIDTH = parseInt(cardGapWidth, 10);
    const GAP_COUNT = 11;
    let TOTAL_WIDTH = gridWidth;
    if (TOTAL_WIDTH.match(/vw|%/)) {
      TOTAL_WIDTH = parseInt(TOTAL_WIDTH, 10) * window.innerWidth;
    } else {
      TOTAL_WIDTH = parseInt(TOTAL_WIDTH, 10);
    }

    const colCount = GAP_COUNT + 1;
    const colWidth = (TOTAL_WIDTH - GAP_WIDTH * GAP_COUNT) / colCount;
    return colWidth * col + GAP_WIDTH * (col - 1);
  };

  const leftWidth = colsToWidth(props.leftCols);
  const rightWidth = `calc(100% - ${props.mediaDistance} - ${leftWidth}px)`;
  const parallaxable = props.foregroundLayout === ForegroundLayout.one;
  // 整体视差
  const wholeParallax
    = parallaxable
    && props.foregroundHasParallax
    && props.foregroundParallax === ForegroundParallax['whole-item'];
  // 内部视差
  const innerParallax
    = parallaxable
    && props.foregroundHasParallax
    && props.foregroundParallax === ForegroundParallax['inner-content'];

  const foreItem = (item) => {
    if (!item) {
      return null;
    }
    const videoPosterAttr = {
      [visibleByDefault ? 'poster' : 'data-layzr-poster']:
        getAsset(item.foregroundVideoPoster),
    };

    const videoStyle = {
      borderRadius: item.mediaBorderRadius,
      overflow: 'hidden',
    };

    const videoBtns = [
      <div
        key="replay"
        data-link="ture"
        className="replay-button cardh-video-buttom cardh-replay-buttom"
        ref={cardForegroundVideoReplay}
      />,
      <div
        key="pause"
        data-link="ture"
        className="pause-button cardh-video-buttom cardh-pause-buttom"
        ref={cardForegroundVideoPause}
      />,
      <div
        key="play"
        data-link="ture"
        className="play-button cardh-video-buttom cardh-play-buttom"
        ref={cardForegroundVideoPlay}
      />,
    ];
    // 卡片前景视频配置循环播放，或 tab配置了自动切换 且 卡片配置了播放一次 或 回屏自动播放，则不展示重播按钮
    const noReplayBtn = item.foregroundVideoPlayType === VideoPlayType.loop || ((item.foregroundVideoPlayType === VideoPlayType.once || item.foregroundVideoPlayType === VideoPlayType.replayWhenRevisible) && curTabPresentation === tabPresentation.carousel);
    const videoWrapper = (
      <div
        className={`cardh-media-video${innerParallax ? ' cardh-parallax-fore-video' : ''}`}
        data-video={item.foregroundVideoPlayType}
      >
        {item.foregroundVideoPlayType === VideoPlayType.withController && getAsset(item.foregroundVideoPoster) && (
          <div
            style={{ backgroundImage: `url(${getAsset(item.foregroundVideoPoster)})` }}
            className="cardh-card-fore-video-poster"
          />
        )}
        <video
          style={videoStyle}
          ref={cardForegroundVideo}
          muted
          {...videoPosterAttr}
          loop={item.foregroundVideoPlayType === VideoPlayType.loop}
        >
          {getAsset(item.foregroundVideoWebm) && (
            <source
              type="video/webm"
              data-layzr-src={getAsset(item.foregroundVideoWebm)}
            />
          )}
          {getAsset(item.foregroundVideo) && (
            <source
              type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'
              data-layzr-src={getAsset(item.foregroundVideo)}
            />
          )}
        </video>
        {innerParallax ? null : videoBtns}
      </div>
    );
    const video = (
      <VideoPlayer
        style={{
          height: !parallaxable || innerParallax ? '100%' : '',
          width: !parallaxable || innerParallax ? '100%' : '',
        }}
        replayWhenRevisible={
          item.foregroundVideoPlayType !== VideoPlayType.once
          && item.foregroundVideoPlayType !== VideoPlayType.withController
        }
        visiblePlay={
          item.foregroundVideoPlayType !== VideoPlayType.withController
        }
        lazyLoad
        lazyLoadThreshold={2000}
        replayClassName={noReplayBtn
          ? ''
          : 'replay-button'}
        pauseClassName={
          item.foregroundVideoPlayType === VideoPlayType.withController
            ? 'pause-button'
            : ''
        }
        playClassName={
          item.foregroundVideoPlayType === VideoPlayType.withController
            ? 'play-button'
            : ''
        }
      >
        {innerParallax ? (
          <ParallaxPicture
            offset="-100"
            style={{ height: 'calc(100% + 100px)', marginTop: '-100px' }}
          >
            {videoWrapper}
          </ParallaxPicture>
        ) : (
          videoWrapper
        )}
        {innerParallax ? videoBtns : null}
      </VideoPlayer>
    );

    let picture = hasZoomImageConfig && canZoomImage ? 
      <LazyLoad offset={1000} once>
        <div style={{ borderRadius: item.mediaBorderRadius, overflow: 'hidden' }}>
          <Image blok={zoomImageConfig[0]} uid={props._uid} modalModeOn/>
        </div>
      </LazyLoad>
    : (
      <LazyWrapper
        visibleByDefault={visibleByDefault}
        parallax={innerParallax}
        type={innerParallax ? 'bg' : 'img'}
        src={getAsset(item.foregroundImage)}
        className={`cardh-media-pic${innerParallax ? ' cardh-parallax-fore-pic' : ''}`}
        alt={filterHtmlTag(item.foregroundImageAlt)}
        style={{ borderRadius: item.mediaBorderRadius, overflow: 'hidden' }}
      />
    );

    return item.media === CardMedia.picture ? picture : video;
  };

  const foreContentMap = {
    [ForegroundLayout.one]: foreItem(props),
    [ForegroundLayout.leftRight]: (
      <div className="cardh-card-media-multi cardh-card-media-multi-leftRight">
        {
          foregroundList.map((item, index) => {
            const gapStyle = (props.foregroundListGap && index !== (foregroundList.length - 1))
              ? { marginRight: props.foregroundListGap } : {};
            return (<div key={index} className="cardh-card-media-part cardh-card-media-item" style={gapStyle}>{foreItem(item)}</div>);
          })
        }
      </div>
    ),
    [ForegroundLayout.upDown]: (
      <div className="cardh-card-media-multi cardh-card-media-multi-updown">
        {foregroundList.map((item, index) => {
          const gapStyle = (props.foregroundListGap && index !== (foregroundList.length - 1))
            ? { marginBottom: props.foregroundListGap } : {};
          return (
            <div key={index} className="cardh-card-media-part cardh-card-media-item" style={gapStyle}>
              {foreItem(item)}
            </div>
          );
        })}
      </div>
    ),
    [ForegroundLayout.left2right1]: (
      <div className="cardh-card-media-multi cardh-card-media-multi-left2right1">
        <div className="cardh-card-media-part" style={{ marginRight: props.foregroundListGap }}>
          <div className="cardh-card-media-item" style={{ marginBottom: props.foregroundListGap }}>{foreItem(foregroundList[0])}</div>
          <div className="cardh-card-media-item">{foreItem(foregroundList[1])}</div>
        </div>
        <div className="cardh-card-media-part">
          <div className="cardh-card-media-item">{foreItem(foregroundList[2])}</div>
        </div>
      </div>
    ),
    [ForegroundLayout.up1down2]: (
      <div className="cardh-card-media-multi cardh-card-media-multi-up1down2">
        <div className="cardh-card-media-part" style={{ marginBottom: props.foregroundListGap }}>
          <div className="cardh-card-media-item">{foreItem(foregroundList[0])}</div>
        </div>
        <div className="cardh-card-media-part">
          <div className="cardh-card-media-item" style={{ marginRight: props.foregroundListGap }}>{foreItem(foregroundList[1])}</div>
          <div className="cardh-card-media-item">{foreItem(foregroundList[2])}</div>
        </div>
      </div>
    ),
    [ForegroundLayout.up2down1]: (
      <div
        className="cardh-card-media-multi cardh-card-media-multi-up2down1"
        style={{ flexDirection: 'column' }}
      >
        <div className="cardh-card-media-part" style={{ marginBottom: props.foregroundListGap }}>
          <div className="cardh-card-media-item" style={{ marginRight: props.foregroundListGap }}>{foreItem(foregroundList[0])}</div>
          <div className="cardh-card-media-item">{foreItem(foregroundList[1])}</div>
        </div>
        <div className="cardh-card-media-part">
          <div className="cardh-card-media-item">{foreItem(foregroundList[2])}</div>
        </div>
      </div>
    ),
  };

  const foreContent = foreContentMap[props.foregroundLayout];

  const hasText
    = !(props.cardLayout === CardLayout['media-text'] && leftCols === '12')
    && !(props.cardLayout === CardLayout['text-media'] && leftCols === '0');

  let mediaHeight = {};
  mediaHeight
    = props.foregroundHeight === '100%'
      ? { 'align-self': 'stretch' }
      : { height: props.foregroundHeight };
  mediaHeight = innerParallax ? mediaHeight : {};
  let mediaWidth
    = props.cardLayout === CardLayout['media-text']
      ? `${leftWidth}px`
      : rightWidth;
  mediaWidth = hasText ? mediaWidth : '100%';

  return (
    <div
      key="media"
      data-aos={mediaFade || aosFade}
      data-aos-once
      className={classNames('cardh-card-media', !showZoomIconInMedia && 'without-zoom-icon')}
      style={{
        ...mediaHeight,
        width: mediaWidth,
        marginRight:
          props.cardLayout === CardLayout['media-text']
            ? props.mediaDistance
            : '',
        borderRadius: props.mediaBorderRadius,
        overflow: wholeParallax ? 'visible' : 'hidden',
      }}
    >
      {
        wholeParallax ? (
          <ParallaxPicture offset="-50">
            {foreContent}
          </ParallaxPicture>
        ) : foreContent
      }
    </div>
  );
});

export default CardMediaComp;
