import { isArray } from "lodash";
import React from "react";
import SbEditable from "storyblok-react";
import PolymerizationItem from './polymerizationItem'
import { DeviceInfo } from "../../../hooks/device";
import './style.css'

const Polymerization = (props) => {
    const{ blok, lang } = props;
    const { isMobile:isM } = DeviceInfo();
    return (
        <SbEditable content={blok} lang={lang}>
            <section className="polymerization-section" style={{backgroundColor:blok.backgroundColore, paddingTop:`${isM ? blok.paddingTopM : blok.paddingTop}px`, paddingBottom: `${isM ? blok.paddingButtomM : blok.paddingButtom}px`}}>
                <h3 className="polymerization-title" dangerouslySetInnerHTML={{__html: blok.title}} style={{color:blok.titleColor}}></h3>
                {
                  blok && blok.polymerizationItem && isArray(blok.polymerizationItem) && blok.polymerizationItem.map((item)=>{
                    return(<PolymerizationItem content={item} key={item?._uid} lang={lang}></PolymerizationItem>)
                  })
                }
            </section>
        </SbEditable>
    );
}
export default Polymerization;