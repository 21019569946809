import React, { useState } from "react"
import SbEditable from "storyblok-react"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import Visibility from 'react-visibility-sensor'
import "./editorial.css"

export default class extends React.Component {

    constructor(props) {
        super(props);  
        this.state = {
          position: this.props.blok.initial_position || 50
        }
      }

    render() {
        const { blok } = this.props


        return (
            <SbEditable content={blok} key={blok._uid}>
                <div className={`editorial row`}  >
                    <ReactCompareSlider portrait={blok.direction === 'true' } position={this.state.position}
                        itemOne={<ReactCompareSliderImage src={blok.image_1.filename} alt="Image one" />}
                        itemTwo={<ReactCompareSliderImage src={blok.image_2.filename}  alt="Image two" />}
                        />
                </div>
            </SbEditable>
        )
    }
}