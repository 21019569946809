/* eslint-disable complexity */
import React from 'react';
import VideoPlayer from '../../../video';
import ParallaxPicture from '../../../ParallaxPicture';
import LazyWrapper from '../../../utils/LazyWrapperScroll';
import { TextLineHeight, TextFontSize } from '../../../basic/Text';
import { filterHtmlTag, getAsset, LinkFix } from '../../../../../utils/helper';
import './style.scss';

import {
  Props,
  ComponentLinkType,
  ContentSize,
  VerticalAlign,
  HorizontalAlign,
  CardLayout,
  CardMedia,
  VideoPlayType,
  IconLayout,
  TitleType,
  TextColor,
  LinkColor,
  JumpType,
  ForegroundParallax,
  foreVideoColor,
  BackgroundSize,
  BackgroundRepeat,
  BackgroundFixed,
  ForegroundLayout,
} from './type';
import Eyebrow from './eyebrow';
import Headline from './headline';
import SubHeadline from './subHeadline';
import Divider from './divider';
import Description from './description';
import Buttons from './buttons';
import Note from './note';
import Icons from './icons';
import Tag from './tag';
import CardMediaComp from './cardMedia';

export default class Card extends React.Component<Props> {
  static handleCardClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void {
    if (!e || !e.target) {
      return;
    }
    const target = e.target as HTMLTextAreaElement;
    if (target.getAttribute('data-link')) {
      e.preventDefault();
    }
  }

  appStoreDom: Array<HTMLAnchorElement | null>;

  googlePlayDom: Array<HTMLAnchorElement | null>;

  androidBtnDom: Array<HTMLAnchorElement | null>;

  cardBgVideoDom: HTMLElement | null;

  cardForegroundVideo: React.RefObject<HTMLElement | null>;

  cardForegroundVideoReplay: React.RefObject<HTMLElement | null>;

  cardForegroundVideoPlay: React.RefObject<HTMLElement | null>;

  cardForegroundVideoPause: React.RefObject<HTMLElement | null>;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    index: 0,
    aosFade: 'none',
    mediaFade: 'none',
    contentSize: ContentSize.xl,
    verticalAlign: VerticalAlign['top-align'],
    horizontalAlign: HorizontalAlign['center-align'],
    canZoomImage: false,
    zoomImageConfig: [],
    showZoomIconInMedia: false,
    minHeight: '',
    marginTop: '0px',
    marginBottom: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    borderRadius: '0px',
    hasJumpLink: false,
    jumpType: JumpType.external,
    jumpLink: {},
    textColor: TextColor.black,
    linkColor: LinkColor.normal,
    linkFontSize: TextFontSize.default,
    linkLineHeight: TextLineHeight.default,
    backgroundColor: '',
    backgroundGradient: '',
    cardLayout: CardLayout['media-text'],
    hasBackgroundImage: false,
    backgroundImage: {},
    backgroundPosition: 'center',
    backgroundSize: BackgroundSize['auto-fit'],
    backgroundSizeCustom: '',
    backgroundRepeat: BackgroundRepeat['no-repeat'],
    backgroundFixed: BackgroundFixed.normal,
    backgroundParallax: false,
    hasBackgroundVideo: false,
    backgroundVideoLoop: true,
    backgroundVideoVisiblePlay: true,
    backgroundVideo: {},
    backgroundVideoPoster: {},
    backgroundVideoMaskColor: '#000000',
    backgroundVideoMaskOpacity: '0',
    hasMedia: false,
    media: CardMedia.picture,
    mediaBorderRadius: '0px',
    mediaDistance: '32px',
    foregroundImage: {},
    foregroundImageAlt: '',
    foregroundHasParallax: false,
    foregroundParallax: ForegroundParallax['inner-content'],
    foregroundHeight: '300px',
    foregroundVideo: {},
    foreVideoColor: foreVideoColor.light,
    foregroundVideoWebm: {},
    foregroundVideoPoster: {},
    foregroundVideoHoldPoster: {},
    foregroundVideoPlayType: VideoPlayType.loop,
    foregroundLayout: ForegroundLayout.one,
    foregroundList: [],
    foregroundCredit: '',
    downgradeVideo: {},
    contentPaddingTop: '',
    contentPaddingBottom: '',
    contentPaddingLeft: '',
    contentPaddingRight: '',
    hasToppingImage: true,
    toppingImage: {},
    toppingImageHeight: '64px',
    hasTag: false,
    tagText: 'Tag',
    tagHeight: '24px',
    tagWidth: '45px',
    tagColor: '#BE4E04',
    tagRadius: '4px',
    hasEyeBrow: false,
    eyeBrowType: TitleType['pure-text'],
    eyeBrowSVG: {},
    eyeBrowSVGHeight: '96px',
    eyeBrowText: 'Eyebrow',
    eyeBrowPureColor: 'rgba(0, 0, 0, 0.85)',
    eyeBrowGradientColor: '',
    eyeBrowFontSize: TextFontSize.default,
    eyeBrowFontWeight: '',
    eyeBrowLineHeight: TextLineHeight.default,
    hasHeadline: true,
    headlineType: TitleType['pure-text'],
    headlineSVG: {},
    headlineText: 'Headline',
    headlineSVGHeight: '32px',
    headlinePureColor: 'rgba(0, 0, 0, 0.85)',
    headlineGradientColor: '',
    headlineFontSize: TextFontSize.default,
    headlineFontWeight: '',
    headlineLineHeight: TextLineHeight.default,
    hasSubHeadline: false,
    subHeadlineType: TitleType['pure-text'],
    subHeadlineColor: 'rgba(0, 0, 0, 0.85)',
    subHeadlineGradientColor: '',
    subHeadlineText: 'Subhead',
    subHeadlineFontSize: TextFontSize.default,
    subHeadlineFontWeight: '',
    subHeadlineLineHeight: TextLineHeight.default,
    hasDescription: true,
    descriptionColor: 'rgba(0, 0, 0, 0.65)',
    descriptionText: 'Description',
    descriptionFontSize: TextFontSize.default,
    descriptionFontWeight: '',
    descriptionLineHeight: TextLineHeight.default,
    hasDividedLine: false,
    dividedBlockHeight: '48px',
    dividedLineWidth: '48px',
    dividedLineHeight: '1px',
    dividedLineRadius: '0',
    dividedLineColor: '',
    dividedLineGradientColor: '',
    hasCardLinks: false,
    secondaryButtonClickID: '',
    thirdlyButtonClickID: '',
    hasNote: false,
    noteMarginTop: '',
    noteText: 'note',
    noteFontSize: TextFontSize.default,
    noteFontWeight: '',
    noteLineHeight: TextLineHeight.default,
    noteColor: 'rgba(0, 0, 0, 0.45)',
    hasIcons1: false,
    icons1MaxWidth: 'none',
    icons1Width: 'auto',
    icons1Layout: IconLayout.vertical,
    icons1Height: '48px',
    icons1: [
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
    ],
    hasIcons2: false,
    icons2MaxWidth: 'none',
    icons2Width: 'auto',
    icons2Layout: IconLayout.vertical,
    icons2Height: '48px',
    icons2: [
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
    ],
    hasIcons3: false,
    icons3MaxWidth: 'none',
    icons3Layout: IconLayout.vertical,
    icons3Height: '48px',
    icons3Width: 'auto',
    icons3: [
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
    ],
    hasIcons4: false,
    icons4MaxWidth: 'none',
    icons4Width: 'auto',
    icons4Layout: IconLayout.vertical,
    icons4Height: '48px',
    icons4: [
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
      {
        hasTitle: true,
        hasPic: true,
        hasDesc: true,
        hasNumber: false,
        hasUnit: false,
        number: '99',
        unit: 'm/s',
        titleColor: 'rgba(0, 0, 0, 0.85)',
        descColor: 'rgba(0, 0, 0, 0.65)',
        title: 'title',
        pic: {},
        desc: 'desc',
        titleFontSize: TextFontSize.default,
        titleFontWeight: '',
        titleLineHeight: TextLineHeight.default,
        descFontSize: TextFontSize.default,
        descFontWeight: '',
        descLineHeight: TextLineHeight.default,
      },
    ],
  };

  constructor(props: Props) {
    super(props);
    this.appStoreDom = [];
    this.googlePlayDom = [];
    this.androidBtnDom = [];
    this.cardBgVideoDom = null;
    this.cardForegroundVideo = React.createRef();
    this.cardForegroundVideoReplay = React.createRef();
    this.cardForegroundVideoPlay = React.createRef();
    this.cardForegroundVideoPause = React.createRef();
  }

  componentDidMount(): void {
    if (typeof window === 'undefined') {
      return;
    }

    const isAndroid
      = typeof window === 'undefined'
        ? true
        : /(Android)/i.test(window.navigator.userAgent);
    const isIOS
      = typeof window === 'undefined'
        ? true
        : /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent);

    if (isAndroid) {
      this.appStoreDom.forEach((item) => {
        if (item && item.remove) {
          item.remove();
        }
      });
    }
    if (isIOS) {
      this.googlePlayDom.forEach((item) => {
        if (item && item.remove) {
          item.remove();
        }
      });

      this.androidBtnDom.forEach((item) => {
        if (item && item.remove) {
          item.remove();
        }
      });
    }
  }

  render(): JSX.Element {
    const { props } = this;
    const {
      aosFade, mediaFade, visibleByDefault = false,
    } = props;
    const isUC
      = typeof window !== 'undefined'
      && window.navigator.userAgent.match(/UCBrowser/gi);

    const renderCardBackgroundVideo = (): JSX.Element => {
      const downgradeDom = (
        <LazyWrapper
          type="bg"
          visibleByDefault={visibleByDefault}
          className="mcardv-card-downgrade-image"
          src={getAsset(props.backgroundVideoPoster)}
        />
      );
      const videoPosterAttr = {};
      videoPosterAttr[visibleByDefault ? 'poster' : 'data-layzr-poster']
        = getAsset(props.backgroundVideoPoster);
      return props.videoAllDowngrade ? (
        downgradeDom
      ) : (
        <div
          className="mcardv-card-background-video"
          style={{ borderRadius: props.borderRadius }}
        >
          <VideoPlayer
            visiblePlay={props.backgroundVideoVisiblePlay}
            replayWhenRevisible={props.backgroundVideoVisiblePlay}
            downgradeDom={downgradeDom}
            lazyLoad
            lazyLoadThreshold={1200}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <video
              ref={(i) => {
                this.cardBgVideoDom = i;
              }}
              muted
              {...videoPosterAttr}
              loop={props.backgroundVideoLoop}
            >
              {getAsset(props.backgroundVideo) && (
                <source
                type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'
                data-layzr-src={getAsset(props.backgroundVideo)}
              />
              )}
            </video>
          </VideoPlayer>

          <div
            className="mcardv-video-mask"
            style={{
              backgroundColor: props.backgroundVideoMaskColor,
              opacity: props.backgroundVideoMaskOpacity,
            }}
          />
        </div>
      );
    };

    const renderCardContent = (index: number): JSX.Element => {
      const contentStyle: {
        paddingTop: string;
        paddingBottom: string;
        paddingLeft: string;
        paddingRight: string;
      } = {
        paddingTop: props.contentPaddingTop,
        paddingBottom: props.contentPaddingBottom,
        paddingLeft: props.contentPaddingLeft,
        paddingRight: props.contentPaddingRight,
      };

      const {
        hasToppingImage,
        hasEyeBrow,
        hasHeadline,
        hasSubHeadline,
        hasDividedLine,
        hasDescription,
        hasCardLinks,
        hasNote,
        hasIcons1,
        hasIcons2,
        hasIcons3,
        hasIcons4,
      } = props;

      const textColorStyleName = `mcardv-content-text-color-${props.textColor}`;

      const hasLegalTag = props.hasTag && props.tagText;
      const hasIcons = hasIcons1 || hasIcons2 || hasIcons3 || hasIcons4;

      return (
        <div
          key="content"
          style={contentStyle}
          className={`mcardv-card-content mcardv-size-${props.contentSize} ${textColorStyleName}`}
        >
          {hasToppingImage && (
            <img
              data-aos={mediaFade}
              data-aos-once
              src={getAsset(props.toppingImage)}
              className="mcardv-topping-img"
              style={{ height: props.toppingImageHeight }}
              alt={getAsset(props.toppingImage) ? filterHtmlTag(props.toppingAlt) : ''}
            />
          )}
          {hasLegalTag && <Tag {...props} aosFade={aosFade} />}
          {hasEyeBrow && <Eyebrow {...props} aosFade={aosFade} />}
          {hasHeadline && <Headline {...props} aosFade={aosFade} />}
          {hasSubHeadline && <SubHeadline {...props} aosFade={aosFade} />}
          {hasDividedLine && <Divider {...props} aosFade={aosFade} />}
          {hasDescription && <Description {...props} aosFade={aosFade} />}
          {hasCardLinks && <Buttons {...props} aosFade={aosFade} index={index} />}
          {hasNote && <Note {...props} aosFade={aosFade} />}
          {hasIcons && (
            <Icons
              {...props}
              aosFade={aosFade}
              visibleByDefault={visibleByDefault}
            />
          )}
        </div>
      );
    };

    const renderCard = (index: number): JSX.Element => {
      const containerStyle: {
        borderRadius: string;
        marginTop: string;
        marginBottom: string;
      } = {
        borderRadius: props.borderRadius,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
      };
      let backgroundImage;
      const wrapperStyle: {
        minHeight?: string;
        paddingTop: string;
        paddingBottom: string;
        paddingLeft: string;
        paddingRight: string;
        backgroundColor: string;
        backgroundGradient?: string;
        backgroundImage?: string;
        backgroundPosition?: string;
        backgroundSize?: string;
        backgroundRepeat?: string;
        backgroundAttachment?: string;
      } = {
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom,
        paddingLeft: props.paddingLeft,
        paddingRight: props.paddingRight,
        backgroundColor: props.backgroundColor,
      };

      const cardVerticalAlignStyleName = `mcardv-vertical-${props.verticalAlign}`;
      const cardHorizontalAlignStyleName = `mcardv-horizontal-${props.horizontalAlign}`;

      if (props.minHeight) {
        wrapperStyle.minHeight = props.minHeight;
      }

      if (props.hasBackgroundImage && getAsset(props.backgroundImage)) {
        backgroundImage = `url('${getAsset(props.backgroundImage)}')`;
        wrapperStyle.backgroundPosition = props.backgroundPosition;
        wrapperStyle.backgroundSize
          = props.backgroundSize === BackgroundSize['auto-fit']
            ? 'cover'
            : props.backgroundSizeCustom;
        wrapperStyle.backgroundRepeat = props.backgroundRepeat;
        wrapperStyle.backgroundAttachment
          = props.backgroundFixed === BackgroundFixed.fix ? 'fixed' : 'scroll';

        if (props.backgroundGradient && !isUC) {
          backgroundImage = `url('${getAsset(props.backgroundImage)}'), ${props.backgroundGradient}`;
        }
      } else if (props.backgroundGradient) {
        backgroundImage = props.backgroundGradient;
      }

      const renderCredit = () => (
        <div className="mcardv-credit-container">
          <span
            className="mcardv-credit-content"
            dangerouslySetInnerHTML={{ __html: props.foregroundCredit }}
          />
        </div>
      );

      const mediaRefs = {
        cardForegroundVideo: this.cardForegroundVideo,
        cardForegroundVideoReplay: this.cardForegroundVideoReplay,
        cardForegroundVideoPlay: this.cardForegroundVideoPlay,
        cardForegroundVideoPause: this.cardForegroundVideoPause,
      };
      const media = props.hasMedia ? <CardMediaComp {...props} ref={mediaRefs} aosFade={aosFade} /> : null;
      const credit = props.foregroundCredit && renderCredit();
      const content = renderCardContent(index);
      const backgroundVideo = props.hasBackgroundVideo
        ? renderCardBackgroundVideo()
        : null;
      const cardContent = (
        <div className={`mcardv-card-content ${cardHorizontalAlignStyleName}`}>
          {
            props.cardLayout === CardLayout['media-text']
              ? [media, credit, content] : [content, media, credit]
          }
        </div>
      );
      if (props.hasBackgroundImage && props.backgroundParallax) {
        wrapperStyle.backgroundImage = backgroundImage;
      }

      const cardElement = (
        <div className="mcardv-card-container" style={containerStyle}>
          {backgroundVideo}
          {props.hasBackgroundImage && props.backgroundParallax ? (
            <ParallaxPicture
              background
              className={`mcardv-card-wrapper ${cardVerticalAlignStyleName}`}
              style={wrapperStyle}
            >
              {cardContent}
            </ParallaxPicture>
          ) : (
            <LazyWrapper
              type="bg"
              visibleByDefault={visibleByDefault}
              src={backgroundImage}
              className={`mcardv-card-wrapper ${cardVerticalAlignStyleName}`}
              style={wrapperStyle}
            >
              {cardContent}
            </LazyWrapper>
          )}
        </div>
      );
      return props.hasJumpLink && LinkFix(props.jumpLink) ? (
        <a
          href={LinkFix(props.jumpLink)}
          data-ga-label={props?.jumpLinkGaLabel || `card-${index}-link`}
          onClick={Card.handleCardClick}
          className="mcardv-card-link-container"
          target={props.jumpType === JumpType.external ? '_blank' : '_self'}
        >
          {cardElement}
        </a>
      ) : (
        cardElement
      );
    };

    return renderCard(props.index);
  }
}
