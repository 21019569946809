/* eslint-disable complexity */
import React from 'react';
import { PropsWithAos } from './type';
import ButtonRow from '../../../../blocks/button_row';
import './style.scss';

const Buttons = (props: PropsWithAos) => {
  const { links } = props;

  return (
    <div className="cardh-buttons-container">
      <ButtonRow content={{ buttons: links }} />
    </div>
  );
};

export default Buttons;
