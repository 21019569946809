import { ScrollManager } from '../../../../../utils/scrollManager';
import React from 'react';

export interface Props {
  onVisible: () => void;
  className?: string;
  height: number | string;
  placeholder?: Element | null;
  width: number | string;
  style?: React.CSSProperties;
}

class Placeholder extends React.Component<Props, {}> {
  placeholderElm: HTMLElement;

  componentDidMount() {
    if (this.placeholderElm) {
      ScrollManager.getInstance().addNode(this.placeholderElm, {
        scrollIntoView: () => {
          this.props.onVisible();
        },
      }, undefined, 1000);
    }
  }

  componentWillUnmount() {
    ScrollManager.getInstance().removeNode(this.placeholderElm);
  }

  render() {
    const {
      className, height, placeholder, style, width,
    } = this.props;

    const styleProp = {
      display: 'block',
      ...style,
    };

    if (typeof width !== 'undefined') {
      styleProp.width = width;
    }

    if (typeof height !== 'undefined') {
      styleProp.height = height;
    }

    return (
      <span
        className={className}
        ref={(el) => { this.placeholderElm = el; }}
        style={styleProp}
      >
        {placeholder}
      </span>
    );
  }
}

// @ts-ignore
Placeholder.defaultProps = {
  className: '',
  placeholder: null,
  style: {},
};

export default Placeholder;
