import React from "react"
import SbEditable from "storyblok-react"
import "./competition_voting.css"
import API from "../../../utils/api_auth"
import Modal from "../../../components/CustomModal"
import t from "../../../utils/languages"
import MarkDown from '../markdown'
import { navigate } from 'gatsby';
import LinkFix from "../../../utils/links";
import urlHelper from "../../../utils/url";
import Slider from "react-slick";
import SocialShare from "../social_share"
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// 最大分值
const MAX_POINT = 5;
export default class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            previouslyVoted: false, // 是否展示投过票弹窗
            confirmSubmit: false, // 确认是否提交
            voteSubmitted : false, // 是否展示提交弹窗
            submissions: [],
            showDetails : false, // 是否展示详情弹窗
            showVoteSummary: false,
            detailSubmissionId : null,
            detailSubmissionURLs: [],
            detailActivePhoto: 0,
            currentSlide: 'normal', // 代表不是第一个也不是最后一个
            social_share_image: '', // 微博分享的图片
            btnLoading: false,
            enablePointsCheck: false, // 开启评分必填检查
        }
        this.mySwiper = null;
        this.showDetails = this.showDetails.bind(this)
        this.setVote = this.setVote.bind(this)
        this.submitVotes = this.submitVotes.bind(this)
        this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
    }

    componentDidMount = () => {
        //get registration 
        API.get('/competitions/'+ this.props.blok.competition_id +'/vote')
            .then((response) => {
                if (response.data.voted){
                    this.setState({ previouslyVoted: true, loading: false })
                }
                else{
                    this.setState({ previouslyVoted: false })
                    API.get('/competitions/' + this.props.blok.competition_id + '/submissions')
                        .then((response) => {
                          // 每个类别内的作品最多30组
                          const submissions = Array.isArray(response.data) ? response.data.slice(0, 30) : [];
                          this.setState({ loading: false, submissions });
                          if (submissions.length > 0) {
                            const imageList = submissions[submissions.length - 1].urls;
                            const social_share_image = imageList[0] ? `${urlHelper.ugcPhoto(imageList[0], 'large')}` : '';
                            this.setState({
                              social_share_image,
                            });
                          }
                        })
                        .catch((err) => {
                          this.setState({ loading: false })
                        })
                }
            })
            .catch((err) => {
                // if (err.message === 'Request failed with status code 400') {
                //   this.logout();
                // }
                console.log(err);
                this.setState({ loading: false })
            })

    }

    /**
    * 登出账号，跳转到登陆页
    */
    logout = () => {
      if (process.env.NODE_ENV === 'development') {
        return;
      }
      const { lang } = this.props;

      window.localStorage.removeItem("authorization_token");
      window.localStorage.removeItem("refresh_token");

      const langSlug = lang === 'default' ? '' : `${lang}/`;
      let url = `${window.location.origin}/${langSlug}my-hasselblad`
      window.location.href = url;
    };

    closeAndReturn = () => {
        navigate(LinkFix(this.props.blok.competition_categories_page))
    }

    setVote = (id, points) => {
      const submissions = this.state.submissions.map((s, idx) => {
        if (id === idx) {
          return { ...s, points };
        } else {
          return s;
        }
      })
      this.setState({ submissions });
    }

    showDetails = (id) => {
        this.setState({ showDetails : true, detailSubmissionId : id, detailSubmissionURLs : this.state.submissions[id].urls, points: this.state.submissions[id].points, detailActivePhoto: 0 })
    }

    get showErrorTips () {
      // 已开启评分必填检查，且存在没有评分的作品
      const { enablePointsCheck, submissions } = this.state;
      return enablePointsCheck && submissions.some((s) => s.points === 0);
    }

    handleSubmitButtonClick = () => {
      const { btnLoading, submissions, enablePointsCheck } = this.state;
      if (btnLoading) {
        return;
      }
      
      if (!enablePointsCheck) {
        this.setState({ enablePointsCheck: true });
      }
      
      // 所有作品都完成评分
      if (submissions.every((s) => s.points > 0)) {
        this.handleConfirmSubmit();
      }
    }

    handleConfirmSubmit() {
      this.setState({
        confirmSubmit: true
      })
    }

    handleCancelSubmit() {
      this.setState({
        confirmSubmit: false
      })
    }

    submitVotes = () => {
        let votes = this.state.submissions.filter(s => s.points > 0).map((sub) => {
            return { submission_id : sub.id, points: sub.points}
        })
        this.setState({ btnLoading: true, confirmSubmit: false })
        API.post('/competitions/'+ this.props.blok.competition_id +'/vote', { submissions: votes })
            .then(() => {
                this.setState({ btnLoading: false, voteSubmitted: true })
            })
            .catch(() => {
                this.setState({ btnLoading: false })
            })
    }

    render() {
        const { blok, lang } = this.props
        const { submissions, detailSubmissionURLs, currentSlide, previouslyVoted, voteSubmitted, social_share_image, btnLoading, confirmSubmit, enablePointsCheck } = this.state
        let settings = {};
        if(detailSubmissionURLs.length > 0) {
          settings = {
            customPaging: function(i) {
              return (
                <a className="custom-dot-img" style={{ backgroundImage: `url('${ urlHelper.ugcPhoto(detailSubmissionURLs[i], 'small') }')` }}></a>
              );
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            onInit: () => {
              this.setState({
                currentSlide: 'first',
              });
            },
            afterChange: (i) => {
              switch (i) {
                case 0:
                  this.setState({
                    currentSlide: 'first',
                  });
                  break;
                case detailSubmissionURLs.length - 1:
                  this.setState({
                    currentSlide: 'last',
                  });
                  break;
                default:
                  this.setState({
                    currentSlide: 'normal',
                  });
                  break;
              }
            }
          };
        }
        const social_share = blok.social_share && blok.social_share[0];

        if (social_share && social_share_image) {
            social_share.weibo_image = social_share_image;
        }

        const voteClassName = `${previouslyVoted ? ' voted-modal' : ' '}${voteSubmitted || confirmSubmit ? ' submit-modal' : ' '}${this.state.showDetails || this.state.showVoteSummary ? ' detail-modal' : ''}`

        const isEmpty = !submissions.length;
        return (
            <SbEditable content={blok}>
              <div className={`vote-editorial-wrapper ${lang}`}>
                <div className="vote-editorial editorial row" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                    <div className="et center-center thirty-three" style={{ height:"100px", background:"url(/assets/icons/loading-indicator.svg) 50% no-repeat" }}>
                        
                    </div>
                </div>
                {/* 投票列表 */}
                <div className="vote-editorial editorial row" style={{ display: !this.state.loading ? 'flex' : 'none' }}>
                    { submissions.map((submission, index) =>
                      <div className={`et center-center twenty-five ${enablePointsCheck && submission.points === 0 ? 'empty-card-wrapper' : 'common-card-wrapper'}`} key={'vote-card-' + index}>
                        <div className="vote-card">
                            <div className="vote-picture-frame" > 
                              <div className="left-frame">
                                <button style={ { backgroundImage : `url('${ urlHelper.ugcPhoto(submission.urls[0], 'small') }')`}} onClick={ () => this.showDetails(index, 0) } value={submission.id} >
                                </button>               
                              </div>
                              <div className="right-frame">
                                <button style={ { marginBottom: '4px', backgroundImage : `url('${ urlHelper.ugcPhoto(submission.urls[1], 'small') }')`}} onClick={ () => this.showDetails(index, 1) } value={submission.id} >
                                </button>
                                <button style={ { backgroundImage : `url('${ urlHelper.ugcPhoto(submission.urls[2], 'small') }')`}} onClick={ () => this.showDetails(index, 2) } value={submission.id} >
                                </button>               
                              </div>
                            </div>
                            <div className="author-name">
                              {`${submission.firstname} ${submission.surname}`}
                            </div>
                            <div className="vote-info">
                                <div className="vote-stars">
                                  {
                                    Array.from({length: MAX_POINT}).map((it, idx) => {
                                      const point = MAX_POINT - idx;
                                      return (
                                        <>
                                          <input
                                            type="radio"
                                            value={submission.points}
                                            name={`rate-${submission.id}`}
                                            id={`r-${submission.id}-${point}`}
                                            checked={submission.points === point}
                                            onChange={() => this.setVote(index, point)} 
                                          /> 
                                          <label htmlFor={`r-${submission.id}-${point}`} title={`${point}`}/>
                                        </>
                                      )
                                    })
                                  }
                                </div>

                            </div>
                        </div>
                    </div>
                    )}
                </div>  
                {/* 提交按钮 */}
                <div className="vote-editorial editorial column">
                    <div className="et center-center sixty-six">
                        <div className="cta-row">
                            <button className={`cta ${btnLoading ? 'loading' : ''} ${isEmpty ? 'disabled' : ''}`} onClick={this.handleSubmitButtonClick} disabled={isEmpty}>
                              { blok.submit_button_text ? blok.submit_button_text : t('form.submit', lang) }
                            </button>
                        </div>  
                        {this.showErrorTips && <div className="error-tips">{blok.error_tips_text}</div>}
                    </div>
                </div>
                <Modal ariaHideApp={false} className={`modal ${ this.state.showDetails || this.state.showVoteSummary ? 'fullscreen' : ''}${voteClassName}`} overlayClassName="overlay" isOpen={ (this.state.previouslyVoted || this.state.voteSubmitted || this.state.showDetails || this.state.showVoteSummary || this.state.confirmSubmit) || false } contentLabel="">
                    {/* 是否展示投过票的弹窗 */}
                    { this.state.previouslyVoted  &&
                        <div className="modal__content" >
                            <div className=" editorial column">
                                <div className="et center-center sixty-six" dangerouslySetInnerHTML={ MarkDown(blok.previously_voted_text) } >
                                </div>
                            </div>
                            <div className="et center-center sixty-six">
                                <div className="cta-row">
                                    <button className={`cta  `} onClick={ this.closeAndReturn } >{ blok.close_button_text ? blok.close_button_text : t('menu.close', lang) }</button>
                                </div>  
                            </div>
                        </div>
                    }
                    {/* 确认提交弹窗 */}
                    { this.state.confirmSubmit  &&
                        <div className="modal__content" >
                            <div className=" editorial column">
                              <div className="et center-center sixty-six" dangerouslySetInnerHTML={ MarkDown(blok.confirm_submit_content) } >
                              </div>
                            </div>
                            <div className="et center-center sixty-six">
                                <div className="cta-row">
                                    <button className="confirm-btn" onClick={ this.submitVotes } >{ blok.confirm_btn_text ? blok.confirm_btn_text : t('menu.submit', lang) }</button>
                                    <button className="back-btn" onClick={ this.handleCancelSubmit } >{ blok.back_btn_text ? blok.back_btn_text : t('form.close', lang) }</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 提交结束弹窗 */}
                    { this.state.voteSubmitted  &&
                        <div className="modal__content" >
                            <div className=" editorial column">
                              <img className="submit-success-icon" src="/assets/icons/submit-success.svg" alt="" />
                              <div className="et center-center sixty-six" dangerouslySetInnerHTML={ MarkDown(blok.vote_submitted) } >
                              </div>
                              <div className="et center-center sixty-six social-share-row">
                                <SocialShare {...social_share} lang={lang} />
                              </div>
                            </div>
                            <div className="et center-center sixty-six">
                                <div className="cta-row">
                                    <button className={`cta  `} onClick={ this.closeAndReturn } >{ blok.close_button_text ? blok.close_button_text : t('menu.close', lang) }</button>
                                </div>  
                            </div>
                        </div>
                    }
                    {/* 是否展示轮播 */}
                    { this.state.showDetails && 
                        <div className="modal__content">
                            <div className=" editorial row" style={{ padding : 0}}>
                                <div className="et center-center full-width">
                                    <button className="close mobile-show" onClick={() => { this.setState({ showDetails : false })}}>
                                      <img alt="" style={{ margin: 0}} src="/assets/icons/close-icon.svg" />
                                    </button>
                                    {
                                      detailSubmissionURLs.length > 0 ? (
                                        <Slider ref={(c) => {this.mySwiper = c;} } {...settings} className={`current-slide-in-${currentSlide}`}>
                                          { this.state.detailSubmissionURLs && this.state.detailSubmissionURLs.map((url, index) => 
                                              <div key={index} className={`vote-picture-frame vote-detail-navitem ${ this.state.detailActivePhoto === index ? 'active' : '' }` }>
                                                <div className="slide-item-wrapper">
                                                  <img src={`${ urlHelper.ugcPhoto(url, 'large') }`}/>
                                                </div>
                                              </div>
                                            )
                                          }
                                        </Slider>
                                      ) : null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    }
                    
                </Modal>
              </div>
            </SbEditable>
        )
    }
}

